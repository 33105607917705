/* eslint-disable no-console */
import React, { Component } from "react";
import { OperationContext } from "../../../operation-context";
import { PropTypes } from "prop-types";
import BaseSettings from "./BaseSettings";
import OverridesGrid from "./OverridesGrid";
import { getCatalogFromMakeVariantMap } from "../../../../../../constants/ModuleConstants";
import * as gtmEvents from "../../../../../utils/gtag-eventlist";
import { toEmptyStringIfUndefined } from "../../../../../../commonUtil/utils/string";
class PricingOpcode extends Component {
  static contextType = OperationContext;

  constructor(props, context) {
    super(props, context);
    const { operation } = props;
    const { make, globalRepairOpFlag, laborTimeRange } = operation;
    const { makeVariantMap } = context.appContext;
    const { pricingMethod } = getCatalogFromMakeVariantMap(
      makeVariantMap,
      make
    );
    const showIncludeOEMParts =
      globalRepairOpFlag ||
      (!!laborTimeRange && make !== "ANY" && pricingMethod === 1);
    this.state = { operation, pricingMethod, showIncludeOEMParts };
  }

  componentDidMount() {
    // GTM - push pageview event to dataLayer
    gtmEvents.trackGtagPageview("/operations/operations/pricing-opcodes");
  }

  render() {
    const { operation, pricingMethod } = this.state;
    const { dealer, locale } = this.context.appContext;
    const { commonOpsAllowAnyPriceOverrides } = dealer;
    const countryPrefix = locale.substr(3, 2);
    const isPricingSupportForAny =
      commonOpsAllowAnyPriceOverrides && countryPrefix === "US";
    const { make } = operation;
    const baseSettings =
      pricingMethod === 0 ? (
        ""
      ) : (
        <BaseSettings
          operation={operation}
          updatePriceStatus={this.props.updatePriceStatus}
        />
      );
    const overrideGrid =
      make === "ANY" && !isPricingSupportForAny ? (
        ""
      ) : (
        <OverridesGrid
          operation={operation}
          showIncludeOEMParts={this.state.showIncludeOEMParts}
          updatePriceStatus={this.props.updatePriceStatus}
        />
      );

    return (
      <div>
        {baseSettings}
        {overrideGrid}
      </div>
    );
  }
}

export default PricingOpcode;

PricingOpcode.propTypes = {
  operation: PropTypes.object,
  updatePriceStatus: PropTypes.func
};
