/* eslint-disable no-alert */
/* eslint-disable no-console */
import React from "react";
import Tabs from "@cx/ui/Tabs";
import Tab from "@cx/ui/Tab";
import SearchableSelect from "@cx/ui/SearchableSelect";
import { PropTypes } from "prop-types";
import VehicleSelector from "./content/VehicleSelector";
import RecentPreview from "./content/selectors/RecentPreview";
import Favorites from "./content/selectors/Favorites";
// import CommonVehicles from "./content/selectors/CommonVehicles";
import {
  isDifferentValue,
  toNumberZeroIfUndefined
} from "../../../commonUtil/utils/string";
import PreviewContext from "./preview-context";
// import LoadingIndicator from "@cx/ui/LoadingIndicator";

class PreviewTabs extends React.Component {
  static contextType = PreviewContext;
  constructor(props, context) {
    super(props, context);

    this.favoritesRef = React.createRef();
    this.previewTabs = React.createRef();
    this.recentPreviewRef = React.createRef();
    this.vehicleSelectorRef = React.createRef();

    this.setPreviewTabs = this.setPreviewTabs.bind(this);
    this.onActivePreviewTab = this.onActivePreviewTab.bind(this);
    this.onCancelClick = this.onCancelClick.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onUpdate = this.onUpdate.bind(this);
    this.getActiveTab = this.getActiveTab.bind(this);
    this.setActiveTab = this.setActiveTab.bind(this);
    const { localeStrings } = context.appContext;
    this.initializeLocaleValues(localeStrings);
    const { preview } = context.appContext;
    const { previewTabs } = preview;
    const { selectedIndex } = previewTabs;
    this.state = {
      // activeIndex: 0,
      favoriteCount: 0,
      recentPreviewCount: 0,
      commonServicedCount: 0,
      selectedIndex,
      selectedMake: "all",
      previewedMakes: this.getFilteredByMakes()
    };
  }
  componentDidMount() {
    // window.addEventListener("mousedown", this.handleClickOutside);
    window.addEventListener(
      "onActivePreviewTab",
      this.onActivePreviewTab,
      false
    );
    // this.setActiveTab(2);
  }
  componentWillUnmount() {
    // window.removeEventListener("mousedown", this.handleClickOutside);
    window.removeEventListener(
      "onActivePreviewTab",
      this.onActivePreviewTab,
      false
    );
  }

  initializeLocaleValues(localeStrings) {
    this.selectVehicleLabel =
      localeStrings["xmm.portal.preview.vehicle.select_vehicle_lbl"];
    this.recentlyPreviewLabel =
      localeStrings["xmm.portal.preview.vehicle.recently_previewed_lbl"];
    this.favoriteLsLabel =
      localeStrings["xmm.portal.preview.vehicle.favorites_lbl"];
    this.filterByMakeLabel =
      localeStrings["xmm.portal.preview.vehicle.filter_by_make_lbl"];
  }

  /*
  // Feature - to close container when clicked outside its scope
  handleClickOutside = event => {
    const { parentHandle, showCancel } = this.props;
    if (
      parentHandle &&
      parentHandle.wrapperRef &&
      !parentHandle.wrapperRef.contains(event.target) &&
      showCancel
    ) {
      this.context.showChangeVehicle(false);
    }
  };
  */
  onActivePreviewTab = event => {
    const { detail } = event;
    const { name } = detail;
    this.setState({ selectedTab: name });
  };

  getFilteredByMakes = () => {
    const { localeStrings, makelist } = this.context.appContext;
    const previewedMakes = makelist.map(make => {
      //
      // const { label, value } = make;
      return make;
    });
    previewedMakes.splice(0, 0, {
      label: localeStrings["xmm.portal.common.all_makes"],
      value: "all"
    });
    return previewedMakes;
  };

  filterByMake = make => {
    const { selectedTab } = this.state;
    switch (selectedTab) {
      case "RecentPreview":
        this.recentPreviewRef &&
          this.recentPreviewRef.current.filterByMake(make);
        break;
      case "Favorites":
        this.favoritesRef && this.favoritesRef.current.filterByMake(make);
        break;
      case "VehicleSelector":
      default:
        if (make !== "all") {
          this.vehicleSelectorRef &&
            this.vehicleSelectorRef.current.filterByMake(make);
        }
        break;
    }
  };

  setPreviewTabs = previewSettings => {
    // const { previewSettings } = this.context;
    const { favorites, recentlyPreview } = previewSettings;
    // const previewedMakes = this.getFilteredByMakes(recentlyPreview);
    // previewedMakes.splice(0, 0, { label: "All Makes", value: "all" });
    const favoriteCount = favorites.length;
    const recentlyPreviewCount = recentlyPreview.length;
    this.setState({ favoriteCount, recentlyPreviewCount }, () => {
      if (this.favoritesRef && this.favoritesRef.current) {
        this.favoritesRef.current.loadVehicles();
      }
    });
  };

  /* common handler to hide "Change vehicle" container */
  onCancelClick = event => {
    event.preventDefault();
    this.context.showChangeVehicle(false);
  };

  onChange = (cxEvent, isValid, domEvent) => {
    const { value } = cxEvent.target;
    const optionValue = value && value.length !== 0 ? value[0].value : "";
    const { selectedMake } = this.state;
    if (isDifferentValue(selectedMake, optionValue)) {
      const selectedMake = optionValue;
      this.setState({ selectedMake });
      const { selectedMakeObj } = this.context;
      selectedMakeObj.selectedMake = selectedMake;
      this.filterByMake(selectedMake);
    }
  };

  onUpdate = event => {
    const { vehicleCountName, vehicleCount } = event.detail;
    this.setState({ [vehicleCountName]: vehicleCount });
    this.props.onUpdate();
  };

  getActiveTab = () => {
    if (this.previewTabs && this.previewTabs.current) {
      return this.previewTabs.current.state.activeIndex;
    }
    return 0;
  };
  setActiveTab = activeIndex => {
    if (this.previewTabs && this.previewTabs.current) {
      // console.log("previewTabs", previewTabs);
      this.previewTabs.current.setState({ activeIndex });
      this.setState({ activeIndex });
    }
  };

  render() {
    /*
    const loadmask = (
      <div className="xmm-mask-preview">
        <div className="xmm-mask-chart-container" />
        <LoadingIndicator htmlId="previewMask" size="large" />
        <div className="xmm-mask-content-container" />
      </div>
    );
    */
    const { preview } = this.context.appContext;
    const { previewTabs } = preview;
    const { selectedIndex } = previewTabs;
    const active = selectedIndex;
    const {
      favoriteCount,
      recentlyPreviewCount,
      previewedMakes,
      selectedMake
    } = this.state;
    const favoriteTabLabel = `${this.favoriteLsLabel} (`.concat(
      toNumberZeroIfUndefined(favoriteCount),
      ")"
    );
    const recentlyPreviewTabLabel = `${this.recentlyPreviewLabel} (`.concat(
      toNumberZeroIfUndefined(recentlyPreviewCount),
      ")"
    );
    // const activeIndex = this.getActiveTab();
    return (
      <div>
        <Tabs
          htmlId="PreviewTabs"
          ref={this.previewTabs}
          onActivate={selectedIndex => {
            console.log(selectedIndex);
            previewTabs.selectedIndex = selectedIndex;
            this.setState({ selectedIndex });
          }}
        >
          <Tab active={active === 0} label={this.selectVehicleLabel}>
            <VehicleSelector
              ref={this.vehicleSelectorRef}
              // selectedIndex={0}
              renderFrom="changeVehicle"
              closeModal={this.onCancelClick}
              showCancel={this.props.showCancel || true}
            />
          </Tab>
          <Tab active={active === 1} label={recentlyPreviewTabLabel}>
            <RecentPreview
              ref={this.recentPreviewRef}
              // selectedIndex={1}
              showCancel={this.props.showCancel}
              onUpdate={this.onUpdate}
              closeModal={this.onCancelClick}
            />
          </Tab>
          <Tab active={active === 2} label={favoriteTabLabel}>
            <Favorites
              ref={this.favoritesRef}
              // selectedIndex={2}
              showCancel={this.props.showCancel}
              onUpdate={this.onUpdate}
              closeModal={this.onCancelClick}
            />
          </Tab>
          {/* <Tab label="Common vehicles serviced (0)">
            <CommonVehicles
              showCancel={this.props.showCancel}
              closeModal={this.onCancelClick}
            />
          </Tab> */}
          {/* <Tab label="Mask Test (0)">{loadmask}</Tab> */}
        </Tabs>
        <div className="xmm-tab-right-content">
          <SearchableSelect
            // displayPlaceholder={false}
            // ref="typeahead"
            className="xmm-scrollable-select"
            htmlId="filterByMakeSelect"
            label={this.filterByMakeLabel}
            name="filterByMakeSelect"
            maxHeight={200}
            enableMultiSelect={false}
            onChange={this.onChange}
            options={previewedMakes}
            value={selectedMake}
            displayLabel={true}
            // layout="horizontal"
          />
        </div>
      </div>
    );
  }
}
export default PreviewTabs;

PreviewTabs.propTypes = {
  showCancel: PropTypes.bool,
  onUpdate: PropTypes.func,
  parentHandle: PropTypes.object
};

/* eslint-enable no-console */
