/* import globalOperationsService from "../services/global-operations/global-operations-service"; */
import {
  GlobalOpsServiceType,
  IncludedInspectionTags
} from "./search.constants";
import has from "lodash/has";
import isEmpty from "lodash/isEmpty";
import operationDetailsSchema from "../schemas/operation-details.schema.js";
import { convertHoursToMinutes } from "./quote.util";
const formatService = (service, globalOperationDetails) => {
  console.log(
    "search formatService- service/globalOperationDetails",
    service,
    globalOperationDetails
  );
  const rawRecord = service.rawRecord;
  let formattedService = null;

  if (rawRecord.operationSource) {
    let globalOpsService = globalOperationDetails;
    globalOpsService = operationDetailsSchema.cast(globalOpsService);
    const rawService = JSON.stringify(globalOpsService);

    if (rawRecord.operationSource === GlobalOpsServiceType.DEALERCATALOG) {
      if (!isEmpty(globalOpsService.laborApps)) {
        let operation = globalOpsService.laborApps[0];
        const laborHours = has(operation, "laborHours")
          ? operation.laborHours || 0
          : 0;
        // @note: convert hours to minutes, send this laborMins in quote payload
        operation.laborMinutes = convertHoursToMinutes(laborHours);
        operation = updateOperationObject(operation, rawRecord);
        globalOpsService.laborApps[0] = operation;
      }
    } else {
      globalOpsService = updateOperationObject(globalOpsService, rawRecord);
      // to be updated when user can select parts & labors
      globalOpsService = {
        ...globalOpsService,
        id: rawRecord.operationId,
        name: rawRecord.operationName,
        parts: has(globalOpsService, "parts") ? globalOpsService.parts : []
      };
    }
    formattedService = {
      ...globalOpsService,
      id: rawRecord.operationId,
      serviceKind: rawRecord.serviceKind,
      operationSource: rawRecord.operationSource,
      quoteRawService: { rawService }
    };
  } else {
    const rawService = JSON.stringify(rawRecord);
    formattedService = {
      ...rawRecord,
      serviceId: has(rawRecord, "id") ? rawRecord.id.toString() : "",
      quoteRawService: { rawService }
    };
  }
  console.log("search - formattedService", formattedService);
  return formattedService;
};

const updateOperationObject = (operation, source) => {
  const operationService = {
    ...operation,
    categoryId: source.categoryId,
    categoryName: source.categoryName,
    categoryGroupId: source.categoryGroupId,
    categoryGroupName: source.categoryGroupName,
    serviceKind: source.serviceKind,
    operationSource: source.operationSource
  };
  return operationService;
};

const isIncludedInspection = service => {
  const { price, serviceCategoryName } = service;
  if (price && price !== 0) {
    return false;
  }
  if (!serviceCategoryName) {
    return false;
  }
  const serviceCategoryLowercase = serviceCategoryName.toLowerCase();
  for (let index = 0; index < IncludedInspectionTags.length; index++) {
    if (
      serviceCategoryLowercase.indexOf(IncludedInspectionTags[index]) !== -1
    ) {
      return true;
    }
  }
  return false;
};

export default {
  formatService,
  isIncludedInspection
};
