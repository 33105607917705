/* eslint-disable no-console */
import React, { Component } from "react";
import { PropTypes } from "prop-types";
import Button from "@cx/ui/Button";
import { toast } from "@cx/ui/Toast";
import { isEmptyString } from "../../../commonUtil/utils/string";
import { isArrayExist } from "../../../commonUtil/utils/object";
import { AgGridReact } from "ag-grid-react";
import { makeSecureRestApi } from "../../../api/xmmAxios";
import CustomLoadingOverlay from "../../../commonUtil/components/loadingmask/CustomLoadingOverlay";
import { AppContext } from "../../../components/app-context";
import Row from "@cx/ui/Row";
import Col from "@cx/ui/Col";
import * as gtmEvents from "../../utils/gtag-eventlist";
import { xlate } from "../../../commonUtil/i18n/locales";

class ShowVehiclesGrid extends Component {
  static contextType = AppContext;
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.disabled !== prevState.isActionDisabled) {
      return {
        isActionDisabled: nextProps.disabled
      };
    }
    return null;
  }

  constructor(props, context) {
    super(props, context);
    this.showVehiclesWidget = this.showVehiclesWidget.bind(this);
    this.onFirstDataRendered = this.onFirstDataRendered.bind(this);
    const localeStrings = context.localeStrings;
    const gridOptions = {
      showGrid: false,
      isActionDisabled: props.disabled,
      vehiclesList: null,
      columnDefs: this.getColumnList(localeStrings),
      defaultColDef: {
        floatingFilter: false, // true - enable column header filters
        sortable: true,
        resizable: true,
        editable: false,
        filter: true,
        suppressMenu: true,
        sortingOrder: ["desc", "asc", null],
        headerComponentParams: {
          template: `
          <div class="ag-cell-label-container" role="presentation">
            <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
            <div ref="eLabel" class="ag-header-cell-label" role="presentation">
              <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
              <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
              <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>
              <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>
              <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>
            </div>
          </div>
          `
        },
        minWidth: 80,
        autoHeight: true,
        rowGroup: false
      },
      rowData: null,
      multiSortKey: "ctrl",
      frameworkComponents: {
        customLoadingOverlay: CustomLoadingOverlay,
        customNoRowsOverlay: CustomLoadingOverlay
      },
      loadingOverlayComponent: "customLoadingOverlay",
      loadingOverlayComponentParams: {
        loadingMessage: "Loading",
        isLoading: true,
        noRows: false
      },
      noRowsOverlayComponent: "customNoRowsOverlay",
      noRowsOverlayComponentParams: {
        loadingMessage: xlate("xmm.portal.common.no_records_msg"),
        isLoading: false,
        noRows: true
      },
      // Note: Set locale strings in this localeText {} for ag-grid controls
      localeText: {
        filteredRows: localeStrings["xmm.portal.ag_grid.filteredRows"],
        selectedRows: localeStrings["xmm.portal.ag_grid.selectedRows"],
        totalRows: localeStrings["xmm.portal.ag_grid.totalRows"],
        totalAndFilteredRows:
          localeStrings["xmm.portal.ag_grid.totalAndFilteredRows"],
        noRowsToShow: localeStrings["xmm.portal.ag_grid.noRowsToShow"]
      },
      sideBar: "filters",
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent", align: "left" }
        ]
      }
    };
    this.state = gridOptions;
  }
  onFirstDataRendered(params) {
    this.sizeToFit();
  }

  showVehiclesWidget = event => {
    event.preventDefault();
    gtmEvents.gtmTrackEvent("xmm.vehiclegroups.show_vehicles_click");
    this.setState(
      {
        showGrid: true,
        isActionDisabled: true
      },
      () => {
        // callback to load vehicles
        this.loadVehiclesData();
      }
    );
  };
  applySortConfig() {
    const defaultSortModel = [
      {
        colId: "model",
        sortIndex: 0,
        sort: "asc"
      },
      {
        colId: "modelYear",
        sortIndex: 1,
        sort: "asc"
      }
    ];
    // this.gridApi && this.gridApi.setSortModel(defaultSortModel);
    this.assignColumnState(defaultSortModel);
  }
  // Sort columns ascending and clear sort on all other columns
  assignColumnState = defaultSortModel => {
    this.gridColumnApi &&
      this.gridColumnApi.applyColumnState({
        state: defaultSortModel,
        defaultState: {
          // important to say 'null' as undefined means 'do nothing'
          sort: null
        }
      });
  };
  // Not Used
  autoSizeCols() {
    const allColumnIds = [];
    if (this.gridColumnApi) {
      this.gridColumnApi.getAllColumns().forEach(function (column) {
        allColumnIds.push(column.colId);
      });
      this.gridColumnApi.autoSizeColumns(allColumnIds);
    }
  }
  sizeToFit() {
    this.gridApi && this.gridApi.sizeColumnsToFit();
  }
  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.closeToolPanel();
    this.applySortConfig();
    this.sizeToFit();
  };

  reset() {
    this.setState({
      vehiclesList: null,
      isActionDisabled: false,
      showGrid: false
    });
  }

  updateState(data) {
    if (data.error) {
      toast.error(data.error);
    } else if (data && data.response) {
      let metavehicles = [];
      // console.log("show vehicles response", data);
      if (
        data.response.metaVehicles &&
        data.response.metaVehicles.metaVehicle
      ) {
        const datalist = data.response.metaVehicles.metaVehicle;
        // check if response has single object or array of objects
        if (!isArrayExist(datalist) && typeof datalist === "object") {
          metavehicles.push(datalist);
        } else if (isArrayExist(datalist) && datalist.length > 0) {
          metavehicles = datalist;
        }
        if (isArrayExist(metavehicles) && metavehicles.length === 0) {
          // show 'no rows' overlay
          this.gridApi && this.gridApi.showNoRowsOverlay();
        } else {
          // clear all overlays
          this.gridApi && this.gridApi.hideOverlay();
        }
        this.setState({
          vehiclesList: metavehicles,
          isActionDisabled: false
        });
      } else {
        this.setState(
          {
            vehiclesList: [],
            isActionDisabled: false
          },
          () => {
            this.gridApi && this.gridApi.showNoRowsOverlay();
          }
        );
      }
    }
  }

  loadVehiclesData() {
    const payload = this.props.payloadFunc();
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      "dds-json-conversion-style": "camel-case"
    };
    this.gridApi && this.gridApi.showLoadingOverlay();
    const restEndPoint =
      "/ops/proxyapi/ddsproxy/rest/proc/findMetaVehDetWFilterAttr";
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "post",
        data: payload,
        params: {},
        headers
      },
      data => {
        if (data) this.updateState(data);
      },
      error => {
        toast.error(error.message);
        this.setState({
          isActionDisabled: false
        });
        this.gridApi && this.gridApi.showNoRowsOverlay();
      }
    );
  }

  getColumnList(localeStrings) {
    const baseCols = [
      {
        headerName: localeStrings["xmm.portal.showvehicles.grid.model"],
        field: "model",
        headerClass: "ag-text-header",
        autoHeight: true,
        sortingOrder: ["asc", "desc"],
        filter: "agSetColumnFilter",
        filterParams: {
          buttons: ["clear"]
        },
        width: 120,
        minWidth: 100
      },
      {
        headerName: localeStrings["xmm.portal.showvehicles.grid.year"],
        field: "modelYear",
        headerClass: "ag-text-header",
        sortingOrder: ["asc", "desc"],
        filter: "agSetColumnFilter",
        filterParams: {
          buttons: ["clear"]
        },
        width: 80,
        minWidth: 60,
        maxWidth: 100
      },
      {
        headerName: localeStrings["xmm.portal.showvehicles.grid.attributes"],
        field: "engineType",
        headerClass: "ag-text-header",
        cellClass: "xmm-wrap-cell",
        valueGetter: attributesValueGetter,
        width: 400,
        minWidth: 350,
        filter: "agTextColumnFilter",
        filterParams: {
          buttons: ["clear"]
        },
        cellStyle: {
          color: "black",
          textAlign: "left"
        }
      }
    ];
    return baseCols;
  }

  render() {
    const showGridClass = this.state.showGrid
      ? "xmm-find-opcode-container ag-theme-balham"
      : "hide";
    const gridWidget = (
      <div id="showVehiclesGrid" className={showGridClass}>
        <AgGridReact
          localeText={this.state.localeText}
          columnDefs={this.state.columnDefs}
          defaultColDef={this.state.defaultColDef}
          suppressMenuHide={false}
          rowData={this.state.vehiclesList}
          onGridReady={this.onGridReady}
          frameworkComponents={this.state.frameworkComponents}
          loadingOverlayComponent={this.state.loadingOverlayComponent}
          loadingOverlayComponentParams={
            this.state.loadingOverlayComponentParams
          }
          noRowsOverlayComponent={this.state.noRowsOverlayComponent}
          noRowsOverlayComponentParams={this.state.noRowsOverlayComponentParams}
          animateRows={true}
          statusBar={this.state.statusBar}
          enableRangeSelection={false}
          enableCellTextSelection={true}
          enableBrowserTooltips={false}
          sideBar={this.state.sideBar}
          multiSortKey={this.state.multiSortKey}
          onFirstDataRendered={this.onFirstDataRendered}
        />
      </div>
    );
    return (
      <React.Fragment>
        <Row>
          <Col xs={11} md={11} className="xmm-vehicles-grid">
            <Button
              htmlId="showVehiclesBtn"
              buttonStyle="secondary"
              disabled={this.state.isActionDisabled}
              onClick={this.showVehiclesWidget}
              size="small"
            >
              {this.context.localeStrings["xmm.portal.showvehicles.btn_lbl"]}
            </Button>
            {gridWidget}
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default ShowVehiclesGrid;

ShowVehiclesGrid.propTypes = {
  disabled: PropTypes.bool,
  payloadFunc: PropTypes.func
};

function attributesValueGetter(params) {
  const record = params.data;
  // const val = params.data ? params.data.parentId | 0 : null;
  let newValue = "";
  if (!isEmptyString(record.engineType)) {
    newValue += record.engineType + ", ";
  }
  if (!isEmptyString(record.engineSize)) {
    newValue += record.engineSize + ", ";
  }
  if (!isEmptyString(record.driveType)) {
    newValue += record.driveType + ", ";
  }
  if (!isEmptyString(record.transmissionType)) {
    newValue += record.transmissionType + ", ";
  }
  if (!isEmptyString(record.bodyTypeName)) {
    newValue += record.bodyTypeName + ", ";
  }
  if (!isEmptyString(record.fuelType)) {
    newValue += record.fuelType + ", ";
  }
  // concat specified columns values
  if (!isEmptyString(newValue) && typeof newValue === "string") {
    newValue = newValue.trim();
    return newValue.substring(0, newValue.length - 1);
  } else {
    return "-";
  }
}
