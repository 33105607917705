/* eslint-disable no-console */
import React from "react";
import Button from "@cx/ui/Button";
import ModalDialog from "@cx/ui/ModalDialog";
import { PropTypes } from "prop-types";
import { FormattedMessage } from "react-intl";
import VehicleGroupSelector from "../../../../../reusable/VehicleGroups/VehicleGroupSelector";
import { AppContext } from "../../../../../app-context";
import { makeSecureRestApi } from "../../../../../../api/xmmAxios";
// import { toast } from "@cx/ui/Toast";
import { xlate } from "../../../../../../commonUtil/i18n/locales";

export default class CopyToVehicleGroupModal extends React.Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);
    this.createDealerRules = this.createDealerRules.bind(this);
    this.initializeLocaleValues();
    const { make } = props;
    const { dealerCode, makeVariantMap } = context;
    const { variant } = makeVariantMap[make];
    const vehicleGroup = {
      make, // in
      variant, // in
      dealerCode, // in
      metaVehicleScope: "1", // in
      metaVehicleFilterId: ""
    };
    this.state = {
      dealerOperations: [],
      serviceIdMap: {},
      errors: {},
      vehicleGroup
    };
  }

  componentDidMount() {}
  initializeLocaleValues() {
    this.savingMsg = xlate("xmm.portal.common.saving");
    this.savedMsg = xlate("xmm.portal.common.saved");
  }

  onBlur = event => {
    //
  };
  onChange = event => {
    const { data } = event.detail;
    if (data) {
      const { metaVehicleFilterId, metaVehicleScope } = data;
      const { vehicleGroup } = this.state;
      if (metaVehicleScope === "1") {
        vehicleGroup.metaVehicleFilterId = "";
      } else {
        vehicleGroup.metaVehicleFilterId = metaVehicleFilterId;
      }
      // this.markDirty("metaVehicleFilterId", true);
      this.setState({ vehicleGroup });
    }
  };
  getPayload = () => {
    const { vehicleGroup } = this.state;
    const { metaVehicleFilterId } = vehicleGroup;
    const { dealerScheduleRules } = this.props;
    const ruleIds = dealerScheduleRules.map(rule => {
      return rule.dealerScheduleRuleId.toString();
    });
    return { dealerScheduleRuleIds: ruleIds, metaVehicleFilterId };
  };
  createDealerRules = () => {
    const { updateStatusBox } = this.props;
    updateStatusBox(this.savingMsg, "pending", false);
    const data = this.getPayload();
    const method = "post";
    const url = "/ops/proxyapi/ddsproxy/rest/proc/copyToVehGrp";
    makeSecureRestApi(
      {
        url,
        method,
        data
      },
      dataList => {
        console.log(dataList);
        this.props.updateDealerMenuGrid();
        updateStatusBox(this.savedMsg, "success", true);
      },
      error => {
        // toast.error(error.message);
        updateStatusBox(error.message, "error", false, true);
      }
    );
  };

  render() {
    const { closeDialog } = this.props;
    const { errors, vehicleGroup } = this.state;

    return (
      <div>
        <ModalDialog
          htmlId="copyToVehicleGroupModal"
          // className="sample-modal-dialog"
          show={this.props.show}
          header={
            <ModalDialog.Title>
              <FormattedMessage
                defaultMessage="Copy Schedule Rules to another Vehicle Group"
                id="xmm.portal.dealermenus.copy_to_vehicle_group"
              />
            </ModalDialog.Title>
          }
          footer={
            <div>
              <Button
                htmlId="cancelCopyButton"
                onClick={closeDialog}
                text="Close Button"
                buttonStyle="secondary"
              >
                <FormattedMessage
                  defaultMessage="Cancel"
                  id="xmm.portal.common.cancel_button"
                />
              </Button>
              <Button
                htmlId="okCopyButton"
                onClick={() => {
                  this.createDealerRules();
                  closeDialog();
                }}
                text="Select"
                buttonStyle="primary"
                disabled={!vehicleGroup.metaVehicleFilterId}
              >
                <FormattedMessage
                  defaultMessage="Copy"
                  id="xmm.portal.common.copy_label"
                />
              </Button>
            </div>
          }
          onHide={this.props.closeDialog}
        >
          <VehicleGroupSelector
            // ref="vehicleGroupSelectorRef"
            autoLoad={true}
            data={vehicleGroup}
            error={errors.metaVehicleFilterId}
            context={this.context}
            onBlur={this.onBlur}
            onChange={this.onChange}
          />
        </ModalDialog>
      </div>
    );
  }
}

CopyToVehicleGroupModal.propTypes = {
  show: PropTypes.bool,
  copyToVehicleGroup: PropTypes.func,
  updateDealerMenuGrid: PropTypes.func,
  updateStatusBox: PropTypes.func,
  closeDialog: PropTypes.func,
  make: PropTypes.string,
  dealerScheduleRules: PropTypes.array
};
