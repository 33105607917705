const tagsMap = {
  tire1: "dt_st7_tire1 (1 tire)",
  tire2: "dt_st7_tire2 (2 tire)",
  tire3: "dt_st7_tire3 (3 tire)",
  tire4: "dt_st7_tire4 (4 tire)",
  tire5: "dt_st7_tire5 (5 tire)",
  tire6: "dt_st7_tire6 (6 tire)"
};
// @note - This mock part record used to merge dealer tire record
const mockPartTemplate = {
  partName: "",
  partType: "part",
  oemPartNumber: "",
  position: null,
  quantity: 0,
  exactQuantity: null,
  unitOfMeasure: null,
  unitPrice: 0,
  partsPrice: 0,
  priceSource: "MANUAL",
  priceType: "MANUAL",
  notes: null,
  status: "Current",
  vehicleAttributes: null,
  alternateParts: [],
  partNumberDefinedByDealerFlag: false,
  dtDmsPartCode: "",
  relationship: null,
  selected: true,
  oilType: null,
  gradeCode: null,
  specification: null,
  qualifiers: null,
  rowId: null,
  partId: null
};

const testVINs = [
  "WBAVD13576K106219",
  "WBAJE7C5XJWD52202",
  "5UXCR6C53KLK85872"
];
export { tagsMap, mockPartTemplate, testVINs };
