import React, { useState, useCallback, useEffect } from "react";
import Button from "@cx/ui/Button";
import AccordionGroup from "@cx/ui/AccordionGroup";
import isEmpty from "lodash/isEmpty";
import ButtonGroup from "../../components/button-group/ButtonGroup";
import { formatNumberWithLetter, formatPrice } from "../../utils/format.util";
import {
  useServiceSearchContext,
  Actions
} from "../../state/service-search.context";
import { SelectedMenuPage } from "./views";
import "./MenusQuickFilterPage.scss";
import formatCalculation from "../../utils/menu-service-calculation-util";
import { sortingMenuServices } from "../../utils/sorting.util.js";

const MenusQuickFilterPage = props => {
  const {
    EditServiceModule,
    applicationType, // csr specific application Type if application is CSR then applicationType will be CSR
    CSRSelectedMenuPage, // csr specific if applicationType is CSR then  CSRSelectedMenuPage will be used instead of selectedmenupage
    onAddMenuServiceClick // csr specific application Type if application is CSR then addMenuServiceClick handler will be used
  } = props;
  const { state, dispatch, ctxRequestServicePoints, ctxOnEditService } =
    useServiceSearchContext();
  const { vehicle, locale, selectedMenuPackage, servicePoints } = state;
  const [servicePointButtonOptions, setServicePointButtonOptions] = useState(
    []
  );
  const [showEditMenu, setShowEditMenu] = useState(false);
  const [accordionExpandedConfig, setAccordionExpandedConfig] = useState({});

  useEffect(() => {
    const sortedServicePoints = sortMenuPackages(servicePoints);
    createServicePointOptions(sortedServicePoints);
  }, [servicePoints]);

  const createServicePointOptions = _servicePoints => {
    const options = _servicePoints
      .map(servicePoint => {
        return {
          id: String(servicePoint.mileage),
          label: formatNumberWithLetter(servicePoint.mileage),
          rawRecord: servicePoint
        };
      })
      .sort((optionA, optionB) =>
        optionA.rawRecord.position > optionB.rawRecord.position ? 1 : -1
      );

    setServicePointButtonOptions(options);
  };

  const sortMenuPackages = menus => {
    const sortedMenus = menus.sort((optionA, optionB) =>
      optionA.position > optionB.position ? 1 : -1
    );
    return sortedMenus;
  };

  const handleButtonGroupLeftArrowClick = useCallback(
    async drivingCondition => {
      if (
        Array.isArray(servicePointButtonOptions) &&
        !isEmpty(servicePointButtonOptions) &&
        servicePointButtonOptions[0].rawRecord.mileage !== 0
      ) {
        const useExactMileage = 1;
        ctxRequestServicePoints({
          drivingCondition,
          locale,
          mileage: servicePointButtonOptions[0].rawRecord.mileage,
          useExactMileage
        });
      }
    },
    [servicePointButtonOptions, locale, ctxRequestServicePoints]
  );

  const handleButtonGroupRightArrowClick = useCallback(
    async drivingCondition => {
      if (
        Array.isArray(servicePointButtonOptions) &&
        !isEmpty(servicePointButtonOptions) &&
        servicePointButtonOptions[servicePointButtonOptions.length - 1]
          .rawRecord.mileage !== 0
      ) {
        const useExactMileage = 1;
        ctxRequestServicePoints({
          drivingCondition,
          locale,
          mileage:
            servicePointButtonOptions[servicePointButtonOptions.length - 1]
              .rawRecord.mileage,
          useExactMileage
        });
      }
    },
    [servicePointButtonOptions, locale, ctxRequestServicePoints]
  );

  const handleDrivingConditionChange = useCallback(
    async drivingCondition => {
      if (
        Array.isArray(servicePointButtonOptions) &&
        !isEmpty(servicePointButtonOptions) &&
        servicePointButtonOptions.length > 1 &&
        servicePointButtonOptions[1].rawRecord.mileage !== 0
      ) {
        const useExactMileage = 0;
        dispatch({
          type: Actions.SET_MENU_SELECTED_DRIVING_CONDITION,
          payload: drivingCondition
        });
        ctxRequestServicePoints({
          drivingCondition,
          locale,
          mileage: servicePointButtonOptions[1].rawRecord.mileage,
          useExactMileage
        });
      }
    },
    [servicePointButtonOptions, locale, ctxRequestServicePoints]
  );

  const handleButtonGroupOptionClick = useCallback(
    (optionIndex, drivingCondition) => {
      if (optionIndex === 0) {
        handleButtonGroupLeftArrowClick(drivingCondition);
      } else if (optionIndex === 2) {
        handleButtonGroupRightArrowClick(drivingCondition);
      }
    },
    [handleButtonGroupLeftArrowClick, handleButtonGroupRightArrowClick]
  );

  const addMileageToQuote = async (e, menuRecord) => {
    e.stopPropagation();
    ctxOnEditService(true);
    if (menuRecord && Object.keys(menuRecord).length > 0) {
      const rawService = JSON.stringify(menuRecord);
      const menuObj = { ...menuRecord, quoteRawService: { rawService } };
      menuObj.services = sortingMenuServices(menuRecord.services);

      dispatch({
        type: Actions.SET_MENU_PACKAGE,
        payload: menuObj
      });
      // TODO: update context to hide search header
      dispatch({
        type: Actions.SET_SEARCH_HEADER,
        payload: false
      });
      // show edit menu page
      setShowEditMenu(true);
      setAccordionExpandedConfig({});
    }
  };
  const updateLocalState = editFlag => {
    setShowEditMenu(editFlag);
  };

  const handleAccordionItemClick = optionIndex => {
    setAccordionExpandedConfig({
      ...accordionExpandedConfig,
      [optionIndex]: accordionExpandedConfig[optionIndex]
        ? !accordionExpandedConfig[optionIndex]
        : true
    });
  };

  const editPage =
    showEditMenu && !isEmpty(selectedMenuPackage) ? (
      applicationType === "CSR" ? ( // if application Type is CSR then CSR SelectedMenuPage is used from CSR specific search wrapper
        <CSRSelectedMenuPage
          onAddMenuServiceClick={onAddMenuServiceClick}
          applicationType={applicationType}
          updateLocalState={updateLocalState}
          selectedMenuPackage={
            formatCalculation.formatMenuPackage(selectedMenuPackage) || {}
          }
          EditServiceModule={EditServiceModule}
        />
      ) : (
        <SelectedMenuPage
          updateLocalState={updateLocalState}
          selectedMenuPackage={
            formatCalculation.formatMenuPackage(selectedMenuPackage) || {}
          }
          EditServiceModule={EditServiceModule}
        />
      )
    ) : null;
  const parentCls = showEditMenu ? "hide-ele" : "empty-cls";
  return (
    <>
      <div id="menusQuickFilterPageContainer" className={parentCls}>
        <div id="header">
          <ButtonGroup
            options={servicePointButtonOptions}
            onLeftArrowClick={handleButtonGroupLeftArrowClick}
            onRightArrowClick={handleButtonGroupRightArrowClick}
            onOptionClick={handleButtonGroupOptionClick}
            onDrivingConditionChange={handleDrivingConditionChange}
            vehicle={vehicle}
          />
          <div className="hide-ele">
            <Button
              htmlId="comparePackagesButton"
              type="button"
              buttonStyle="link"
              className="ss-string-link"
              size="sm"
            >
              Compare packages
            </Button>
          </div>
        </div>

        {servicePoints[1] ? (
          <AccordionGroup.Container
            htmlId="accordionGroupContainer"
            independent
          >
            {servicePoints[1].menuTypes.map((option, optionIndex) => {
              option.mileage = servicePoints[1].mileage;
              option.units = servicePoints[1].units;
              return (
                <AccordionGroup.Item
                  key={`menu-${option.id}`}
                  htmlId={`menu-${option.id}`}
                  className="accordionItem"
                  header={
                    <div className="header">
                      <span className="name">{option.description}</span>
                      <div className="options">
                        <span className="price">
                          {formatPrice(option.totalPrice)}
                        </span>
                        <div className="separator" />
                        <Button
                          htmlId={`selectMenu${option.id}`}
                          type="button"
                          size="sm"
                          onClick={e => addMileageToQuote(e, option)}
                        >
                          Select
                        </Button>
                      </div>
                    </div>
                  }
                  expanded={accordionExpandedConfig[optionIndex] || false}
                  onToggle={() => handleAccordionItemClick(optionIndex)}
                >
                  <ul>
                    {option.services.map(service => (
                      <li key={service.id}>
                        <span>{service.name}</span>
                      </li>
                    ))}
                  </ul>
                </AccordionGroup.Item>
              );
            })}
          </AccordionGroup.Container>
        ) : null}
      </div>
      {editPage}
    </>
  );
};

export default MenusQuickFilterPage;
