/* eslint-disable camelcase */
/* eslint-disable no-console */
import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { AgGridReact } from "ag-grid-react";
import { AppContext } from "../../../../components/app-context";
import CustomLoadingOverlay from "../../../../commonUtil/components/loadingmask/CustomLoadingOverlay";
import IconMore from "@cx/ui/Icons/IconMore";
// import DropdownButton from "react-bootstrap/lib/DropdownButton";
// import DropdownMenuItem from "@cx/ui/DropdownMenuItem";
import Dropdown from "@cx/ui/Dropdown";
import Tooltip from "@cx/ui/Tooltip";
import { isArrayExist, isEmpty } from "../../../../commonUtil/utils/object";
import {
  toEmptyStringIfUndefined,
  isEmptyString,
  isSameValue
} from "../../../../commonUtil/utils/string";
import PriceEditor from "../../../../commonUtil/editors/PriceEditor";
import TextEditor from "../../../../commonUtil/editors/TextEditor";
import {
  makeSecureRestApi,
  showBodyMask,
  hideBodyMask
} from "../../../../api/xmmAxios";
import {
  // floatParser,
  priceFormatter
} from "../../../../commonUtil/utils/formatter";
import { applyCustomKeyNavigation } from "../../../../commonUtil/utils/keyNavigation";
import { toast } from "@cx/ui/Toast";
import SelectInput from "@cx/ui/SelectInput";
import ImportDMSPartsPricingModal from "./ImportDMSPartsPricingModal";
import BulkEditPane from "./BulkEditPane";
import GenericSlider from "../../../../commonUtil/components/GenericSlider";
import AlertBox from "../../../../commonUtil/components/templates/AlertBox";
import { FormattedMessage } from "react-intl";
import Button from "@cx/ui/Button";
import {
  PartsPricingState,
  PriceLevel
} from "../../../../constants/ModuleConstants";
import Confirmation from "../../../../commonUtil/dialog/Confirmation";
import { PartsPricingContext } from "./parts-pricing-context";
import StatusBox from "../../../../commonUtil/components/templates/StatusBox";
import * as gtmEvents from "../../../utils/gtag-eventlist";
import { loadAgGridLocale } from "../../../../i18n/LocaleSender";
import { xlate } from "../../../../commonUtil/i18n/locales";
import { getCellClassRule } from "../../../../commonUtil/utils/validation";
import { formatDateTimezone } from "../../../../commonUtil/utils/date";

class PartsPricing extends Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);
    // Add Default filter to show only Calculated pricing workflow supported records.
    const supportedMakeList = context.makelist.filter(function (obj) {
      return obj.pricingMethod === 1;
    });
    // Bind grid functions in constructor
    this.getRowNodeId = this.getRowNodeId.bind(this);
    this.onCellClickedEvent = this.onCellClickedEvent.bind(this);
    this.onCellValueChanged = this.onCellValueChanged.bind(this);
    this.handleColumnResized = this.handleColumnResized.bind(this);
    this.onSearchBoxChanged = this.onSearchBoxChanged.bind(this);
    this.onVariantTypeChange = this.onVariantTypeChange.bind(this);
    this.openImportPartsModal = this.openImportPartsModal.bind(this);
    this.closeImportPartsModal = this.closeImportPartsModal.bind(this);
    this.openBulkEditSlider = this.openBulkEditSlider.bind(this);
    this.closeBulkEditSlider = this.closeBulkEditSlider.bind(this);
    this.updateGridAfterBulkEdit = this.updateGridAfterBulkEdit.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.onFilterChanged = this.onFilterChanged.bind(this);
    this.updateStatusBox = this.updateStatusBox.bind(this);
    this.onFirstDataRendered = this.onFirstDataRendered.bind(this);
    this.pullDMSParts = this.pullDMSParts.bind(this);
    this.closePullDMSPartsModal = this.closePullDMSPartsModal.bind(this);
    this.handleDMSPullParts = this.handleDMSPullParts.bind(this);
    this.loadGridData = this.loadGridData.bind(this);
    // bind other external actions
    const partsHeader = xlate("xmm.portal.nav.parts_pricing");
    this.importDmsPartsPricingLabel = xlate(
      "xmm.portal.common.import_dms_parts_pricing"
    );

    const { dealer, localeStrings, partManufacturerCodeMap } = context;
    thePartManufacturerCodeMap = partManufacturerCodeMap; // javascript map used in map
    const { commonOpsDtdmsPartsLookupEnabled, dmsType } = dealer;
    const commonOpsDtdmsPartsLookupEnabledFlag =
      commonOpsDtdmsPartsLookupEnabled && dmsType === "DEALERTRACK";
    const gridOptions = {
      commonOpsDtdmsPartsLookupEnabled: commonOpsDtdmsPartsLookupEnabledFlag,
      // other state props
      variantType: context.partsPricingGrid.variantType,
      searchKey: context.partsPricingGrid.searchKey,
      partsPricingGrid: context.partsPricingGrid,
      supportedMakeList,
      pageTitle: partsHeader,
      editOption: null, // set values as edit, add, delete
      operationName: "",
      flexWidth: false,
      showSlide: false,
      loadOperation: PartsPricingState,
      selectionlist: [],
      bulkEditSlide: false,
      bulkEditSliderWidth: 600,
      // ag-grid props
      rowData: null, // should be null - fix to skip "No records found" msg on grid load.
      columnDefs: this.getColumnList(
        localeStrings,
        commonOpsDtdmsPartsLookupEnabled
      ),
      defaultColDef: {
        floatingFilter: false, // true - enable column header filters
        sortable: true,
        resizable: true,
        editable: false, // default disable editor
        enableRowGroup: false,
        sortingOrder: ["asc", "desc", null],
        width: 120,
        autoHeight: true,
        filter: "agTextColumnFilter",
        headerComponentParams: {
          template: `
          <div class="ag-cell-label-container" role="presentation">
            <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
            <div ref="eLabel" class="ag-header-cell-label" role="presentation">
              <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
              <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
              <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>
              <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>
              <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>
            </div>
          </div>
          `
        },
        getQuickFilterText: params => {
          if (!params.column.visible) {
            return null;
          } else {
            return params.value;
          }
        },
        suppressKeyboardEvent: applyCustomKeyNavigation,
        cellClassRules: getCellClassRule(["price"], [], []),
        rowGroup: false
      },
      multiSortKey: "ctrl",
      components: {
        iconCellRendererMSRP,
        iconCellRendererDMS,
        iconCellRendererManual,
        descriptionCellRenderer,
        partNumberCellRenderer
      },
      frameworkComponents: {
        customLoadingOverlay: CustomLoadingOverlay,
        customNoRowsOverlay: CustomLoadingOverlay,
        textEditor: TextEditor,
        priceEditor: PriceEditor
      },
      loadingOverlayComponent: "customLoadingOverlay",
      loadingOverlayComponentParams: {
        loadingMessage: localeStrings["xmm.portal.common.loading_msg"],
        isLoading: true,
        noRows: false
      },
      noRowsOverlayComponent: "customNoRowsOverlay",
      noRowsOverlayComponentParams: {
        loadingMessage: localeStrings["xmm.portal.common.no_records_msg"],
        isLoading: false,
        noRows: true
      },
      // Note: Set locale strings in this localeText {} for ag-grid controls
      // localeText: {
      //   filteredRows: localeStrings["xmm.portal.ag_grid.filteredRows"],
      //   selectedRows: localeStrings["xmm.portal.ag_grid.selectedRows"],
      //   totalRows: localeStrings["xmm.portal.ag_grid.totalRows"],
      //   totalAndFilteredRows:
      //     localeStrings["xmm.portal.ag_grid.totalAndFilteredRows"],
      //   noRowsToShow: localeStrings["xmm.portal.ag_grid.noRowsToShow"]
      // },
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left"
          },
          {
            statusPanel: "agFilteredRowCountComponent"
          },
          {
            statusPanel: "agSelectedRowCountComponent",
            align: "left"
          }
        ]
      },
      // true - use browser default tooltip instead of ag-grid tooltip
      enableCellChangeFlash: true,
      enableBrowserTooltips: true,
      columnTypes: {
        priceColumn: {
          editable: false,
          valueFormatter: priceFormatter,
          // valueParser: floatParser,
          cellStyle: {
            color: "black",
            textAlign: "right"
          }
        },
        numberColumn: {
          maxWidth: 120,
          minWidth: 120,
          filter: "agNumberColumnFilter",
          filterParams: {
            includeBlanksInEquals: false,
            includeBlanksInLessThan: false,
            includeBlanksInGreaterThan: false,
            buttons: ["clear"]
          }
        },
        nonEditableColumn: { editable: false },
        noFilterColumn: {
          width: 100,
          columnGroupShow: "open",
          filter: false
        }
      },
      rowSelection: "multiple",
      isRowSelectable(rowNode) {
        return true; // to see checkbox
        // return rowNode.data ? rowNode.data.make !== "ANY" : false;
      },
      localeText: loadAgGridLocale(localeStrings),
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: localeStrings["xmm.portal.ag_grid.columns"],
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
            toolPanelParams: {
              suppressPivots: true,
              suppressPivotMode: true,
              suppressValues: true,
              suppressRowGroups: true
            }
          },
          {
            id: "filters",
            labelDefault: localeStrings["xmm.portal.ag_grid.filters"],
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
          }
        ],
        showImportPartsModal: false,
        hiddenByDefault: false
      },
      showpullDMSPartsModal: false,
      pullingDMSParts: false,
      showDMSColumns: false
    };
    this.state = gridOptions;
  }

  /**
   * Add event listeners
   * when an instance of a component is being created and inserted into the DOM
   * component that’s being rendered for that route would be able to access fromDashboard by using props.location.state
   */
  componentDidMount() {
    // console.log("parts props", this.context);
    this.getPartsRequestStatus();
  }
  /**
   * Remove event listeners
   * when a component is being removed from the DOM
   */
  componentWillUnmount() {
    this.saveGridState();
    if (this.dmsStatusTimeout) {
      clearTimeout(this.dmsStatusTimeout);
    }
  }
  /* Action to save ag-grid {column, filter, pivot, sort} to local state
   */
  saveGridState() {
    const { searchKey, variantType } = this.state;
    if (this.gridApi && this.gridColumnApi) {
      const partsPricingGrid = {
        colState: this.gridColumnApi.getColumnState(),
        pivotState: this.gridColumnApi.isPivotMode(),
        filterState: this.gridApi.getFilterModel(),
        searchKey,
        variantType
      };
      console.log("save filter", partsPricingGrid);
      this.setState({
        partsPricingGrid
      });
      this.context.setPartsPricingGridState(partsPricingGrid);
    }
  }
  /* This Util called to restore ag-grid controls,filters,sorters from app-context when re-visited page */
  restoreGridState() {
    const { colState, filterState, pivotState, searchKey, variantType } =
      this.state.partsPricingGrid;
    if (colState && this.gridApi && this.gridColumnApi) {
      this.gridColumnApi.setColumnState(colState);
      this.gridColumnApi.setPivotMode(pivotState);
      this.assignColumnState(colState);
      this.gridApi.setFilterModel(filterState);
      this.setState(
        {
          searchKey,
          variantType
        },
        prevState => {
          this.gridApi.setQuickFilter(searchKey);
          console.log(
            "parts grid state",
            this.state.partsPricingGrid,
            this.gridApi.getFilterModel()
          );
        }
      );
    }
  }
  onFirstDataRendered(params) {
    this.restoreGridState();
    this.sizeToFit();
  }
  // bind to add PartsPricing click event
  addPartsPricingSlider = event => {
    event.preventDefault();
    this.setState(prevState => ({
      showSlide: !prevState.showSlide,
      editPartsPricing: "add",
      loadPartsPricing: PartsPricingState,
      partsPricingName: "Add Parts Pricing"
    }));
  };
  /* handler for open bulkdedit slider */
  openBulkEditSlider = event => {
    event.preventDefault();
    this.setState(prevState => ({
      bulkEditSlide: !prevState.bulkEditSlide
    }));
  };
  closeBulkEditSlider = event => {
    if (event) {
      event.preventDefault();
    }
    this.setState(prevState => ({
      bulkEditSlide: !prevState.bulkEditSlide
    }));
    this.gridApi.redrawRows(this.params);
  };
  handleColumnResized = () => {
    this.gridApi.resetRowHeights();
  };
  handleCsvImport = (make, file) => {
    const { localeStrings } = this.context;
    this.setState({ showImportPartsModal: false });
    this.gridApi.showLoadingOverlay();
    const { dealerCode, makeVariantMap } = this.context;
    const { variant } = makeVariantMap[make];
    const formData = new FormData();
    formData.append("file", file);
    const headers = {
      "Content-Type": "multipart/form-data"
    };
    this.updateStatusBox(
      localeStrings["xmm.portal.common.saving"],
      "pending",
      false
    );
    makeSecureRestApi(
      {
        url: "ops/upload/batchUpdateParts",
        method: "post",
        data: formData,
        params: { dealerCode, make, variant },
        headers
      },
      response => {
        this.gridApi.hideOverlay();
        if (!response.success) {
          this.updateStatusBox(
            this.context.localeStrings["xmm.portal.errors.import_error"],
            "error",
            false,
            true
          );
        } else {
          this.updateStatusBox(
            this.context.localeStrings["xmm.portal.common.saved"],
            "success",
            true
          );

          // toast.success(
          //   response.data + " Part Numbers updated with DMS Pricing",
          //   {
          //     autoClose: 10000
          //   }
          // );
          setTimeout(() => {
            this.refreshParts();
          }, 3000);
        }
      },
      error => {
        toast.error(error.message);
        this.gridApi.hideOverlay();
      }
    );
    // GTM - push click event to dataLayer
    gtmEvents.gtmTrackEvent("xmm.partspricing.import_click");
  };
  /* IMP - this function required for CRUD operations, to get RowNode */
  getRowNodeId(data) {
    return data.dealerPartId; // primary or unique key of record
  }
  sizeToFit() {
    this.gridApi && this.gridApi.sizeColumnsToFit();
  }
  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.loadGridData();
    this.gridApi.closeToolPanel();
    this.applySortConfig();
    this.sizeToFit();
  };
  updateState(data) {
    if (data) {
      let datalist = [];
      if (!isArrayExist(data) && typeof data === "object") {
        datalist.push(data);
      } else if (isArrayExist(data) && data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          if (isEmpty(data[i]["description"])) {
            data[i]["description"] = data[i]["oemDescription"];
          }
          if (isEmpty(data[i]["partNumber"])) {
            data[i]["partNumber"] = data[i]["oemPartNumber"];
          }
        }
        datalist = data;
      }
      if (isArrayExist(datalist) && datalist.length === 0) {
        // show 'no rows' overlay
        this.gridApi && this.gridApi.showNoRowsOverlay();
      } else {
        // clear all overlays
        this.gridApi && this.gridApi.hideOverlay();
      }
      let variantType = "ALL";
      // Read variant type if passed from Dashbaord
      const { variantFilters } = this.context;
      if (variantFilters && Object.keys(variantFilters).length > 0) {
        variantType = variantFilters.variantType;
      }
      this.setState(
        {
          rowData: datalist,
          variantType
        },
        prevState => {
          this.sizeToFit();
          if (variantFilters && Object.keys(variantFilters).length > 0) {
            this.setMakeModel(variantFilters.make);
            this.applyStatusFilter(null, variantFilters.variantType);
            // reset variantFilter in App context
            this.context.updateVariantFilters(null);
          }
        }
      );
    }
  }
  loadGridData() {
    const { dealerCode } = this.context;
    const payload = {
      dealerCode
    };
    const restUrl = `/ops/proxyapi/ddsproxy/rest/table/dealerPart?partType=part&enabled=1`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };
    // this.gridApi && this.gridApi.showLoadingOverlay();
    showBodyMask();
    makeSecureRestApi(
      {
        url: restUrl,
        method: "get",
        data: {},
        params: payload,
        headers
      },
      data => {
        if (data) {
          // Add Default filter to show only Calculated pricing workflow supported records.
          const filterList = data.filter(function (obj) {
            return obj.pricingMethod === 1;
          });
          hideBodyMask();
          this.updateState(filterList);
        }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error while fetching data for this dealer.  Please try again later.";
        toast.error(msg, {
          closeOnClick: true
        });
        this.gridApi && this.gridApi.showNoRowsOverlay();
        hideBodyMask();
      }
    );
  }
  applySortConfig() {
    const defaultSortModel = [
      {
        colId: "make",
        sortIndex: 0,
        sort: "asc"
      },
      {
        colId: "oemDescription",
        sortIndex: 1,
        sort: "asc"
      },
      {
        colId: "partNumber",
        sortIndex: 2,
        sort: "asc"
      }
    ];
    // this.gridApi && this.gridApi.setSortModel(defaultSortModel);
    this.assignColumnState(defaultSortModel);
  }
  assignColumnState = defaultSortModel => {
    this.gridColumnApi &&
      this.gridColumnApi.applyColumnState({
        state: defaultSortModel,
        defaultState: {
          // important to say 'null' as undefined means 'do nothing'
          sort: null
        }
      });
  };
  callRefreshAfterMillis(params, gridApi) {
    gridApi.redrawRows(params);
    setTimeout(function () {
      gridApi.refreshCells(params);
    }, 30);
  }
  /* "cellClicked" event handler fired on specific columns */
  onCellClickedEvent(params) {
    // do nothing for now
  }
  // This event fired after a cell has been changed with default editing
  onCellValueChanged(params) {
    // console.log(params.oldValue, params.newValue);
    if (
      toEmptyStringIfUndefined(params.oldValue) ===
      toEmptyStringIfUndefined(params.newValue)
    ) {
      return;
    } else {
      if (params.colDef.field === "price") {
        if (params.newValue === "") {
          params.data.source = "MSRP";
        } else {
          params.data.source = "MANUAL";
        }
      }
      if (this.validateField(params)) {
        this.clearFieldValidation(params);
        this.onSaveCellEdit(params);
      }
    }
  }
  onSaveCellEdit(params) {
    this.updateStatusBox(
      this.context.localeStrings["xmm.portal.common.saving"],
      "pending",
      false
    );
    this.savePayload(params, params.value);
  }
  /* celledit handler to save edits */
  savePayload(gridParams, value) {
    const record = gridParams.data;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };
    const restEndPoint = `/ops/proxyapi/ddsproxy/rest/table/dealerPart/${record.dealerPartId}/?_method=put`;
    const payload = this.getCellEditPayload(record, value);
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "post",
        data: payload,
        params: {},
        headers
      },
      response => {
        if (!response.success) {
          this.updateStatusBox(
            this.context.localeStrings["xmm.portal.errors.save_error"],
            "error",
            false,
            true
          );
        } else {
          this.updateStatusBox(
            this.context.localeStrings["xmm.portal.common.saved"],
            "success",
            true
          );

          const { dealerPart } = response;
          const { field } = gridParams.colDef;
          if (field === "description" || field === "partNumber") {
            if (isEmpty(dealerPart[field])) {
              dealerPart[field] =
                field === "description"
                  ? dealerPart.oemDescription
                  : dealerPart.oemPartNumber;
            }
          }
          Object.assign(gridParams.data, dealerPart);
          this.gridApi.applyTransaction({
            update: [gridParams.data]
          });

          // const { field } = gridParams.colDef;
          // this.refreshCell(record, field);
          this.callRefreshAfterMillis(gridParams, this.gridApi);
        }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error saving your changes.";
        toast.error(msg, {
          closeOnClick: true
        });
      }
    );
  }
  getCellEditPayload(record, value) {
    let payload = {};

    payload = {
      source: record.source,
      price: record.price,
      description: record.description,
      partNumber: record.partNumber,
      dealerPartId: record.dealerPartId,
      mfrCode: record.mfrCode
    };

    return payload;
  }
  /* "filterChanged" - listen to the column filter events; can be used to  clear column filters */
  onFilterChanged = params => {
    if (this.gridApi) {
      this.clearGridSelections();
    }
  };
  /* Un-select all rows, regardless of filtering from grid */
  clearGridSelections = () => {
    if (this.gridApi) {
      this.gridApi.deselectAll();
      this.setState({ selectionlist: [] });
    }
  };
  // Quick filter handler
  onSearchBoxChanged = event => {
    if (event) {
      event.preventDefault();
    }
    if (this.gridApi) {
      const searchKey = document.querySelector(
        "#parts-pricing-search-box"
      ).value;
      this.gridApi.setQuickFilter(searchKey);
      this.clearGridSelections();
      this.setState({
        searchKey
      });
    }
  };
  onVariantTypeChange = (cxEvent, isValid, domEvent) => {
    const { value, name } = cxEvent.target;
    this.setState(
      {
        [name]: value
      },
      prevState => {
        this.applyStatusFilter(cxEvent, value);
        this.sizeToFit();
      }
    );
  };
  refreshParts = () => {
    this.loadGridData();
  };
  // This Make Filter used when Parts page launched from Dashboard
  setMakeModel(filterVal) {
    if (this.gridApi) {
      const makeFilter = this.gridApi.getFilterInstance("make");
      if (filterVal && filterVal === "all") {
        makeFilter.setModel(null);
      } else {
        const data = [];
        data.push(filterVal);
        const model = {
          type: "set",
          values: data
        };
        makeFilter.setModel(model);
      }
    }
  }
  applyStatusFilter = (event, filterVal) => {
    if (this.gridApi) {
      const statusAddedFilter = this.gridApi.getFilterInstance("variantAdded");
      const statusChangedFilter =
        this.gridApi.getFilterInstance("variantChanged");
      const statusOverriddenFilter =
        this.gridApi.getFilterInstance("overriddenParts");
      if (filterVal && filterVal === "ADDED") {
        statusAddedFilter.setModel({
          type: "startsWith",
          filter: "1"
        });
        statusChangedFilter.setModel({
          type: "startsWith",
          filter: ""
        });
        statusOverriddenFilter.setModel({
          type: "startsWith",
          filter: ""
        });
      }

      if (filterVal && filterVal === "CHANGED") {
        statusChangedFilter.setModel({
          type: "startsWith",
          filter: "1"
        });
        statusAddedFilter.setModel({
          type: "startsWith",
          filter: ""
        });
        statusOverriddenFilter.setModel({
          type: "startsWith",
          filter: ""
        });
      }
      if (filterVal === "ALL") {
        statusChangedFilter.setModel({
          type: "startsWith",
          filter: ""
        });
        statusAddedFilter.setModel({
          type: "startsWith",
          filter: ""
        });
        statusOverriddenFilter.setModel({
          type: "startsWith",
          filter: ""
        });
      }
      if (filterVal === "OVERRIDDEN") {
        statusChangedFilter.setModel({
          type: "startsWith",
          filter: ""
        });
        statusAddedFilter.setModel({
          type: "startsWith",
          filter: ""
        });
        statusOverriddenFilter.setModel({
          type: "startsWith",
          filter: "1"
        });
      }
      // gridApi to run filter on operations
      this.gridApi.onFilterChanged();
    }
  };
  /* Action event to clear column filters */
  clearFilters() {
    if (this.gridApi) {
      const filterModel = this.gridApi.getFilterModel();
      if (filterModel) {
        // console.log("before clear> fitler", filterModel);
        this.gridApi.setFilterModel(null);
      }
      this.gridApi.onFilterChanged();
      document.querySelector("#parts-pricing-search-box").value = "";
      this.gridApi.setQuickFilter("");
    }
  }
  getColumnList(localeStrings, commonOpsDtdmsPartsLookupEnabled) {
    const baseCols = [
      {
        headerName: "",
        // suppressColumnsToolPanel: true, // hide item in sidebar.columns
        children: [
          {
            headerName: "",
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            checkboxSelection: true,
            pinned: "left",
            field: "dealerPartId",
            filter: false,
            suppressSizeToFit: true,
            suppressColumnsToolPanel: true, // hide item in sidebar.columns
            maxWidth: 37,
            minWidth: 37,
            width: 37
          },
          {
            headerName: localeStrings["xmm.portal.grid.make"],
            headerClass: "ag-text-header",
            cellClass: "xmm-wrap-cell",
            field: "make",
            sortingOrder: ["asc", "desc"],
            suppressColumnsToolPanel: true, // hide item in sidebar.columns
            maxWidth: 150,
            minWidth: 100,
            width: 100,
            editable: false,
            valueFormatter(params) {
              return params.value;
            },
            filter: "agSetColumnFilter",
            filterParams: {
              buttons: ["clear"]
            }
          },
          {
            headerName:
              localeStrings["xmm.portal.parts_pricing.grid_description"],
            headerClass: "ag-text-header",
            field: "description",
            editable: true,
            cellClass: "editable-cell",
            suppressColumnsToolPanel: true, // hide item in sidebar.columns
            maxWidth: 500,
            minWidth: 120,
            width: 120,
            sortingOrder: ["asc", "desc"],
            valueFormatter(params) {
              return params.value;
            },
            cellRenderer: "descriptionCellRenderer",
            filter: "agSetColumnFilter",
            filterParams: {
              buttons: ["clear"]
            }
          },
          {
            headerName:
              localeStrings["xmm.portal.parts_pricing.grid_part_number"],
            headerClass: "ag-text-header",
            field: "partNumber",
            editable: true,
            cellClass: "editable-cell",
            valueFormatter(params) {
              return params.value;
            },
            cellRenderer: "partNumberCellRenderer",
            suppressColumnsToolPanel: true, // hide item in sidebar.columns
            maxWidth: 500,
            minWidth: 120,
            width: 120,
            filterParams: {
              buttons: ["clear"]
            }
          },
          {
            headerName:
              localeStrings["xmm.portal.parts_pricing.grid_manufacturer_code"],
            field: "mfrCode",
            editable: true,
            headerClass: "ag-text-header",
            cellClass: "editable-caret-cell",
            filterParams: {
              cellHeight: 0
            },
            cellEditorSelector(params) {
              return {
                component: "agRichSelectCellEditor",
                params: {
                  values: getPartManufacturerCodeValues(
                    params,
                    thePartManufacturerCodeMap
                  )
                }
              };
            },
            maxWidth: 150,
            minWidth: 130
          },
          {
            headerName:
              localeStrings["xmm.portal.parts_pricing.grid_quantity_available"],
            field: "quantityOnHand",
            suppressColumnsToolPanel: !commonOpsDtdmsPartsLookupEnabled,
            suppressFiltersToolPanel: !commonOpsDtdmsPartsLookupEnabled,
            headerClass: "ag-numeric-header",
            cellClass: "xmm-grid-price",
            cellStyle: { "text-align": "right" },
            type: "numberColumn",
            hide: !commonOpsDtdmsPartsLookupEnabled,
            filterParams: {
              cellHeight: 0
            },
            maxWidth: 130,
            minWidth: 100
            // width: 60
          },
          {
            headerName:
              localeStrings["xmm.portal.parts_pricing.grid_part_price_level"],
            field: "source",
            editable: true,
            headerClass: "ag-text-header",
            cellClass: "editable-caret-cell",
            cellEditorSelector(params) {
              return {
                component: "agRichSelectCellEditor",
                params: {
                  values: extractValues(params)
                }
              };
            },
            refData: PriceLevel,
            filter: "agSetColumnFilter",
            filterParams: {
              buttons: ["clear"]
            },
            suppressColumnsToolPanel: true, // hide item in sidebar.columns
            maxWidth: 250,
            minWidth: 120,
            width: 120
          }
        ]
      },
      {
        headerName: localeStrings["xmm.portal.parts_pricing.grid_group_header"],
        children: [
          {
            headerName: localeStrings["xmm.portal.grid.msrp"],
            field: "msrp",
            editable: false,
            headerClass: "ag-numeric-header",
            // cellClass: "xmm-wrap-cell xmm-grid-price",
            // cellStyle: { "text-align": "right" },
            cellRenderer: "iconCellRendererMSRP",
            type: "numberColumn",
            valueFormatter: priceFormatter,
            maxWidth: 130,
            minWidth: 60,
            width: 60
          },
          {
            headerName: localeStrings["xmm.portal.parts_pricing.grid_DMSPrice"],
            field: "dmsPrice",
            editable: false,
            headerClass: "ag-numeric-header",
            // cellClass: "xmm-wrap-cell xmm-grid-price",
            // cellStyle: { "text-align": "right" },
            cellRenderer: "iconCellRendererDMS",
            type: "numberColumn",
            valueFormatter: priceFormatter,
            maxWidth: 130,
            minWidth: 60,
            width: 60
          },
          {
            headerName: localeStrings["xmm.portal.parts_pricing.grid_Price"],
            field: "price",
            colId: "price",
            editable: true,
            headerClass: "ag-numeric-header",
            cellEditor: "priceEditor",
            // cellClass: "editable-cell xmm-grid-price",
            // cellStyle: { "text-align": "right" },
            cellRenderer: "iconCellRendererManual",
            cellEditorParams: { keepInvalidValue: true },
            type: "numberColumn",
            maxWidth: 130,
            minWidth: 60,
            width: 60
          }
        ]
      },
      {
        // "New Parts",
        headerName:
          localeStrings["xmm.portal.parts_pricing.status_new_parts_only"],
        field: "variantAdded",
        hide: true,
        suppressColumnsToolPanel: true,
        suppressFiltersToolPanel: true,
        filterParams: {
          cellHeight: 0
        }
      },
      {
        // "Changed Parts",
        headerName:
          localeStrings["xmm.portal.parts_pricing.status_changed_parts_only"],
        field: "variantChanged",
        hide: true,
        suppressColumnsToolPanel: true,
        suppressFiltersToolPanel: true,
        filterParams: {
          cellHeight: 0
        }
      },
      {
        // "Overridden Parts",
        headerName:
          localeStrings["xmm.portal.parts_pricing.status_changed_parts_only"],
        field: "overriddenParts",
        valueGetter: overriddenPartFormatter,
        hide: true,
        suppressColumnsToolPanel: true,
        suppressFiltersToolPanel: true,
        filterParams: {
          cellHeight: 0
        }
      },
      {
        headerName: localeStrings["xmm.portal.parts_pricing.grid_dms_cost"],
        field: "dmsCost",
        suppressColumnsToolPanel: !commonOpsDtdmsPartsLookupEnabled,
        suppressFiltersToolPanel: !commonOpsDtdmsPartsLookupEnabled,
        headerClass: "ag-numeric-header",
        type: "priceColumn",
        cellClass: "xmm-grid-price",
        cellStyle: { "text-align": "right" },
        hide: true,
        filterParams: {
          cellHeight: 0
        },
        maxWidth: 130,
        minWidth: 100
      },
      {
        headerName: localeStrings["xmm.portal.parts_pricing.grid_dms_message"],
        field: "dmsMessage",
        suppressColumnsToolPanel: !commonOpsDtdmsPartsLookupEnabled,
        suppressFiltersToolPanel: !commonOpsDtdmsPartsLookupEnabled,
        hide: true,
        filterParams: {
          cellHeight: 0
        },
        maxWidth: 300,
        minWidth: 150
      }
    ];
    return baseCols;
  }
  handleRowSelected = event => {
    //  console.log(
    //    `dealer part id: ${
    //      event.node.data.dealerPartId
    //    } is selected=${event.node.selected}`,
    //    event.node
    //  );
  };
  // when bulkedit saved saved from slider, callback to update records of selected rows in operation grid
  updateGridAfterBulkEdit = (results, columns) => {
    if (this.gridApi && !isEmpty(results)) {
      this.gridApi.applyTransaction({ update: results });
      const rowNodes = results.map(data =>
        this.gridApi.getRowNode(data.dealerPartId)
      );
      this.gridApi.flashCells({ rowNodes, columns });
      this.context.updateOperationAfterSave("bulk", results);
    }
  };
  /* This selection handler returns selected records from grid */
  handleSelectionChanged = event => {
    if (this.gridApi) {
      const selectedRows = this.gridApi.getSelectedRows();
      this.setState({ selectionlist: selectedRows });
    }
  };

  openImportPartsModal = event => {
    this.setState({
      showImportPartsModal: true
    });
  };

  closeImportPartsModal = event => {
    this.setState({ showImportPartsModal: false });
    if (this.importDMSPartsPricingRef) {
      this.importDMSPartsPricingRef.current.initializeStates();
    }
  };
  /* These Utils used for grid validation style */
  updateStatusBox(msg, type, close, errorInTooltip) {
    // console.log("status", msg, type, close);
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
    sleep(0).then(() => {
      this.setState({
        statusMsg: msg,
        autoClose: close,
        statusType: type,
        errorInTooltip
      });
    });
  }
  setFieldValidation(operation, field, errorKey) {
    const { localeStrings } = this.context;
    if (!operation.errors) {
      operation.errors = {};
    }
    operation.errors[field] = localeStrings[errorKey];
    this.refreshCell(operation, field);
    this.updateStatusBox(operation.errors[field], "error", false, true);
  }
  refreshCell(record, field) {
    const rowNode =
      this.gridApi && this.gridApi.getRowNode(record.dealerPartId);
    if (rowNode) {
      const params = {
        // don't do force since cell would be flashed as well
        // force: true,
        columns: [field],
        rowNodes: [rowNode]
      };
      this.gridApi.refreshCells(params);
    }
  }
  clearFieldValidation(params) {
    const { field } = params.colDef;
    const operation = params.data;
    if (operation && operation.errors && operation.errors[field]) {
      operation.errors[field] = "";
    }
  }
  validateField(params) {
    const { colDef, data, newValue } = params;
    const field = colDef ? colDef.field : null;
    let errorKey = null;
    if (data && field) {
      switch (field) {
        case "price":
          if (newValue && (parseFloat(newValue) || 0) > 9999.99) {
            errorKey = "xmm.portal.errors.exceed_max_price";
          }
          break;
        default:
          break;
      }
    }
    if (errorKey) {
      this.setFieldValidation(data, field, errorKey);
      this.callRefreshAfterMillis(params, this.gridApi);
    }
    return !errorKey;
  }
  pullDMSParts() {
    this.setState({ showpullDMSPartsModal: true, pullingDMSParts: false });
  }
  closePullDMSPartsModal = () => {
    this.setState({ showpullDMSPartsModal: false });
  };
  getGridColumns() {
    const { showDMSColumns } = this.state;
    this.gridColumnApi.applyColumnState({
      state: [
        {
          colId: "dmsCost",
          hide: !showDMSColumns
        },
        {
          colId: "quantityOnHand",
          hide: !showDMSColumns
        },
        {
          colId: "dmsMessage",
          hide: !showDMSColumns
        }
      ]
    });
  }
  getPartsRequestStatus() {
    const { dealerCode } = this.context;
    makeSecureRestApi(
      {
        url: `/ops/proxyapi/ddsproxy/rest/proc/getDmsRequestStatus?dealerCode=${dealerCode}`,
        method: "get"
      },
      response => {
        if (response && response.length) {
          const { status, statusDetail, modTime } = response[0];
          switch (status) {
            case "QUEUED": {
              this.setState({ pullingDMSParts: true, showDMSColumns: false });
              this.dmsStatusTimeout = setTimeout(() => {
                this.getPartsRequestStatus();
              }, 60000);
              break;
            }
            case "ERROR": {
              this.dmsStatusTimeout = null;
              let lastDmsPullStatusDetail = statusDetail;
              if (!lastDmsPullStatusDetail) {
                lastDmsPullStatusDetail =
                  "Error in pulling DMS Parts Pricing & Inventory.";
              }
              this.setState({
                pullingDMSParts: false,
                showDMSColumns: false,
                lastDmsPullStatusDetail
              });
              // toast.error(errorMessage);
              break;
            }
            case "SUCCESS": {
              const { pullingDMSParts } = this.state;
              if (pullingDMSParts) {
                this.dmsStatusTimeout = null;
                this.loadGridData();
              }
              const { locale } = this.context;
              const dateStr = formatDateTimezone(modTime, true, locale);
              const lastDmsPullStatusDetail = xlate(
                "xmm.portal.parts_pricing.dms_pull_on_date"
              ).replace("%1", dateStr);
              this.setState({
                pullingDMSParts: false,
                showDMSColumns: true,
                lastDmsPullStatusDetail
              });
              break;
            }
            default: {
              break;
            }
          }
        } else {
          this.setState({ pullingDMSParts: false });
        }
        // this.getGridColumns();
      },
      error => {
        const msg = error["message"] ? error.message : `error `;
        toast.error(msg, {
          closeOnClick: true
        });
      }
    );
  }
  handleDMSPullParts() {
    const { dealerCode } = this.context;
    makeSecureRestApi(
      {
        url: `/ops/proxyapi/ddsproxy/rest/proc/createDmsRequest?dealerCode=${dealerCode}`,
        method: "get"
      },
      response => {
        this.getPartsRequestStatus();
      },
      error => {
        const msg = error["message"] ? error.message : `error `;
        toast.error(msg, {
          closeOnClick: true
        });
      }
    );
    this.setState({ showpullDMSPartsModal: false });
  }

  renderPullDMSPartsButton() {
    const {
      commonOpsDtdmsPartsLookupEnabled,
      lastDmsPullStatusDetail,
      pullingDMSParts
    } = this.state;
    const pullDMSPartLblBtn = xlate("xmm.portal.parts.pull_dms_parts");

    let pullDMSPartsButton = "";
    if (lastDmsPullStatusDetail) {
      pullDMSPartsButton = (
        <Tooltip
          htmlId="tipRight"
          tooltipContent={lastDmsPullStatusDetail}
          position="right"
        >
          <Button
            htmlId="pullDMSParts"
            buttonStyle="primary"
            disabled={pullingDMSParts}
            onClick={this.pullDMSParts}
            hidden={!commonOpsDtdmsPartsLookupEnabled}
          >
            {pullDMSPartLblBtn}
          </Button>
        </Tooltip>
      );
    } else {
      pullDMSPartsButton = (
        <Button
          htmlId="pullDMSParts"
          buttonStyle="primary"
          disabled={pullingDMSParts}
          onClick={this.pullDMSParts}
          hidden={!commonOpsDtdmsPartsLookupEnabled}
        >
          {pullDMSPartLblBtn}
        </Button>
      );
    }
    return pullDMSPartsButton;
  }

  render() {
    const { localeStrings } = this.context;
    const contextValue = {
      selectionlist: this.state.selectionlist,
      dealerPartId: this.state.dealerPartId,
      appContext: this.context,
      updateGridAfterBulkEdit: this.updateGridAfterBulkEdit
    };
    const importDMSPartsPricingModal = (
      <ImportDMSPartsPricingModal
        ref={this.importDMSPartsPricingRef}
        show={this.state.showImportPartsModal}
        importParts={this.handleCsvImport}
        closeDialog={this.closeImportPartsModal}
        makesList={this.state.supportedMakeList}
        title={this.importDmsPartsPricingLabel}
      />
    );
    const gridWidget = (
      <div id="grid-wrapper">
        <div id="mainGrid" className="ag-grid-container ag-theme-balham">
          <AgGridReact
            localeText={this.state.localeText}
            columnDefs={this.state.columnDefs}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            suppressMenuHide={false}
            suppressContextMenu={true}
            rowData={this.state.rowData}
            rowSelection={this.state.rowSelection}
            rowDeselection={true}
            singleClickEdit={true}
            stopEditingWhenCellsLoseFocus={true}
            animateRows={true}
            onGridReady={this.onGridReady}
            frameworkComponents={this.state.frameworkComponents}
            loadingOverlayComponent={this.state.loadingOverlayComponent}
            loadingOverlayComponentParams={
              this.state.loadingOverlayComponentParams
            }
            noRowsOverlayComponent={this.state.noRowsOverlayComponent}
            noRowsOverlayComponentParams={
              this.state.noRowsOverlayComponentParams
            }
            statusBar={this.state.statusBar}
            components={this.state.components}
            onCellClicked={this.onCellClickedEvent}
            onCellValueChanged={this.onCellValueChanged}
            onColumnResized={this.handleColumnResized}
            getRowNodeId={this.getRowNodeId}
            onRowSelected={this.handleRowSelected}
            onSelectionChanged={this.handleSelectionChanged}
            sideBar={this.state.sideBar}
            columnTypes={this.state.columnTypes}
            multiSortKey={this.state.multiSortKey}
            enableRangeSelection={false}
            enableCellTextSelection={true}
            enableBrowserTooltips={true}
            // enableCellChangeFlash={true}
            // enterMovesDownAfterEdit={true}
            // enterMovesDown={true}
            rowHeight={35}
            onFilterChanged={this.onFilterChanged}
            onFirstDataRendered={this.onFirstDataRendered}
          />
        </div>
      </div>
    );
    const statusHtml = this.state.statusMsg ? (
      <StatusBox
        htmlId="statusBox"
        type={this.state.statusType}
        autoClose={this.state.autoClose}
        linkHtml={null}
        message={this.state.statusMsg}
        // autoCloseTime={1500}
        errorInTooltip={this.state.errorInTooltip}
      />
    ) : (
      ""
    );
    const pullDMSPartsMsg =
      localeStrings["xmm.portal.parts.confirm_pull_dms_parts_msg"];
    const pullDMSPartsBannerMsg =
      localeStrings["xmm.portal.parts.pulling_dms_parts_msg"];
    const { pullingDMSParts } = this.state;
    const msgSection = pullingDMSParts ? (
      <AlertBox
        htmlId="pullDMSPartsBanner"
        type="info"
        closeButton={true}
        message={pullDMSPartsBannerMsg}
        loading={true}
      />
    ) : (
      ""
    );
    const pullDMSPartsModal = (
      <Confirmation
        htmlId="pullDMSPartsModal"
        message={pullDMSPartsMsg}
        proceedButtonStyle="primary"
        show={this.state.showpullDMSPartsModal}
        actionFunction={this.handleDMSPullParts}
        closeDialog={this.closePullDMSPartsModal}
      />
    );

    const pullDMSPartsButton = this.renderPullDMSPartsButton();

    const header = (
      <React.Fragment>
        {importDMSPartsPricingModal}
        <div>{msgSection}</div>
        <div className="content-header">
          <h3 className="xmm-main-title">{this.state.pageTitle}</h3>
          <div className="xmm-form-header">
            <div className="float-right">{statusHtml}</div>
            {pullDMSPartsButton}
            <Button
              htmlId="openBulkeditBtn"
              buttonStyle="primary"
              onClick={this.openBulkEditSlider}
              className="xmm-bulkedit-btn"
              hidden={this.state.selectionlist.length < 2}
            >
              <FormattedMessage
                defaultMessage="Bulk Edit"
                id="xmm.portal.parts_pricing.bulkedit.title"
              />
              {" ("}
              {this.state.selectionlist.length}
              {")"}
            </Button>
            <div className="xmm-input-search">
              <input
                type="text"
                id="parts-pricing-search-box"
                className="xmm-input"
                placeholder={localeStrings["xmm.portal.common.search_label"]}
                onChange={this.onSearchBoxChanged}
                value={this.state.searchKey}
                autoComplete="off"
              />
            </div>
            <SelectInput
              htmlId="VariantSelect"
              placeholder="Select"
              disabled={false}
              displayLabel={false}
              displayPlaceholder={false}
              displayDeselectOption={false}
              maxHeight={120}
              name="variantType"
              onChange={this.onVariantTypeChange}
              label="View"
              value={this.state.variantType}
              options={[
                {
                  value: "ALL",
                  label:
                    this.context.localeStrings[
                      "xmm.portal.parts_pricing.status_any"
                    ]
                },
                {
                  value: "ADDED",
                  label:
                    this.context.localeStrings[
                      "xmm.portal.parts_pricing.status_new_parts_only"
                    ]
                },
                {
                  value: "CHANGED",
                  label:
                    this.context.localeStrings[
                      "xmm.portal.parts_pricing.status_changed_parts_only"
                    ]
                },
                {
                  value: "OVERRIDDEN",
                  label:
                    this.context.localeStrings[
                      "xmm.portal.parts_pricing.status_overridden_parts"
                    ]
                }
              ]}
            />
            <Dropdown
              icon={<IconMore />}
              id="fluidsPricingActionBtn"
              htmlId="fluidsPricingActionBtn"
              name="fluidsPricingActionBtn"
              className="xmm-dotted-dropdown btn--icon"
              buttonStyle="link"
              displayCaret={false}
              size="small"
              options={[
                {
                  label:
                    localeStrings["xmm.portal.common.import_dms_parts_pricing"],
                  value: "importDMSPartsPricing",
                  onSelect: this.openImportPartsModal
                },
                {
                  label: localeStrings["xmm.portal.common.clear_filters"],
                  value: "clear-filters",
                  onSelect: this.clearFilters
                }
              ]}
              pullRight
            />
          </div>
        </div>
      </React.Fragment>
    );
    const bulkModalTitle = (
      <span>
        <FormattedMessage
          defaultMessage="Bulk Edit"
          id="xmm.portal.parts_pricing.bulkedit.title"
        />
        {": "} {this.state.selectionlist.length} {" Parts"}
      </span>
    );
    const bulkeditSlider = (
      <GenericSlider
        title={bulkModalTitle}
        htmlId="bulkEditSlider"
        showSlide={this.state.bulkEditSlide}
        toggleSlider={this.closeBulkEditSlider}
        sliderWidth={this.state.bulkEditSliderWidth}
        flexWidth={false}
      >
        <BulkEditPane
          key={"bulkPartsPricing"}
          closeSlider={this.closeBulkEditSlider}
          selectionlist={this.state.selectionlist}
          updateStatusBox={this.updateStatusBox}
        />
      </GenericSlider>
    );
    return (
      <React.Fragment>
        <PartsPricingContext.Provider value={contextValue}>
          {header}
          {gridWidget}
          {bulkeditSlider}
          {pullDMSPartsModal}
        </PartsPricingContext.Provider>
      </React.Fragment>
    );
  }
}

let thePartManufacturerCodeMap = {};

function extractValues(params) {
  const arr = [];
  if (!isEmptyString(params.data["msrp"])) {
    arr["MSRP"] = "MSRP";
  }
  if (!isEmptyString(params.data["dmsPrice"])) {
    arr["DMS"] = "DMS";
  }
  if (!isEmptyString(params.data["price"])) {
    arr["MANUAL"] = "MANUAL";
  }
  return Object.keys(arr);
}
function descriptionCellRenderer(params) {
  const { data } = params;
  if (!data) {
    return "";
  }
  const { description, oemDescription } = data;
  if (isSameValue(description, oemDescription)) {
    return params.value;
  } else {
    return (
      params.value +
      '&nbsp;<span title=" ' +
      toEmptyStringIfUndefined(oemDescription) +
      ' " class="fas fa-pen float-right"></span>'
    );
  }
}
function iconCellRendererMSRP(params) {
  return iconCellRenderer(params, "MSRP");
}
function partNumberCellRenderer(params) {
  const { data } = params;
  if (!data) {
    return "";
  }
  const { partNumber, oemPartNumber } = data;
  if (isSameValue(partNumber, oemPartNumber)) {
    return params.value;
  } else {
    const oemPartNumberLabel = xlate("xmm.portal.common.oem_part_number_label");
    const tooltipLabel = oemPartNumberLabel.replace(
      "%1",
      toEmptyStringIfUndefined(oemPartNumber)
    );
    return (
      params.value +
      "&nbsp;<span title=" +
      '"' +
      tooltipLabel +
      '" class="fas fa-pen float-right"></span>'
    );
  }
}
function iconCellRendererDMS(params) {
  return iconCellRenderer(params, "DMS");
}
function iconCellRendererManual(params) {
  return iconCellRenderer(params, "MANUAL");
}
function iconCellRenderer(params, level) {
  let statusValue = toEmptyStringIfUndefined(params.data["source"]);
  statusValue = statusValue ? statusValue.toUpperCase() : "";

  let iconHtml = "";
  if (statusValue === level) {
    if (priceFormatter(params) === "-") {
      iconHtml = priceFormatter(params);
    } else {
      const { field } = params.colDef;
      if (field === "price" && params.data.errors && params.data.errors.price) {
        iconHtml = priceFormatter(params);
      } else {
        iconHtml =
          '<div class="xmm-flex-cell"><i class="fas fa-check"></i> <span>' +
          priceFormatter(params) +
          "</span></div>";
      }
    }
  } else {
    iconHtml = priceFormatter(params);
  }
  return iconHtml;
}
function overriddenPartFormatter(params) {
  if (params && params.data) {
    return params.data.oemPartNumber === params.data.partNumber ? "0" : "1";
  }
  return "0";
}
function getPartManufacturerCodeValues(params, partManufacturerCodeMap) {
  const { data } = params;
  const make = data.make;
  const partManfacturerCode = partManufacturerCodeMap[make];
  return partManfacturerCode ? [partManfacturerCode.oemCode, "OT"] : ["OT"];
}

export default PartsPricing;

PartsPricing.propTypes = {
  location: PropTypes.object
};
/* eslint-enable no-console */
