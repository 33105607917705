/* eslint-disable no-console */
import React, { Component } from "react";
import Grid from "@cx/ui/Grid";
import Row from "@cx/ui/Row";
import Col from "@cx/ui/Col";
import SelectInput from "@cx/ui/SelectInput";
import SearchableSelect from "@cx/ui/SearchableSelect";
import Button from "@cx/ui/Button";
import IconAdd from "@cx/ui/Icons/IconAdd";
import IconRemove from "@cx/ui/Icons/IconRemove";
// import ButtonActions from "./ButtonActions";
import {
  AllMetaVehicleCriteriaMinusStartEndYear,
  NonUsaMetaVehicleCriteriaMinusStartEndYear,
  SETS,
  MODELS,
  TRIMS,
  YEARS,
  ENGINE_TYPES,
  ENGINE_SIZES,
  DRIVE_TYPES,
  TRANSMISSION_TYPES,
  BODY_TYPES,
  FUEL_TYPES
} from "../../../constants/ModuleConstants";
import { PropTypes } from "prop-types";
import { isLargerThan } from "../../../commonUtil/utils/value";
import { locale, xlate } from "../../../commonUtil/i18n/locales";

class CriteriaField extends Component {
  constructor(props) {
    super(props);
    this.addCriteria = this.addCriteria.bind(this);
    this.removeCriteria = this.removeCriteria.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onChange = this.onChange.bind(this);
    this.validateCriteria = this.validateCriteria.bind(this);
    this.validateField = this.validateField.bind(this);
    const values = this.getLabelValue(props.field.values);
    this.state = {
      name: props.field.name,
      operator: props.field.operator,
      values,
      startYear: props.field.startYear || [],
      endYear: props.field.endYear || [],
      allCriteriaOptions: this.getAllCriteriaOptions(),
      errors: {}
    };
  }

  componentDidMount() {}

  getAllCriteriaOptions() {
    let allCriteriaOptions = [];
    if (locale.indexOf("_US") !== -1) {
      allCriteriaOptions = AllMetaVehicleCriteriaMinusStartEndYear;
    } else {
      allCriteriaOptions = NonUsaMetaVehicleCriteriaMinusStartEndYear;
    }
    allCriteriaOptions = allCriteriaOptions.map(mvc => {
      return {
        label: this.mapMetaVehicleCriteriaName(mvc),
        value: this.convertLabelToKey(mvc)
      };
    });
    return allCriteriaOptions;
  }
  mapMetaVehicleCriteriaName(name) {
    switch (name) {
      case SETS:
        return xlate("xmm.portal.vehiclegroups.sets");
      case MODELS:
        return xlate("xmm.portal.vehiclegroups.models");
      case TRIMS:
        return xlate("xmm.portal.vehiclegroups.trims");
      case YEARS:
        return xlate("xmm.portal.vehiclegroups.years");
      case ENGINE_TYPES:
        return xlate("xmm.portal.vehiclegroups.engine_types");
      case ENGINE_SIZES:
        return xlate("xmm.portal.vehiclegroups.engine_sizes");
      case DRIVE_TYPES:
        return xlate("xmm.portal.vehiclegroups.drive_types");
      case TRANSMISSION_TYPES:
        return xlate("xmm.portal.vehiclegroups.transmission_types");
      case BODY_TYPES:
        return xlate("xmm.portal.vehiclegroups.body_types");
      case FUEL_TYPES:
        return xlate("xmm.portal.vehiclegroups.fuel_types");
      default:
        return "";
    }
  }

  getLabelValue(values) {
    if (!values) {
      return [];
    }
    return values.map(v => {
      return v.value === undefined ? { value: v, label: v } : v;
    });
  }

  isSameValues = (list1, list2) => {
    if (Array.isArray(list1) && Array.isArray(list2)) {
      if (list1.length !== list2.length) {
        return false;
      }
    } else if (Array.isArray(list1) || Array.isArray(list2)) {
      return false;
    } else {
      return list1 === list2;
    }
    const valueList1 = list1
      .map(item => {
        if (item.label === undefined) {
          return item;
        }
        return item.value;
      })
      .sort();
    const valueList2 = list2
      .map(item => {
        if (item.label === undefined) {
          return item;
        }
        return item.value;
      })
      .sort();
    return valueList1.every((item, index) => {
      return item === valueList2[index];
    });
  };

  getDefaultOperatorByName(name) {
    if (name === "years") {
      return "isfrom";
    } else if (name === "sets") {
      return "isany";
    }
    return "is";
  }

  validateField = (name, value) => {
    if (!value || (Array.isArray(value) && value.length === 0)) {
      const error = xlate("xmm.portal.errors.field_is_required");
      this.setState(prevState => {
        return {
          errors: {
            ...prevState.errors,
            [name]: error
          }
        };
      });
    } else if (
      this.state.errors.name !== "" &&
      this.state.errors.operator !== ""
    ) {
      this.setState(prevState => {
        return {
          errors: {
            ...prevState.errors,
            name: "",
            operator: ""
          }
        };
      });
    } else {
      this.setState(prevState => {
        return {
          errors: {
            ...prevState.errors,
            [name]: ""
          }
        };
      });
    }
  };
  validateYearsIsfrom = () => {
    const { startYear, endYear } = this.state;
    if (startYear.length === 0 && endYear.length === 0) {
      this.setState({
        errors: {
          startYear: xlate("xmm.portal.errors.start_or_end_year_required"),
          endYear: xlate("xmm.portal.errors.start_or_end_year_required")
        }
      });
    } else if (
      startYear.length !== 0 &&
      endYear.length !== 0 &&
      isLargerThan(startYear[0].value, endYear[0].value)
    ) {
      this.setState({
        errors: {
          startYear: xlate("xmm.portal.errors.start_after_end_year"),
          endYear: xlate("xmm.portal.errors.end_before_start_year")
        }
      });
    } else {
      this.setState({
        errors: {
          startYear: "",
          endYear: ""
        }
      });
    }
  };
  validateCriteria = () => {
    const { name, operator, values } = this.state;
    if (name === "years" && operator === "isfrom") {
      this.validateYearsIsfrom();
    } else {
      this.validateField("name", name);
      this.validateField("values", values);
    }
  };

  onBlur = (cxEvent, isValid, domEvent) => {
    this.onChange(cxEvent, isValid, domEvent);
  };
  onChange = (cxEvent, isValid, domEvent) => {
    const { name, value } = cxEvent.target;
    if (name === "name" && value !== this.state.name) {
      const operator = this.getDefaultOperatorByName(value);
      this.props.field.operator = operator;
      this.props.field.values = [];
      this.setState({
        operator,
        values: []
      });
    }
    if (!this.isSameValues(this.props.field[name], value)) {
      this.props.field[name] = value;
      this.setState({ [name]: value }, () => {
        if (name === "startYear" || name === "endYear") {
          this.validateYearsIsfrom();
        } else {
          this.validateField(name, value);
        }
      });
      window.dispatchEvent(
        new CustomEvent("dirtyVehicleGroupFormEvent", {
          detail: { dirty: true },
          bubbles: true,
          cancelable: true
        })
      );
    }

    // if (name === "startYear" || name === "endYear") {
    //   this.validateYearsIsfrom();
    // } else {
    //   this.validateField(name, value);
    // }
  };

  enableMultiSelect = fieldName => {
    // startYear and endYear allow single select
    return fieldName !== "startYear" && fieldName !== "endYear";
  };

  addCriteria = event => {
    // console.log("addCriteria", event);
    const index = this.props.index;
    window.dispatchEvent(
      new CustomEvent("addCriteriaEvent", {
        detail: { index },
        bubbles: true,
        cancelable: true
      })
    );
  };

  removeCriteria = event => {
    // console.log("removeCriteria", event);
    const index = this.props.index;
    window.dispatchEvent(
      new CustomEvent("removeCriteriaEvent", {
        detail: { index },
        bubbles: true,
        cancelable: true
      })
    );
  };

  convertLabelToKey(label) {
    return label.replace(/[^a-zA-Z]/g, "").replace(/^\w/g, c => {
      return c.toLowerCase();
    });
  }

  getOperatorOptions(name) {
    if (name === "years") {
      return [
        { value: "isfrom", label: xlate("xmm.portal.vehiclegroups.is_from") },
        { value: "isnot", label: xlate("xmm.portal.vehiclegroups.is_not") }
      ];
    }
    if (name === "sets") {
      return [
        { value: "isany", label: xlate("xmm.portal.vehiclegroups.is_any") },
        { value: "isall", label: xlate("xmm.portal.vehiclegroups.is_all") },
        {
          value: "isnotany",
          label: xlate("xmm.portal.vehiclegroups.is_not_any")
        },
        {
          value: "isnotall",
          label: xlate("xmm.portal.vehiclegroups.is_not_all")
        }
      ];
    }
    return [
      { value: "is", label: xlate("xmm.portal.vehiclegroups.is") },
      { value: "isnot", label: xlate("xmm.portal.vehiclegroups.is_not") }
    ];
  }

  renderRegularFields() {
    // console.log("renderRegularFields", this.state.name, this.state.operator);
    const { index } = this.props;
    const { name, operator, errors } = this.state;
    const disableOperator = name === "";
    const disableValues = disableOperator || operator === "";
    const isIncludedYears = name === "years" && operator === "isfrom";
    const yearsClass = isIncludedYears ? "xmm-included-years" : "hide";
    const notYearsClass = !isIncludedYears ? "xmm-scrollable-select" : "hide";
    const options = this.props.criteriaOptionsMap[name] || [];
    return (
      <Grid>
        <Row>
          <Col
            xs={3}
            md={3}
            className="full-col"
            style={{ marginLeft: "15px" }}
          >
            <SelectInput
              htmlId={`name${index}`}
              label=""
              displayPlaceholder={true}
              displayDeselectOption={false}
              displayLabel={false}
              name="name"
              placeholder={xlate("xmm.portal.common.select")}
              // onBlur={this.onBlur}
              onChange={this.onChange}
              options={this.state.allCriteriaOptions || []}
              value={this.state.name}
              // hasError={!!errors.name}
              // errorMessage={errors.name}
            />
          </Col>
          <Col xs={2} md={2}>
            <SelectInput
              htmlId={`operator${index}`}
              label=""
              name="operator"
              disabled={disableOperator}
              displayLabel={false}
              displayDeselectOption={false}
              onBlur={this.onBlur}
              onChange={this.onChange}
              options={this.getOperatorOptions(this.state.name) || []}
              value={this.state.operator}
              hasError={!!errors.operator}
              errorMessage={errors.operator}
            />
          </Col>
          <Col xs={5} md={5} className="full-col">
            <SearchableSelect
              htmlId={`value${index}`}
              label=""
              name="values"
              disabled={disableValues}
              placeholder={xlate("xmm.portal.common.select")}
              displayLabel={false}
              allowNewEntries={false}
              className={notYearsClass}
              enableMultiSelect={this.enableMultiSelect(this.state.name)}
              maxHeight={150}
              onBlur={this.onBlur}
              onChange={this.onChange}
              options={options}
              value={this.state.values}
              hasError={!!errors.values}
              errorMessage={errors.values}
            />
            <div className={yearsClass}>
              <SearchableSelect
                htmlId={`startYear${index}`}
                label=""
                disabled={disableValues}
                displayPlaceholder={true}
                displayLabel={false}
                name="startYear"
                className="xmm-years-options"
                enableMultiSelect={false}
                placeholder={xlate("xmm.portal.vehiclegroups.start_year")}
                maxHeight={150}
                onBlur={this.onBlur}
                onChange={this.onChange}
                options={options}
                value={this.state.startYear || []}
                hasError={!!errors.startYear}
                errorMessage={errors.startYear}
              />
              <div style={{ padding: "5px" }}>&nbsp;&nbsp;to&nbsp;&nbsp;</div>
              <SearchableSelect
                htmlId={`endYear${index}`}
                label=""
                disabled={disableValues}
                displayPlaceholder={true}
                displayLabel={false}
                name="endYear"
                className="xmm-years-options"
                enableMultiSelect={false}
                placeholder={xlate("xmm.portal.vehiclegroups.end_year")}
                maxHeight={150}
                onBlur={this.onBlur}
                onChange={this.onChange}
                options={options}
                value={this.state.endYear || []}
                hasError={!!errors.endYear}
                errorMessage={errors.endYear}
              />
            </div>
          </Col>
          <Col>
            <Button
              htmlId={`IconRemove${index}`}
              className="btn--icon"
              style={{ paddingLeft: "12px" }}
              aria-label="Remove"
              buttonStyle="link"
              size="sm"
              hidden={this.props.totalCriteriaFields === 1}
              onClick={this.removeCriteria}
            >
              <IconRemove />
            </Button>
            <Button
              htmlId={`IconAdd${index}`}
              className="btn--icon"
              style={{ paddingLeft: "12px" }}
              aria-label="Add"
              buttonStyle="link"
              size="sm"
              onClick={this.addCriteria}
            >
              <IconAdd />
            </Button>
          </Col>
        </Row>
      </Grid>
    );
  }

  render() {
    // const { name, operator } = this.state;
    // const { name } = this.state;
    // if (name === "years" && operator === "isfrom") {
    // return "";
    // }
    return this.renderRegularFields();
  }
}

export default CriteriaField;

CriteriaField.propTypes = {
  criteriaOptionsMap: PropTypes.object,
  field: PropTypes.object,
  index: PropTypes.number,
  totalCriteriaFields: PropTypes.number
};
