/* eslint-disable no-console */
import React, { Component } from "react";
import { PropTypes } from "prop-types";
import Row from "@cx/ui/Row";
import Col from "@cx/ui/Col";
import SelectInput from "@cx/ui/SelectInput";
import TextInput from "@cx/ui/TextInput";
import { AppContext } from "../../../../../components/app-context";
import { xlate } from "../../../../../commonUtil/i18n/locales";

class ExternalFilters extends Component {
  static contextType = AppContext;

  // Any changes to props, this method updates state
  static getDerivedStateFromProps(nextProps, prevState) {
    // console.log("External Filters stateFromProps", nextProps);
    if (nextProps.disableFilters !== prevState.disableFilters) {
      return {
        disableFilters: nextProps.disableFilters,
        makes: nextProps.makes,
        opcodeValidationGrid: nextProps.opcodeValidationGrid
      };
    }
    return null;
  }

  constructor(props, context) {
    super(props, context);
    const { filters } = props.opcodeValidationGrid;
    this.state = {
      isChanged: false,
      disableFilters: props.disableFilters,
      makes: props.makes,
      filters
    };
    const { localeStrings } = context;
    this.initializeLocaleValues(localeStrings);
  }
  componentDidMount() {
    // this.restoreFilters();
  }
  /**
   * Remove event listeners
   * when a component is being removed from the DOM
   */
  componentWillUnmount() {
    this.saveFilters();
  }
  initializeLocaleValues(localeStrings) {
    this.makelbl = localeStrings["xmm.portal.grid.make"];
    this.createdBylbl = localeStrings["xmm.portal.grid.createdby"];
    this.serviceKindlbl = localeStrings["xmm.portal.grid.servicekind"];
    this.contentStatuslbl = localeStrings["xmm.portal.grid.content_status"];
    this.opcodeStatuslbl = localeStrings["xmm.portal.grid.opcode_status"];
    this.selectMakelbl = localeStrings["xmm.portal.common.select_make_label"];
    this.searchlbl = localeStrings["xmm.portal.common.search_label"];

    this.allTypeslbl = localeStrings["xmm.portal.common.all_types_lbl"];
    this.allKindslbl = localeStrings["xmm.portal.common.all_kinds_lbl"];
    this.allContentstatuslbl =
      localeStrings["xmm.portal.common.all_contentstatus_lbl"];
    this.allStatuslbl = localeStrings["xmm.portal.common.all_status_lbl"];
  }
  /* clear filter values from parent ag-grid as well */
  clearExternalFilters() {
    const filters = {
      make: "all",
      type: "all",
      kind: "all",
      opcodestatus: "all",
      contentstatus: "all",
      searchkey: ""
    };
    this.setState({
      filters: { ...filters }
    });
  }
  restoreFilters() {
    const { filters } = this.context.opcodeValidationGrid;
    this.setState({
      filters
    });
  }
  saveFilters() {
    const { filters } = this.state;
    const { opcodeValidationGrid } = this.props;
    opcodeValidationGrid.filters = filters;
    // this.context.setOpcodeValidationGridState(opcodeValidationGrid);
  }
  onChange = (cxEvent, isValid, domEvent) => {
    const { name, value } = cxEvent.target;
    this.setState(
      prevState => {
        return {
          filters: {
            ...prevState.filters,
            [name]: value
          },
          isChanged: !prevState.isChanged
        };
      },
      () => {
        // callback
        this.handleMultipleFilters(cxEvent, this.state.filters);
      }
    );
  };

  handleMultipleFilters = (event, filters) => {
    if (filters) {
      // console.log(filters);
      this.props.applyfilters(event, filters);
    }
  };

  clearFilters() {
    this.state({
      filters: {
        make: "all",
        type: "all",
        kind: "all",
        opcodestatus: "all",
        contentstatus: "all", // custom value to display all content menus & alacarte records
        searchkey: ""
      }
    });
  }
  render() {
    const { filters, disableFilters } = this.state;
    return (
      <Row>
        <Col xs={10} md={10} className="xmm-filter-section">
          <SelectInput
            htmlId="makeFilter"
            label={this.makelbl}
            name="make"
            placeholder={this.selectMakelbl}
            displayDeselectOption={false}
            displayPlaceholder={false}
            disabled={disableFilters}
            onChange={this.onChange}
            options={this.state.makes}
            value={filters.make}
            maxHeight={200}
          />
          <SelectInput
            htmlId="typeFilter"
            label={this.createdBylbl}
            name="type"
            displayPlaceholder={false}
            displayDeselectOption={false}
            displayLabel={true}
            disabled={disableFilters}
            placeholder=""
            onChange={this.onChange}
            options={[
              { value: "all", label: this.allTypeslbl },
              {
                value: "Factory",
                label: xlate("xmm.portal.common.factory_lbl")
              },
              {
                value: "Dealer",
                label: xlate("xmm.portal.common.dealership_lbl")
              }
            ]}
            value={filters.type}
          />
          <SelectInput
            htmlId="kindFilter"
            label={this.serviceKindlbl}
            name="kind"
            displayPlaceholder={false}
            displayDeselectOption={false}
            placeholder="Select Kind"
            disabled={disableFilters}
            onChange={this.onChange}
            options={[
              { value: "all", label: this.allKindslbl },
              {
                value: "maintenance",
                label: xlate("xmm.portal.common.maintenance_lbl")
              },
              { value: "repair", label: xlate("xmm.portal.common.repair_lbl") },
              {
                value: "autorecall",
                label: xlate("xmm.portal.common.autorecall_lbl")
              }
            ]}
            value={filters.kind}
          />
          <SelectInput
            htmlId="contentFilter"
            label={this.contentStatuslbl}
            name="contentstatus"
            displayPlaceholder={false}
            displayDeselectOption={false}
            placeholder=""
            disabled={disableFilters}
            onChange={this.onChange}
            // Fix - Use mapping key to support Text filter
            options={[
              { value: "all", label: this.allContentstatuslbl },
              {
                value: "b",
                label: xlate("xmm.portal.common.active_menu_alacarte")
              },
              { value: "mb", label: xlate("xmm.portal.common.active_menu") },
              {
                value: "lb",
                label: xlate("xmm.portal.common.active_alacarte")
              },
              {
                value: "dis",
                label: xlate("xmm.portal.common.inactive_content")
              }
            ]}
            // Prod - we used actual label to match filter value
            // options={[
            //   { value: "all", label: "All Content Status" },
            //   { value: "1-1", label: "Active in Menu or A la Carte" },
            //   { value: "1-0", label: "Active in Menu" },
            //   { value: "0-1", label: "Active in A la Carte" },
            //   { value: "0-0", label: "Inactive for All Content" }
            // ]}
            value={filters.contentstatus}
          />
          {/*
            Valid - IconComplete
            Review - IconHelpOutline
            Invalid - IconWarning
            Ignored - IconRemove
          */}
          <SelectInput
            htmlId="opcodeFilter"
            label={this.opcodeStatuslbl}
            name="opcodestatus"
            displayPlaceholder={false}
            placeholder=""
            disabled={disableFilters}
            displayDeselectOption={false}
            onChange={this.onChange}
            options={[
              { value: "all", label: this.allStatuslbl },
              { value: "Valid", label: xlate("xmm.portal.common.valid") },
              { value: "Review", label: xlate("xmm.portal.common.review") },
              { value: "Invalid", label: xlate("xmm.portal.common.invalid") },
              { value: "Ignored", label: xlate("xmm.portal.common.ignored") }
            ]}
            value={filters.opcodestatus}
          />
        </Col>
        <Col xs={2} md={2} className="float-right">
          <div className="xmm-input-search xmm-label-space float-right">
            <TextInput
              htmlId="nameFilter"
              label=""
              name="searchkey"
              autoComplete="off"
              disabled={disableFilters}
              onChange={this.onChange}
              value={filters.searchkey}
              placeholder={this.searchlbl}
            />
          </div>
        </Col>
      </Row>
    );
  }
}

export default ExternalFilters;

ExternalFilters.propTypes = {
  makes: PropTypes.array,
  disableFilters: PropTypes.bool,
  applyfilters: PropTypes.func,
  opcodeValidationGrid: PropTypes.object
};
