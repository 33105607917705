import React, { useCallback, useState, useEffect } from "react";
import isEmpty from "lodash/isEmpty";
import has from "lodash/has";
import "./SearchServicesPage.scss";
import QuickFilters from "../../components/quick-filters/QuickFilters";
import {
  useServiceSearchContext,
  Actions
} from "../../state/service-search.context";
import { customHomeSearch } from "../../utils/custom-search.util";
import { quickFilterPages } from "../../constants/quick-filters.constants";
import TopServicesQuickFilterPage from "../top-services-quick-filter/TopServicesQuickFilterPage";
import MenusQuickFilterPage from "../menus-quick-filter/MenusQuickFilterPage";
import DiagnosisQuickFilterPage from "../diagnosis-quick-filter/DiagnosisQuickFilterPage";
import DeclinedQuickFilterPage from "../declined-services-quick-filter/DeclinedServicesQuickFilterPage";
import GlobalServiceSearchPage from "../global-service-search/GlobalServiceSearchPage";
import DealerTire from "../dealer-tire/";
import useComponentDidMount from "../../hooks/useComponentDidMount";
import RecallServiceQuickFilterPage from "../recall-services-quick-filter/RecallServicesQuickFIlterPage";

const SearchServicesPage = props => {
  const {
    EditServiceModule,
    applicationType, // csr specific application Type if application is CSR then applicationType will be CSR
    CSRSelectedMenuPage, // csr specific if applicationType is CSR then  CSRSelectedMenuPage will be used instead of selectedmenupage
    onAddMenuServiceClick // csr specific application Type if application is CSR then addMenuServiceClick handler will be used
  } = props;
  const { state, dispatch, ctxRequestDealerTireConfig } =
    useServiceSearchContext();
  const {
    serviceSearchQuery,
    servicePoints,
    currentQuickFilterPage,
    allOperations,
    topServices,
    diagnosisServices,
    declinedServices,
    serviceFilterBy,
    servicesForSearch,
    recallServices,
    searchHeader,
    quickFilters,
    appConfig
  } = state;
  const [searchType, setSearchType] = useState("");
  const [services, setServices] = useState([]);
  const [showQuickFilters, setShowQuickFilters] = useState(true);
  const quickFilterPagesMap = {
    [quickFilterPages.TOP_SERVICES]: (
      <TopServicesQuickFilterPage EditServiceModule={EditServiceModule} />
    ),
    [quickFilterPages.MENU]: (
      <MenusQuickFilterPage
        applicationType={applicationType || null}
        EditServiceModule={EditServiceModule}
        CSRSelectedMenuPage={CSRSelectedMenuPage}
        onAddMenuServiceClick={onAddMenuServiceClick}
      />
    ),
    [quickFilterPages.DIAGNOSIS]: (
      <DiagnosisQuickFilterPage EditServiceModule={EditServiceModule} />
    ),
    [quickFilterPages.DECLINED]: (
      <DeclinedQuickFilterPage EditServiceModule={EditServiceModule} />
    ),
    [quickFilterPages.TIRES]: (
      <DealerTire EditServiceModule={EditServiceModule} />
    ),
    [quickFilterPages.RECALLS]: (
      <RecallServiceQuickFilterPage EditServiceModule={EditServiceModule} />
    )
  };
  // @note: verify quick filters flag from appConfig in search context
  useComponentDidMount(() => {
    const { quickFilterAccess } = appConfig;
    const filters = quickFilters.map(filter => {
      if (quickFilterAccess.menu && filter.id === quickFilterPages.MENU) {
        filter.serviceCount = !servicePoints ? 0 : servicePoints.length;
      }
      if (
        quickFilterAccess.topServices &&
        filter.id === quickFilterPages.TOP_SERVICES
      ) {
        filter.serviceCount = !topServices ? 0 : topServices.length;
      }
      if (
        quickFilterAccess.diagnosis &&
        filter.id === quickFilterPages.DIAGNOSIS
      ) {
        filter.serviceCount = !diagnosisServices ? 0 : diagnosisServices.length;
      }
      if (
        quickFilterAccess.declined &&
        filter.id === quickFilterPages.DECLINED
      ) {
        filter.serviceCount = !declinedServices ? 0 : declinedServices.length;
      }
      // @note: check dealerTire property, to show Tires quick filter
      if (quickFilterAccess.tires && filter.id === quickFilterPages.TIRES) {
        if (!isEmpty(appConfig)) {
          filter.serviceCount = !appConfig.isDealerTireEnabled ? 0 : 1;
        }
      }
      if (quickFilterAccess.recall && filter.id === quickFilterPages.RECALLS) {
        filter.serviceCount = !recallServices ? 0 : recallServices.length;
      }
      return filter;
    });
    dispatch({
      type: Actions.SET_QUICK_FILTERS,
      payload: filters
    });
  });

  // @note: we should return ANY combination of the “words” contained in either the Service Name or synonyms list
  const searchByTokens = (tokens, services) => {
    const results = customHomeSearch(tokens, services);
    return results;
  };

  const customSearch = useCallback(() => {
    try {
      const results = [...servicesForSearch];
      if (!isEmpty(serviceSearchQuery)) {
        const searchValue = serviceSearchQuery.toString().toLowerCase().trim();
        const tokens = searchValue.split(" ");
        const filteredData = searchByTokens(tokens, results);
        return filteredData;
      } else {
        return [];
      }
    } catch (err) {
      console.error(err);
    }
  }, [serviceSearchQuery, servicesForSearch]);

  // @note hooks dependency is `serviceSearchQuery` which gets changed on every input by the user which in turn executes the function
  useEffect(() => {
    try {
      if (serviceSearchQuery && serviceSearchQuery.length > 0) {
        const globalServices = customSearch();
        setServices(!globalServices ? [] : globalServices);
        setShowQuickFilters(false);
        setSearchType("FUSE");
      } else {
        // TODO: when search text is empty, check if category pre-selected. if not show quick filter results(top services)
        const hasCategory = isEmpty(serviceFilterBy) ? true : false;
        setShowQuickFilters(hasCategory);
        setSearchType("NONE");
        if (!isEmpty(serviceFilterBy)) {
          setServices(servicesForSearch);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }, [serviceSearchQuery, customSearch, serviceFilterBy, servicesForSearch]);

  useEffect(() => {
    try {
      // @note: when ALL categories selected, serviceFilterBy becomes "" then show Top Services page
      // Issue rootcause - QuickFilters props never updated when user select All categories while there in any quick filter page
      if (isEmpty(serviceFilterBy)) {
        setShowQuickFilters(false);
        dispatch({
          type: Actions.SET_SERVICES_FOR_SEARCH,
          payload: [...allOperations]
        });
        dispatch({
          type: Actions.SET_CURRENT_QUICK_FILTER_PAGE,
          payload: quickFilterPages.TOP_SERVICES
        });
        setSearchType("ALL CAT");
        setShowQuickFilters(true);
      } else {
        const filteredServices = allOperations.filter(({ rawRecord }) => {
          const realCategoryId = has(rawRecord, "categoryId")
            ? rawRecord.categoryId
            : has(rawRecord, "serviceCategoryId")
            ? rawRecord.serviceCategoryId
            : null;
          const findService = serviceFilterBy.serviceCategories.find(
            serviceCategory =>
              serviceCategory.serviceCategoryId === realCategoryId
          );
          return findService;
        });

        dispatch({
          type: Actions.SET_SERVICES_FOR_SEARCH,
          payload: [...filteredServices]
        });
        setServices(filteredServices);
        setShowQuickFilters(false);
        setSearchType(`CATEGORY-${serviceFilterBy.name}`);
      }
    } catch (error) {
      console.error(error);
    }
  }, [serviceFilterBy, allOperations, dispatch]);

  const handleQuickFilterClick = useCallback(
    filterId => {
      dispatch({
        type: Actions.SET_CHANGE_SEARCH_QUERY,
        payload: ""
      });
      dispatch({
        type: Actions.SET_CURRENT_QUICK_FILTER_PAGE,
        payload: filterId
      });
      if (filterId === "TIRES") {
        ctxRequestDealerTireConfig();
      }
    },
    [dispatch, ctxRequestDealerTireConfig]
  );

  const compCls = searchHeader ? "empty-cls" : "hide-ele";
  const widgetQuickFilters = !isEmpty(appConfig) ? (
    <QuickFilters
      className="quickFiltersCmp"
      filters={quickFilters}
      onFilterClick={handleQuickFilterClick}
    />
  ) : null;
  return (
    <>
      <div id="serviceSearchPageContainer" className={compCls}>
        {showQuickFilters && isEmpty(serviceFilterBy) && widgetQuickFilters}
      </div>
      {showQuickFilters ? (
        quickFilterPagesMap[currentQuickFilterPage]
      ) : (
        <GlobalServiceSearchPage
          services={services}
          searchType={searchType}
          EditServiceModule={EditServiceModule}
        />
      )}
    </>
  );
};

export default SearchServicesPage;
