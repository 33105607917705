/* eslint-disable no-console */
import React from "react";
import { PropTypes } from "prop-types";
import { isSameValue } from "../../../commonUtil/utils/string";

function getItemKey(item) {
  const key = Object.keys(item)[0];
  return key;
}

function getItemValue(item) {
  const list = Object.values(item);
  if (list && list[0] && list[0].length > 0) {
    return list[0];
  } else {
    return [];
  }
}

function getClassName(selectedItem, group) {
  if (!group.intervalId) {
    console.log("group.intervalId", group);
  }
  if (
    selectedItem &&
    selectedItem.intervalId &&
    group.intervalId &&
    isSameValue(selectedItem.intervalId, group.intervalId)
  ) {
    // console.log("match", selectedItem.intervalId, group.intervalId);
    return "list-group-item active";
  }
  return "list-group-item";
}

const GroupNameList = props => {
  return (
    <div>
      <ul className="list-group">
        {props.items.map(item => {
          const keyName = Object.keys(item)[0];
          const valueList = getItemValue(item);

          return (
            <div key={keyName} className="xmm-section-separator">
              <b key={getItemKey(item)}>{keyName}</b>
              {valueList.length > 0 &&
                valueList.map(group => (
                  <li
                    onClick={event => {
                      props.selectFunc(group);
                    }}
                    className={getClassName(props.selectedItem, group)}
                    data-category={group}
                    key={group.intervalId}
                  >
                    {group.name}
                  </li>
                ))}
            </div>
          );
        })}
      </ul>
    </div>
  );
};

export default GroupNameList;

GroupNameList.propTypes = {
  items: PropTypes.array.isRequired,
  selectedItem: PropTypes.object,
  selectFunc: PropTypes.func
};
/* eslint-enable no-console */
