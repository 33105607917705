import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import IconArrowUpward from "@cx/ui/Icons/IconArrowUpward";
import isEmpty from "lodash/isEmpty";
import ConfirmPopup from "../../../components/modals/ConfirmPopup";
import {
  useServiceSearchContext,
  Actions
} from "../../../state/service-search.context";
import serviceDataMapper from "../../../utils/service-data-mapper";
import { saveActionTypes } from "../../../utils/search.constants";

const EditGlobalRepair = props => {
  const { service, EditServiceModule } = props;
  const [showDiscardEditPopup, setShowDiscardEditPopup] = useState(false);
  const [serviceHasChanged, setServiceHasChanged] = useState(false);
  const { dispatch, state, ctxRequestPartsInventoryCommon } =
    useServiceSearchContext();
  const { localeStrings, debugQuote, vehicle, userPermissions } = state;
  const payTypes = state.payTypes;
  const backToService = () => {
    props.updateParentState("GlobalCatalog", false);
    dispatch({
      type: Actions.SET_SEARCH_HEADER,
      payload: true
    });
  };
  const handleCancel = () => {
    if (serviceHasChanged) {
      setShowDiscardEditPopup(true);
    } else {
      backToService();
    }
  };
  const handleServiceUpdate = updatedService => {
    const newService = serviceDataMapper.updateServiceGlobalRepair(
      service,
      updatedService
    );
    dispatch({
      type: Actions.SET_SAVE_ACTION_TYPE,
      payload: saveActionTypes.SAVE
    });
    dispatch({
      type: Actions.SET_SELECTED_GLOBAL_OPS_SERVICE,
      payload: newService
    });
  };
  const saveServiceAndGoBack = updatedService => {
    const newService = serviceDataMapper.updateServiceGlobalRepair(
      service,
      updatedService
    );
    dispatch({
      type: Actions.SET_SELECTED_GLOBAL_OPS_SERVICE,
      payload: newService
    });
    dispatch({
      type: Actions.SET_SAVE_ACTION_TYPE,
      payload: saveActionTypes.SAVEANOTHER
    });
    backToService();
  };
  const asyncUpdatePartsByPaytype = async (paytype, editedService) => {
    service.payTypeCode = paytype;
    ctxRequestPartsInventoryCommon(service, editedService);
  };
  const handleServiceChange = serviceChanged => {
    setServiceHasChanged(serviceChanged);
  };
  const clonedEditServiceModule = !isEmpty(service)
    ? React.cloneElement(EditServiceModule, {
        debugMode: debugQuote,
        userPermissions,
        vehicle,
        rawOperationDetails: service,
        service: serviceDataMapper.editModulePropsFromService(service),
        localeStrings,
        payTypes,
        partsPricingAndInventory: state.partsPricingAndInventory,
        onCancelHandler: handleCancel,
        onSaveHandler: handleServiceUpdate,
        onSaveAnotherHandler: saveServiceAndGoBack,
        onChangePaytype: asyncUpdatePartsByPaytype,
        onServiceChange: handleServiceChange
      })
    : null;
  const editPage = !isEmpty(service) ? clonedEditServiceModule : "";
  const discardEditPopup = (
    <ConfirmPopup
      title={localeStrings["sq.search.common.alert_lbl"]}
      message={localeStrings["sq.search.common.leaving_edit_page"]}
      show={showDiscardEditPopup}
      okText={localeStrings["sq.search.common.proceed_button"]}
      cancelText={localeStrings["sq.search.common.cancel_button"]}
      okAction={backToService}
      cancelAction={() => setShowDiscardEditPopup(false)}
      hideCancel={false}
      hideOk={false}
      buttonStyle="danger"
    />
  );
  return (
    <>
      <div id="editGlobalRepair" className="search-flex-grid-container">
        <span className="back-nav-label" onClick={handleCancel}>
          <IconArrowUpward
            htmlId="backArrowIcon"
            isActive={false}
            className="back-arrow"
          />
          Back
        </span>
        <div className="edit-page-wrapper">{editPage}</div>
      </div>
      {discardEditPopup}
    </>
  );
};

export default memo(EditGlobalRepair);

EditGlobalRepair.defaultProps = {
  service: null,
  EditServiceModule: null
};
EditGlobalRepair.propTypes = {
  service: PropTypes.object.isRequired,
  updateParentState: PropTypes.func.isRequired,
  EditServiceModule: PropTypes.element
};
