import LaborRatesPage from "./LaborRates/LaborRatesPage";
import FluidsPricing from "./FluidsPricing/FluidsPricing";
import PartsPricing from "./PartsPricing/PartsPricing";
import PayTypesPage from "./PayTypes/PayTypesPage";

// bundle components into one object and export
const PartsLaborViews = {
  FluidsPricing,
  PartsPricing,
  LaborRatesPage,
  PayTypesPage
};

export default PartsLaborViews;
