/* eslint-disable no-console */
import React, { Component } from "react";
import Button from "@cx/ui/Button";
import PreviewModal from "../pages/Preview";
import { AppContext } from "../app-context";
import * as gtmEvents from "../utils/gtag-eventlist";
class PreviewButton extends Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);
    this.state = {
      showModal: false,
      disableAction: false
    };
    this.showPreviewModal = this.showPreviewModal.bind(this);
    this.closePreviewModal = this.closePreviewModal.bind(this);
    this.previewActionHandler = this.previewActionHandler.bind(this);
  }

  /**
   * Add event listener
   */
  componentDidMount() {}

  showPreviewModal = event => {
    gtmEvents.trackGtagPageview("/preview");
    event.preventDefault();
    this.setState(prevState => ({
      showModal: !prevState.showModal,
      disableAction: true
    }));
  };

  closePreviewModal = event => {
    this.setState({
      showModal: false,
      disableAction: false
    });
  };

  previewActionHandler = (event, mode) => {
    console.log("preview action triggered");
  };

  render() {
    const { localeStrings, makelist } = this.context;
    const disabled = false; // makelist.length === 0;
    let showModal = null;
    if (this.state.showModal) {
      showModal = (
        <PreviewModal
          show={this.state.showModal}
          okAction={this.previewActionHandler}
          closeModal={this.closePreviewModal}
        >
          <div />
        </PreviewModal>
      );
    }
    return (
      <span className="float-right">
        <Button
          htmlId="PreviewBtn"
          size="small"
          buttonStyle="secondary"
          className="btn-separation"
          disabled={disabled}
          onClick={this.showPreviewModal}
        >
          {localeStrings["xmm.portal.nav.preview"]}
        </Button>
        {showModal}
      </span>
    );
  }
}

export default PreviewButton;
/* eslint-enable no-console */
