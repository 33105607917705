/* eslint-disable no-console */
import React from "react";
import { PropTypes } from "prop-types";
import Button from "@cx/ui/Button";
import IconKeyboardArrowDown from "@cx/ui/Icons/IconKeyboardArrowDown";
import IconKeyboardArrowRight from "@cx/ui/Icons/IconKeyboardArrowRight";
import IconInfoOutline from "@cx/ui/Icons/IconInfoOutline";
import Popover from "@cx/ui/Popover";
import SearchableSelect from "@cx/ui/SearchableSelect";

import OperationRow from "./OperationRow";
import PreviewContext from "../../preview-context";
import {
  isEmpty,
  isObject,
  isArrayExist
} from "../../../../../commonUtil/utils/object";
import {
  convertMinutesToTenths,
  convertMinutesToHundredths,
  defaultToZeroIfNullOrEmpty
} from "../../../../../commonUtil/utils/value";
import { makeSecureRestApi } from "../../../../../api/xmmAxios";
import { toast } from "@cx/ui/Toast";
import * as gtmEvents from "../../../../utils/gtag-eventlist";
import { isSameValue } from "../../../../../commonUtil/utils/string";

class InspectOperationsTable extends React.Component {
  static contextType = PreviewContext;
  constructor(props, context) {
    super(props, context);
    const { localeStrings, preview } = context.appContext;
    // preview.resultTabs.selectedIndex = props.selectedIndex;

    const { search } = preview;
    this.serviceRows = [];
    this.expandAllClick = this.expandAllClick.bind(this);
    this.onChange = this.onChange.bind(this);

    this.initializeLocaleValues(localeStrings);
    console.log("inspect search{}", context.appContext.preview.search);
    let vin = "";
    if (search.hasOwnProperty("vin")) {
      vin = !search.vin ? "-" : search.vin;
    }
    this.state = {
      services: [],
      filteredData: [],
      expandAll: true,
      isExpand: false,
      filterCount: 0,
      totalCount: 0,
      isChanged: false,
      isLoaded: false,
      statusMsg: "",
      inspectCategoryIds: [],
      vin
    };
  }
  componentDidMount() {
    this.getInspectCategories();
  }

  initializeLocaleValues(localeStrings) {
    this.vinLabel = localeStrings["xmm.portal.preview.vehicle.vin_lbl"];
    this.previewLabel = localeStrings["xmm.portal.nav.preview"];
    this.categoryLabel = localeStrings["xmm.portal.common.category_lbl"];
    this.noRecordFound = localeStrings["xmm.portal.common.no_records_msg"];
    this.invalidVin =
      localeStrings["xmm.portal.preview.vehicle.inspect.invalid_vin"];
    this.allCategoriesLabel = localeStrings["xmm.portal.common.all_categories"];

    this.inspectPreviewUnpublishedLabel =
      localeStrings["xmm.portal.preview.vehicle.inspect_in_unpublished"];
    this.inspectPreviewPublishedNoVin =
      localeStrings["xmm.portal.preview.vehicle.inspect_in_published"];
  }

  // Alternate way to track page-view for tab click by simulate click event
  simulateClick = elem => {
    // Create our event (with options)
    const event = new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
      view: window
    });
    window.document.dispatchEvent(event);
    gtmEvents.trackGtagPageview("/preview/inspect-preview");
  };

  getInspectCategories() {
    makeSecureRestApi(
      {
        url: "/ops/proxyapi/internaloeproxy/rest/internal/proc/getXmmConfigValue?keyName=InspectCategoryIds",
        method: "get",
        data: {},
        params: {}
      },
      response => {
        if (response && response.hasOwnProperty("xmmConfig")) {
          const inspectCategoryIds = response.xmmConfig.value
            .split(",")
            .map(String);
          console.log(inspectCategoryIds);
          const { serviceCategoryValueLabelMap } = this.context.appContext;
          const serviceCategories = inspectCategoryIds.map(value => {
            const label = serviceCategoryValueLabelMap[value];
            return { label, value };
          });
          // sort categories alphabetically
          serviceCategories.sort((cat1, cat2) => {
            return cat1.label < cat2.label ? -1 : 1;
          });
          serviceCategories.splice(0, 0, {
            label: this.allCategoriesLabel,
            value: "0"
          });
          this.setState(
            {
              serviceCategories,
              filteredCategories: [...serviceCategories],
              categoryIds: response.xmmConfig.value,
              inspectCategoryIds: [],
              selectedCategory: ""
            },
            (prevState, props) => {
              const { categoryIds } = this.state;
              this.simulateClick("inspect-preview");
              this.loadPreviewData(categoryIds);
            }
          );
        }
      },
      error => {
        toast.error(error.message);
      }
    );
  }

  loadPreviewData(categoryIds) {
    // const dealerCode = "20170811xtqaxx1";
    // const vin = "5XYKTCA67FG571802";
    // const make = "KIA";
    const {
      dealerCode,
      makeVariantMap,
      preview,
      serviceCategoryValueLabelMap
    } = this.context.appContext;
    const { search } = preview;
    const { vin, make } = search;
    const url = `/ops/inspect/getCommonOperationDetails/make/${make}/dealerCode/${dealerCode}/vin/${vin}?categoryIds=${categoryIds}`;
    makeSecureRestApi(
      {
        url,
        method: "get"
      },
      data => {
        let isLoaded = false;
        let statusMsg = "";
        let datalist = [];
        let services = [];
        if (data && data.errorCode) {
          statusMsg = this.invalidVin.replace("%1", vin); // `${vin} is an invalid VIN.`;
        } else if (data && data.hasOwnProperty("operations")) {
          const { operations } = data;
          // check if response has single object or array of objects
          if (!isArrayExist(operations) && typeof operations === "object") {
            datalist.push(operations);
          } else if (isArrayExist(operations) && operations.length > 0) {
            datalist = operations;
          }

          let laborOpsScale = "TENTHS"; // default  case
          const dealerCatalog = makeVariantMap[make];
          if (
            dealerCatalog.laborTimePrecision &&
            !isEmpty(dealerCatalog.laborTimePrecision)
          ) {
            laborOpsScale = dealerCatalog.laborTimePrecision.toUpperCase();
          }

          isLoaded = true;
          services = datalist.map((s, index) => {
            const service = s; // Object.assign({}, s);
            service.index = index;
            if (service.hasOwnProperty("categoryId")) {
              service.categoryLabel =
                serviceCategoryValueLabelMap[service.categoryId];
            }
            if (
              service.hasOwnProperty("price") &&
              service.price.hasOwnProperty("priceSource")
            ) {
              const { priceSource } = service.price;
              let source = "";
              if (priceSource === "DEALER_CALCULATED_PRICING") {
                source = "";
              } else if (priceSource === "DEALER_TOTAL_FLAT_PRICING") {
                source = "Overridden Total";
              }
              service.priceSource = source;
            }

            service.priceLabel = "";
            if (
              service.hasOwnProperty("price") &&
              defaultToZeroIfNullOrEmpty(service.price.price) !== 0
            ) {
              service.priceLabel =
                service.price.price > 0
                  ? "$" + service.price.price.toFixed(2)
                  : " ";
            }

            // convert service "duration" to minutes based on dealer-setting has 'HUNDREDTHS' or TENTHS
            service.laborTimePrecision = laborOpsScale;
            service.time = 0;
            service.time = isObject(service.labor, "time")
              ? defaultToZeroIfNullOrEmpty(service.labor.time)
              : 0;
            if (service.time) {
              let durationInMins = 0;
              if (laborOpsScale === "HUNDREDTHS") {
                durationInMins = parseFloat(
                  convertMinutesToHundredths(service.time)
                );
              } else if (laborOpsScale === "TENTHS") {
                durationInMins = parseFloat(
                  convertMinutesToTenths(service.time)
                );
              }
              service.time = durationInMins;
            }
            // Transform "unscheduledLaborPrice" to null when value is 0 or property missing in service{}
            const localLaborPrice = isObject(service.labor, "price")
              ? defaultToZeroIfNullOrEmpty(service.labor.price) !== 0
                ? service.labor.price
                : null
              : null;
            service.unscheduledLaborPrice = localLaborPrice;
            return service;
          });
          services.sort((service1, service2) => {
            const category1 = serviceCategoryValueLabelMap[service1.categoryId];
            const category2 = serviceCategoryValueLabelMap[service2.categoryId];
            return category1 === category2
              ? service1.index < service2.index
                ? -1
                : 1
              : category1 < category2
              ? -1
              : 1;
          });
        } else {
          statusMsg = this.noRecordFound;
        }
        this.setState(
          {
            isLoaded,
            services,
            statusMsg,
            filteredData: services
          },
          (prevState, props) => {
            if (services.length > 0) this.expandAllClick();
          }
        );
      }
    );
  }
  /* ExpandAll "icon" click handler */
  expandAllClick = event => {
    if (event) {
      event.preventDefault();
    }
    const { expandAll } = this.state;
    // event is null if invoked from service call
    const isExpand = !event ? expandAll : !expandAll;
    this.serviceRows.forEach(ref => {
      if (ref) {
        ref.setState({
          isExpand
        });
      }
    });
    if (isExpand !== expandAll) {
      this.setState(prevState => ({
        expandAll: !prevState.expandAll
      }));
    }
  };

  filterByCategoryId(categoryId) {
    const { services } = this.state;
    const filteredData = services.filter(service =>
      isSameValue(service.categoryId, categoryId)
    );
    this.setState({ filteredData });
  }
  onChange = (cxEvent, isValid, domEvent) => {
    const { name, value } = cxEvent.target;
    if (Array.isArray(value) && value.length !== 0) {
      const { inspectCategoryIds } = this.state;
      if (
        value.length !== inspectCategoryIds.length ||
        value[0] !== inspectCategoryIds[0]
      ) {
        this.setState({ [name]: value });
        this.loadPreviewData(
          value[0].value === "0" ? this.state.categoryIds : value[0].value
        );
      }
    }
  };

  renderInspectPreview() {
    let resultTable = null;
    let externalFilters = null;
    const {
      filteredData,
      isLoaded,
      statusMsg,
      expandAll,
      inspectCategoryIds,
      serviceCategories
    } = this.state;
    const { localeStrings } = this.context;
    const clsHideBtn = "btn--icon";
    const helpLabel =
      localeStrings["xmm.portal.preview.vehicle.inspect_help_label"];
    const helpText =
      localeStrings["xmm.portal.preview.vehicle.inspect_help_text"];
    const iconHelp = (
      <Popover
        popoverContent={helpText}
        position="bottom"
        trigger={["click", "outsideClick"]}
      >
        <IconInfoOutline className="hand-cursor" />
      </Popover>
    );

    if (this.state.isLoaded) {
      const iconCmp = expandAll ? (
        <IconKeyboardArrowDown />
      ) : (
        <IconKeyboardArrowRight />
      );

      externalFilters = (
        <div className="component-header-preview sticky">
          <div className="filter-section">
            <span id="expandAll">
              <Button
                htmlId="IconSelectAll"
                className={clsHideBtn}
                buttonStyle="secondary"
                onClick={this.expandAllClick}
              >
                {iconCmp}
              </Button>
            </span>

            <span>
              <div className="xmm-label">{this.vinLabel}</div>
              <div className="xmm-readonly-label">{this.state.vin}</div>
            </span>
            <span>
              <SearchableSelect
                name="inspectCategoryIds"
                htmlId="inspectCategoryIds"
                displayLabel={true}
                label={localeStrings["xmm.portal.common.category_lbl"]}
                placeholder={localeStrings["xmm.portal.common.all_categories"]}
                value={inspectCategoryIds}
                enableMultiSelect={false}
                options={serviceCategories}
                onChange={this.onChange}
                maxHeight={200}
                warning={""}
              />
            </span>
          </div>
          <div className="info-section">
            {helpLabel}
            {iconHelp}
          </div>
        </div>
      );

      if (filteredData && filteredData.length !== 0) {
        const serviceList = filteredData.map((s, index) => {
          return (
            <div key={"service-" + s.id}>
              <OperationRow
                ref={ref => (this.serviceRows[index + 1] = ref)}
                service={s}
              />
            </div>
          );
        });
        resultTable = (
          <div className="op-table xmm-alacarte-table xmm-modal-alacarte-table">
            {serviceList}
          </div>
        );
      } else {
        resultTable = <div className="xmm-content-adjust"> {statusMsg} </div>;
      }
    } else if (!isLoaded && statusMsg) {
      externalFilters = (
        <div className="xmm-chart-container">
          <div className="xmm-content-adjust">
            <b> {statusMsg}</b>
          </div>
        </div>
      );
    }

    return (
      <React.Fragment>
        {externalFilters}
        {resultTable}
      </React.Fragment>
    );
  }

  render() {
    const { preview } = this.context.appContext;
    const { isPreview, search } = preview;
    const { vin } = search;
    const inspectPreviewView = isPreview ? (
      <div className="xmm-chart-container">
        <div className="xmm-content-adjust">
          {" "}
          {this.inspectPreviewUnpublishedLabel}{" "}
        </div>
      </div>
    ) : !vin ? (
      <div className="xmm-chart-container">
        <div className="xmm-content-adjust">
          {" "}
          {this.inspectPreviewPublishedNoVin}{" "}
        </div>
      </div>
    ) : (
      this.renderInspectPreview()
    );
    return <React.Fragment>{inspectPreviewView}</React.Fragment>;
  }
}

export default InspectOperationsTable;

InspectOperationsTable.propTypes = {
  // selectedIndex: PropTypes.number,
  services: PropTypes.array
};
