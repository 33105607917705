import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Col from "@cx/ui/Col";
import Row from "@cx/ui/Row";
import Button from "@cx/ui/Button";
import SelectInput from "@cx/ui/SelectInput";
import NumericInput from "@cx/ui/NumericInput";
import TextInput from "@cx/ui/TextInput";
import {
  doesEmpty,
  isDifferentValue,
  toEmptyStringIfUndefined
} from "./utils/helper";

const AddPartInput = props => {
  const [quantity, setQuantity] = useState(1);
  const [partNumber, setPartNumber] = useState("");
  const [partManufacturer, setPartManufacturer] = useState(
    props.defaultPartManufacturerCode
  );
  const [errors, setErrors] = useState({});

  const [partManufacturerOptions, setPartManufacturerOptions] = useState(
    props.partManufacturerOptions
  );
  //   const [enableAdd, setEnableAdd] = useState(false);

  useEffect(() => {
    if (props.partManufacturerOptions && props.partManufacturerOptions.length) {
      setPartManufacturerOptions(props.partManufacturerOptions);
    }
  }, [props.partManufacturerOptions]);

  //   useEffect(() => {
  //     if (
  //       !doesEmpty(quantity) &&
  //       !doesEmpty(partNumber) &&
  //       partNumber.trim().length !== 0 &&
  //       !doesEmpty(partManufacturer)
  //     ) {
  //       if (!enableAdd) {
  //         setEnableAdd(true);
  //       }
  //     } else {
  //       if (enableAdd) {
  //         setEnableAdd(false);
  //       }
  //     }
  //   }, [quantity, partNumber, partManufacturer]);

  const onChangeQuantity = cxEvent => {
    const { value } = cxEvent.target;
    if (isDifferentValue(quantity, value)) {
      setQuantity(value);
      if (errors.quantity && isValidQuantity(value)) {
        setErrors({ ...errors, quantity: "" });
      }
    }
  };
  const onChangePartNumber = cxEvent => {
    const { value } = cxEvent.target;
    if (isDifferentValue(partNumber, value)) {
      setPartNumber(value.trim());
      if (errors.partNumber && isValidPartNumber(value)) {
        setErrors({ ...errors, partNumber: "" });
      }
    }
  };
  const onChangePartManufacturer = cxEvent => {
    const { value } = cxEvent.target;
    if (isDifferentValue(partManufacturer, value)) {
      setPartManufacturer(value);
    }
  };
  const resetInputToDefaults = () => {
    clearErrors();
    setQuantity(1);
    setPartNumber("");
    setPartManufacturer(props.defaultPartManufacturerCode);
  };
  const validateFields = () => {
    const newErrors = {};
    const qty = parseInt(quantity, 10);
    if (isNaN(qty) && !qty) {
      newErrors.quantity = "Quantity is required";
    } else if (qty > 100) {
      newErrors.quantity = `'${quantity}' exceeds the maximum value of 100`;
    } else if (qty === 0) {
      newErrors.quantity = `'${quantity}' is less than the minimum value of 1`;
    } else {
      const qty = toEmptyStringIfUndefined(quantity);
      if (qty.substr(0, 1) === "0") {
        newErrors.quantity = "Leading 0 is not valid";
      }
    }
    if (doesEmpty(partNumber)) {
      newErrors.partNumber = "Part Number is required";
    } else if (!hasValidPartNumberCharacters(partNumber)) {
      newErrors.partNumber =
        "Part Number is restricted to alpanumeric, space, and hyphen";
    }
    const errorCnt = Object.keys(newErrors).length;
    if (errorCnt !== 0) {
      setErrors(newErrors);
    }
    return errorCnt === 0;
  };
  const clearErrors = () => {
    setErrors({});
  };

  const isValidQuantity = qty => {
    const value = toEmptyStringIfUndefined(qty);
    const x = value.replace(/^0+/, "");
    if (x !== value) {
      return false;
    }
    return !(doesEmpty(value) || isNaN(value) || value > 100);
  };
  const isValidPartNumber = value => {
    return !doesEmpty(value) && hasValidPartNumberCharacters(value);
  };
  const hasValidPartNumberCharacters = value => {
    const regex = /^[a-zA-Z0-9]([ \-a-zA-Z0-9*]){0,}$/i;
    if (value && !regex.test(value.trim())) {
      return false;
    }
    return true;
  };

  return (
    <div>
      <Row>
        <Col xs={2} md={2}>
          <span>
            Quantity <span className="xmm-red-label">*</span>
          </span>
        </Col>
        <Col xs={3} md={3}>
          <span>
            Part Number <span className="xmm-red-label">*</span>
          </span>
        </Col>
        <Col xs={3} md={3}>
          <span>
            Manufacturer <span className="xmm-red-label">*</span>
          </span>
        </Col>
      </Row>
      <Row>
        <Col xs={2} md={2} className="part-grid-field-content">
          <NumericInput
            type="text"
            htmlId="quantity"
            displayLabel={false}
            maxLength={3}
            value={quantity}
            error={errors.quantity}
            autoComplete="off"
            onChange={onChangeQuantity}
          />
        </Col>
        <Col xs={3} md={3} className="part-grid-field-content">
          <TextInput
            type="text"
            htmllId="partNumber"
            displayLabel={false}
            maxLength={32}
            value={partNumber}
            error={errors.partNumber}
            autoComplete="off"
            onChange={onChangePartNumber}
          />
        </Col>
        <Col xs={3} md={3} className="part-grid-field-content">
          <SelectInput
            htmlId="partManufacturer"
            displayLabel={false}
            placeholder="Select"
            disabled={false}
            displayDeselectOption={false}
            displayPlaceholder={false}
            maxHeight={100}
            name="viewMode"
            value={partManufacturer}
            options={partManufacturerOptions}
            onChange={onChangePartManufacturer}
          />
        </Col>
        <Col
          xs={2}
          md={2}
          className="part-grid-field-content btn-bottom-aligned"
        >
          <Button
            buttonStyle="primary"
            //   disabled={!enableAdd}
            onClick={() => {
              if (validateFields()) {
                const newPart = {
                  quantity,
                  partNumber,
                  partManufacturer,
                  relationship: "Primary"
                };
                props.onAddPart(newPart);
                resetInputToDefaults();
              }
            }}
          >
            Add part
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default AddPartInput;

AddPartInput.propTypes = {
  defaultPartManufacturerCode: PropTypes.string,
  partManufacturerOptions: PropTypes.array,
  onAddPart: PropTypes.func
};

AddPartInput.defaultProps = {
  defaultPartManufacturerCode: "",
  partManufacturerOptions: [],
  onAddPart: () => {}
};
