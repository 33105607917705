const DEFAULT_APP_CONFIG = {
  userPermissions: {},
  applicationType: null,
  isDebug: false,
  isDealerTireEnabled: false,
  webKey: "",
  dealer: {
    dealerCode: ""
  },
  schemaName: "",
  user: {
    userId: "",
    userName: ""
  },
  customOperation: false,
  quickFilterAccess: {
    topServices: true,
    menu: false,
    diagnosis: false,
    declined: false,
    recall: false,
    tires: false
  },
  editModuleAccess: false,
  showPrice: true,
  showOpcode: false
};
// @place holder
const CATALOG_SEARCH_CONFIG = {
  userPermissions: {},
  isDebug: false,
  isDealerTireEnabled: false,
  webKey: "",
  dealer: {
    dealerCode: ""
  },
  schemaName: "",
  user: {
    userId: "",
    userName: ""
  },
  customOperation: false,
  quickFilterAccess: {
    topServices: true,
    menu: false,
    diagnosis: false,
    declined: false,
    recall: false,
    tires: false
  },
  editModuleAccess: false,
  showPrice: true,
  showOpcode: true
};
// @place holder
const INSPECT_SEARCH_CONFIG = {
  userPermissions: {},
  isDebug: false,
  isDealerTireEnabled: false,
  webKey: "",
  dealer: {
    dealerCode: ""
  },
  schemaName: "",
  user: {
    userId: "",
    userName: ""
  },
  customOperationPage: false,
  quickFilterAccess: {
    topServices: true,
    menu: false,
    diagnosis: false,
    declined: false,
    recall: false,
    tires: false
  },
  editModuleAccess: false,
  showPrice: false,
  showOpcode: true
};

export { DEFAULT_APP_CONFIG, CATALOG_SEARCH_CONFIG, INSPECT_SEARCH_CONFIG };
