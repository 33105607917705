/* eslint-disable react/prop-types */
import React, { Component } from "react";
import {
  isCopyAndPaste,
  isDeleteOrBackspace,
  isKeyPressedPrintable,
  isLeftOrRight,
  isNavKeyPressed
} from "../utils/keyNavigation";
import { sanitize } from "../utils/string";

const KEY_F2 = 113;
const LocaleValueMaxLengthMap = {
  MENU: { DESC: 64, BENEFITS: 4000 },
  SERVICE: { INTNAME: 512, EXTNAME: 512, DESC: 4000 }
};

export default class LocaleValueEditor extends Component {
  constructor(props) {
    super(props);

    this.inputRef = React.createRef();
    this.cancelBeforeStart = false;
    this.state = this.createInitialState(props);

    this.onKeyDown = this.onKeyDown.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.inputRef.current.addEventListener("keydown", this.onKeyDown);
  }

  componentWillUnmount() {
    this.inputRef.current.removeEventListener("keydown", this.onKeyDown);
  }

  getMaxLength(data) {
    const { category, type } = data;
    const categoryMap = LocaleValueMaxLengthMap[category];
    const maxlength = categoryMap ? categoryMap[type] : -1;
    return maxlength ? maxlength : -1;
  }
  createInitialState(props) {
    let startValue = "";
    let highlightAllOnFocus = true;
    const maxlength = this.getMaxLength(props.data);

    const event = { keyCode: props.keyPress };
    if (isDeleteOrBackspace(event)) {
      // if backspace or delete pressed, we clear the cell
      startValue = "";
    } else if (props.charPress) {
      // if a letter was pressed, we start with the letter
      startValue = props.charPress;
      highlightAllOnFocus = false;
    } else {
      // otherwise we start with the current value
      startValue = props.value || "";
      if (props.keyPress === KEY_F2) {
        highlightAllOnFocus = false;
      }
    }

    return {
      maxlength,
      value: startValue,
      highlightAllOnFocus
    };
  }

  afterGuiAttached() {
    // get ref from React component
    const eInput = this.inputRef.current;
    eInput.focus();
    if (this.state.highlightAllOnFocus) {
      eInput.select();

      this.setState({
        highlightAllOnFocus: false
      });
    } else {
      // when we started editing, we want the carot at the end, not the start.
      // this comes into play in two scenarios: a) when user hits F2 and b)
      // when user hits a printable character, then on IE (and only IE) the carot
      // was placed after the first character, thus 'apply' would end up as 'pplea'
      const length = eInput.value ? eInput.value.length : 0;
      if (length > 0) {
        eInput.setSelectionRange(length, length);
      }
    }
  }

  getValue() {
    return this.state.value;
  }

  isCancelBeforeStart() {
    return this.cancelBeforeStart;
  }

  // will reject the number if it greater than 1,000,000
  // not very practical, but demonstrates the method.
  isCancelAfterEnd() {
    return false; // this.state.value > 1000000;
  }

  onKeyDown(event) {
    if (isLeftOrRight(event) || isDeleteOrBackspace(event)) {
      event.stopPropagation();
      return false;
    }

    if (
      !(
        isNavKeyPressed(event) ||
        isKeyPressedPrintable(event) ||
        isCopyAndPaste(event)
      )
    ) {
      if (event.preventDefault) {
        event.preventDefault();
        return false;
      }
    }
    return true;
  }

  handleChange(event) {
    // prevent copy and paste of non-numeric character
    const { value } = event.target;
    this.setState({ value: sanitize(value) });
  }

  getCharCodeFromEvent(event) {
    // eslint-disable-next-line no-param-reassign
    event = event || window.event;
    return typeof event.which === "undefined" ? event.keyCode : event.which;
  }

  render() {
    return (
      <input
        ref={this.inputRef}
        maxLength={this.state.maxlength}
        value={this.state.value}
        onChange={this.handleChange}
        className="xmm-cell-editor"
      />
    );
  }
}
