/* eslint-disable no-console */
import React from "react";
import Button from "@cx/ui/Button";
import ModalDialog from "@cx/ui/ModalDialog";
import { PropTypes } from "prop-types";
import { FormattedMessage } from "react-intl";
import SelectInput from "@cx/ui/SelectInput";
import { makeSecureRestApi } from "../../../../../../api/xmmAxios";
import { toast } from "@cx/ui/Toast";
import { AppContext } from "../../../../../app-context";
import { xlate } from "../../../../../../commonUtil/i18n/locales";

export default class CopyToMenuTypeModal extends React.Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);
    this.createDealerRules = this.createDealerRules.bind(this);
    this.initializeLocaleValues();
    const { dealerScheduleRules, menuTypes } = props;
    this.selectMenuTypeLabel = xlate("xmm.portal.common.select_menu_type");
    const filteredMenuTypes = this.filterMenuTypes(
      dealerScheduleRules,
      menuTypes
    );
    this.state = {
      menuTypes: filteredMenuTypes,
      menuTypeId: ""
    };
  }

  componentDidMount() {}
  initializeLocaleValues() {
    this.savingMsg = xlate("xmm.portal.common.saving");
    this.savedMsg = xlate("xmm.portal.common.saved");
  }

  filterMenuTypes = (dealerScheduleRules, menuTypes) => {
    const menuTypeIdMap = {};
    dealerScheduleRules.forEach(rule => {
      const { menuTypeId } = rule;
      menuTypeIdMap[menuTypeId.toString()] = true;
    });
    const filteredMenuTypes = menuTypes.filter(menuType => {
      const { menuTypeId } = menuType;
      return !menuTypeIdMap[menuTypeId.toString()];
    });
    return filteredMenuTypes;
  };

  onChange = (cxEvent, isValid, domEvent) => {
    const { value } = cxEvent.target;
    this.setState({ menuTypeId: value });
  };

  getPayload = () => {
    const { menuTypeId } = this.state;
    const { dealerScheduleRules } = this.props;
    const ruleIds = dealerScheduleRules.map(rule => {
      return rule.dealerScheduleRuleId.toString();
    });
    return { dealerScheduleRuleIds: ruleIds, menuTypeId };
  };
  createDealerRules = () => {
    const { updateStatusBox } = this.props;
    updateStatusBox(this.savingMsg, "pending", false);
    const data = this.getPayload();
    const method = "post";
    const url = "/ops/proxyapi/ddsproxy/rest/proc/copyToMenuType";
    makeSecureRestApi(
      {
        url,
        method,
        data
      },
      dataList => {
        console.log(dataList);
        this.props.updateDealerMenuGrid();
        updateStatusBox(this.savedMsg, "success", true);
      },
      error => {
        toast.error(error.message);
      }
    );
  };

  render() {
    // const { closeDialog, copyToMenuType, dealerScheduleRules } = this.props;
    const { closeDialog } = this.props;
    const { menuTypeId, menuTypes } = this.state;
    return (
      <div>
        <ModalDialog
          htmlId="CopyToMenuTypeModal"
          // className="sample-modal-dialog"
          show={this.props.show}
          header={
            <ModalDialog.Title>
              <FormattedMessage
                defaultMessage="Copy Schedule Rules to a Different Menu Type"
                id="xmm.portal.dealermenus.copy_to_menu_type"
              />
            </ModalDialog.Title>
          }
          footer={
            <div>
              <SelectInput
                htmlId="menuTypeSelect"
                label="Menu Type"
                name="menuType"
                placeholder={this.selectMenuTypeLabel}
                displayDeselectOption={false}
                displayLabel={false}
                options={menuTypes}
                onChange={this.onChange}
                maxHeight={200}
              />
              <Button
                htmlId="cancelCopyButton"
                onClick={closeDialog}
                text="Close Button"
                buttonStyle="secondary"
              >
                <FormattedMessage
                  defaultMessage="Cancel"
                  id="xmm.portal.common.cancel_button"
                />
              </Button>
              <Button
                htmlId="okCopyButton"
                onClick={() => {
                  this.createDealerRules();
                  closeDialog();
                }}
                text="Select"
                buttonStyle="primary"
                disabled={!menuTypeId}
              >
                <FormattedMessage
                  defaultMessage="Copy"
                  id="xmm.portal.common.copy_label"
                />
              </Button>
            </div>
          }
          onHide={this.props.closeDialog}
        />
      </div>
    );
  }
}

CopyToMenuTypeModal.propTypes = {
  show: PropTypes.bool,
  updateDealerMenuGrid: PropTypes.func,
  updateStatusBox: PropTypes.func,
  closeDialog: PropTypes.func,
  make: PropTypes.string,
  dealerScheduleRules: PropTypes.array,
  menuTypes: PropTypes.array
};
