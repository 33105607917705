import isEmpty from "lodash/isEmpty";
import operationDetailsSchema from "../../schemas/operation-details.schema.js";
import {
  preparePartsWithDealerTire,
  getNotes,
  getTagName
} from "./dealer-tire-util";
// @note: convert raw response of DealerTire API to readable service records
const processDealerTireServices = rawData => {
  const responseObj = {};
  const services = [];
  const parts = [];
  const count = rawData.count;
  let totalPrice = 0;
  const arrayBrand = rawData.brand.split(",");
  const arrayModel = rawData.model.split(",");
  const arrayQuantity = rawData.quantity.split(",");
  const arrayRetail = !rawData.retail ? "" : rawData.retail.split(",");
  const arrayDiscount = !rawData.discount ? "" : rawData.discount.split(",");
  const arrayPart = !rawData.part ? "" : rawData.part.split(",");
  const arraySize = !rawData.sizeDescription
    ? ""
    : rawData.sizeDescription.split(",");
  const totalTires = arrayQuantity.reduce((acc, next) => {
    return acc + parseInt(next, 10);
  }, 0);
  for (let i = 0; i < count; i++) {
    const retailPrice = arrayRetail[i] || 0;
    const discountPrice = arrayDiscount[i]
      ? arrayDiscount[i].replace(/(?:\$)/gi, "")
      : 0;
    const price = parseFloat(retailPrice) - parseFloat(discountPrice) || 0;
    const quantity = !isEmpty(arrayQuantity[i]) ? arrayQuantity[i] : 0;
    const unitPrice = price / quantity;
    totalPrice += price;
    const keyId = generateRandomIntegerInRange(100, 9999);
    const record = {
      id: keyId.toString(),
      brand: arrayBrand[i],
      model: arrayModel[i],
      quantity,
      unitPrice,
      partsPrice: price,
      part: arrayPart[i],
      sizeDescription: arraySize[i]
    };
    record.notes = getNotes(record);
    record.tagName = getTagName(totalTires);
    services.push(record);
    const part = preparePartsWithDealerTire(record);
    parts.push(part);
  }
  responseObj.services = services;
  responseObj.parts = parts;
  responseObj.totalPrice = totalPrice;
  return responseObj;
};

const generateRandomIntegerInRange = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

// @note - use mock data of one catalog API tire service format same as unified response
function tireServiceMapper(tireServices, catalogTireService) {
  const localTireService = !isEmpty(catalogTireService)
    ? catalogTireService
    : null;
  const globalOperation = operationDetailsSchema.cast(localTireService);
  const { laborApps } = localTireService;
  const operation = laborApps[0];
  let serviceType = "";
  if (!isEmpty(tireServices)) {
    const { totalPrice, parts } = tireServices;
    operation.totalPrice = totalPrice;
    operation.parts = !parts ? [] : parts;
    operation.rawTireService = tireServices;
    if (
      globalOperation.serviceKind === "repair" &&
      globalOperation.catalogSource === "DealerPublishedCatalog"
    ) {
      serviceType = "DEALER_PUB_REPAIR_OPS";
    }
    // TOOD: prepare notes text using dealer tire record
    if (!isEmpty(parts)) {
      const firstPart = parts[0];
      let partName = firstPart.partName;
      let quantity = firstPart.quantity;
      const moreParts = parts.slice(1);
      if (moreParts.length > 0) {
        moreParts.forEach(part => {
          partName = partName.concat(", ", part.partName);
          quantity += part.quantity;
        });
      }
      const dealerTireComments = `Tire pricing from DealerTire. ${quantity} tires selected. ${partName}.`;
      operation.notes = dealerTireComments;
    }
    const mapOperation = {
      ...operation,
      serviceName: operation.name,
      serviceKind: globalOperation.serviceKind,
      source: serviceType,
      operationSource: globalOperation.catalogSource,
      price: totalPrice,
      partsPrice: totalPrice,
      categoryId: !globalOperation.serviceCategoryId
        ? ""
        : globalOperation.serviceCategoryId,
      categoryName: !globalOperation.serviceCategoryName
        ? ""
        : globalOperation.serviceCategoryName,
      categoryGroupName: "",
      categoryGroupId: ""
    };
    globalOperation.laborApps[0] = mapOperation;
  }
  // @note: rawService should have rawTireService object to extract service in modify parts (summary page)
  const rawService = JSON.stringify(globalOperation);
  const formattedService = {
    ...globalOperation,
    source: serviceType,
    id: String(globalOperation.operationId),
    serviceKind: globalOperation.serviceKind,
    operationSource: globalOperation.catalogSource,
    quoteRawService: { rawService }
  };
  return formattedService;
}

const filterDealerTireService = (services, tokenStr) => {
  const matches = services.filter(item => {
    const tagStr = !item["tags"] ? "" : item["tags"].length > 0 && item.tags[0];
    return tagStr === tokenStr;
  });
  return matches.length !== 0 ? matches[0] : null;
};

const filterServiceByTag = (catalogServices, tireServices) => {
  const { services } = tireServices;
  const token = services[0].tagName;
  return filterDealerTireService(catalogServices, token);
};

// util to return default paytype parse thru unified response
const getDefaultPayType = (operationSource, payType) => {
  const tempPayType = !payType ? "C" : payType;
  const payTypeCode =
    operationSource === "RECALL"
      ? "W"
      : operationSource === "DECLINED"
      ? "C"
      : tempPayType;
  return payTypeCode;
};

export default {
  getDefaultPayType,
  processDealerTireServices,
  filterServiceByTag,
  tireServiceMapper
};
