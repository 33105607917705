// refer: https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent
export const IS_MOBILE = window.navigator.userAgent.indexOf("Mobi") > -1;
export const IS_IE = window.navigator.userAgent.indexOf("Trident") > -1;
export const IS_EDGE = window.navigator.userAgent.indexOf("Edge") > -1;
export const IS_CHROME = window.navigator.userAgent.indexOf("Chrome") > -1;

/* Use this file, Add ag-grid controls to global state  */
export const initAppConfig = {
  dashboard: {
    make: "all",
    lastPublishMsg: ""
  },
  demoGridState: {
    colState: null,
    filterState: null,
    sortState: null,
    pivotState: null,
    searchKey: ""
  },
  operationsGrid: {
    colState: null,
    filterState: null,
    sortState: null,
    pivotState: null,
    searchKey: "",
    variantType: "ALL",
    viewMode: "basic"
  },
  opcodeValidationGrid: {
    colState: null,
    filterState: null,
    sortState: null,
    pivotState: null,
    filters: {
      make: "all",
      type: "all",
      kind: "all",
      opcodestatus: "all",
      contentstatus: "all",
      searchkey: ""
    }
  },
  partsPricingGrid: {
    colState: null,
    filterState: null,
    sortState: null,
    pivotState: null,
    searchKey: "",
    variantType: "ALL"
  },
  fluidsPricingGrid: {
    colState: null,
    filterState: null,
    sortState: null,
    pivotState: null,
    searchKey: "",
    variantType: "ALL"
  },
  laborRatesGrid: {
    colState: null,
    filterState: null,
    sortState: null,
    pivotState: null,
    searchKey: ""
  },
  payTypesGrid: {
    colState: null,
    filterState: null,
    sortState: null,
    pivotState: null,
    searchKey: ""
  },
  menuTypesGrid: {
    selectedMenuType: "all",
    showDisabled: true
  },
  dealerMenusGrid: {
    colState: null,
    filterState: null,
    sortState: null,
    pivotState: null,
    make: "",
    searchKey: ""
  },
  packagesGrid: {
    colState: null,
    filterState: null,
    sortState: null,
    pivotState: null,
    searchKey: "",
    packageType: "ALL"
  },
  reportsGrid: {
    make: ""
  },
  translationGrid: {
    colState: null,
    filterState: null,
    sortState: null,
    pivotState: null,
    searchKey: "",
    viewMode: "ALL"
  }
};
