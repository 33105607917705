/* eslint-disable no-console */
import React, { Component } from "react";
import { PropTypes } from "prop-types";
import Card from "@cx/ui/Card";
import Row from "@cx/ui/Row";
import Col from "@cx/ui/Col";
import Button from "@cx/ui/Button";
import Alert from "@cx/ui/Alert";
import TextInput from "@cx/ui/TextInput";
import NumericInput from "@cx/ui/NumericInput";
import PriceInput from "@cx/ui/PriceInput";
import IconSearch from "@cx/ui/Icons/IconSearch";
import StatusBox from "../../../../../commonUtil/components/templates/StatusBox";
import { PackageContext } from "../package-context";
import * as formvalidator from "../../../../formik/formvalidator";
import { isDifferentValue } from "../../../../../commonUtil/utils/string";
import { makeSecureRestApi } from "../../../../../api/xmmAxios";
import { toastMessageFormatter } from "../../../../../commonUtil/utils/formatter";
import * as gtmEvents from "../../../../utils/gtag-eventlist";
import { xlate } from "../../../../../commonUtil/i18n/locales";

class OverrideField extends Component {
  static contextType = PackageContext;
  static propTypes = {
    onChangeInput: PropTypes.func
  };
  constructor(props, context) {
    super(props, context);
    this.onBlur = this.onBlur.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.updateStatusBox = this.updateStatusBox.bind(this);
    this.initializeLocaleValues();
    this.state = {
      bulkEditPackageType: context.bulkEditPackageType,
      bulkEditMake: context.bulkEditMake,
      dmsOpcode: "",
      shopDuration: "",
      price: "",
      dirty: false,
      valid: false,
      errors: {
        dmsOpcode: "",
        shopDuration: "",
        price: ""
      }
    };
  }

  componentDidMount() {}
  initializeLocaleValues() {
    this.selectSameTypeError = xlate("xmm.portal.errors.select_same_type");
    this.bulkeditEnterValueLabel = xlate(
      "xmm.portal.common.bulkedit_enter_Value"
    );
    this.opcodeLabel = xlate("xmm.portal.common.opcode");
    this.priceLabel = xlate("xmm.portal.packages.price_lbl");
    this.schedulingDurationLabel = xlate(
      "xmm.portal.common.schedule_duration_lbl"
    );
    this.fieldRequiredError = xlate("xmm.portal.errors.required_field");
    this.invalidOpcodeError = xlate("xmm.portal.errors.opcode_invalid");
    this.exceedMaxShopDurationError = xlate(
      "xmm.portal.errors.exceed_max_shop_duration"
    );
    this.exceedMaxPriceError = xlate("xmm.portal.errors.exceed_max_price");
    this.applyEditLabel = xlate("xmm.portal.common.apply_edit");
    this.correctInvalidFielsError = xlate(
      "xmm.portal.errors.correct_invalid_fields"
    );
    this.savingMsg = xlate("xmm.portal.common.saving");
    this.savedMsg = xlate("xmm.portal.common.saved");
    this.saveError = xlate("xmm.portal.errors.save_data_error");
  }
  // validate value on blur event
  // NOTE: CX BUG - event.target.name missing in NumberInput, TextInput, TextArea
  onBlur = (cxEvent, isValid, domEvent) => {
    const { name, id } = cxEvent.target;
    const endIndex = id.indexOf("-") !== -1 ? id.indexOf("-") : id.length;
    const fieldName = name !== "" ? name : id.substring(0, endIndex);
    const valid = this.validate(fieldName);
    this.setState({ valid });
  };

  // CX BUG - blur event not exposed; Hence handle blur event inside onChange()
  onChangeInput = (event, isValid, domEvent) => {
    const { name, value } = event.target;
    // NOTE: CX BUG - event.target.name missing in NumberInput, TextInput, PriceInput
    if (domEvent && domEvent.type === "blur") {
      // console.log("blur", name, value);
      this.validate(name);
      return;
    }
    if (isDifferentValue(this.state[name], value)) {
      this.markDirty(name);
      this.setState(
        prevState => {
          const { errors } = prevState;
          return {
            [name]: value,
            errors
          };
        },
        () => {
          // callback to validate form
          this.validate(name);
        }
      );
    }
  };
  // validation utils
  // call this for each field change event
  markDirty(fieldName, validate) {
    const dirty = true;
    if (validate) {
      const valid = this.validate(fieldName);
      this.setState({ dirty, valid });
    } else {
      this.setState({ dirty });
    }
  }

  /* common validator called upon onblur() of each field */
  validate(fieldName) {
    const { errors } = this.state;
    const { dmsOpcode, shopDuration, price } = this.state;
    const packageType = this.state.bulkEditPackageType;
    let valid = true;
    // validate for these fields only
    if (
      !fieldName ||
      fieldName === "dmsOpcode" ||
      fieldName === "price" ||
      fieldName === "shopDuration"
    ) {
      if (packageType === "DMS_OPCODE") {
        if (!dmsOpcode) {
          errors["dmsOpcode"] = !dmsOpcode ? this.fieldRequiredError : "";
          valid = false;
        } else if (
          dmsOpcode &&
          formvalidator.validateAlphaNumPunctuation(dmsOpcode)
        ) {
          errors["dmsOpcode"] = this.invalidOpcodeError;
          valid = false;
        } else {
          errors["dmsOpcode"] = "";
        }
      } else if (packageType === "PRICE") {
        if (!price) {
          errors["price"] = !price ? this.fieldRequiredError : "";
          valid = false;
        } else if (price && (parseFloat(price) || 0) > 9999.99) {
          errors["price"] = this.exceedMaxPriceError;
          valid = false;
        } else {
          errors["price"] = "";
        }
      } else if (packageType === "SHOP_DURATION") {
        if (!shopDuration) {
          errors["shopDuration"] = !shopDuration ? this.fieldRequiredError : "";
          valid = false;
        } else if (shopDuration && (parseInt(shopDuration, 10) || 0) > 1440) {
          errors["shopDuration"] = this.exceedMaxShopDurationError;
          valid = false;
        } else {
          errors["shopDuration"] = "";
        }
      }
    }
    this.setState({ valid, errors });
    return valid;
  }

  /* This func) checks for error object has strings with {null,"", undefined} in given object
  and returns count for error strings.*/
  hasErrorStrings(state) {
    const array1 = Object.values(state);
    const iterator = array1.values();
    let errCount = 0;
    for (const value of iterator) {
      if (value === "" || value === null || value.length === 0) {
        // In case of valid error string
      } else if (value && value.length > 1) {
        errCount++;
      }
    }
    return errCount === 0 ? false : true;
  }

  getEditPayload() {
    const { appContext, selectionlist } = this.context;
    const { locale } = appContext;
    const { bulkEditPackageType, dmsOpcode, price, shopDuration } = this.state;
    const selections = [];
    const requestObj = {};
    if (selectionlist && selectionlist.length > 0) {
      selectionlist.forEach(op => {
        if (!op.id) {
          // donot add to list
        } else {
          selections.push(op.id.toString());
        }
      });
      requestObj["packages"] = selections;
      requestObj.locale = locale;
      requestObj.dmsOpcode = null;
      requestObj.shopDuration = null;
      requestObj.price = null;
    }
    if (bulkEditPackageType === "DMS_OPCODE" && dmsOpcode) {
      requestObj.dmsOpcode = dmsOpcode;
    } else if (bulkEditPackageType === "PRICE" && price) {
      requestObj.price = price;
    } else if (bulkEditPackageType === "SHOP_DURATION" && shopDuration) {
      requestObj.shopDuration = shopDuration;
    }
    // console.log("bulkedit postobj", requestObj);
    return requestObj;
  }
  handleApply = e => {
    e.preventDefault();
    this.updateStatusBox(this.savingMsg, "pending", false);
    let isValid = true;
    // check if any errors exist on fields
    if (Object.keys(this.state.errors).length) {
      isValid = !this.hasErrorStrings(this.state.errors);
    }
    // cancel save if we have errors on fields
    if (!isValid) {
      // toast.warning(this.correctInvalidFielsError);
      this.updateStatusBox(this.correctInvalidFielsError, "warning", false);
      return;
    }
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };
    const payload = this.getEditPayload();
    const restEndPoint =
      "ops/proxyapi/ddsproxy/rest/proc/bulkUpdatePackageAttribute";
    // call rest api
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "post",
        data: payload,
        params: {},
        headers
      },
      data => {
        if (data) {
          // const regex = /\n/gi;
          let msgToShow = "";
          // Error case
          if (data.error) {
            msgToShow =
              "Unable to save Bulk Edit for selected packages at this time. Please try again.";
            this.updateStatusBox(msgToShow, "error", false, true);
          }
          // Overlap case - duplicate packages found
          if (data.response && data.response.statusCode !== 0) {
            msgToShow = toastMessageFormatter(data.response.statusMessage);
            this.updateStatusBox(msgToShow, "error", false, true);
            // Success case
          } else if (data.response && data.response.statusCode === 0) {
            // msgToShow = toastMessageFormatter(data.response.statusMessage);
            this.updateStatusBox(this.savedMsg, "success", true);
            // callback to close slider and refresh grid rows.
            setTimeout(() => {
              this.props.closeSlider();
            }, 2000);
            const record = {
              packageType: this.state.bulkEditPackageType,
              make: this.state.bulkEditMake
            };
            this.context.updateGridAfterSave(record);
          }
        }
      },
      error => {
        const msg = error["message"] ? error.message : this.saveError;
        this.updateStatusBox(msg, "error", false, true);
      }
    );
    this.trackBulkEditEvent(this.state.bulkEditPackageType);
  };

  // push event to GTM
  trackBulkEditEvent = fieldName => {
    const tagObj = {
      eventResult: "Bulk Edit - " + fieldName,
      eventFeature: "Menus> Packages Page",
      eventFeatures: "packages page> BulkEdit> " + fieldName,
      trackPageUrl: "menus/packages/bulk-edit/" + fieldName.toLowerCase()
    };
    gtmEvents.gtmTrackEventWithParams("xmm.packages.bulk_edit_click", tagObj);
  };

  // eslint-disable-next-line no-unused-vars
  setOpcodeValue = dmsOpcode => {
    this.setState({ dmsOpcode }, () => {
      this.markDirty("dmsOpcode", true);
    });
  };
  updateStatusBox(msg, type, close, errorInTooltip) {
    console.log("status", msg, type, close);
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
    sleep(0).then(() => {
      this.setState({
        statusMsg: msg,
        autoClose: close,
        statusType: type,
        errorInTooltip
      });
    });
  }
  render() {
    const { dmsOpcode, price, shopDuration, errors, valid, dirty } = this.state;
    const packageType = this.state.bulkEditPackageType;
    const warnMsg = !packageType
      ? xlate("xmm.portal.errors.select_same_type")
      : "";
    const clsHideOpcode = packageType === "DMS_OPCODE" ? "" : "hide";
    const clsHidePrice = packageType === "PRICE" ? "" : "hide";
    const clsHideDuration =
      packageType === "SHOP_DURATION" ? "long-label" : "hide";
    const clsApply = !packageType ? "hide" : "float-right";
    let alertMsg = null;
    let helpText = null;
    if (!packageType) {
      alertMsg = (
        <Alert htmlId="valueAlert" type="warning">
          {warnMsg}
        </Alert>
      );
    } else {
      helpText = (
        <div className="xmm-help-text">{this.bulkeditEnterValueLabel}</div>
      );
    }
    const markDisabled = !valid || !dirty;
    const statusBox = this.state.statusMsg ? (
      <StatusBox
        htmlId="statusBox"
        type={this.state.statusType}
        autoClose={this.state.autoClose}
        linkHtml={null}
        message={this.state.statusMsg}
        autoCloseTime={1500}
        errorInTooltip={this.state.errorInTooltip}
      />
    ) : (
      ""
    );

    return (
      <div>
        <Row>
          <Col xs={12} sm={12}>
            {alertMsg}
          </Col>
        </Row>
        <Row>
          <Col xs={7} sm={7}>
            {helpText}
          </Col>
          <Col xs={5} sm={5}>
            <div className="xmm-tab-bar no-margin">
              {statusBox}{" "}
              <Button
                htmlId="applyValueBtn"
                className={clsApply}
                size="sm"
                disabled={markDisabled}
                onClick={this.handleApply}
                buttonStyle="primary"
              >
                {this.applyEditLabel}
              </Button>
            </div>
          </Col>
        </Row>
        <Card>
          <Col xs={9} sm={9} className="full-col">
            <TextInput
              htmlId="dmsOp"
              label={this.opcodeLabel}
              name="dmsOpcode"
              layout="horizontal"
              className={clsHideOpcode}
              value={dmsOpcode || ""}
              onChange={this.onChangeInput}
              error={errors.dmsOpcode}
              appendChild={
                <Button
                  // aria-label="More"
                  htmlId="findopcodeBtn"
                  icon={<IconSearch htmlId="iconSearchForm" />}
                  buttonStyle="secondary"
                  onClick={() => {
                    // show Find Op Code dialog with blank serivceId, internalName, dmsOpcode, and a callback to set opcode value in BullkEditPane
                    this.props.showFindOpcodeModal(
                      "",
                      "",
                      "",
                      "",
                      this.setOpcodeValue
                    );
                  }}
                />
              }
            />
            <PriceInput
              htmlId="price"
              label={this.priceLabel}
              name="price"
              layout="horizontal"
              className={clsHidePrice}
              onChange={this.onChangeInput}
              value={price || ""}
              error={errors.price}
              maxLength={7}
              minLength={0}
            />
            <NumericInput
              htmlId="shopDuration"
              label={this.schedulingDurationLabel}
              name="shopDuration"
              layout="horizontal"
              className={clsHideDuration}
              onChange={this.onChangeInput}
              error={errors.shopDuration}
              value={shopDuration || ""}
              inputSuffix={"Minutes"}
              maxLength={4}
              minLength={0}
            />
          </Col>
        </Card>
      </div>
    );
  }
}

export default OverrideField;

OverrideField.propTypes = {
  closeSlider: PropTypes.func,
  showFindOpcodeModal: PropTypes.func
};
/* eslint-enable no-console */
