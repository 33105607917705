/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React, { Component } from "react";
import IconSearch from "@cx/ui/Icons/IconSearch";
import { PropTypes } from "prop-types";
import { sanitize } from "../utils/string";
import { isDeleteOrBackspace } from "../utils/keyNavigation";

const KEY_F2 = 113;

export default class OpcodeEditor extends Component {
  constructor(props) {
    super(props);

    this.inputRef = React.createRef();

    this.onChange = this.handleChange.bind(this);
    this.onClick = this.handleClick.bind(this);
    this.state = this.createInitialState(props);
  }

  getMaxLength(field) {
    if (field === "currentOpcode") {
      return 256;
    } else if (field === "dmsOpcode") {
      return 256;
    }
    return -1;
  }

  createInitialState(props) {
    let startValue;
    let highlightAllOnFocus = true;
    const maxlength = this.getMaxLength(props.colDef.field);

    const event = { keyCode: props.keyPress };
    if (isDeleteOrBackspace(event)) {
      // if backspace or delete pressed, we clear the cell
      startValue = "";
    } else if (props.charPress) {
      // if a letter was pressed, we start with the letter
      startValue = props.charPress;
      highlightAllOnFocus = false;
    } else {
      // otherwise we start with the current value
      startValue = props.value;
      if (props.keyPress === KEY_F2) {
        highlightAllOnFocus = false;
      }
    }
    return {
      data: props.data,
      value: startValue || "",
      highlightAllOnFocus,
      maxlength
    };
  }

  handleChange = event => {
    const value = sanitize(event.target.value);
    this.setState({ value });
  };

  handleClick = event => {
    console.log("OpcodeEditor.onClick", event);
    const { parentHandle } = this.props;
    window.dispatchEvent(
      new CustomEvent("showFindOpcodeEvent", {
        detail: {
          data: this.state.data,
          colDef: this.props.colDef,
          parentHandle
        },
        bubbles: true,
        cancelable: true
      })
    );
  };

  getValue() {
    return this.state.value;
  }

  // for testing
  setValue(newValue) {
    this.setState({
      value: newValue
    });
  }

  isCancelBeforeStart() {
    return false;
  }

  isCancelAfterEnd() {
    return false;
  }

  afterGuiAttached() {
    const eInput = this.inputRef.current;
    eInput.focus();
    if (this.state.highlightAllOnFocus) {
      eInput.select();

      this.setState({
        highlightAllOnFocus: false
      });
    } else {
      // when we started editing, we want the carot at the end, not the start.
      // this comes into play in two scenarios: a) when user hits F2 and b)
      // when user hits a printable character, then on IE (and only IE) the carot
      // was placed after the first character, thus 'apply' would end up as 'pplea'
      const length = eInput.value ? eInput.value.length : 0;
      if (length > 0) {
        eInput.setSelectionRange(length, length);
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="xmm-search-opcode-cell">
          <span onClick={this.handleClick}>
            <IconSearch htmlId="opcodeSearch" />
          </span>
          <input
            className="xmm-cell-editor"
            ref={this.inputRef}
            type="text"
            maxLength={this.state.maxlength}
            value={this.state.value}
            onChange={this.handleChange}
          />
        </div>
      </React.Fragment>
    );
  }
}

OpcodeEditor.propTypes = {
  parentHandle: PropTypes.object
};
