import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import has from "lodash/has";
import IconArrowUpward from "@cx/ui/Icons/IconArrowUpward";
import ConfirmPopup from "../../components/modals/ConfirmPopup";
import {
  useServiceSearchContext,
  Actions
} from "../../state/service-search.context";
import serviceDataMapper from "../../utils/service-data-mapper";
import { convertHoursToMinutes } from "../../utils/quote.util";
import { saveActionTypes } from "../../utils/search.constants";

const EditRecallServicePage = props => {
  const { service, updateParentState, EditServiceModule } = props;
  const { dispatch, state, ctxRequestPartsInventoryCommon, ctxOnEditService } =
    useServiceSearchContext();
  const { localeStrings, debugQuote, vehicle } = state;
  const [localService, setLocalService] = useState(null);
  const [showDiscardEditPopup, setShowDiscardEditPopup] = useState(false);
  const [serviceHasChanged, setServiceHasChanged] = useState(false);
  const payTypes = state.payTypes;

  useEffect(() => {
    setLocalService(service);
  }, [service]);

  const backToService = () => {
    updateParentState(false);
    dispatch({
      type: Actions.SET_SEARCH_HEADER,
      payload: true
    });
    setLocalService(null);
  };

  const handleServiceChange = serviceChanged => {
    setServiceHasChanged(serviceChanged);
  };

  const handleCancel = () => {
    ctxOnEditService(false);
    if (serviceHasChanged) {
      setShowDiscardEditPopup(true);
    } else {
      backToService();
    }
  };

  const handleServiceUpdate = updatedService => {
    const newRecallService = serviceDataMapper.updateRecallService(
      localService,
      updatedService
    );
    // @issue - rawService should not be formatted, store API response - recall service record
    // const rawService = JSON.stringify(newRecallService);
    // @fix - read new object coming from service-data-mapper
    const rawApiService = has(localService, "rawApiService")
      ? localService.rawApiService
      : {};
    const rawService = JSON.stringify(rawApiService);
    const recallServiceObj = {
      ...newRecallService,
      durationMins: convertHoursToMinutes(newRecallService.duration),
      dmsOpcode: newRecallService.opCode,
      quoteRawService: { rawService }
    };
    console.log("final edited recall", recallServiceObj);
    dispatch({
      type: Actions.SET_SAVE_ACTION_TYPE,
      payload: saveActionTypes.SAVE
    });
    dispatch({
      type: Actions.SET_SELECTED_RECALL_SERVICE,
      payload: recallServiceObj
    });
  };

  const saveServiceAndGoBack = updatedService => {
    const newRecallService = serviceDataMapper.updateRecallService(
      localService,
      updatedService
    );
    // @issue - rawService should not be formatted, store API response - recall service record
    const rawService = JSON.stringify(newRecallService);
    const recallServiceObj = {
      ...newRecallService,
      durationMins: convertHoursToMinutes(newRecallService.duration),
      dmsOpcode: newRecallService.opCode,
      quoteRawService: { rawService }
    };
    dispatch({
      type: Actions.SET_SAVE_ACTION_TYPE,
      payload: saveActionTypes.SAVEANOTHER
    });
    dispatch({
      type: Actions.SET_SELECTED_RECALL_SERVICE,
      payload: recallServiceObj
    });
    backToService();
  };
  const asyncUpdatePartsByPaytype = async (paytype, editedService) => {
    localService.payTypeCode = paytype;
    ctxRequestPartsInventoryCommon(localService, editedService);
  };
  const discardEditPopup = (
    <ConfirmPopup
      title={localeStrings["sq.search.common.alert_lbl"]}
      message={localeStrings["sq.search.common.leaving_edit_page"]}
      show={showDiscardEditPopup}
      okText={localeStrings["sq.search.common.proceed_button"]}
      cancelText={localeStrings["sq.search.common.cancel_button"]}
      okAction={backToService}
      cancelAction={() => setShowDiscardEditPopup(false)}
      hideCancel={false}
      hideOk={false}
      buttonStyle="danger"
    />
  );

  const clonedEditServiceModule = !isEmpty(localService)
    ? React.cloneElement(EditServiceModule, {
        debugMode: debugQuote,
        vehicle,
        // @todo-beta: rawOperationDetails - pass localService to be converted as same datamodel like dealer publish record
        rawOperationDetails:
          serviceDataMapper.editModulePropsFromRecall(localService),
        service: serviceDataMapper.editModulePropsFromRecall(localService),
        localeStrings,
        payTypes,
        onCancelHandler: handleCancel,
        onSaveHandler: handleServiceUpdate,
        onSaveAnotherHandler: saveServiceAndGoBack,
        onChangePaytype: asyncUpdatePartsByPaytype,
        onServiceChange: handleServiceChange
      })
    : null;

  return (
    <>
      <div id="editRecallService" className="search-flex-grid-container">
        <span className="back-nav-label" onClick={handleCancel}>
          <IconArrowUpward
            htmlId="backArrowIcon"
            isActive={false}
            className="back-arrow"
          />
          Back to results
        </span>
        {clonedEditServiceModule}
        {discardEditPopup}
      </div>
    </>
  );
};

export default EditRecallServicePage;

EditRecallServicePage.defaultProps = {
  service: null,
  updateParentState: () => {},
  onSaveRecall: () => {},
  EditServiceModule: null
};

EditRecallServicePage.propTypes = {
  service: PropTypes.object.isRequired,
  updateParentState: PropTypes.func.isRequired,
  onSaveRecall: PropTypes.func.isRequired,
  EditServiceModule: PropTypes.element
};
