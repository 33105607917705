import React, { useState } from "react";
import PropTypes from "prop-types";
import findIndex from "lodash/findIndex";
import isEmpty from "lodash/isEmpty";
import IconArrowUpward from "@cx/ui/Icons/IconArrowUpward";
import { useServiceSearchContext } from "../../../state/service-search.context";
import serviceDataMapper from "../../../utils/service-data-mapper";
import ConfirmPopup from "../../../components/modals/ConfirmPopup";

const EditMenuServicePage = props => {
  const { state, ctxRequestPartsInventoryCommon } = useServiceSearchContext();
  const {
    localeStrings,
    payTypes,
    debugQuote,
    vehicle,
    partsPricingAndInventory
  } = state;
  const {
    service,
    originalService,
    EditServiceModule,
    selectedMenuPackage,
    username,
    onBackToPackage,
    onServiceChange,
    applicationType // for getting application type for CSR
    // onDiscountReasonChange // TODO: Remove if not needed after edit pattern impelmentation
  } = props;

  const [showDiscardEditPopup, setShowDiscardEditPopup] = useState(false);
  const [serviceHasChanged, setServiceHasChanged] = useState(false);

  const handleSave = editContextService => {
    const newService =
      applicationType === "CSR"
        ? serviceDataMapper.updateCSRMenuService(service, editContextService) //  wrapper function specific for CSR menu services
        : serviceDataMapper.updateMenuService(service, editContextService);
    const selectedServiceIndex = findIndex(
      selectedMenuPackage.services,
      item => item.id === service.id
    );
    onServiceChange(newService, selectedServiceIndex);
    // onDiscountReasonChange(discountReason, selectedServiceIndex); // TODO: Remove if not needed after edit pattern impelmentation
    onBackToPackage(false);
  };

  const handleCancel = () => {
    if (serviceHasChanged) {
      setShowDiscardEditPopup(true);
    } else {
      onBackToPackage(false);
    }
  };

  const handleServiceChange = serviceChanged => {
    setServiceHasChanged(serviceChanged);
  };
  const asyncUpdatePartsByPaytype = async (paytype, editedService) => {
    service.payTypeCode = paytype;
    ctxRequestPartsInventoryCommon(service, editedService);
  };

  const clonedEditServiceModule = !isEmpty(service)
    ? React.cloneElement(EditServiceModule, {
        debugMode: debugQuote,
        vehicle,
        rawOperationDetails:
          serviceDataMapper.updateRawOperationFromMenuService(service),
        service: serviceDataMapper.editModulePropsFromMenuService(
          service,
          originalService
        ),
        localeStrings,
        username,
        payTypes,
        ...(!props.serviceHasBeenEdited && { partsPricingAndInventory }),
        onCancelHandler: handleCancel,
        onSaveHandler: handleSave,
        onSaveAnotherHandler: () => {},
        onChangePaytype: asyncUpdatePartsByPaytype,
        onServiceChange: handleServiceChange
      })
    : null;

  // specific for CSR edit Service Module
  /*
  const clonedCSREditServiceModule = !isEmpty(service)
    ? React.cloneElement(EditServiceModule, {
        debugMode: debugQuote,
        mode: "edit",
        vehicle,
        rawOperationDetails:
          serviceDataMapper.updateRawOperationFromMenuService(service),
        serviceData: serviceDataMapper.editCSRModulePropsFromMenuService(
          service,
          originalService
        ),
        localeStrings,
        username,
        payTypes,
        ...(!props.serviceHasBeenEdited && { partsPricingAndInventory }),
        onEditModuleCancel: handleCancel,
        onUpdateEditService: handleSave,
        onSaveAnotherHandler: () => {},
        onChangePaytype: asyncUpdatePartsByPaytype,
        onServiceChange: handleServiceChange
      })
    : null;
    */
  return (
    <>
      <div id="editServiceContainer">
        <span className="back-nav-label" onClick={handleCancel}>
          <IconArrowUpward
            htmlId="backArrowIcon"
            isActive={false}
            className="back-arrow"
          />
          {localeStrings["sq.search.menu.edit_back_review_package_details_lbl"]}
        </span>
      </div>
      {clonedEditServiceModule}
      {/* {applicationType === "CSR"
        ? clonedCSREditServiceModule // csr specific edit service module
        : clonedEditServiceModule} */}
      <ConfirmPopup
        title={localeStrings["sq.search.common.alert_lbl"]}
        message={localeStrings["sq.search.common.leaving_menu_package_edit"]}
        show={showDiscardEditPopup}
        okText={localeStrings["sq.search.common.proceed_button"]}
        cancelText={localeStrings["sq.search.common.cancel_button"]}
        okAction={() => onBackToPackage(false)}
        cancelAction={() => setShowDiscardEditPopup(false)}
        buttonStyle="danger"
      />
    </>
  );
};

export default EditMenuServicePage;

EditMenuServicePage.defaultProps = {
  service: null,
  EditServiceModule: null,
  selectedMenuPackage: null,
  username: "",
  applicationType: null,
  onBackToPackage: () => {},
  onServiceChange: () => {}
  // onDiscountReasonChange: () => {}, // TODO: Remove if not needed after edit pattern impelmentation
};
EditMenuServicePage.propTypes = {
  service: PropTypes.object.isRequired,
  applicationType: PropTypes.string,
  EditServiceModule: PropTypes.element,
  selectedMenuPackage: PropTypes.object.isRequired,
  username: PropTypes.string.isRequired,
  onBackToPackage: PropTypes.func.isRequired,
  onServiceChange: PropTypes.func.isRequired
  // onDiscountReasonChange: PropTypes.func.isRequired, // TODO: Remove if not needed after edit pattern impelmentation
};
