/* eslint-disable no-console */
import Button from "@cx/ui/Button";
import Card from "@cx/ui/Card";
import IconSearch from "@cx/ui/Icons/IconSearch";
import NumericInput from "@cx/ui/NumericInput";
import PriceInput from "@cx/ui/PriceInput";
import SearchableSelect from "@cx/ui/SearchableSelect";
import SelectInput from "@cx/ui/SelectInput";
import TextArea from "@cx/ui/TextArea";
import TextInput from "@cx/ui/TextInput";
import { toast } from "@cx/ui/Toast";
import ToggleSwitch from "@cx/ui/ToggleSwitch";
import { PropTypes } from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
// import IconWarning from "@cx/ui/Icons/IconWarning";
import { makeSecureRestApi } from "../../../../../../api/xmmAxios";
import {
  initBulkEditOperation,
  ApplicationVisibilityOptions,
  EnabledDisabledOptions,
  MetaVehicleScopeAnyCatalogMap,
  MetaVehicleScopeDealerCatalogMap,
  MetaVehicleScopeVariantCatalogMap,
  MetaVehicleScopeGlobalRepairMap,
  MVC_ALL_VEHICLES,
  MVC_CHOOSE_VEHICLES,
  ServiceTypeOptions,
  DispatchSkillLevelTypeOptions,
  PayTypeGroupOptions,
  TaxTypeOptions
} from "../../../../../../constants/ModuleConstants";
import * as formvalidator from "../../../../../formik/formvalidator";
import { OperationContext } from "../../../operation-context";
import * as gtmEvents from "../../../../../utils/gtag-eventlist";
import {
  getDaysOfWeekString,
  isDifferentValue,
  toEmptyStringIfUndefined
} from "../../../../../../commonUtil/utils/string";
import {
  objectToArrayOfLabelValue,
  doesEmpty
} from "../../../../../../commonUtil/utils/object";
import VehicleGroupSelector from "../../../../../reusable/VehicleGroups/VehicleGroupSelector";
// import { DisplayFormikState } from "../../../../reusable/helper";

function getMakeVariant(selections) {
  const { make, variant } = selections[0];
  let hasDealer = false;
  let hasVariant = false;
  let hasAny = false;
  let hasGlobalRepairOp = false;
  const differentMakes = selections.filter(s => {
    const { globalRepairOpFlag, parentId } = s;
    if (globalRepairOpFlag) {
      hasGlobalRepairOp = true;
      hasAny = true;
    } else {
      if (!isNaN(parentId) && parentId > 0) {
        hasVariant = true;
      } else {
        hasDealer = true;
      }
      if (!hasAny && s.make === "ANY") {
        hasAny = true;
      }
    }
    return s.make !== make;
  });
  if (differentMakes.length === 0) {
    return { make, variant, hasDealer, hasVariant, hasAny, hasGlobalRepairOp };
  }
  return {
    make: "",
    variant: "",
    hasDealer,
    hasVariant,
    hasAny,
    hasGlobalRepairOp
  };
}

function getMetaVehicleScopeOptions(
  hasAny,
  hasDealer,
  hasVariant,
  hasGlobalRepairOp
) {
  if (hasAny) {
    if (hasGlobalRepairOp) {
      return objectToArrayOfLabelValue(MetaVehicleScopeGlobalRepairMap);
    }
    return objectToArrayOfLabelValue(MetaVehicleScopeAnyCatalogMap);
  } else if (hasDealer && hasVariant) {
    return objectToArrayOfLabelValue(MetaVehicleScopeDealerCatalogMap);
  } else if (hasDealer) {
    return objectToArrayOfLabelValue(MetaVehicleScopeDealerCatalogMap);
  }
  return objectToArrayOfLabelValue(MetaVehicleScopeVariantCatalogMap);
}

function getHasFactoryOperation(selections) {
  for (let index = 0; index < selections.length; index++) {
    if (formvalidator.isFactoryOperation(selections[index].parentId)) {
      return true;
    }
    return false;
  }
}

function BulkEditPane(props) {
  const opContext = useContext(OperationContext);
  const localeStrings = opContext.appContext.localeStrings;
  // set default object or passed props in state
  const { dealer, locale } = opContext.appContext;

  const { commonOpsDisplayCsrFields, dmsType } = dealer;

  const [isCsrFieldsEnable, setIsCsrFieldsEnable] = useState(
    commonOpsDisplayCsrFields && locale === "en_US" && dmsType === "DMS+"
  );
  const [payTypeOptions, setPayTypeOptions] = useState(
    PayTypeGroupOptions.filter(item => item.value !== "Service Contract")
  );
  const makeVariant = getMakeVariant(opContext.selectionlist);
  const { noPricing, flatTotalPricing } = getPricingWorkflow(opContext);
  const { make, variant, hasAny, hasDealer, hasVariant, hasGlobalRepairOp } =
    makeVariant;
  // const variant = makeVariant.variant;
  const metaVehicleScopeOptions = getMetaVehicleScopeOptions(
    hasAny,
    hasDealer,
    hasVariant,
    hasGlobalRepairOp
  );
  const hasFactoryOperation = getHasFactoryOperation(opContext.selectionlist);

  const initToggleStatus = {
    dmsOpcode: true,
    rank: true,
    shopDuration: true,
    price: true,
    priceCaption: true,
    loanerAllowed: true,
    waiterAllowed: true,
    noPartReviewRequired: true,
    enabled: true,
    appVisibility: true,
    description: true,
    storeHoursDisplay: true,
    metaVehicleScope: true,
    dealerLaborRateCodeId: true,
    serviceCategoryId: true,
    defaultServiceType: true,
    dispatchSkillLevel: true,
    complaint: true,
    defaultPayType: true,
    excludeDiscount: true,
    applyShopSupplies: true,
    defaultTaxCode: true,
    laborCostOverride: true
  };
  const appsInDisplay = ["enabled", "reservableByCustomers"];

  // This payload state will update on each toggleChange, used to save bulkedit
  const [payload, setPayload] = useState(null);
  const [formData, setValues] = useState(initBulkEditOperation);
  const [toggleStatus, setToggleStatus] = useState(initToggleStatus);
  const [toggleCount, setToggleCount] = useState(0);
  const [errors, setErrors] = useState({});
  const [bulkSelections, setBulkSelections] = useState(opContext.selectionlist);
  const [serviceCategories] = useState(opContext.appContext.serviceCategories);
  const [storeHoursData, setStoreHoursData] = useState([]);
  const laborRateOptions = !doesEmpty(make)
    ? opContext.makeRateCodesMap[make]
      ? opContext.makeRateCodesMap[make]
      : []
    : [];
  /* This object used to view props in UI
  const stateProps = {
    payload,
    errors,
    toggleCount
  };
  */
  // Note - go with useEffect() if we don't have complex logic to deal(no life cycle methods)
  useEffect(function persistForm() {
    if (formData && Object.keys(formData).length !== 0) {
      // console.log("formData", formData);
    }
  });

  const handleApply = e => {
    e.preventDefault();
    const { selectionlist } = opContext;
    // const { selectionList } = opContext;
    const dealerOperations = selectionlist.filter(op => !op.globalRepairOpFlag);
    const globalOperations = selectionlist.filter(op => op.globalRepairOpFlag);

    let isValid = true;
    // check if any errors exist on fields
    if (Object.keys(errors).length) {
      isValid = !hasErrorStrings(errors);
    }

    if (!isValid) {
      toast.warning("Please correct invalid fields to proceed.");
      return;
    }

    props.updateStatusBox(
      localeStrings["xmm.portal.common.saving"],
      "pending",
      false
    );

    // if (dealerOperations.length && globalOperations.length) {
    //   bulkeditGlobalOps(globalOperations, dealerOperations);
    // } else if (dealerOperations.length) {
    //   bulkeditDealerOps(dealerOperations);
    // } else {
    //   bulkeditGlobalOps(globalOperations);
    // }

    if (globalOperations.length) {
      bulkeditGlobalOps(globalOperations);
    }
    if (dealerOperations.length) {
      bulkeditDealerOps(dealerOperations);
    }

    // GTM - push click event to dataLayer
    gtmEvents.gtmTrackEvent("xmm.operations.apply_bulk_edit_click");
  };

  const bulkeditGlobalOps = (services, dealerOperations) => {
    // iterate payload and parse values
    const refinePayload = parsePayload();
    setPayload(refinePayload);
    const data = getBulkEditGlobalOpPayload(refinePayload);

    const { dealerCode, localeStrings } = opContext;
    let completeCount = 0;
    services.forEach(s => {
      const restEndPoint = `/ops/operations/overrides/dealerCode/${dealerCode}/operation/${s.serviceId.substring(
        1
      )}`;

      makeSecureRestApi(
        {
          url: restEndPoint,
          method: "post",
          data
        },
        data => {
          completeCount++;
          if (completeCount >= services.length) {
            if (dealerOperations) {
              bulkeditDealerOps(dealerOperations);
            } else {
              markBulkEditedFields(refinePayload);
              updateSelectionsAfterBulkEdit(refinePayload);
              props.updateStatusBox(
                localeStrings["xmm.portal.common.saved"],
                "success",
                true
              );
            }
          }
        },
        error => {
          props.updateStatusBox(
            localeStrings["xmm.portal.common.cannot_save_changes"],
            "error",
            false,
            localeStrings["xmm.portal.errors.bulkedit_save"]
          );
        }
      );
    });
  };

  const bulkeditDealerOps = services => {
    // iterate payload and parse values
    const refinePayload = parsePayload();
    if (
      !toggleStatus.storeHoursDisplay &&
      Object.keys(refinePayload).length === 0 &&
      storeHoursData
      // storeHoursData.length !== 0
    ) {
      markBulkEditedFields(refinePayload);
      saveBulkEditStoreHours(storeHoursData);
      return;
    }
    setPayload(refinePayload);

    const postObj = getEditPayload(services, refinePayload);
    const { make } = postObj[0];
    const { makeVariantMap } = opContext.appContext;
    const { baseLocale } = makeVariantMap[make];
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Variant-Base-Locale": baseLocale
    };
    const restEndPoint =
      "/ops/proxyapi/ddsproxy/rest/table/dealerOperation?_method=put";
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "post",
        data: postObj,
        params: {},
        headers
      },
      data => {
        markBulkEditedFields(refinePayload);
        if (!refinePayload.dmsOpcode && refinePayload.dmsOpcode !== "") {
          updateSelectionsAfterBulkEdit(refinePayload);
        } else {
          getUpdatedOpcode(postObj, refinePayload);
        }
        if (!toggleStatus.storeHoursDisplay && storeHoursData) {
          saveBulkEditStoreHours(storeHoursData);
        } else {
          // success case - update operations in opContext & ag-grid state
          // toast.success("Your changes in Bulk edit have been saved.", {
          //   autoClose: 3000
          // });
          props.updateStatusBox(
            localeStrings["xmm.portal.common.saved"],
            "success",
            true
          );
        }
      },
      error => {
        // toast.error(localeStrings["xmm.portal.errors.bulkedit_save"], {
        //   autoClose: 3000
        // });
        props.updateStatusBox(
          localeStrings["xmm.portal.common.cannot_save_changes"],
          "error",
          false,
          localeStrings["xmm.portal.errors.bulkedit_save"]
        );
      }
    );
  };
  function getServiceCategoryName(serviceCategoryId) {
    if (serviceCategoryId !== "") {
      for (let index = 0; index < serviceCategories.length; index++) {
        const { label, value } = serviceCategories[index];
        if (value === serviceCategoryId) {
          return label;
        }
      }
    }
    return "";
  }
  function markBulkEditedFields(payload) {
    const bulkeditedFields = {};
    Object.keys(payload).forEach(field => {
      let fieldName = field;
      if (appsInDisplay.includes(field)) {
        fieldName = "appsInDisplay";
      }
      bulkeditedFields[fieldName] = true;
      if (fieldName === "serviceCategoryId") {
        payload.serviceCategoryName = getServiceCategoryName(
          payload.serviceCategoryId
        );
        bulkeditedFields.serviceCategoryName = true;
      }
    });
    payload.bulkeditedFields = bulkeditedFields;
  }
  function getUpdatedOpcode(postObjList, refinePayload) {
    const { dealerCode } = opContext;
    const includeWithLaborOnly = 0;
    const serviceKind = "";

    let postObjCount = 0;
    postObjList.forEach(postObj => {
      // get all operations like summary view
      const make = postObj.make;
      const variant = !postObj.variant ? "SERVICETAB" : postObj.variant;
      makeSecureRestApi(
        {
          url: "/ops/proxyapi/ddsproxy/rest/proc/findDealerOperationSummary",
          method: "get",
          data: {},
          params: {
            make,
            variant,
            dealerCode,
            includeWithLaborOnly,
            serviceKind,
            serviceId: postObj.serviceId
          }
        },
        data => {
          // check if response has single object or array of objects
          if (Array.isArray(data) && data.length !== 0) {
            const { dmsOpcode } = data[0];
            refinePayload.dmsOpcode = dmsOpcode;
            bulkSelections
              .filter(op => {
                return op.serviceId === postObj.serviceId;
              })
              .forEach(op1 => {
                const keys = Object.keys(refinePayload);
                keys.forEach(key => {
                  op1[key] = refinePayload[key];
                });
              });
          }
          postObjCount = postObjCount + 1;
          if (postObjCount === postObjList.length) {
            // console.log("Updated dmsOpcodes", refinePayload);
            setBulkSelections(bulkSelections);
            props.closeSlider();
            // update Ag-grid operations with latest bulkedit response[]
            const columns = Object.keys(refinePayload);
            opContext.updateGridAfterBulkEdit(bulkSelections, columns);
            props.updateStatusBox(
              localeStrings["xmm.portal.common.saved"],
              "success",
              true
            );
          }
        },
        error => {
          const msg = error.message ? error.message : "Failed to bulkedit.";
          const errorMsg =
            localeStrings["xmm.portal.common.cannot_save_changes"];
          props.updateStatusBox(errorMsg, "error", false, msg);
        }
      );
    });
  }

  // parse payload before sending to rest call
  function parsePayload() {
    const newObject = {};
    // for (const [key, value] of Object.entries(payload)) {
    //   console.log(`${key}: ${value}`);
    // }
    Object.keys(payload).forEach(key => {
      if (
        key === "loanerAllowed" ||
        key === "waiterAllowed" ||
        key === "rank" ||
        key === "shopDuration"
      ) {
        const parsed = Number.parseInt(payload[key], 10);
        newObject[key] = Number.isNaN(parsed) ? null : parsed;
      } else if (key === "price") {
        newObject[key] = parseFloat(payload[key]) || null;
      } else if (key === "appVisibility" || key === "storeHoursDisplay") {
        // Don't add to newObject
      } else {
        newObject[key] = payload[key];
      }
    });
    return newObject;
  }

  function statusUpdatable(refinePayload) {
    const statusFields = [
      "dealerLaborRateCodeId",
      "metaVehicleFilterId",
      "price"
    ];
    const arr = Object.keys(refinePayload);
    for (let indx = 0; indx < arr.length; indx++) {
      if (statusFields.includes(arr[indx])) {
        return true;
      }
    }
    return false;
  }
  function resetErrors(results, refinePayload) {
    const { bulkeditedFields } = refinePayload;
    if (bulkeditedFields) {
      Object.keys(bulkeditedFields).forEach(field => {
        results.forEach(operation => {
          const { errors } = operation;
          if (errors && errors[field]) {
            errors[field] = false;
          }
        });
      });
    }
  }
  // callback after bulkedit save
  function updateSelectionsAfterBulkEdit(refinePayload) {
    // console.log("after save", refinePayload);
    const results = bulkSelections.map(operation => {
      return Object.assign(operation, refinePayload);
    });
    setBulkSelections(results);
    props.closeSlider();
    // update Ag-grid operations with latest bulkedit response[]
    resetErrors(results, refinePayload);
    const columns = Object.keys(refinePayload);
    opContext.updateGridAfterBulkEdit(
      results,
      columns,
      statusUpdatable(refinePayload)
    );
    // props.updateStatusBox("Bulk-edited", "success", true);
  }

  // function hasServiceLocaleValue(request) {
  //   const keys = Object.keys(request);
  //   for (let index = 0; index < keys.length; index++) {
  //     if (SerivceLocaleKeys.includes(keys[index])) {
  //       return true;
  //     }
  //   }
  //   return false;
  // }
  function getEditPayload(services, request) {
    // const { selectionlist } = opContext;
    // const services = selectionlist.filter(op => op.serviceId !== null);
    const postArray = [];
    if (services && services.length > 0) {
      // const includeServiceLocaleValues = hasServiceLocaleValue(request);
      services.forEach(service => {
        let cloneObj = {};
        // if (includeServiceLocaleValues) {
        //   SerivceLocaleKeys.forEach(serviceLocaleKey => {
        //     cloneObj[serviceLocaleKey] = service[serviceLocaleKey];
        //   });
        // }
        cloneObj = Object.assign(cloneObj, request);
        cloneObj.serviceId = service.serviceId;
        cloneObj.make = service.make;
        cloneObj.variant = service.variant;
        cloneObj.modUser = opContext.userName;
        postArray.push(cloneObj);
      });
    }
    // console.log("bulkedit postobj", postArray);
    return postArray;
  }

  // function getEditGlobalPayload(services, request) {
  //   // const { selectionlist } = opContext;
  //   // const services = selectionlist.filter(op => op.serviceId !== null);
  //   const postArray = [];
  //   if (services && services.length > 0) {
  //     // const includeServiceLocaleValues = hasServiceLocaleValue(request);
  //     services.forEach(service => {
  //       let cloneObj = {};
  //       cloneObj = Object.assign(cloneObj, request);
  //       cloneObj.serviceId = Number(service.serviceId.substring(1));
  //       cloneObj.modUser = opContext.userName;
  //       cloneObj.modTime = "now";
  //       postArray.push(cloneObj);
  //     });
  //   }
  //   // console.log("bulkedit postobj", postArray);
  //   return postArray;
  // }
  function getBulkEditGlobalOpPayload(bulkedits) {
    const payload = Object.assign({}, bulkedits);
    payload.modUser = opContext.userName;
    payload.modTime = "now";
    return payload;
  }

  /* Price chang event */
  const onChangePrice = (cxEvent, isValid, domEvent) => {
    const { name, value } = cxEvent.target;
    const newValue = !value || value === "" ? "" : value;
    let showError = false;
    if (domEvent && domEvent.type === "blur") {
      if (value) {
        if (((value && parseFloat(value)) || 0) > 10000) {
          showError = true;
        }
      } else if (!value) {
        // Do nothing
      }
      setErrors({
        ...errors,
        price: showError
          ? localeStrings["xmm.portal.errors.exceed_max_price"]
          : ""
      });
    }
    // Note: skip change event for initial form load
    if (formData[name] === value) {
      return;
    }
    setValues({
      ...formData,
      [name]: newValue
    });
    setPayload({
      ...payload,
      [name]: newValue
    });
  };
  /* Field change event handler */
  const updateField = (cxEvent, isValid, domEvent) => {
    const { name, value } = cxEvent.target;
    const newValue = !value || value === "" ? "" : value;
    // Note: skip change event when form loaded
    if (formData[name] === value) {
      return;
    }

    setValues({
      ...formData,
      [name]: newValue
    });
    setPayload({
      ...payload,
      [name]: newValue
    });
    // console.log("field change for ", name, newValue, payload, errors);
  };

  const updateFieldCheckbox = (cxEvent, isValid, domEvent) => {
    const { name, value, checked } = cxEvent.target;
    if (formData[name] === value) {
      return;
    }

    setValues({
      ...formData,
      [name]: checked ? 1 : 0
    });
    setPayload({
      ...payload,
      [name]: checked ? 1 : 0
    });
  };

  const onToggleClick = cxEvent => {
    const { name, value } = cxEvent.target;
    const fieldName = name.split("-")[0];
    // const checkValue = value === "checked" ? true : false;
    const disable = value === "checked" ? false : true;
    if (value === "checked") {
      setToggleCount(toggleCount + 1);

      setPayload({
        ...payload,
        [fieldName]:
          fieldName === "excludeDiscount" || fieldName === "applyShopSupplies"
            ? 0
            : ""
      });
      if (fieldName === "storeHoursDisplay") {
        setValues({
          ...formData,
          [fieldName]:
            localeStrings["xmm.portal.operations.form.any_store_hours"]
        });
      } else if (fieldName === "metaVehicleScope") {
        if (hasGlobalRepairOp) {
          setValues({
            ...formData,
            [fieldName]: MVC_ALL_VEHICLES
          });
          setPayload({
            ...payload,
            [fieldName]: MVC_ALL_VEHICLES
          });
        } else {
          setValues({
            ...formData,
            [fieldName]: MVC_ALL_VEHICLES,
            metaVehicleFilterId: ""
          });
          setPayload({
            ...payload,
            [fieldName]: MVC_ALL_VEHICLES,
            metaVehicleFilterId: ""
          });
        }
      } else if (
        fieldName === "dealerLaborRateCodeId" ||
        fieldName === "defaultServiceType" ||
        fieldName === "dispatchSkillLevel" ||
        fieldName === "defaultPayType" ||
        fieldName === "defaultTaxCode"
      ) {
        setPayload({
          ...payload,
          [fieldName]: null
        });
      } else if (fieldName === "waiterAllowed") {
        setValues({
          ...formData,
          [fieldName]: 1
        });
        setPayload({
          ...payload,
          [fieldName]: 1
        });
      }
    } else if (value === "unchecked") {
      setToggleCount(toggleCount - 1);
      if (fieldName === "appVisibility") {
        delete payload["enabled"];
        delete payload["reservableByCustomers"];
      }
      if (fieldName === "dealerLaborRateCodeId") {
        delete payload["dealerLaborRateCodeId"];
      }
      // when toggle is off, set {""} to formData, payload, errors
      setValues({
        ...formData,
        [fieldName]: ""
      });
      /* disable field case - remove field value from payload state
         remove error msg from errors state
         */
      setErrors({
        ...errors,
        [fieldName]: ""
      });
      delete errors[fieldName];
      const newErrors = removeByKey(errors, [fieldName]);
      setErrors(newErrors);

      setPayload({
        ...payload,
        [fieldName]: ""
      });
      delete payload[fieldName];
      // console.log("payload", payload);
      const newPayload = removeByKey(payload, [fieldName]);
      setPayload(newPayload);
    }
    // enable/disable field using toggleStatus object
    setToggleStatus({
      ...toggleStatus,
      [fieldName]: disable
    });

    // console.log("toggle click", name, value, toggleStatus);
  };
  // This func() filters the given "key" that should be deleted from Object,
  // then builds a new object from the remaining keys and the state object.
  function removeByKey(stateObj, deleteKey) {
    return Object.keys(stateObj)
      .filter(key => key !== deleteKey)
      .reduce((result, current) => {
        result[current] = stateObj[current];
        return result;
      }, {});
  }
  /* This func) checks for error object has strings with {null,"", undefined} in given object
  and returns count for error strings.*/
  function hasErrorStrings(state) {
    const array1 = Object.values(state);
    const iterator = array1.values();
    let errCount = 0;
    // let count = 0;
    for (const value of iterator) {
      if (value === "" || value === null || value.length === 0) {
        // count++;
        // In case of valid error string
      } else if (value && value.length > 1) {
        errCount++;
      }
    }
    return errCount === 0 ? false : true;
  }
  function getStoreHoursDisplay(serviceHoursCount) {
    if (serviceHoursCount) {
      return serviceHoursCount
        .toString()
        .concat(
          " ",
          localeStrings["xmm.portal.operations.form.custom_hours_set"]
        );
    }
    return localeStrings["xmm.portal.operations.form.any_store_hours"];
  }
  function bulkEditServiceHoursCallback(storeHoursData) {
    // console.log("storeHoursData", storeHoursData);
    setStoreHoursData(storeHoursData);
    const storeHoursDisplay = getStoreHoursDisplay(storeHoursData.length);
    setValues({
      ...formData,
      storeHoursDisplay
    });
    // setPayload({
    //   ...payload,
    //   storeHoursDisplay
    // });
  }

  function getStoreHoursData(serviceId, storeHoursData) {
    // i_request_type IN VARCHAR2,
    // i_auth_id IN NUMBER,
    // i_service_id IN t_service.service_id%TYPE,
    // i_name_tab IN name_tab,
    // i_days_of_week_tab IN name_tab,
    // i_start_time_tab IN id_table,
    // i_end_time_tab IN id_table)
    const data = {
      requestType: "",
      authId: 0,
      serviceId,
      nameTab: storeHoursData.map(storeHours => {
        return storeHours.name;
      }),
      daysOfWeekTab: storeHoursData.map(storeHours => {
        return getDaysOfWeekString(storeHours.daysOfWeek);
      }),
      startTimeTab: storeHoursData.map(storeHours => {
        return storeHours.startTime.toString();
      }),
      endTimeTab: storeHoursData.map(storeHours => {
        return storeHours.endTime.toString();
      })
    };
    return data;
  }

  function saveBulkEditStoreHours(storeHoursData) {
    const services = bulkSelections.filter(op => op.serviceId !== null);
    let count = 0;
    services.forEach(service => {
      const data = getStoreHoursData(service.serviceId, storeHoursData);
      const restEndPoint = "/ops/proxyapi/ddsproxy/rest/proc/editServiceHours";
      makeSecureRestApi(
        {
          url: restEndPoint,
          method: "post",
          data
        },
        resp => {
          count += 1;
          const { statusCode, statusMessage } = resp.response;
          if (statusCode === 0) {
            service.serviceHoursCount = storeHoursData.length;
            if (!service.bulkeditedFields) {
              service.bulkeditedFields = {};
            }
            service.bulkeditedFields.serviceHoursCount = true;
            if (count === services.length) {
              // success case - update operations in opContext & ag-grid state
              // toast.success("Your changes in Bulk edit have been saved.", {
              //   autoClose: 3000
              // });
              setBulkSelections(bulkSelections);
              opContext.updateGridAfterBulkEdit(bulkSelections, [
                "serviceHoursCount"
              ]);
              props.closeSlider();
              props.updateStatusBox(
                localeStrings["xmm.portal.common.saved"],
                "success",
                true
              );
            }
          } else {
            props.updateStatusBox(
              localeStrings["xmm.portal.common.cannot_save_changes"],
              "error",
              false,
              statusMessage
            );
            //   toast.error(statusMessage, {
            //   autoClose: 3000
            // });
          }
        },
        error => {
          props.updateStatusBox(
            localeStrings["xmm.portal.common.cannot_save_changes"],
            "error",
            false,
            localeStrings["xmm.portal.errors.bulkedit_save"]
          );
          // toast.error(localeStrings["xmm.portal.errors.bulkedit_save"], {
          //   autoClose: 3000
          // });
        }
      );
    });
  }

  const onAppVisibilityChange = cxEvent => {
    const { value } = cxEvent.target;
    const options = {
      enabled: 0,
      reservableByCustomers: 0
    };
    if (value) {
      // setDirtyCount(dirtyCount + 1);
      switch (value) {
        case "0":
          options.enabled = 1;
          options.reservableByCustomers = 1;
          break;
        case "1":
          options.enabled = 1;
          options.reservableByCustomers = 0;
          break;
        default:
          options.enabled = 0;
          options.reservableByCustomers = 0;
          break;
      }
    } else if (!value) {
      // setDirtyCount(dirtyCount > 0 ? dirtyCount - 1 : 0);
    }
    // update form values state
    setValues({
      ...formData,
      appVisibility: value
    });
    // update payload with visibility
    setPayload({
      ...payload,
      enabled: options.enabled,
      reservableByCustomers: options.reservableByCustomers
    });
  };
  // validate duration - should set error msg or ""
  const onBlurDuration = event => {
    // NOTE: CX BUG - event.target.name missing in NumberInput, TextInput
    const { value } = event.target;
    let showError = false;
    if (value && value > 1440) {
      showError = true;
    }
    setErrors({
      ...errors,
      shopDuration: showError
        ? opContext.localeStrings[
            "xmm.portal.validation.duration_max_value_exceeded"
          ]
        : ""
    });
  };

  // validation handler for rank field -  should set error msg or ""
  const onBlurRank = event => {
    let showError = false;
    const { value } = event.target;
    if (value && value > 99999) {
      showError = true;
    }
    setErrors({
      ...errors,
      rank: showError ? "The Maximum value for this field is 99999" : ""
    });
  };

  // validation handler for opcode field - should set error msg or ""
  const onBlurOpcode = event => {
    const { value } = event.target;
    // NOTE: CX BUG - event.target.name missing in NumberInput, TextInput
    const name = "dmsOpcode";
    let errorMsg = "";
    if (value) {
      errorMsg = formvalidator.validateAlphaNumPunctuation(value);
    }
    setErrors({
      ...errors,
      [name]: !errorMsg ? "" : errorMsg
    });
  };
  // validate caption field - should set error msg or ""
  const onBlurCaption = event => {
    const { value } = event.target;
    // NOTE: CX BUG - event.target.name missing in NumberInput, TextInput
    const name = "priceCaption";
    let errorMsg = "";
    if (value) {
      errorMsg = formvalidator.validateAlphaNumWithSpecialCharacters(value);
    }
    setErrors({
      ...errors,
      [name]: !errorMsg ? "" : errorMsg
    });
  };
  // const validateMetaVehicleScope = value => {
  //   // const { value } = event.target;
  //   // NOTE: CX BUG - event.target.name missing in NumberInput, TextInput
  //   // const name = "metaVehicleScope";
  //   let errorMsg = "";
  //   if (
  //     value === MVC_CHOOSE_VEHICLES &&
  //     toEmptyStringIfUndefined(formData.metaVehicleFilterId) === ""
  //   ) {
  //     errorMsg = "Please select a vehicle group.";
  //   }
  //   setErrors({
  //     ...errors,
  //     metaVehicleFilterId: !errorMsg ? "" : errorMsg
  //   });
  // };
  // eslint-disable-next-line no-unused-vars
  const setOpcodeValue = dmsOpcode => {
    setValues({
      ...formData,
      dmsOpcode
    });
    setPayload({
      ...payload,
      dmsOpcode
    });
  };

  const onMetaVehicleScopeChange = (event, field, form) => {
    // console.log("Select change in parent", event.target);
    if (event.target.name === "metaVehicleScope") {
      // validateMetaVehicleScope(event.target.value);
      if (event.target.value !== MVC_CHOOSE_VEHICLES) {
        setValues({
          ...formData,
          metaVehicleFilterId: ""
        });
        setPayload({ ...payload, metaVehicleFilterId: "" });
      }
    }
    setValues({ ...formData, metaVehicleScope: event.target.value });
    setPayload({ ...payload, metaVehicleScope: event.target.value });
  };

  const onDefaultLaborRateCodeChange = (event, field, form) => {
    setValues({ ...formData, dealerLaborRateCodeId: event.target.value });
    setPayload({ ...payload, dealerLaborRateCodeId: event.target.value });
  };

  const onDefaultServiceTypeChange = (event, field, form) => {
    setValues({ ...formData, defaultServiceType: event.target.value });
    setPayload({ ...payload, defaultServiceType: event.target.value });
  };

  const ondispatchSkillLevelChange = (event, field, form) => {
    setValues({ ...formData, dispatchSkillLevel: event.target.value });
    setPayload({ ...payload, dispatchSkillLevel: event.target.value });
  };

  const ondispatchTaxTypeChange = (event, field, form) => {
    setValues({ ...formData, defaultTaxCode: event.target.value });
    setPayload({ ...payload, defaultTaxCode: event.target.value });
  };

  const onDefaultPayTypeChange = (event, field, form) => {
    setValues({ ...formData, defaultPayType: event.target.value });
    setPayload({ ...payload, defaultPayType: event.target.value });
  };

  const onChangeVehicleGroup = event => {
    const { data } = event.detail;
    const record = {
      metaVehicleScope: MVC_ALL_VEHICLES,
      metaVehicleFilterId: ""
    };
    if (record && data) {
      const { metaVehicleFilterId, metaVehicleScope } = data;
      record.metaVehicleScope = metaVehicleScope;
      if (metaVehicleScope === MVC_ALL_VEHICLES) {
        record.metaVehicleFilterId = "";
      } else {
        record.metaVehicleScope = MVC_CHOOSE_VEHICLES;
        record.metaVehicleFilterId = metaVehicleFilterId;
      }
      setValues({ ...formData, metaVehicleScope: record.metaVehicleScope });
      setValues({
        ...formData,
        metaVehicleFilterId: record.metaVehicleFilterId
      });
      setPayload({
        ...payload,
        metaVehicleScope: record.metaVehicleScope
      });
      setPayload({
        ...payload,
        metaVehicleFilterId: record.metaVehicleFilterId
      });
      setErrors({ ...errors, metaVehicleFilterId: "" });
    }
  };

  function getPricingWorkflow(opContext) {
    const { appContext, selectionlist } = opContext;
    const { makeVariantMap } = appContext;
    let calculatedPricing = false;
    let noPricing = false;
    let flatTotalPricing = false;
    // const { makeVariantMap } = opContext.appContext;
    selectionlist.forEach(operation => {
      const { make } = operation;
      const { pricingMethod } = makeVariantMap[make];
      if (pricingMethod === 0) {
        noPricing = true;
      } else if (pricingMethod === 2) {
        flatTotalPricing = true;
      } else if (pricingMethod === 1) {
        calculatedPricing = true;
      }
    });
    return { calculatedPricing, noPricing, flatTotalPricing };
  }

  function getLaborRateWarning(make, unsupportedPricing, hasAny) {
    let defaultLaborRateCodeWarning = "";
    if (unsupportedPricing) {
      defaultLaborRateCodeWarning =
        localeStrings["xmm.portal.common.cannot_bulkedit_operations"];
    } else if (hasAny) {
      defaultLaborRateCodeWarning =
        localeStrings[
          "xmm.portal.common.cannot_bulkedit_labor_rate_with_any_make"
        ];
    } else if (doesEmpty(make)) {
      defaultLaborRateCodeWarning =
        localeStrings[
          "xmm.portal.common.cannot_bulkedit_labor_rate_with_multiple_makes"
        ];
    }
    return defaultLaborRateCodeWarning;
  }

  function handleCategoryChange(cxEvent, isValid, domEvent) {
    const { name, value } = cxEvent.target;
    const optionValue = value && value.length !== 0 ? value[0].value : "";
    if (isDifferentValue(formData[name], optionValue)) {
      setValues({
        ...formData,
        [name]: optionValue
      });
      setPayload({
        ...payload,
        [name]: optionValue
      });
    }
  }

  const vehicleGroup = {
    make,
    variant,
    dealerCode: opContext.dealerCode,
    metaVehicleScope: "",
    metaVehicleFilterId: ""
  };

  let markDisabled = true;
  if (toggleCount !== 0) {
    markDisabled = false;
    if (Object.keys(errors).length !== 0) {
      markDisabled = hasErrorStrings(errors); // returns true - when no errors
    } else {
      const { metaVehicleScope, metaVehicleFilterId } = formData;
      if (
        metaVehicleScope === MVC_CHOOSE_VEHICLES &&
        toEmptyStringIfUndefined(metaVehicleFilterId) === ""
      ) {
        markDisabled = true;
      }
    }
  }

  const metaVehicleScopeWarning = doesEmpty(make)
    ? localeStrings[
        "xmm.portal.common.cannot_bulkedit_vehicles_with_multiple_makes"
      ]
    : "";

  const serviceCategoryWarning = hasFactoryOperation
    ? localeStrings["xmm.portal.common.cannot_bulkedit_factory_operations"]
    : "";

  const defaultLaborRateCodeWarning = getLaborRateWarning(
    make,
    noPricing || flatTotalPricing,
    hasAny
  );

  const defaultPriceWarning = noPricing
    ? localeStrings["xmm.portal.common.cannot_bulkedit_operations"]
    : "";

  const vehicleGroupSelector =
    doesEmpty(make) || make === "ANY" ? (
      ""
    ) : (
      <VehicleGroupSelector
        data={vehicleGroup}
        error={errors.metaVehicleFilterId}
        context={opContext.appContext}
        hideAllVehicles={true}
        autoLoad={true}
        // onBlur={onBlurVehicleGroup}
        onChange={onChangeVehicleGroup}
      />
    );

  return (
    <>
      <div className="xmm-bulk-form-bar">
        <span className="xmm-description-msg">
          <ul>
            <li>
              <FormattedMessage
                defaultMessage="Enable the Toggle switch to bulk edit a field."
                id="xmm.portal.operations.bulkedit.help_text1"
              />
            </li>
            <li>
              <FormattedMessage
                defaultMessage="Enter the correct value or leave it empty to revert to the
                default value."
                id="xmm.portal.operations.bulkedit.help_text2"
              />
            </li>
          </ul>
        </span>
        <Button
          htmlId="ApplyBulkEditAction"
          buttonStyle="primary"
          disabled={markDisabled}
          onClick={handleApply}
          className="xmm-bulkedit-btn float-right"
        >
          {localeStrings["xmm.portal.common.apply_x_edits"].replace(
            "%1",
            toggleCount
          )}
          {/* Apply {toggleCount !== 0 ? toggleCount : ""} Edit
  {toggleCount > 1 ? "s" : ""*/}
        </Button>
      </div>
      <Card id="operationBulkEdit">
        <div className="bulk-edit-form">
          <div className="xmm-bulk-form-row">
            <span className="text-right">
              {localeStrings["xmm.portal.common.opcode"]}
            </span>
            <TextInput
              htmlId="dmsOpEdit"
              label="Op Code"
              name="dmsOpcode"
              onChange={updateField}
              onBlur={onBlurOpcode}
              value={formData.dmsOpcode}
              error={errors.dmsOpcode}
              disabled={toggleStatus.dmsOpcode}
              displayLabel={false}
              appendChild={
                <Button
                  // aria-label="More"
                  htmlId="dmsOpcodeAppendButton"
                  icon={
                    <IconSearch
                      htmlId="iconSearchForm"
                      // className="pull-right"
                    />
                  }
                  buttonStyle="secondary"
                  onClick={() => {
                    // show Find Op Code dialog with blank serivceId, internalName, dmsOpcode, and a callback to set opcode value in BullkEditPane
                    props.showFindOpcodeModal("", "", "", "", setOpcodeValue);
                  }}
                />
              }
            />
            <ToggleSwitch
              htmlId="dmsOpcodeToggle"
              name="dmsOpcode-chk"
              defaultChecked={false}
              onClick={onToggleClick}
            />
          </div>
          <div className="xmm-bulk-form-row">
            <span className="text-right">
              {localeStrings["xmm.portal.common.rank"]}
            </span>
            <NumericInput
              htmlId="rankBulkEdit"
              name="rank"
              label="Rank"
              displayLabel={false}
              maxLength={5}
              minLength={0}
              disabled={toggleStatus.rank}
              onChange={updateField}
              onBlur={onBlurRank}
              error={errors.rank}
              value={formData.rank}
            />
            <ToggleSwitch
              htmlId="rankToggle"
              name="rank-chk"
              disabled={hasGlobalRepairOp}
              onClick={onToggleClick}
            />
          </div>
          <div className="xmm-bulk-form-row">
            <span className="text-right">
              {localeStrings["xmm.portal.common.schedule_duration_lbl"]}
            </span>
            <NumericInput
              htmlId="shopDurationBulkEdit"
              name="shopDuration"
              label="Schedule Duration"
              inputSuffix={localeStrings["xmm.portal.common.minutes"]}
              displayLabel={false}
              maxLength={5}
              minLength={0}
              onBlur={onBlurDuration}
              onChange={updateField}
              value={formData.shopDuration}
              error={errors.shopDuration}
              disabled={toggleStatus.shopDuration}
            />
            <ToggleSwitch
              htmlId="shopDurationToggle"
              name="shopDuration-chk"
              disabled={hasGlobalRepairOp}
              onClick={onToggleClick}
            />
          </div>
          <div className="xmm-bulk-form-row">
            <span className="text-right">
              {localeStrings["xmm.portal.common.default_price"]}
            </span>
            <div>
              <PriceInput
                htmlId="priceBulkEdit"
                name="price"
                label="Default Price"
                onChange={onChangePrice}
                value={formData.price}
                error={errors.price}
                maxLength={7}
                disabled={toggleStatus.price}
                displayLabel={false}
              />
              <div className={defaultPriceWarning ? "xmm-warning-box" : "hide"}>
                {defaultPriceWarning}
              </div>
            </div>
            <ToggleSwitch
              htmlId="priceToggle"
              name="price-chk"
              disabled={defaultPriceWarning !== ""}
              onClick={onToggleClick}
            />
          </div>
          <div className="xmm-bulk-form-row">
            <span className="text-right">
              {localeStrings["xmm.portal.common.price_caption"]}
            </span>
            <TextInput
              htmlId="priceCaptionBulkEdit"
              name="priceCaption"
              label="Price Caption"
              onChange={updateField}
              onBlur={onBlurCaption}
              value={formData.priceCaption}
              error={errors.priceCaption}
              disabled={toggleStatus.priceCaption}
              maxLength={64}
              displayLabel={false}
            />
            <ToggleSwitch
              htmlId="priceCaptionToggle"
              name="priceCaption-chk"
              disabled={hasGlobalRepairOp}
              onClick={onToggleClick}
            />
          </div>
          <div className="xmm-bulk-form-row">
            <span className="text-right">
              {localeStrings["xmm.portal.operations.form.loaners_label"]}
            </span>
            <SelectInput
              htmlId="loanerAllowedBulkEdit"
              name="loanerAllowed"
              options={EnabledDisabledOptions}
              value={formData.loanerAllowed}
              displayDeselectOption={false}
              error={errors.loanerAllowed}
              disabled={toggleStatus.loanerAllowed}
              onChange={updateField}
              displayLabel={false}
            />
            <ToggleSwitch
              htmlId="loanerAllowedToggle"
              name="loanerAllowed-chk"
              disabled={hasGlobalRepairOp}
              onClick={onToggleClick}
            />
          </div>
          <div className="xmm-bulk-form-row">
            <span className="text-right">
              {localeStrings["xmm.portal.operations.form.waiters_label"]}
            </span>
            <SelectInput
              htmlId="waiterAllowedBulkEdit"
              name="waiterAllowed"
              label="Allow Waiters"
              options={EnabledDisabledOptions}
              value={formData.waiterAllowed}
              displayDeselectOption={false}
              error={errors.waiterAllowed}
              disabled={toggleStatus.waiterAllowed}
              onChange={updateField}
              displayLabel={false}
            />
            <ToggleSwitch
              htmlId="waiterAllowedToggle"
              name="waiterAllowed-chk"
              disabled={hasGlobalRepairOp}
              onClick={onToggleClick}
            />
          </div>
          <div
            className="xmm-bulk-form-row"
            hidden={!props.commonOpsNoPartsReviewRequired}
          >
            <span className="text-right">
              {
                localeStrings[
                  "xmm.portal.operations.form.no_parts_review_req_label"
                ]
              }
            </span>
            <SelectInput
              htmlId="noPartReviewRequiredBulkEdit"
              name="noPartReviewRequired"
              label="No Parts Required"
              options={EnabledDisabledOptions}
              value={formData.noPartReviewRequired}
              displayDeselectOption={false}
              error={errors.noPartReviewRequired}
              disabled={toggleStatus.noPartReviewRequired}
              onChange={updateField}
              displayLabel={false}
            />
            <ToggleSwitch
              htmlId="noPartReviewRequiredToggle"
              name="noPartReviewRequired-chk"
              // disabled={disableNoPartReviewRequired}
              onClick={onToggleClick}
            />
          </div>
          <div className="xmm-bulk-form-row">
            <span className="text-right">
              {localeStrings["xmm.portal.common.appl_visibility"]}
            </span>
            <SelectInput
              htmlId="appVisibilityBulkEdit"
              label="Application Visibility"
              name="appVisibility"
              options={ApplicationVisibilityOptions}
              value={formData.appVisibility}
              onChange={onAppVisibilityChange}
              error={errors.appVisibility}
              displayDeselectOption={false}
              disabled={toggleStatus.appVisibility}
              displayLabel={false}
            />
            <ToggleSwitch
              htmlId="appVisibilityToggle"
              name="appVisibility-chk"
              disabled={hasGlobalRepairOp}
              onClick={onToggleClick}
            />
          </div>
          <div className="xmm-bulk-form-row">
            <span className="text-right">
              {localeStrings["xmm.portal.common.category_lbl"]}
            </span>
            <div>
              <SearchableSelect
                htmlId="serviceCategoryId"
                name="serviceCategoryId"
                displayLabel={false}
                placeholder={
                  localeStrings["xmm.portal.common.select_service_category_lbl"]
                }
                disabled={hasFactoryOperation || toggleStatus.serviceCategoryId}
                value={formData.serviceCategoryId}
                enableMultiSelect={false}
                options={serviceCategories}
                onChange={(cxEvent, isValid, domEvent) => {
                  handleCategoryChange(cxEvent, isValid, domEvent);
                }}
              />
              <div
                className={serviceCategoryWarning ? "xmm-warning-box" : "hide"}
              >
                {serviceCategoryWarning}
              </div>
            </div>
            <ToggleSwitch
              htmlId="serviceCategoryIdToggle"
              name="serviceCategoryId-chk"
              defaultChecked={false}
              disabled={hasFactoryOperation || hasGlobalRepairOp}
              onClick={onToggleClick}
            />
          </div>
          <div className="xmm-bulk-form-row">
            <span className="text-right">
              {localeStrings["xmm.portal.common.description_lbl"]}
            </span>
            <TextArea
              htmlId="descriptionBulkEdit"
              name="description"
              label="Description"
              rows={5}
              maxLength={2000}
              onChange={updateField}
              value={formData.description}
              error={errors.description}
              disabled={toggleStatus.description}
              displayLabel={false}
              className="xmm-vertical-resize"
            />
            <ToggleSwitch
              htmlId="descriptionToggle"
              name="description-chk"
              disabled={hasGlobalRepairOp}
              onClick={onToggleClick}
            />
          </div>
          <div className="xmm-bulk-form-row">
            <span className="text-right">
              {localeStrings["xmm.portal.common.available_hours"]}
            </span>
            <TextInput
              // addonPosition="append"
              htmlId="serviceHoursCount"
              className="selector-search"
              displayLabel={false}
              name="serviceHoursCount"
              onChange={event => {
                // noop - making the field readonly
                // console.log(formData.storeHoursDisplay);
              }}
              disabled={toggleStatus.storeHoursDisplay}
              value={formData.storeHoursDisplay}
              appendChild={
                <Button
                  buttonStyle="secondary"
                  htmlId="bulkEditStoreHours"
                  onClick={event => {
                    window.dispatchEvent(
                      new CustomEvent("bulkEditServiceHours", {
                        detail: { bulkEditServiceHoursCallback },
                        bubbles: true,
                        cancelable: true
                      })
                    );
                  }}
                >
                  <FormattedMessage
                    defaultMessage="Edit"
                    id="xmm.portal.common.edit_button"
                  />
                </Button>
              }
            />
            <ToggleSwitch
              htmlId="storeHoursToggle"
              name="storeHoursDisplay"
              defaultChecked={false}
              disabled={hasGlobalRepairOp}
              onClick={onToggleClick}
            />
          </div>
          <div
            className="xmm-bulk-form-row"
            disabled={make === "" || (hasGlobalRepairOp && hasVariant)}
          >
            <span className="text-right">
              {localeStrings["xmm.portal.common.vehicles"]}
            </span>
            <div>
              <SelectInput
                htmlId="metaVehicleScope"
                name="metaVehicleScope"
                options={metaVehicleScopeOptions}
                value={formData.metaVehicleScope}
                // onBlur={onBlurMetaVehicleScope}
                onChange={onMetaVehicleScopeChange}
                displayDeselectOption={false}
                error={errors.metaVehicleScope}
                disabled={toggleStatus.metaVehicleScope || make === ""}
                displayLabel={false}
                displayPlaceholder={false}
              />
              {/* <div className={make === "" ? "" : "hide"}>
              <div className="xmm-warning-msg">
                <IconWarning htmlId="IconWarning" />
                <span>
                  Cannot bulk edit vehicles with multiple Makes selected
                </span>
              </div>
            </div> */}
              <div
                className={metaVehicleScopeWarning ? "xmm-warning-box" : "hide"}
              >
                {metaVehicleScopeWarning}
              </div>
              <div
                className={
                  formData.metaVehicleScope === MVC_CHOOSE_VEHICLES
                    ? ""
                    : "hide"
                }
              >
                {vehicleGroupSelector}
              </div>
            </div>
            <ToggleSwitch
              htmlId="metaVehicleScopeToggle"
              name="metaVehicleScope"
              defaultChecked={false}
              disabled={make === ""}
              onClick={onToggleClick}
            />
          </div>
          <div id="displayprops">
            {/* <DisplayFormikState {...stateProps} /> */}
          </div>
          <div className="xmm-bulk-form-row" disabled={make === ""}>
            <span className="text-right">
              {localeStrings["xmm.portal.common.default_labor_rate"]}
            </span>
            <div>
              <SelectInput
                htmlId="defaultLaborRateCode"
                name="dealerLaborRateCodeId"
                options={laborRateOptions}
                value={formData.dealerLaborRateCodeId}
                onChange={onDefaultLaborRateCodeChange}
                error={errors.dealerLaborRateCodeId}
                disabled={toggleStatus.dealerLaborRateCodeId || make === ""}
                displayLabel={false}
                displayDeselectOption={false}
                displayPlaceholder={true}
              />
              <div
                className={
                  defaultLaborRateCodeWarning ? "xmm-warning-box" : "hide"
                }
              >
                {defaultLaborRateCodeWarning}
              </div>
            </div>
            <ToggleSwitch
              htmlId="defaultLaborRateCodeToggle"
              name="dealerLaborRateCodeId"
              defaultChecked={false}
              disabled={defaultLaborRateCodeWarning !== "" || hasGlobalRepairOp}
              onClick={onToggleClick}
            />
          </div>
          {isCsrFieldsEnable && (
            <>
              <div className="xmm-bulk-form-row">
                <span className="text-right">
                  {
                    localeStrings[
                      "xmm.portal.operations.form.csr_settings.default_service_type_label"
                    ]
                  }
                </span>
                <div>
                  <SelectInput
                    htmlId="defaultServiceType"
                    name="defaultServiceType"
                    options={ServiceTypeOptions}
                    value={formData.defaultServiceType}
                    onChange={onDefaultServiceTypeChange}
                    error={errors.defaultServiceType}
                    disabled={toggleStatus.defaultServiceType}
                    displayLabel={false}
                    displayDeselectOption={false}
                    displayPlaceholder={true}
                  />
                </div>
                <ToggleSwitch
                  htmlId="defaultServiceTypeToggle"
                  name="defaultServiceType"
                  defaultChecked={false}
                  onClick={onToggleClick}
                />
              </div>
              <div className="xmm-bulk-form-row">
                <span className="text-right">
                  {
                    localeStrings[
                      "xmm.portal.operations.form.csr_settings.dispatch_skill_level_label"
                    ]
                  }
                </span>
                <div>
                  <SelectInput
                    htmlId="dispatchSkillLevel"
                    name="dispatchSkillLevel"
                    options={DispatchSkillLevelTypeOptions}
                    value={formData.dispatchSkillLevel}
                    onChange={ondispatchSkillLevelChange}
                    displayDeselectOption={false}
                    error={errors.dispatchSkillLevel}
                    disabled={toggleStatus.dispatchSkillLevel}
                    displayLabel={false}
                    displayPlaceholder={false}
                  />
                </div>

                <ToggleSwitch
                  htmlId="dispatchSkillLevelToggle"
                  name="dispatchSkillLevel"
                  defaultChecked={false}
                  onClick={onToggleClick}
                />
              </div>

              <div className="xmm-bulk-form-row">
                <span className="text-right">
                  {
                    localeStrings[
                      "xmm.portal.operations.form.ccc_statements.complaint_label"
                    ]
                  }
                </span>
                <div>
                  <TextArea
                    htmlId="complaint"
                    label="Complaint"
                    maxLength={500}
                    name="complaint"
                    className={errors.complaint}
                    onChange={updateField}
                    disabled={toggleStatus.complaint}
                    value={formData.complaint || ""}
                    displayLabel={false}
                    layout="horizontal"
                  />
                </div>

                <ToggleSwitch
                  htmlId="complaintToggle"
                  name="complaint"
                  defaultChecked={false}
                  onClick={onToggleClick}
                />
              </div>

              <div className="xmm-bulk-form-row">
                <span className="text-right">
                  {
                    localeStrings[
                      "xmm.portal.operations.form.csr_settings.default_pay_type"
                    ]
                  }
                </span>
                <div>
                  <SelectInput
                    htmlId="defaultPayType"
                    name="defaultPayType"
                    options={payTypeOptions}
                    value={formData.defaultPayType}
                    onChange={onDefaultPayTypeChange}
                    displayDeselectOption={false}
                    error={errors.defaultPayType}
                    disabled={toggleStatus.defaultPayType}
                    displayLabel={false}
                    displayPlaceholder={false}
                  />
                </div>

                <ToggleSwitch
                  htmlId="defaultPayTypeToggle"
                  name="defaultPayType"
                  defaultChecked={false}
                  onClick={onToggleClick}
                />
              </div>

              <div className="xmm-bulk-form-row">
                <span className="text-right">
                  {
                    localeStrings[
                      "xmm.portal.operations.form.csr_settings.exclude_discount"
                    ]
                  }
                </span>
                <div>
                  <input
                    className="form-checkbox"
                    type="checkbox"
                    name="excludeDiscount"
                    id="excludeDiscount"
                    disabled={toggleStatus.excludeDiscount}
                    checked={formData.excludeDiscount}
                    onChange={updateFieldCheckbox}
                  />
                </div>

                <ToggleSwitch
                  htmlId="excludeDiscountToggle"
                  name="excludeDiscount"
                  defaultChecked={false}
                  onClick={onToggleClick}
                />
              </div>

              <div className="xmm-bulk-form-row">
                <span className="text-right">
                  {
                    localeStrings[
                      "xmm.portal.operations.form.csr_settings.apply_shop_supplies"
                    ]
                  }
                </span>
                <div>
                  <input
                    className="form-checkbox"
                    type="checkbox"
                    name="applyShopSupplies"
                    id="applyShopSupplies"
                    disabled={toggleStatus.applyShopSupplies}
                    onChange={updateFieldCheckbox}
                  />
                </div>

                <ToggleSwitch
                  htmlId="applyShopSuppliesToggle"
                  name="applyShopSupplies"
                  defaultChecked={false}
                  onClick={onToggleClick}
                />
              </div>

              <div className="xmm-bulk-form-row">
                <span className="text-right">
                  {
                    localeStrings[
                      "xmm.portal.operations.form.csr_settings.default_tax_type"
                    ]
                  }
                </span>
                <div>
                  <SelectInput
                    htmlId="defaultTaxCode"
                    name="defaultTaxCode"
                    options={TaxTypeOptions}
                    value={formData.defaultTaxCode}
                    onChange={ondispatchTaxTypeChange}
                    displayDeselectOption={false}
                    error={errors.defaultTaxCode}
                    disabled={toggleStatus.defaultTaxCode}
                    displayLabel={false}
                    displayPlaceholder={false}
                  />
                </div>

                <ToggleSwitch
                  htmlId="defaultTaxTypeToggle"
                  name="defaultTaxCode"
                  defaultChecked={false}
                  onClick={onToggleClick}
                />
              </div>

              <div className="xmm-bulk-form-row">
                <span className="text-right">
                  {
                    localeStrings[
                      "xmm.portal.operations.form.csr_settings.labor_cost_overrides"
                    ]
                  }
                </span>
                <div>
                  <PriceInput
                    htmlId="laborCostOverride"
                    name="laborCostOverride"
                    label="Labor Cost Override"
                    onChange={onChangePrice}
                    value={formData.laborCostOverride || ""}
                    error={errors.laborCostOverride}
                    maxLength={6}
                    maxValue={999.99}
                    disabled={toggleStatus.laborCostOverride}
                    displayLabel={false}
                  />
                </div>
                <ToggleSwitch
                  htmlId="laborCostOverrideToggle"
                  name="laborCostOverride"
                  onClick={onToggleClick}
                />
              </div>
            </>
          )}
        </div>
      </Card>
    </>
  );
}
export default BulkEditPane;

BulkEditPane.propTypes = {
  closeSlider: PropTypes.func,
  showFindOpcodeModal: PropTypes.func,
  updateStatusBox: PropTypes.func,
  commonOpsNoPartsReviewRequired: PropTypes.bool
};
