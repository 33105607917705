/* eslint-disable no-console */
import React from "react";
import { PropTypes } from "prop-types";
import Button from "@cx/ui/Button";
import IconKeyboardArrowDown from "@cx/ui/Icons/IconKeyboardArrowDown";
import IconKeyboardArrowRight from "@cx/ui/Icons/IconKeyboardArrowRight";
import SelectInput from "@cx/ui/SelectInput";
import TextInput from "@cx/ui/TextInput";
import OperationRow from "./OperationRow";
import PreviewContext from "../../preview-context";
import {
  isEmpty,
  doesEmpty,
  isCalculatedPricing
} from "../../../../../commonUtil/utils/object";
import * as gtmEvents from "../../../../utils/gtag-eventlist";
class IndividualOperationsTable extends React.Component {
  static contextType = PreviewContext;
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.result !== prevState.rawResult) {
      return prevState.getNewStateInfo(nextProps);
    }
    return null;
  }
  constructor(props, context) {
    super(props, context);
    const { localeStrings } = context.appContext;
    // preview.resultTabs.selectedIndex = props.selectedIndex;

    this.expandAllClick = this.expandAllClick.bind(this);
    this.onChange = this.onChange.bind(this);
    this.getNewStateInfo = this.getNewStateInfo.bind(this);

    const {
      rawResult,
      services,
      filteredData,
      // isExpand: false,
      filterCount,
      totalCount,
      disableFilters,
      makes,
      // isChanged: false,
      isLoaded,
      statusMsg
    } = this.getNewStateInfo(props);

    this.serviceKindOptions = [
      { value: "", label: localeStrings["xmm.portal.common.all_kinds"] },
      {
        value: "maintenance",
        label: localeStrings["xmm.portal.common.maintenance_lbl"]
      },
      { value: "repair", label: localeStrings["xmm.portal.common.repair_lbl"] }
    ];

    this.state = {
      rawResult,
      services,
      filteredData,
      isExpand: false,
      filterCount,
      totalCount,
      disableFilters,
      makes,
      isChanged: false,
      isLoaded,
      statusMsg,
      filters: {
        make: "",
        kind: "",
        searchTerm: ""
      },
      getNewStateInfo: this.getNewStateInfo
    };
    console.log("Alacarte table", services);
  }
  componentDidMount() {
    gtmEvents.trackGtagPageview("/preview/individual-operations");
  }

  getNewStateInfo(props) {
    const { localeStrings } = this.context.appContext;
    this.serviceRows = [];

    let isLoaded = false;
    let statusMsg = null;
    const result = isEmpty(props.result) ? null : props.result;
    let services = [];
    if (result && result.hasOwnProperty("services")) {
      services = result.services;
      isLoaded = true;
    } else if (result && result.hasOwnProperty("message")) {
      statusMsg = result.message;
      isLoaded = false;
    }
    let makelistFilter = [];
    const makeDataFilter =
      isEmpty(result) || !result.hasOwnProperty("makeDataFilter")
        ? null
        : result.makeDataFilter;
    if (makeDataFilter && makeDataFilter.length > 0) {
      makelistFilter = [...makeDataFilter];
      makelistFilter.splice(0, 0, {
        label: localeStrings["xmm.portal.common.all_makes"],
        value: "",
        make: "all"
      });
    }

    return {
      rawResult: result,
      services,
      filteredData: services,
      // isExpand: false,
      filterCount: services.length,
      totalCount: services.length,
      disableFilters: !result ? true : false,
      makes: makelistFilter,
      // isChanged: false,
      isLoaded,
      statusMsg
    };
  }
  /* ExpandAll "icon" click handler */
  expandAllClick = event => {
    event.preventDefault();
    const { expandAll } = this.state;

    this.serviceRows.forEach(ref => {
      if (ref) {
        ref.setState({
          isExpand: !expandAll
        });
      }
    });
    this.setState(prevState => ({
      expandAll: !prevState.expandAll
    }));
  };

  /* clear filter values from parent ag-grid as well */
  clearExternalFilters() {
    const filters = {
      make: "all",
      kind: "all",
      searchTerm: ""
    };
    this.setState({
      filters: { ...filters }
    });
  }
  onChange = (cxEvent, isValid, domEvent) => {
    const { name, value } = cxEvent.target;
    this.setState(
      prevState => {
        return {
          filters: {
            ...prevState.filters,
            [name]: value
          },
          isChanged: !prevState.isChanged
        };
      },
      () => {
        // callback
        this.applyMultiFilters(cxEvent);
      }
    );
  };
  applyMultiFilters = event => {
    const { services, filters } = this.state;

    const makeFilter = doesEmpty(filters.make)
      ? ""
      : filters.make.toLowerCase();
    const kindFilter = doesEmpty(filters.kind)
      ? ""
      : filters.kind.toLowerCase();
    const searchTerm = doesEmpty(filters.searchTerm)
      ? ""
      : filters.searchTerm.toLowerCase();
    if (!searchTerm && !makeFilter && !kindFilter) {
      this.setState(() => ({
        filteredData: services,
        filterCount: services.length
      }));
      return;
    } else {
      const filteredData = services.filter(item => {
        const make = item.make.toLowerCase();
        const kind = item.serviceKind.toLowerCase();
        const name = item.name;
        const internalName = item.internalName;
        const dmsOpcode = item.dmsOpcode;
        // add part number from parts[]
        const parts = item.parts;
        const partNames = [];
        const partNumbers = [];
        const isMake = make.indexOf(makeFilter) !== -1;
        const isKind = kind.indexOf(kindFilter) !== -1;
        const isSearch =
          (name && name.toLowerCase().indexOf(searchTerm) !== -1) ||
          (internalName &&
            internalName.toLowerCase().indexOf(searchTerm) !== -1) ||
          (dmsOpcode && dmsOpcode.toLowerCase().indexOf(searchTerm) !== -1);
        /* Check if parts exist per operation */
        if (!isEmpty(parts) && parts.length > 0) {
          // parse parts object
          parts.forEach(aPart => {
            if (!doesEmpty(aPart.description)) {
              partNames.push(aPart.description);
            }
            if (!doesEmpty(aPart.oemPartNumber)) {
              partNumbers.push(aPart.oemPartNumber);
            }
          });
          const strPartNames = partNames.toString().toLowerCase();
          const strPartNumbers = partNumbers.toString().toLowerCase();
          return (
            isMake &&
            isKind &&
            (isSearch ||
              strPartNames.toLowerCase().indexOf(searchTerm) > -1 ||
              strPartNumbers.toLowerCase().indexOf(searchTerm) > -1)
          );
        } else {
          return isMake && isKind && isSearch;
        }
      });
      this.setState({ filteredData, filterCount: filteredData.length });
    }
  };

  render() {
    let resultTable = null;
    let externalFilters = null;
    const {
      filteredData,
      filters,
      disableFilters,
      isLoaded,
      statusMsg,
      expandAll
    } = this.state;
    const { localeStrings } = this.context;
    const { make } = this.context.appContext.preview.search;
    const pricingFlow = isCalculatedPricing(this.context.makelist, make);
    const clsHideBtn = pricingFlow ? "btn--icon" : "hidden";
    if (this.state.isLoaded) {
      const xOfTotalLabel = localeStrings[
        "xmm.portal.common.display_x_of_count"
      ]
        .replace("%1", this.state.filterCount)
        .replace("%2", this.state.totalCount);
      const iconCmp = expandAll ? (
        <IconKeyboardArrowDown />
      ) : (
        <IconKeyboardArrowRight />
      );

      externalFilters = (
        <div className="component-header-alacarte sticky">
          <span id="expandAll">
            <Button
              htmlId="IconSelectAll"
              className={clsHideBtn}
              buttonStyle="secondary"
              onClick={this.expandAllClick}
              icon={iconCmp}
            />
          </span>
          <span>
            <SelectInput
              htmlId="makeFilter"
              displayLabel={false}
              label={
                localeStrings["xmm.portal.opcode_validation.filter_make_lbl"]
              }
              name="make"
              placeholder={localeStrings["xmm.portal.common.select_make_label"]}
              displayPlaceholder={false}
              displayDeselectOption={false}
              disabled={false}
              onChange={this.onChange}
              options={this.state.makes}
              value={filters.make}
              maxHeight={200}
            />
          </span>
          <span>
            <SelectInput
              htmlId="kindFilter"
              displayLabel={false}
              label={
                localeStrings["xmm.portal.opcode_validation.filter_kind_lbl"]
              }
              name="kind"
              displayPlaceholder={false}
              placeholder="Select Kind"
              displayDeselectOption={false}
              disabled={disableFilters}
              onChange={this.onChange}
              options={this.serviceKindOptions}
              value={filters.kind}
            />
          </span>
          <span className="xmm-modal-help-text">
            {xOfTotalLabel}
            {/* Displaying {this.state.filterCount} of {this.state.totalCount} */}
          </span>
          <span className="xmm-input-search">
            <TextInput
              htmlId="searchFilter"
              label=""
              name="searchTerm"
              autoComplete="off"
              disabled={disableFilters}
              displayLabel={false}
              onChange={this.onChange}
              value={filters.searchTerm}
              placeholder={localeStrings["xmm.portal.common.search_label"]}
            />
          </span>
        </div>
      );

      if (filteredData && filteredData.length !== 0) {
        const serviceList = filteredData.map((s, index) => {
          return (
            <div key={"service-" + s.id}>
              <OperationRow
                ref={ref => (this.serviceRows[index + 1] = ref)}
                service={s}
              />
            </div>
          );
        });
        resultTable = (
          <div className="op-table xmm-alacarte-table">{serviceList}</div>
        );
      } else {
        resultTable = (
          <div className="xmm-content-adjust"> No records matched </div>
        );
      }
    } else if (!isLoaded && statusMsg) {
      externalFilters = (
        <div className="xmm-chart-container">
          <div className="xmm-content-adjust">
            <b> {statusMsg}</b>
          </div>
        </div>
      );
    }

    return (
      <React.Fragment>
        {externalFilters}
        {resultTable}
      </React.Fragment>
    );
  }
}

export default IndividualOperationsTable;

IndividualOperationsTable.propTypes = {
  // selectedIndex: PropTypes.string,
  result: PropTypes.object
};
