import React, { useState, memo, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import Button from "@cx/ui/Button";
import IconArrowUpward from "@cx/ui/Icons/IconArrowUpward";
import Tooltip from "@cx/ui/Tooltip";
import IconInfoOutline from "@cx/ui/Icons/IconInfoOutline";
import TextInput from "@cx/ui/TextInput";
import SelectInput from "@cx/ui/SelectInput";

import {
  isNumeric,
  priceValueFormatter,
  formatNumberWithThousands
} from "../../../utils/format.util";
import { convertMinutesToHours } from "../../../utils/quote.util";
import { getPreselectedServices } from "../../../utils/data-transformer.util";
import {
  useServiceSearchContext,
  Actions
} from "../../../state/service-search.context";
import EditMenuServicePage from "./EditMenuServicePage";
import MenuServiceDetails from "./MenuServiceDetails";
import Banner from "../../../components/banner/Banner";
import selectedMenuPackageReducer from "../../../state/selected-menu-package.reducer";
import MenuInspectServiceDetails from "./MenuInspectServiceDetails";
import selectServiceFormat from "../../../utils/select-service-format";
import ConfirmPopup from "../../../components/modals/ConfirmPopup";
import useComponentDidMount from "../../../hooks/useComponentDidMount";
import { saveActionTypes } from "../../../utils/search.constants";
import has from "lodash/has";
import isEmpty from "lodash/isEmpty";

const SelectedMenuPage = props => {
  const [showEditCmp, setShowEditCmp] = useState(false);
  const { state, dispatch, ctxOnEditService, ctxRequestPartsInventoryCommon } =
    useServiceSearchContext();
  const {
    localeStrings,
    appConfig,
    payTypes,
    selectedMenuPackage: selectedMenuPackageFromState
  } = state;
  const [payTypChanged, setPayTypeChanged] = useState(false);
  const {
    selectedMenuPackage: originalSelectedMenu,
    updateLocalState,
    EditServiceModule
  } = props;
  const [selectedMenuPackage, dispatchSelectedMenu] = useReducer(
    selectedMenuPackageReducer,
    {
      ...originalSelectedMenu
    }
  );
  const [showAll, setShowAll] = useState(false);
  const [inspectServices, setInspectServices] = useState(
    selectedMenuPackage && selectedMenuPackage.services
      ? selectedMenuPackage.services.filter(service => {
          return selectServiceFormat.isIncludedInspection(service);
        })
      : []
  );

  const [menuServices, setMenuServices] = useState(
    selectedMenuPackage && selectedMenuPackage.services
      ? selectedMenuPackage.services.filter(service => {
          return !selectServiceFormat.isIncludedInspection(service);
        })
      : []
  );
  // @todo-beta: sine want to move this default paytype logic to method and call method either in useEffect or useComponentDidMount
  let defaultPayTypeOption = "-1";

  const defaultPayTypeCode = selectedMenuPackageFromState.defaultPayTypeCode
    ? selectedMenuPackageFromState.defaultPayTypeCode
    : "";
  let selectedDefault = {
    payCode: "",
    description: "",
    dealerPayTypeId: "-1"
  };
  if (!isEmpty(payTypes)) {
    let tempSelectedDefault = payTypes.find(
      pt =>
        pt.payCode === defaultPayTypeCode &&
        pt.make === state.vehicle.make &&
        pt.defaultPayType === true
    );
    if (!tempSelectedDefault) {
      tempSelectedDefault = payTypes.find(
        pt => pt.payCode === defaultPayTypeCode && pt.defaultPayType === true
      );
    }
    if (!tempSelectedDefault) {
      tempSelectedDefault = payTypes.find(
        pt =>
          pt.payCode === defaultPayTypeCode && pt.make === state.vehicle.make
      );
    }
    if (!tempSelectedDefault) {
      tempSelectedDefault = payTypes.find(
        pt => pt.payCode === defaultPayTypeCode
      );
    }
    selectedDefault = tempSelectedDefault && tempSelectedDefault;
    defaultPayTypeOption = selectedDefault
      ? selectedDefault.dealerPayTypeId
      : "";
  }
  const { payCode: selectedPayCode, description: selectedDescription } =
    selectedDefault;
  useEffect(() => {
    if (!isEmpty(payTypes)) {
      processPayTypeChange(selectedPayCode, selectedDescription);
    }
  }, [selectedDefault]);

  const processPayTypeChange = (payCode, description) => {
    const updatedMenuPackage = selectedMenuPackageFromState;
    const updatedWithPayTypeService = selectedMenuPackageFromState.services.map(
      newService =>
        // @todo-edit: copy override values coming from menu level to service object here
        Object.assign(newService, {
          // TBD - verify how menu overrides can be passed to menu service
          // totalPriceOverridden: selectedMenuPackageFromState.totalPriceOverridden,
          payTypeCode: payCode,
          payTypeDescription: description
        })
    );
    updatedMenuPackage.services = updatedWithPayTypeService;
    updatedMenuPackage.payTypeCode = payCode;
    updatedMenuPackage.payTypeDescription = description;
    updatedMenuPackage.drivingCondition = isEmpty(
      state.menuSelectedDrivingCondition
    )
      ? "Normal"
      : state.menuSelectedDrivingCondition;
    // @todo: when menu pkg is updated with paytype values, we need to update reducer with latest menu - TBD, not fixed today
    dispatch({
      type: Actions.SET_MENU_PACKAGE,
      payload: updatedMenuPackage
    });
  };

  // PayType options build
  const buildPayTypeOption = () => {
    const returnedPayType = [];
    payTypes.forEach(payType => {
      returnedPayType.push({
        value: payType.dealerPayTypeId.toString(),
        label: `${payType.payCode} - ${payType.description}`
      });
    });
    return returnedPayType;
  };

  const handlePayTypeChange = e => {
    setPayTypeChanged(true);

    const selection = payTypes.find(
      pt => pt.dealerPayTypeId.toString() === e.target.value
    );
    processPayTypeChange(selection.payCode, selection.description);
  };

  const [changedServices, setChangedServices] = useState([]);

  const [selectedList, setSelectedList] = useState(
    getPreselectedServices(menuServices)
  );

  const [selectedService, setSelectedService] = useState("");

  const [originalService, setOriginalService] = useState({});

  const [originalCheckedState, setOriginalCheckedState] = useState();

  const [checkedState, setCheckedState] = useState(() => {
    const checkedState =
      menuServices && menuServices.length !== 0
        ? menuServices.map(service => {
            if (!service.selectable && !service.selectByDefault) {
              return true;
            }
            return service.selectByDefault;
          })
        : [];

    setOriginalCheckedState(checkedState);
    return checkedState;
  });

  const [totalPrice, setTotalPrice] = useState(0);
  const [totalLaborPrice, setTotalLaborPrice] = useState(0);
  const [totalPartPrice, setTotalPartPrice] = useState(0);
  const [totalHours, setTotalHours] = useState(0);
  const [isMenuSelected, setIsMenuSelected] = useState(false);
  const {
    user: { userName }
  } = appConfig;

  const [serviceList] = useState();
  const [showDiscardMenuPopup, setShowDiscardMenuPopup] = useState(false);
  const [serviceHasBeenEdited, setServiceHasBeenEdited] = useState(false);

  const isPackagePriceOverriden = selectedMenuPackage.totalPriceOverridden;
  const selectable = selectedMenuPackage.selectable;
  const allowPriceOverride = isPackagePriceOverriden && !selectable;

  useComponentDidMount(() => {
    dispatchSelectedMenu({
      type: "SET_ORIGINAL_SERVICES",
      payload: originalSelectedMenu.services
    });
  });

  useEffect(() => {
    const isMenuSelected = checkedState.some(element => element);
    setIsMenuSelected(isMenuSelected);
  }, [checkedState, showAll]);

  useEffect(() => {
    setMenuServices(
      selectedMenuPackage.services.filter(service => {
        return !selectServiceFormat.isIncludedInspection(service);
      })
    );
    setInspectServices(
      selectedMenuPackage.services.filter(service => {
        return selectServiceFormat.isIncludedInspection(service);
      })
    );
    setChangedServices(selectedMenuPackage.changedServices);
  }, [selectedMenuPackage]);

  const calculateMenuPackage = services => {
    const subtotalLabor = calculateLaborSubtotal(services);
    const subtotalPart = calculatePartsSubtotal(services);
    const totalLaborHour = calculateTotalLaborHour(services);
    let totalPrice = 0;
    if (isPackagePriceOverriden) {
      totalPrice = selectedMenuPackage.totalPrice;
    } else {
      totalPrice = subtotalLabor + subtotalPart;
    }
    setTotalPrice(totalPrice);
    setTotalHours(totalLaborHour);
    setTotalLaborPrice(subtotalLabor);
    setTotalPartPrice(subtotalPart);
  };

  const calculatePartsSubtotal = services => {
    const totalParts = services
      .map(service => service.partsPrice)
      .reduce((acc, next) => {
        return acc + next;
      });
    return totalParts;
  };

  const calculateLaborSubtotal = services => {
    if (isPackagePriceOverriden) {
      return selectedMenuPackage.totalPrice - calculatePartsSubtotal(services);
    }
    const totalLabor = services
      .map(service => service.finalLaborPrice)
      .reduce((acc, next) => {
        return acc + next;
      });
    return totalLabor;
  };

  const calculateTotalLaborHour = services => {
    const totalLaborHour = services
      .map(service => service.shopDurationMins)
      .reduce((acc, next) => {
        return acc + next;
      });
    return totalLaborHour;
  };

  useEffect(() => {
    const checkedServices = menuServices.filter(
      (item, index) => checkedState[index] === true
    );
    setSelectedList(checkedServices);
    if (Array.isArray(checkedServices) && checkedServices.length > 0) {
      calculateMenuPackage(checkedServices);
    }
    if (Array.isArray(changedServices) && changedServices.length > 0) {
      const changedServicesIds = changedServices.map(item => item.id);
      setSelectedList(prevList => {
        const updatedSelectedList = prevList.filter(
          item => !changedServicesIds.includes(item.id)
        );
        changedServices.forEach(item => {
          updatedSelectedList.push(item);
        });
        return updatedSelectedList;
      });
    }
  }, [menuServices, checkedState, changedServices]);

  const handleDiscountReasonChange = (/* reason, */ serviceIndex) => {
    dispatchSelectedMenu({
      type: "SET_MENU_ITEM_DISCOUNT_REASON",
      payload: {
        reason: "Placeholder reason",
        serviceIndex
      }
    });
  };
  const handleServicePriceChange = (newPrice, serviceIndex) => {
    dispatchSelectedMenu({
      type: "SET_MENU_ITEM_PRICE",
      payload: {
        newPrice,
        serviceIndex
      }
    });
    handleDiscountReasonChange(serviceIndex);
  };
  const handleLaborPriceChange = (newPrice, serviceIndex) => {
    dispatchSelectedMenu({
      type: "SET_MENU_ITEM_LABOR_PRICE",
      payload: {
        newPrice,
        serviceIndex
      }
    });
  };
  const handleServiceChange = (newService, serviceIndex) => {
    dispatchSelectedMenu({
      type: "SET_MENU_ITEM",
      payload: {
        newService,
        serviceIndex
      }
    });
  };

  const handleBackToMenuPopup = () => {
    ctxOnEditService(false);
    const hasServicesSelectionChange = checkedState.reduce(
      (previousValue, currentValue, currentIndex) => {
        return (
          previousValue || currentValue !== originalCheckedState[currentIndex]
        );
      },
      false
    );

    const hasAnyPriceInPackagesChange = selectedMenuPackage.services.reduce(
      (previousValue, currentValue, currentIndex) => {
        return (
          previousValue ||
          currentValue.price !==
            originalSelectedMenu.services[currentIndex].price
        );
      },
      false
    );

    if (hasServicesSelectionChange || hasAnyPriceInPackagesChange) {
      setShowDiscardMenuPopup(true);
    } else {
      handleBackToMenus();
    }
  };

  const handleBackToMenus = () => {
    // TODO: update context to hide search header

    dispatchSelectedMenu({
      type: "RESET_PACKAGE_STATE",
      payload: originalSelectedMenu
    });
    dispatch({
      type: Actions.SET_SEARCH_HEADER,
      payload: true
    });
    updateLocalState(false);
  };

  const handleBackToPackage = showEdit => {
    setShowEditCmp(showEdit);
  };

  const addPackageToQuote = async (e, menuRecord, addAnother) => {
    e.preventDefault();
    // Note - Selection and/or inspections conditions are valid when either one of the two following are true:
    // A. there is at least one selected or pre-selected service, or
    // B. package is non-selectable and there in at least one included inspection.
    const selectionAndInspectionConditionsAreValid =
      isMenuSelected || (!menuRecord.selectable && inspectServices.length > 0);
    if (
      menuRecord &&
      Object.keys(menuRecord).length > 0 &&
      selectionAndInspectionConditionsAreValid
    ) {
      const uniqueSelectedList = selectedList.reduce((acc, current) => {
        const x = acc.find(item => item.id === current.id);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);

      const uniqueInspectServices = inspectServices.reduce((acc, current) => {
        const x = acc.find(item => item.id === current.id);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);

      menuRecord.services = uniqueSelectedList.concat(uniqueInspectServices);
      menuRecord.totalPrice = totalPrice;
      menuRecord.partsPrice = totalPartPrice;
      menuRecord.laborPrice = totalLaborPrice;
      menuRecord.duration = totalHours;
      menuRecord.payTypeCode = selectedMenuPackageFromState.payTypeCode;
      menuRecord.payTypeDescription =
        selectedMenuPackageFromState.payTypeDescription;
      // @todo remove menuRecord after make sure it doesn't affect any side effect
      const menuObj = {
        ...originalSelectedMenu,
        services: uniqueSelectedList.concat(uniqueInspectServices),
        totalPrice,
        partsPrice: totalPartPrice,
        laborPrice: totalLaborPrice,
        duration: totalHours,
        payTypeCode: selectedMenuPackageFromState.payTypeCode,
        payTypeDescription: selectedMenuPackageFromState.payTypeDescription,
        drivingCondition: isEmpty(state.menuSelectedDrivingCondition)
          ? "Normal"
          : state.menuSelectedDrivingCondition
      };
      dispatch({
        type: Actions.SET_MENU_PACKAGE,
        payload: menuObj
      });
      dispatch({
        type: Actions.IS_MENU_UPDATED,
        payload: true
      });
      // TODO: update search context to show search header
      dispatch({
        type: Actions.SET_SEARCH_HEADER,
        payload: true
      });
      if (addAnother) {
        dispatch({
          type: Actions.SET_SAVE_ACTION_TYPE,
          payload: saveActionTypes.SAVEANOTHER
        });
      } else {
        dispatch({
          type: Actions.SET_SAVE_ACTION_TYPE,
          payload: saveActionTypes.SAVE
        });
        dispatch({
          type: Actions.SET_PAGE_MASK,
          payload: true
        });
      }
    }
  };

  const editPage = showEditCmp ? (
    <EditMenuServicePage
      onBackToPackage={handleBackToPackage}
      service={selectedService}
      selectedMenuPackage={selectedMenuPackage}
      originalService={originalService}
      onServicePriceChange={handleServicePriceChange}
      onLaborPriceChange={handleLaborPriceChange}
      onServiceChange={handleServiceChange}
      // onDiscountReasonChange={handleDiscountReasonChange}
      username={userName}
      EditServiceModule={EditServiceModule}
      serviceHasBeenEdited={serviceHasBeenEdited}
    />
  ) : (
    ""
  );
  const getServiceName = menu => {
    if (menu) {
      const { mileage, units, description } = menu;
      const formattedMileage = formatNumberWithThousands(mileage);
      return `${formattedMileage} ${units} - ${description}`;
    }
    return menu.description;
  };
  /* checkbox change handler */
  const showDetailsClick = event => {
    setShowAll(event.target.checked);
  };
  const componentHeader = (
    <div className="pkg-header-block">
      <div className="menu-title">
        <div className="search-truncate-text">
          Review {getServiceName(selectedMenuPackage)} package details
        </div>
      </div>
      <div className="action-box">
        <div className="xmm-checkbox-container" id="showAll">
          <input
            className="form-checkbox"
            name="showAll"
            id="showAllChk"
            type="checkbox"
            checked={showAll}
            onChange={showDetailsClick}
          />
          <span className="xmm-checkmark" />
          <span className="label-checkbox full-label">
            {localeStrings["sq.search.edit_service.summary.show_details_lbl"]}
          </span>
        </div>
      </div>
    </div>
  );

  // @todo-workaround: Here call open track api to get dms prices when edit clicked to pass service as props
  const handleEditService = async (e, service, showEdit) => {
    e.preventDefault();
    setShowEditCmp(showEdit);
    const originalSelectedService = originalSelectedMenu.services.find(
      item => item.id === service.id
    );
    // console.log(
    //   ">>> originalSelectedService, service",
    //   originalSelectedService,
    //   service
    // );
    // @important: to support for opentrack api bindings, we have to add priceSource field in MenuService object;
    // since MenuService.part has partPriceSource field
    const rawParts = originalSelectedService.part;
    rawParts.map(part => {
      part.priceSource = part.partPriceSource;
      return part;
    });
    setOriginalService(originalSelectedService);
    const serviceParts = service.part;
    serviceParts.map(part => {
      part.priceSource = part.partPriceSource;
      return part;
    });
    setOriginalService(originalSelectedService);
    setSelectedService(service);
    const changedServicesIds = selectedMenuPackage.changedServices.map(
      service => service.id
    );
    const serviceHasBeenEdited = changedServicesIds.includes(service.id);
    setServiceHasBeenEdited(serviceHasBeenEdited);
    if (!serviceHasBeenEdited) {
      service.operationSource = "Menus";
      delete service.parts;
      ctxRequestPartsInventoryCommon(service);
    }
  };
  // TODO: update state with checked service and remove unchecked
  const handleServiceClick = (event, service, position) => {
    const { checked } = event.target;
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedState(updatedCheckedState);
    let result = selectedList;
    if (checked) {
      result.push(service);
    } else {
      result = selectedList.filter(s => s.id !== service.id);
    }
    setSelectedList(result);
  };

  const createPriceChangedTooltip = serviceCurrent => {
    const serviceOriginal = originalSelectedMenu.services.find(
      item => item.id === serviceCurrent.id
    );
    if (serviceOriginal && serviceOriginal.price !== serviceCurrent.price) {
      return (
        <Tooltip
          htmlId="menuServiceLaborPriceEdited"
          tooltipContent={`Labor changed from ${priceValueFormatter(
            serviceOriginal.price
          )} to ${priceValueFormatter(serviceCurrent.price)} by ${userName}`}
          position="left"
        >
          <IconInfoOutline htmlId="iconFee" />
        </Tooltip>
      );
    } else {
      return null;
    }
  };

  const menuServicesHtml = menuServices.map((service, index) => {
    const showPrice = isNumeric(service.price) && service.price > 0;
    const isPackagePriceOverriden = selectedMenuPackage.totalPriceOverridden;
    const selectable = service.selectable;
    // const selectByDefault = selected[service.id];
    return (
      <div className={showAll ? "op-row modified" : "op-row"} key={service.id}>
        <div className="op-name">
          <div className="top-level">
            {selectable ? (
              <div
                className="xmm-checkbox-container"
                id={`service-${service.id}`}
              >
                <input
                  className="form-checkbox"
                  name={service.id}
                  value={service.id}
                  id={`service-chk-${index}`}
                  type="checkbox"
                  checked={checkedState[index]}
                  onChange={e => handleServiceClick(e, service, index)}
                />
                <span className="xmm-checkmark" />
                <span className="label-checkbox full-label" />
              </div>
            ) : (
              ""
            )}
            <div
              className={!selectable ? "op-title margin-left-18" : "op-title"}
            >
              {service.name}
            </div>
            <div className="op-price">
              {createPriceChangedTooltip(service, index)}
              {isPackagePriceOverriden
                ? ""
                : showPrice
                ? priceValueFormatter(service.price)
                : "Incl."}
            </div>
            {isPackagePriceOverriden ? (
              <div className="pkg-no-edit">&nbsp;</div>
            ) : showPrice ? (
              <div className="pkg-action-links">
                <span
                  className="hand-cursor"
                  id="editServiceLink"
                  onClick={e => handleEditService(e, service, true)}
                >
                  {localeStrings["sq.search.common.edit_button"]}
                </span>
              </div>
            ) : (
              <div className="pkg-no-edit">&nbsp;</div>
            )}
          </div>
        </div>
        <MenuServiceDetails
          key={`key-${service.id.toString()}`}
          expandDetails={showAll}
          service={service}
          isPackagePriceOverriden={isPackagePriceOverriden}
        />
      </div>
    );
  });

  const inspectServicesHtml = (
    <div
      className={showAll ? "op-row modified" : "op-row"}
      key="key-Inspect-services"
    >
      <div className="op-name">
        <div className="top-level">
          <div className="op-title margin-left-18">
            {
              localeStrings[
                "sq.search.edit_service.summary.included_inspection"
              ]
            }{" "}
            ({inspectServices.length})
          </div>
        </div>
      </div>
      <MenuInspectServiceDetails
        key="inspect-services-details"
        expandDetails={showAll}
        inspectServices={inspectServices}
      />
    </div>
  );
  const parentCls = showEditCmp ? "hide-ele" : "search-flex-grid-container";
  const getBannerMessage = () => {
    if (allowPriceOverride) {
      return localeStrings["sq.search.menu.package.customize"];
    } else if (selectable && !isPackagePriceOverriden) {
      return localeStrings["sq.search.menu.package.selectable"];
    } else if (!selectable && !isPackagePriceOverriden) {
      return localeStrings["sq.search.menu.package.modifiable"];
    }
  };

  const handleDiscardMenuPopupProceed = () => {
    handleBackToMenus();
  };

  const handleDiscardMenuPopupCancel = () => {
    setShowDiscardMenuPopup(false);
  };

  const discardMenuReviewPopup = (
    <ConfirmPopup
      title={localeStrings["sq.search.common.alert_lbl"]}
      message={localeStrings["sq.search.common.leaving_menu_package_edit"]}
      show={showDiscardMenuPopup}
      okText={localeStrings["sq.search.common.proceed_button"]}
      cancelText={localeStrings["sq.search.common.cancel_button"]}
      okAction={handleDiscardMenuPopupProceed}
      cancelAction={handleDiscardMenuPopupCancel}
      hideCancel={false}
      hideOk={false}
      buttonStyle="danger"
    />
  );

  return (
    <>
      <div id="selectedMenuContainer" className={parentCls}>
        <span className="back-nav-label" onClick={handleBackToMenuPopup}>
          <IconArrowUpward
            htmlId="backArrowIcon"
            isActive={false}
            className="back-arrow"
          />{" "}
          Back to menu packages
        </span>

        {!isMenuSelected && (
          <Banner type="warning">
            <span>
              {
                localeStrings[
                  "sq.newquote.edit_menu_service.no_service_selected_msg"
                ]
              }
            </span>
          </Banner>
        )}

        <div className="pkg-service-main">
          <div className="pkg-services-box">
            {componentHeader}
            <div className="pkg-op-table pkg-alacarte-table">
              {menuServicesHtml}
              {inspectServices.length !== 0 ? inspectServicesHtml : ""}
            </div>
            <div className="pkg-op-table pkg-alacarte-table">{serviceList}</div>
            <div className="pkg-service-customize-info">
              <Banner type="notice">
                <span>{getBannerMessage()}</span>
              </Banner>
            </div>
            {/* @todo Take below form to a external component once dynamic calcs are available on API */}
            <div className="pkg-service-customize-controls">
              <div className="labor-form">
                <div className="pkg-opcode-field input-field">
                  <TextInput
                    htmlId="editMenuPackageOpCode"
                    label="Package op code"
                    name="edit-menu-package-op-code"
                    onChange={() => {}}
                    value={
                      has(selectedMenuPackage, "dmsOpcode")
                        ? selectedMenuPackage.dmsOpcode
                        : ""
                    }
                    disabled
                    appendChild={
                      <Button
                        buttonStyle="secondary"
                        htmlId="menuOpcodeLookupBtn"
                        type="submit"
                      >
                        <i className="fa fa-search" aria-hidden="true" />
                      </Button>
                    }
                  />
                  <p className="pkg-opcode-disclosure">
                    {localeStrings["sq.search.menu.package.opcode"]}
                  </p>
                </div>
                <SelectInput
                  htmlId="payTypeSelect"
                  name="payType"
                  label="Pay Type"
                  maxHeight={150}
                  displayDeselectOption={false}
                  value={
                    !payTypChanged && defaultPayTypeOption.toString() !== "-1"
                      ? defaultPayTypeOption.toString()
                      : ""
                  }
                  onChange={cxEvent => handlePayTypeChange(cxEvent)}
                  options={[...buildPayTypeOption()]}
                  className="input-field select-field"
                />
                <div className="total-field">
                  <div className="total-label">Total hours</div>
                  <div className="total-value">
                    {convertMinutesToHours(totalHours)}
                  </div>
                </div>
                <div className="total-field">
                  <div className="total-label">Total labor</div>
                  <div className="total-value">
                    {priceValueFormatter(totalLaborPrice)}
                  </div>
                </div>
                <div className="total-field">
                  <div className="total-label">Total parts</div>
                  <div className="total-value">
                    {priceValueFormatter(totalPartPrice)}
                  </div>
                </div>
                <TextInput
                  htmlId="menuPackageTotal"
                  inputPrefix="$"
                  label="Package total"
                  name="menu-package-total"
                  onChange={() => {}}
                  className="input-field"
                  disabled
                  value={parseFloat(totalPrice).toFixed(2)}
                />
              </div>
            </div>
            <div className="pkg-flex-bgroup">
              <Button
                htmlId="save-and-add-another-menuBtn"
                buttonStyle="secondary"
                type="button"
                onClick={e => addPackageToQuote(e, selectedMenuPackage, true)}
              >
                Save and add another
              </Button>
              <div className="menu-service-save-cancel">
                <span
                  id="cancelMenuBtn"
                  className="hand-cursor"
                  onClick={handleBackToMenuPopup}
                >
                  {localeStrings["sq.search.common.cancel_button"]}
                </span>

                <Button
                  htmlId={`save-menu-${selectedMenuPackage.id}`}
                  buttonStyle="primary"
                  type="button"
                  size="sm"
                  onClick={e =>
                    addPackageToQuote(e, selectedMenuPackage, false)
                  }
                >
                  {localeStrings["sq.search.common.save_button"]}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {editPage}
      {discardMenuReviewPopup}
    </>
  );
};

export default memo(SelectedMenuPage);

SelectedMenuPage.defaultProps = {
  updateLocalState: () => {},
  selectedMenuPackage: null,
  EditServiceModule: null
};

SelectedMenuPage.propTypes = {
  updateLocalState: PropTypes.func,
  selectedMenuPackage: PropTypes.object.isRequired,
  EditServiceModule: PropTypes.element
};
