/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React, { Component } from "react";
import VehicleGroupSelector from "../VehicleGroups/VehicleGroupSelector";
import { PropTypes } from "prop-types";
import { toEmptyStringIfUndefined } from "../../../commonUtil/utils/string";

// const KEY_BACKSPACE = 8;
// const KEY_DELETE = 46;
// const KEY_F2 = 113;

export default class VehicleGroupEditor extends Component {
  // static contextType = AppContext;
  constructor(props) {
    super(props);

    this.vehicleGroupSelectorRef = React.createRef();

    console.log("VehicleGroupEditor", props);

    this.onBlur = this.onBlur.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onClick = this.onClick.bind(this);
    this.state = this.createInitialState(props);
  }

  createInitialState(props) {
    const highlightAllOnFocus = true;
    const { data, parentHandle } = props;
    const { make, metaVehicleFilterName } = data;
    const { appContext } = parentHandle.context;
    const context = !appContext ? parentHandle.context : appContext;
    const { locale, vehicleGroupMakeList } = context;
    const makes = vehicleGroupMakeList.filter(m => {
      return m.make === make;
    });
    const { dealerCode, variant } = makes[0];
    const metaVehicleFilterId = toEmptyStringIfUndefined(
      data.metaVehicleFilterId
    );
    const metaVehicleScope = metaVehicleFilterId === "" ? "1" : "0";
    const dataValue = {
      dealerCode, // in
      make, // in
      variant, // in
      locale, // in
      metaVehicleScope,
      metaVehicleFilterId,
      metaVehicleFilterName: toEmptyStringIfUndefined(metaVehicleFilterName) // in and out
    };
    if (data.dealerScheduleRuleId) {
      parentHandle.setState({ dealerScheduleRule: data });
    }
    return {
      context,
      outdata: dataValue,
      metaVehicleFilterName: data.metaVehicleFilterName,
      highlightAllOnFocus
    };
  }

  onBlur = (cxEvent, domEvent) => {
    //
  };

  onChange = event => {
    const { data } = event.detail;
    this.props.data.outdata = data;
    this.setState({ metaVehicleFilterName: data.name });
  };

  onClick = event => {
    console.log("VehicleGroupEditor.onClick", event);
  };

  getValue() {
    return this.state.metaVehicleFilterName;
  }

  // for testing
  setValue(newValue) {
    this.setState({ metaVehicleFilterName: newValue });
  }

  isCancelBeforeStart() {
    return false;
  }

  isCancelAfterEnd() {
    return false;
  }

  afterGuiAttached() {
    if (this.state.highlightAllOnFocus) {
      this.setState({
        highlightAllOnFocus: false
      });
    }
    const selectorRef = this.vehicleGroupSelectorRef.current;
    selectorRef.showPanel();
  }

  isPopup() {
    return true;
  }

  render() {
    const { context, outdata } = this.state;
    const editorStyle = this.props.column
      ? { width: this.props.column.actualWidth }
      : {};
    return (
      <React.Fragment>
        <div style={editorStyle}>
          <VehicleGroupSelector
            ref={this.vehicleGroupSelectorRef}
            context={context}
            data={outdata}
            fromEditor={true}
            error={this.props.error}
            onBlur={this.onBlur}
            onChange={this.onChange}
            autoLoad={true}
            rowHeight={this.props.node.rowHeight}
          />
        </div>
      </React.Fragment>
    );
  }
}

VehicleGroupEditor.propTypes = {
  data: PropTypes.object,
  error: PropTypes.string,
  parentHandle: PropTypes.object
};
