/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React from "react";
import { PropTypes } from "prop-types";
import IconKeyboardArrowDown from "@cx/ui/Icons/IconKeyboardArrowDown";
import IconKeyboardArrowRight from "@cx/ui/Icons/IconKeyboardArrowRight";
import Badge from "@cx/ui/Badge";
import { isEmpty } from "../../../../../commonUtil/utils/object";
import PreviewContext from "../../preview-context";

class InspectionTemplate extends React.Component {
  static contextType = PreviewContext;
  constructor(props, context) {
    super(props, context);
    this.toggleRowClick = this.toggleRowClick.bind(this);
    this.state = {
      isExpand: false
    };
  }
  componentDidMount() {}
  toggleRowClick = event => {
    event.preventDefault();
    this.setState(prevState => ({
      isExpand: !prevState.isExpand
    }));
  };

  render() {
    let inspectTable = null;
    const { isExpand } = this.state;
    const services = this.props.results;
    const rawMenuTypes =
      !isEmpty(this.props.menuTypes) && this.props.menuTypes.length > 0
        ? this.props.menuTypes
        : [];
    // For Badge counts
    const totalMenuCounts = rawMenuTypes.map(raw => {
      return 0;
    });
    const clsHide = isExpand
      ? "xmm-row xmm-row-expanded"
      : "xmm-row xmm-row-hide";
    const clsMenu = rawMenuTypes.length > 0 ? "xmm-services" : "hide";
    let serviceHtml = null;
    if (services && services.length > 0) {
      serviceHtml = services.map(s => {
        return (
          <div className="xmm-total-labels" key={"inspect" + s.id}>
            {" "}
            {s.name}
          </div>
        );
      });
    }
    let childHtml = null;
    let menuTypeHtml = null;

    if (services && services.length > 0) {
      childHtml = services.map(service => {
        const { serviceMenuTypes } = service;

        if (serviceMenuTypes && serviceMenuTypes.length > 0) {
          menuTypeHtml = serviceMenuTypes.map((menuType, index) => {
            const { id, selectableType, includedInMenu } = menuType;
            let selectIcon = <i> &nbsp; </i>;
            if (!includedInMenu) {
              selectIcon = <i> &nbsp; </i>;
            } else if (selectableType === "non-selected") {
              selectIcon = <i className="fas fa-check" />;
            } else if (selectableType === "selected") {
              selectIcon = <i className="far fa-circle" />;
            } else if (selectableType === "selected-default") {
              selectIcon = <i className="fas fa-check-circle" />;
            }
            // Note: Check if each service included in this menu; then add count to Badge total
            if (includedInMenu) {
              totalMenuCounts[index] = totalMenuCounts[index] + 1;
            }
            return (
              <div className="xmm-total-labels" key={"menutype-" + id}>
                {selectIcon}
              </div>
            );
          });
        }
        return (
          <div className="xmm-services" key={"service-menu-" + service.id}>
            {menuTypeHtml}
          </div>
        );
      });
    }
    const iconCmp = this.state.isExpand ? (
      <IconKeyboardArrowDown />
    ) : (
      <IconKeyboardArrowRight />
    );
    inspectTable = (
      <div>
        <div className="xmm-row">
          <div
            className="xmm-left-header xmm-inspections"
            onClick={this.toggleRowClick}
          >
            <span className="op-icon">{iconCmp}</span>

            <span className="op-title">
              <b>Included inspections</b>
            </span>
            <span className="op-price" />
          </div>
          <div className={clsMenu}>
            {totalMenuCounts.map((serviceCount, index) => {
              const keyVal = "badge-menu-" + index;
              return (
                <div className="xmm-total-labels" key={keyVal}>
                  <Badge htmlId={keyVal} color="gray">
                    {serviceCount}
                  </Badge>
                </div>
              );
            })}
          </div>
        </div>
        <div className={clsHide}>
          <div className="xmm-desc-label left-aligned">{serviceHtml}</div>
          <div>{childHtml}</div>
        </div>
      </div>
    );

    return <React.Fragment>{inspectTable}</React.Fragment>;
  }
}

export default InspectionTemplate;

InspectionTemplate.propTypes = {
  results: PropTypes.array,
  menuTypes: PropTypes.array
};
