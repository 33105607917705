/* Declare AG-GRID value setters in this file */
import { toast } from "@cx/ui/Toast";
import { toEmptyStringIfUndefined } from "./string";

/**
 * This value setters used in operations, packages
 * @param {object} params
 * @returns
 */
export function shopDurationValueSetter(params) {
  if (!params.data) {
    return false;
  }
  if (params.newValue > 1440) {
    toast.error("Service Duration exceeds 1440 miniutes.");
    // toast.error(enLocaleObj["xmm.portal.validation.duration_max_value_exceeded"]);
    return false;
  }
  if (!params.newValue || toEmptyStringIfUndefined(params.newValue) === "") {
    params.data.shopDuration = null;
  } else {
    params.data.shopDuration = params.newValue;
  }
  return true;
}
/**
 *
 * @param {object} params
 * @returns
 */
export function priceValueSetter(params) {
  if (!params.data) {
    return false;
  }
  if (params.newValue && params.newValue > 9999.99) {
    toast.error("Price value exceeds $9,999.99.");
    // toast.error(enLocaleObj["xmm.portal.validation.price_exceeds_max"]);
    return false;
  }
  const { field } = params.colDef;
  if (
    params.newValue === "" ||
    params.newValue === null ||
    isNaN(params.newValue)
  ) {
    params.data[field] = null;
  } else {
    params.data[field] = parseFloat(Number(params.newValue).toFixed(2));
  }
  return true;
}
/**
 *
 * @param {object} params
 * @returns
 */
export function serviceKindSetter(params) {
  if (!params.data) {
    return;
  }
  let value = params.newValue;
  if (typeof value !== "string") {
    const keys = Object.keys(value || {});
    value = keys[0];
  }
  params.data.serviceKind = value;
}
/**
 *
 * @param {object} params
 * @returns
 */
export function yesnoValueSetter(params) {
  if (isNaN(params.newValue)) {
    return false;
  }
  if (!params.data) {
    return false;
  }
  params.data.enabled = params.newValue;
  return true;
}
/**
 *
 * @param {object} params
 * @returns
 */
export function nullSetter(params) {
  return false;
}
/**
 *
 * @param {object} params
 * @returns
 */
export function laborTimeSetter(params) {
  if (!params || !params.data) {
    return false;
  }
  // if (params.newValue && params.newValue > 24.0) {
  //   toast.error(enLocaleObj["xmm.portal.validation.price_exceeds_max"]);
  //   return false;
  // }
  const { field } = params.colDef;
  if (
    params.newValue === "" ||
    params.newValue === null ||
    isNaN(params.newValue)
  ) {
    params.data[field] = null;
    if (field === "scheduledLaborTimeInHours") {
      params.data["scheduledLaborTime"] = null;
    } else if (field === "unscheduledLaborTimeInHours") {
      params.data["unscheduledLaborTime"] = null;
    }
  } else {
    const newValue = parseFloat(Number(params.newValue).toFixed(2));
    params.data[field] = newValue;
    if (field === "scheduledLaborTimeInHours") {
      params.data["scheduledLaborTime"] = newValue * 60.0;
    } else if (field === "unscheduledLaborTimeInHours") {
      params.data["unscheduledLaborTime"] = newValue * 60.0;
    }
  }
  return true;
}
