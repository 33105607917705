import Settings from "./Settings/SettingsPage";
import VehicleGroups from "./VehicleGroups/VehicleGroupsPage";
import Intervals from "./Intervals/IntervalsPage";
import Translations from "./Translations";

// bundle components into one object and export
const SettingsViews = {
  Settings,
  VehicleGroups,
  Intervals,
  Translations
};

export default SettingsViews;
