/* eslint-disable no-console */
import React, { Component } from "react";
import Col from "@cx/ui/Col";
import Grid from "@cx/ui/Grid";
import Row from "@cx/ui/Row";
import { PropTypes } from "prop-types";
import { AppContext } from "../../../../../components/app-context";
import SelectMenuCriteria from "./SelectMenuCriteria";
import { BlankSelectMenuCriteria } from "../../../../../constants/ModuleConstants";
import { cloneObject } from "../../../../../commonUtil/utils/object";
import { xlate } from "../../../../../commonUtil/i18n/locales";

export default class SelectMenuContainer extends Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);
    this.isDirty = this.isDirty.bind(this);
    this.isValid = this.isValid.bind(this);
    this.onChange = this.onChange.bind(this);
    this.addCriteria = this.addCriteria.bind(this);
    this.removeCriteria = this.removeCriteria.bind(this);
    this.createEmptyDealerMenuRuleCriteria =
      this.createEmptyDealerMenuRuleCriteria.bind(this);
    const blankCriteria = this.createEmptyDealerMenuRuleCriteria();
    this.initializeLocaleValues();
    this.state = {
      dirty: false,
      dealerMenuRuleCriteriaList: [blankCriteria],
      menuTypes: props.menuTypes
    };
  }
  componentDidMount() {}
  componentWillUnmount() {}
  initializeLocaleValues() {
    this.menuLabel = xlate("xmm.portal.common.menu_lbl");
    this.vehicleGroupLabel = xlate("xmm.portal.common.vehiclegroup");
    this.intervalLabel = xlate("xmm.portal.common.interval");
  }
  onChange = event => {
    this.props.onValidate();
    this.props.onTotalCount(this.selectMenuCriteriaRefs.length);
    const dirty = true;
    this.setState({ dirty });
  };
  isDirty = () => {
    const { dirty } = this.state;
    console.log("select menu", dirty);
    return dirty;
  };
  isValid = () => {
    // need to validate the list of dealer menu rule criteria
    let valid = true;
    for (let index = 0; index < this.selectMenuCriteriaRefs.length; index++) {
      if (!this.selectMenuCriteriaRefs[index].isValid()) {
        valid = false;
        break;
      }
    }
    return valid;
  };
  getSelectMenuCriteriaList = () => {
    const { dealerMenuRuleCriteriaList } = this.state;
    return dealerMenuRuleCriteriaList;
  };
  createEmptyDealerMenuRuleCriteria = () => {
    const { dealerCode, makeVariantMap, locale } = this.context;
    const { make, menuTypes } = this.props;
    const { variant } = makeVariantMap[make];
    const menuTypeId =
      menuTypes && menuTypes.length === 1 ? menuTypes[0].menuTypeId : "";
    // const blankCriteria = {
    //   menuTypeId: "",
    //   interval: {
    //     make,
    //     variant,
    //     dealerCode,
    //     intervalId: "",
    //     intervalName: ""
    //   },
    //   vehicleGroup: {
    //     make,
    //     variant,
    //     dealerCode,
    //     metaVehicleFilterId: "",
    //     metaVehicleScope: "1"
    //   }
    // };
    const blankCriteria = cloneObject(BlankSelectMenuCriteria);
    const { interval, vehicleGroup } = blankCriteria;
    blankCriteria.menuTypeId = menuTypeId.toString();
    interval.make = make;
    interval.variant = variant;
    interval.dealerCode = dealerCode;
    interval.locale = locale;
    vehicleGroup.make = make;
    vehicleGroup.variant = variant;
    vehicleGroup.dealerCode = dealerCode;
    return blankCriteria;
  };
  addCriteria = index => {
    const blankCriteria = this.createEmptyDealerMenuRuleCriteria();
    console.log("blankCriteria", blankCriteria);
    const { dealerMenuRuleCriteriaList } = this.state;
    dealerMenuRuleCriteriaList.splice(index + 1, 0, blankCriteria);
    this.setState({ dealerMenuRuleCriteriaList }, () => {
      this.onChange(null);
    });
  };
  removeCriteria = index => {
    const { dealerMenuRuleCriteriaList } = this.state;
    dealerMenuRuleCriteriaList.splice(index, 1);
    this.setState({ dealerMenuRuleCriteriaList }, () => this.onChange(null));
  };
  renderHeading = () => {
    return (
      <Row>
        <Col xs={3} md={3} className="full-padding-right-col">
          <span className="xmm-select-menu-label">{this.menuLabel}</span>{" "}
          <span className="xmm-red-label">*</span>
        </Col>
        <Col xs={4} md={4} className="full-padding-right-col">
          <span className="xmm-select-menu-label">
            {this.vehicleGroupLabel}
          </span>{" "}
          <span className="xmm-red-label">*</span>
        </Col>
        <Col xs={4} md={4} className="full-padding-right-col">
          <span className="xmm-select-menu-label">{this.intervalLabel}</span>{" "}
          <span className="xmm-red-label">*</span>
        </Col>
        <Col xs={1} md={1} className="full-col float-right">
          <div>&nbsp;</div>
        </Col>
      </Row>
    );
  };
  renderDealerMenuRuleCriteria = () => {
    const { dealerMenuRuleCriteriaList, menuTypes } = this.state;
    console.log("dealerMenuRuleCriteriaList", dealerMenuRuleCriteriaList);
    this.selectMenuCriteriaRefs = [];
    let keyCount = new Date().getTime();
    const list = dealerMenuRuleCriteriaList.map((criteria, index) => {
      if (!criteria.key) {
        criteria.key = keyCount++;
      }
      return (
        <SelectMenuCriteria
          ref={ref => {
            if (ref) {
              this.selectMenuCriteriaRefs[index] = ref;
            }
          }}
          key={criteria.key}
          index={index}
          totalCriteriaFields={dealerMenuRuleCriteriaList.length}
          ruleCriteria={criteria}
          menuTypes={menuTypes}
          parentHandle={this}
          onChange={this.onChange}
        />
      );
    });
    return list;
  };
  render() {
    const header = this.renderHeading();
    const dealerMenuRuleCriteria = this.renderDealerMenuRuleCriteria();
    return (
      <React.Fragment>
        <Grid>
          {header}
          {dealerMenuRuleCriteria}
        </Grid>
      </React.Fragment>
    );
  }
}

SelectMenuContainer.propTypes = {
  make: PropTypes.string,
  menuTypes: PropTypes.array,
  onValidate: PropTypes.func,
  onTotalCount: PropTypes.func
};
