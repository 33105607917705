/*
 * Place Util methods to support quote price calci
 */
const isLocalDev = () => {
  return process.env.NODE_ENV === "development";
};

function convertHoursToMinutes(hours) {
  let laborMins = hours || 0;
  laborMins = hours * 60;
  return laborMins;
}
const convertMinutesToHours = minutes => +(+minutes / 60).toFixed(1);

// @note - this util return labortime in hours
function convertLaborTime(value, laborTimePrecision) {
  let timeValue = value || 0;
  if (laborTimePrecision === "hundredths") {
    timeValue = parseFloat(convertMinutesToHundredths(timeValue)).toFixed(2);
  } else if (laborTimePrecision === "tenths") {
    timeValue = parseFloat(convertMinutesToTenths(timeValue)).toFixed(1);
  }
  return timeValue;
}
function convertMinutesToTenths(timeParam) {
  let time = 0;
  if (timeParam) {
    time = timeParam;
    time /= 60;
    time *= 10;
    time = Math.ceil(time);
    time /= 10;
    return time;
  }
  return 0;
}
function convertMinutesToHundredths(timeParam) {
  let time = 0;
  if (timeParam) {
    time = timeParam;
    time /= 60;
    time *= 100;
    /* just one decimal is enough to take ceiling */
    time = time.toFixed(1);
    time = Math.ceil(time);
    time /= 100;
    time = time.toFixed(2);
    return time;
  }
  return 0;
}

/**
 * Returns true if the passed value is empty, false otherwise. The value is deemed to be empty if it is either:
 *
 * - `null`
 * - `undefined`
 * - a zero-length array
 * - a zero-length string (Unless the `allowEmptyString` parameter is set to `true`)
 *
 * @param {Object} value The value to test
 * @param {Boolean} allowEmptyString (optional) true to allow empty strings (defaults to false)
 * @return {Boolean}
 * @markdown
 */
const doesEmpty = (value, allowEmptyString) => {
  return (
    value === null ||
    value === undefined ||
    (!allowEmptyString ? value === "" : false) ||
    (isArrayExist(value) && value.length === 0)
  );
};

/**
 * Returns true if the passed value is a JavaScript Array, false otherwise.
 *
 * @param {Object} target The target to test
 * @return {Boolean}
 * @method
 */
const isArrayExist = value => {
  return Object.prototype.toString.call(value) === "[object Array]";
};

function getAppEnvironment() {
  const { hostname } = window.location;
  const host = hostname.includes("localhost")
    ? "servicequoting.dev6.xtimeappsnp.xtime.com"
    : hostname;
  const hostArr = host.split(".");
  let quoteEnv = hostArr[1];
  quoteEnv = quoteEnv.includes("xtime") ? "prod" : quoteEnv;
  // console.log("getAppEnvironment", host, quoteEnv);
  let returnedEnv = "dev5";
  switch (quoteEnv) {
    case "dev6":
      returnedEnv = "dev5";
      break;
    case "qa6":
      returnedEnv = "qa5";
      break;
    case "stg4":
      returnedEnv = "stg4";
      break;
    case "prod":
      returnedEnv = "prod";
      break;
    default:
      returnedEnv = "dev5";
  }
  return returnedEnv;
}

const checkSpecialChars = str => {
  const specialChars = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
  return specialChars.test(str);
};

export {
  isLocalDev,
  convertHoursToMinutes,
  convertMinutesToHours,
  convertLaborTime,
  doesEmpty,
  isArrayExist,
  getAppEnvironment,
  checkSpecialChars
};
