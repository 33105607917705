import React, { useCallback, useState } from "react";
import isEmpty from "lodash/isEmpty";
import ServiceList from "../../components/service-list/ServiceList";
import {
  useServiceSearchContext,
  Actions
} from "../../state/service-search.context";
import EditRecallServicePage from "./EditRecallServicePage";

const RecallServiceQuickFilterPage = props => {
  const { EditServiceModule } = props;
  const { state, dispatch } = useServiceSearchContext();
  const { recallServices, appConfig } = state;
  const [showEditCmp, setShowEditCmp] = useState(false);
  const [localService, setLocalService] = useState(null);

  const handleServiceClick = useCallback(
    service => {
      setLocalService(service);
      if (appConfig.editModuleAccess) {
        setShowEditCmp(true);
        dispatch({
          type: Actions.SET_SEARCH_HEADER,
          payload: false
        });
      }
    },
    [dispatch, appConfig.editModuleAccess]
  );

  const updateParentState = closeEdit => {
    setShowEditCmp(closeEdit);
  };

  const editPage =
    showEditCmp && !isEmpty(localService) ? (
      <EditRecallServicePage
        updateParentState={updateParentState}
        service={localService}
        EditServiceModule={EditServiceModule}
      />
    ) : (
      ""
    );

  const parentCls =
    showEditCmp && !isEmpty(localService) ? "hide-ele" : "empty-cls";

  return (
    <>
      <div id="recallServicesQuickFilterPageContainer" className={parentCls}>
        <ServiceList
          services={recallServices}
          viewType="RECALL"
          onSelectService={handleServiceClick}
        />
      </div>
      {editPage}
    </>
  );
};

export default RecallServiceQuickFilterPage;
