/* eslint-disable no-irregular-whitespace */
/**
 * The objective of this function is to perform the calculation of the complete menu package,
 *  for this it has to perform the previous calculation of all its services within the package
 */
import has from "lodash/has";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
/**
 * This function receives the complete menu package as a parameter
 * and will iterate each of its services to apply the calculation logic to each of the services within a menu package
 */
const formatMenuPackage = menu => {
  if (menu && has(menu, "services")) {
    const formatedMenuServices = menu.services.map(service => {
      // @workaround for menu service case
      service.defaultLaborRate = 0;
      const menuService = applyCalculationForMenuServices(cloneDeep(service));
      // console.log("FORMAT MENU", menuService);
      return menuService;
    });
    menu.services = formatedMenuServices;
  }
  return menu;
};

const applyCalculationForMenuServices = service => {
  setFlags(service);
  addCalculation(service);
  const clonedService = setOverridenPrices(service);
  setFinalPrices(clonedService);
  return clonedService;
};

/**
 * This function will read API if returned override flag fields {priceOverride, scheduledLaborPriceOverride, partsPriceOverride}, if not set default false
 * And update menu service by adding these fields { partsPriceOverridden, laborPriceOverridden,  totalPriceOverridden} with API override flag fields
 * Catalog API returns Labor Price in {scheduledLaborPrice}, Parts Price in {partsPrice}, TotalPrice in {price}
 */
const setFlags = service => {
  service.totalPriceOverridden = service.priceOverride || false;
  service.laborPriceOverridden = service.scheduledLaborPriceOverride || false;
  service.partsPriceOverridden = service.partsPriceOverride || false;
  // @note: Later, we want to delete catalog returned override flags {priceOverride, scheduledLaborPriceOverride, partsPriceOverride} here?
};

/**
 * Calculate the final prices for each services,
 * this final prices are the prices that should be showed in the UI and taked for menu package calculation
 * this logic is used to calculate the final price
 * If "Total Price Override" is true, then use it.
 * Else, use "Total Subtotal"
 */
const setFinalPrices = service => {
  const finalLaborPrice = setFinalLaborPrice(service);
  const finalPartsPrice = setFinalPartPrice(service);
  let finalTotalPrice = 0;
  if (service.totalPriceOverridden) {
    finalTotalPrice = service.totalPriceOverride;
  } else {
    finalTotalPrice = finalLaborPrice + finalPartsPrice;
  }

  service.finalLaborPrice = finalLaborPrice;
  service.price = finalTotalPrice;
  // TODO: verify this for circular field reference when updated?
  service.partsPrice = finalPartsPrice;
};

/**
 * Calculate Final Labor Price follwing the next logic
 * If "Total Price Override"" is true, then use ("Total Price Override" - "Final Parts Price")
 * Else,  if "Labor Price Override" is true, then use it.
 * Else,  use "Labor SubTotal"
 */
const setFinalLaborPrice = service => {
  if (service.totalPriceOverridden) {
    return service.totalPriceOverride - setFinalPartPrice(service);
  } else if (service.laborPriceOverridden) {
    return service.totalLaborPriceOverride;
  }
  return service.calculatedLaborPrice;
};

/**
 * Calculate Final Part Price follwinng next logic:
 * If "Total Price Override" is true, then use "Parts SubTotal".
 * Else, if "Parts Price Override" is true, then use it.
 * Else, use "Parts SubTotal"
 */
const setFinalPartPrice = service => {
  if (service.totalPriceOverridden) {
    return service.calculatedPartsPrice;
  } else if (service.partsPriceOverridden) {
    return service.totalPartsPriceOverride;
  }
  return service.calculatedPartsPrice;
};

/**
 * Set these fields totalLaborPriceOverride, totalPartsPriceOverride and totalPriceOverride with override price values
 * store the scheduledLaborPrice coming from the Catalog API as totalLaborPriceOverride in Quote
 * only if them respective flags are true
 */
const setOverridenPrices = service => {
  // Note: Fix for direct object reference - partsPrice is updated later using finalPartsPrice
  const clonedService = cloneDeep(service);
  const clonePartsPrice = service.partsPrice;
  clonedService.clonePartsPrice = clonePartsPrice;
  delete clonedService.partsPrice;

  clonedService.totalLaborPriceOverride = null;
  clonedService.totalPartsPriceOverride = null;
  clonedService.totalPriceOverride = null;
  if (clonedService.laborPriceOverridden) {
    clonedService.totalLaborPriceOverride = clonedService.scheduledLaborPrice;
  }
  if (clonedService.partsPriceOverridden) {
    clonedService.totalPartsPriceOverride = clonePartsPrice;
  }
  if (clonedService.totalPriceOverridden) {
    clonedService.totalPriceOverride = clonedService.price;
  }
  return clonedService;
};

/**
 * Calculate the subtotal for labor & part prices.
 * for total price follow the next logic
 * Calculate "Total Subtotal" as "Calculated Labor Price" + "Calculated Parts Price"
 */
const addCalculation = service => {
  calculateLabor(service);
  calculateParts(service);
  service.calculatedTotalPrice =
    service.calculatedPartsPrice + service.calculatedLaborPrice;
};

/**
 * Calculate part subtotal with the follow logic
 * Calculate "Parts Subtotal" by summing up the price of each part (unit price x quantity)
 */
const calculateParts = service => {
  const hasParts = !isEmpty(service.part) && Array.isArray(service.part);
  const partObj = service.part || [];
  const calculatedPartsPrice = hasParts
    ? partObj
        .map(part =>
          !part.unitPrice ? 0 : part.unitPrice * part.adjustedQuantity
        )
        .reduce((acc, part) => acc + part, 0)
    : 0;
  service.calculatedPartsPrice = calculatedPartsPrice;
};

/**
 * 	Calculate "Labor Subtotal" as Labor Time x Labor Rate
 */
const calculateLabor = service => {
  // !deprecated: From catalog API, menus never return defaultLaborRate; always 0
  // const defaultLaborRate = has(service, "defaultLaborRate")
  //   ? service.defaultLaborRate
  //   : 0;
  // const duration = has(service, "duration") ? service.duration : 0;
  // service.calculatedLaborPrice = defaultLaborRate * duration;
  service.calculatedLaborPrice = service.scheduledLaborPriceOverride
    ? 0
    : service.scheduledLaborPrice;
};

export default {
  applyCalculationForMenuServices,
  formatMenuPackage
};
