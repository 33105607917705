/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import pages from "../constants/pages.constants";
import {
  QUICK_FILTERS,
  quickFilterPages
} from "../constants/quick-filters.constants";
import { saveActionTypes } from "../utils/search.constants";
import { generateCategoryGroupFilters } from "../utils/global-synonyms.util";

const defaultState = {
  debugQuote: false,
  userPermissions: {},
  appConfig: null,
  saveActionType: saveActionTypes.NA,
  locale: "en_US",
  localeStrings: {},
  makeVariantMap: null,
  searchHeader: true, // show search header
  isMenuUpdated: false,
  allOperations: [],
  diagnosisServices: [],
  topServices: [],
  declinedServices: [],
  recallServices: [],
  currentPage: pages.SEARCH_SERVICES,
  closeModal: false,
  vehicle: null,
  payTypes: [],
  serviceSearchQuery: "",
  serviceFilterBy: "",
  servicePoints: [],
  selectedMenuPackage: null,
  currentQuickFilterPage: quickFilterPages.TOP_SERVICES,
  currentQuickFilterEditPage: "",
  categoryGroupFilters: [],
  servicesForSearch: [],
  selectedService: null, // !deprecated - not used now, try to use for tellusmore service case
  selectedGlobalOpsService: null,
  selectedAlacarteService: null,
  selectedDeclinedService: null,
  selectedRecallService: null,
  showPageMask: false,
  globalSynonyms: [],
  vehicleDefaultDrivingCondition: "",
  menuSelectedDrivingCondition: "",
  quickFilters: QUICK_FILTERS,
  globalOperationDetails: {},
  partsPricingAndInventory: null,
  dealerTireParams: {},
  dealerTireAuth: {}
};

const ServiceSearchContext = React.createContext(defaultState);
const Actions = {
  SET_DEBUG_MODE: "SET_DEBUG_MODE",
  SET_USER_PERMISSIONS: "SET_USER_PERMISSIONS",
  SET_APP_CONFIG: "SET_APP_CONFIG",
  SET_MODULE_CONFIG: "SET_MODULE_CONFIG",
  SET_SAVE_ACTION_TYPE: "SET_SAVE_ACTION_TYPE",
  SET_LOCALE: "SET_LOCALE",
  SET_LOCALE_STRINGS: "SET_LOCALE_STRINGS",
  IS_MENU_UPDATED: false,
  RESET_SEARCH_MODULE: "RESET_SEARCH_MODULE",
  SET_MAKE_VARIANT_MAP: "SET_MAKE_VARIANT_MAP",
  WIPE_STATE: "WIPE_STATE",
  RESET_NAVIGATION: "RESET_NAVIGATION",
  SET_CURRENT_PAGE: "SET_CURRENT_PAGE",
  SET_CHANGE_SEARCH_QUERY: "SET_CHANGE_SEARCH_QUERY",
  SET_SERVICE_FILTER_BY: "SET_SERVICE_FILTER_BY",
  SET_CLOSE_MODAL: "SET_CLOSE_MODAL",
  SET_SERVICE_POINTS: "SET_SERVICE_POINTS",
  SET_ALL_OPERATIONS: "SET_ALL_OPERATIONS",
  SET_CURRENT_QUICK_FILTER_PAGE: "SET_CURRENT_QUICK_FILTER_PAGE",
  SET_VEHICLE: "SET_VEHICLE",
  SET_PAY_TYPES: "SET_PAY_TYPES",
  SET_MENU_PACKAGE: "SET_MENU_PACKAGE",
  REMOVE_SELECTED_MENU_PACKAGE: "REMOVE_SELECTED_MENU_PACKAGE",
  SET_SELECTED_SERVICE: "SET_SELECTED_SERVICE",
  SET_TOP_SERVICES: "SET_TOP_SERVICES",
  SET_DIAGNOSIS_SERVICES: "SET_DIAGNOSIS_SERVICES",
  SET_DECLINED_SERVICES: "SET_DECLINED_SERVICES",
  REMOVE_SELECTED_DECLINED_SERVICE: "REMOVE_SELECTED_DECLINED_SERVICE",
  SET_SELECTED_DECLINED_SERVICE: "SET_SELECTED_DECLINED_SERVICE",
  SET_SERVICES_FOR_SEARCH: "SET_SERVICES_FOR_SEARCH",
  SET_SELECTED_GLOBAL_OPS_SERVICE: "SET_SELECTED_GLOBAL_OPS_SERVICE",
  REMOVE_SELECTED_GLOBAL_OPS_SERVICE: "REMOVE_SELECTED_GLOBAL_OPS_SERVICE",
  SET_SELECTED_ALACARTE_SERVICE: "SET_SELECTED_ALACARTE_SERVICE",
  REMOVE_SELECTED_ALACARTE_SERVICE: "REMOVE_SELECTED_ALACARTE_SERVICE",
  SET_PAGE_MASK: "SET_PAGE_MASK",
  SET_RECALL_SERVICES: "SET_RECALL_SERVICES",
  SET_SELECTED_RECALL_SERVICE: "SET_SELECTED_RECALL_SERVICE",
  SET_VEHICLE_DEFAULT_DRIVING_CONDITION:
    "SET_VEHICLE_DEFAULT_DRIVING_CONDITION",
  SET_MENU_SELECTED_DRIVING_CONDITION: "SET_MENU_SELECTED_DRIVING_CONDITION",
  SET_QUICK_FILTERS: "SET_QUICK_FILTERS",
  SET_GLOBAL_SYNONYMS: "SET_GLOBAL_SYNONYMS",
  SET_GLOBAL_OPERATION_DETAILS: "SET_GLOBAL_OPERATION_DETAILS",
  REMOVE_GLOBAL_OPERATION_DETAILS: "REMOVE_GLOBAL_OPERATION_DETAILS",
  SET_DEALER_TIRE_PARAMS: "SET_DEALER_TIRE_PARAMS",
  SET_DEALER_TIRE_AUTH: "SET_DEALER_TIRE_AUTH",
  SET_PAYTYPE_FOR_MENU_SERVICES: "SET_PAYTYPE_FOR_MENU_SERVICES",
  SET_MENU_PAYTYPE: "SET_MENU_PAYTYPE",
  SET_PARTS_PRICING_INVENTORY: "SET_PARTS_PRICING_INVENTORY"
};

const serviceSearchReducer = (state, action) => {
  switch (action.type) {
    case Actions.SET_APP_CONFIG: {
      return {
        ...state,
        appConfig: action.payload
      };
    }
    case Actions.SET_DEBUG_MODE: {
      return {
        ...state,
        debugQuote: action.payload
      };
    }
    case Actions.SET_USER_PERMISSIONS: {
      return {
        ...state,
        userPermissions: action.payload
      };
    }
    case Actions.SET_QUICK_FILTERS: {
      return {
        ...state,
        quickFilters: action.payload
      };
    }
    // @todo: update only parts with opentrack api repsonse to pass as props to edit service module
    case Actions.SET_PARTS_PRICING_INVENTORY: {
      return {
        ...state,
        partsPricingAndInventory: action.payload
      };
    }
    case Actions.SET_LOCALE: {
      return {
        ...state,
        locale: action.payload
      };
    }
    case Actions.SET_LOCALE_STRINGS: {
      return {
        ...state,
        localeStrings: action.payload
      };
    }
    case Actions.SET_SEARCH_HEADER: {
      return {
        ...state,
        searchHeader: action.payload
      };
    }
    case Actions.SET_MAKE_VARIANT_MAP: {
      return {
        ...state,
        makeVariantMap: action.payload
      };
    }
    case Actions.IS_MENU_UPDATED: {
      return {
        ...state,
        isMenuUpdated: action.payload
      };
    }
    case Actions.WIPE_STATE: {
      defaultState.debugQuote = state.debugQuote;
      return defaultState;
    }
    // @note: call this action to reset all search values, quick filters
    case Actions.RESET_SEARCH_MODULE: {
      return {
        ...state,
        serviceSearchQuery: "",
        serviceFilterBy: "",
        currentQuickFilterPage: quickFilterPages.TOP_SERVICES
      };
    }
    case Actions.RESET_NAVIGATION: {
      return {
        ...state,
        currentPage: pages.SEARCH_SERVICES,
        currentQuickFilterPage: quickFilterPages.TOP_SERVICES
      };
    }
    case Actions.SET_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: action.payload
      };
    }
    case Actions.SET_CHANGE_SEARCH_QUERY: {
      return {
        ...state,
        serviceSearchQuery: action.payload
      };
    }
    case Actions.SET_SERVICE_FILTER_BY: {
      return {
        ...state,
        serviceFilterBy: action.payload
      };
    }
    case Actions.SET_CLOSE_MODAL: {
      return {
        ...state,
        closeModal: action.payload
      };
    }
    case Actions.SET_SERVICE_POINTS: {
      return {
        ...state,
        servicePoints: action.payload
      };
    }
    case Actions.SET_ALL_OPERATIONS: {
      return {
        ...state,
        allOperations: action.payload
      };
    }
    case Actions.SET_DIAGNOSIS_SERVICES: {
      return {
        ...state,
        diagnosisServices: action.payload
      };
    }
    case Actions.SET_TOP_SERVICES: {
      return {
        ...state,
        topServices: action.payload
      };
    }
    case Actions.SET_PAY_TYPES: {
      return {
        ...state,
        payTypes: action.payload
      };
    }
    case Actions.SET_DECLINED_SERVICES: {
      return {
        ...state,
        declinedServices: action.payload
      };
    }
    case Actions.SET_CURRENT_QUICK_FILTER_PAGE: {
      return {
        ...state,
        currentQuickFilterPage: action.payload
      };
    }
    case Actions.SET_VEHICLE: {
      return {
        ...state,
        vehicle: action.payload
      };
    }
    case Actions.SET_MENU_PACKAGE: {
      return {
        ...state,
        selectedMenuPackage: action.payload
      };
    }
    case Actions.REMOVE_SELECTED_MENU_PACKAGE: {
      return {
        ...state,
        selectedMenuPackage: null
      };
    }
    case Actions.SET_SELECTED_SERVICE: {
      return {
        ...state,
        selectedService: action.payload
      };
    }
    case Actions.SET_SELECTED_DECLINED_SERVICE: {
      return {
        ...state,
        selectedDeclinedService: action.payload
      };
    }
    case Actions.REMOVE_SELECTED_DECLINED_SERVICE: {
      return {
        ...state,
        selectedDeclinedService: null
      };
    }
    case Actions.SET_SERVICES_FOR_SEARCH: {
      return {
        ...state,
        servicesForSearch: action.payload
      };
    }
    case Actions.SET_SELECTED_GLOBAL_OPS_SERVICE: {
      return {
        ...state,
        selectedGlobalOpsService: action.payload
      };
    }
    case Actions.REMOVE_SELECTED_GLOBAL_OPS_SERVICE: {
      return {
        ...state,
        selectedGlobalOpsService: null
      };
    }
    case Actions.SET_SELECTED_ALACARTE_SERVICE: {
      return {
        ...state,
        selectedAlacarteService: action.payload
      };
    }
    case Actions.REMOVE_SELECTED_ALACARTE_SERVICE: {
      return {
        ...state,
        selectedAlacarteService: null
      };
    }
    case Actions.SET_PAGE_MASK: {
      return {
        ...state,
        showPageMask: action.payload
      };
    }
    case Actions.SET_RECALL_SERVICES: {
      return {
        ...state,
        recallServices: action.payload
      };
    }
    case Actions.SET_SELECTED_RECALL_SERVICE: {
      return {
        ...state,
        selectedRecallService: action.payload
      };
    }
    case Actions.SET_VEHICLE_DEFAULT_DRIVING_CONDITION: {
      return {
        ...state,
        vehicleDefaultDrivingCondition: action.payload
      };
    }
    case Actions.SET_MENU_SELECTED_DRIVING_CONDITION: {
      return {
        ...state,
        menuSelectedDrivingCondition: action.payload
      };
    }
    case Actions.SET_GLOBAL_SYNONYMS: {
      return {
        ...state,
        globalSynonyms: action.payload,
        categoryGroupFilters: generateCategoryGroupFilters(action.payload)
      };
    }
    case Actions.SET_GLOBAL_OPERATION_DETAILS: {
      return {
        ...state,
        globalOperationDetails: action.payload
      };
    }
    case Actions.REMOVE_GLOBAL_OPERATION_DETAILS: {
      return {
        ...state,
        globalOperationDetails: null
      };
    }
    // @dealer-tire pass required params used in deler-tire page
    case Actions.SET_DEALER_TIRE_PARAMS: {
      return {
        ...state,
        dealerTireParams: action.payload
      };
    }
    // @dealer-tire holds dealer tire API secret key data
    case Actions.SET_DEALER_TIRE_AUTH: {
      return {
        ...state,
        dealerTireAuth: action.payload
      };
    }
    case Actions.SET_SAVE_ACTION_TYPE: {
      return {
        ...state,
        saveActionType: action.payload
      };
    }
    default: {
      console.log(`Unhandled action type: ${JSON.stringify(action)}`);
    }
  }
};

const ServiceSearchProvider = ({
  ctxOnEditService,
  ctxRequestOperationDetails,
  ctxRequestDealerTireConfig,
  ctxRequestPartsPricingAndInventory,
  ctxRequestServicePoints,
  ctxRequestPartsInventoryCommon,
  children
}) => {
  const [state, dispatch] = React.useReducer(
    serviceSearchReducer,
    defaultState
  );
  const value = {
    state,
    dispatch,
    ctxOnEditService,
    ctxRequestOperationDetails,
    ctxRequestPartsPricingAndInventory,
    ctxRequestDealerTireConfig,
    ctxRequestServicePoints,
    ctxRequestPartsInventoryCommon
  };
  return (
    <ServiceSearchContext.Provider value={value}>
      {children}
    </ServiceSearchContext.Provider>
  );
};

ServiceSearchProvider.propTypes = {
  ctxOnEditService: PropTypes.func,
  ctxRequestOperationDetails: PropTypes.func,
  ctxRequestPartsPricingAndInventory: PropTypes.func,
  ctxRequestDealerTireConfig: PropTypes.func,
  ctxRequestServicePoints: PropTypes.func,
  ctxRequestPartsInventoryCommon: PropTypes.func,
  children: PropTypes.node.isRequired
};

const useServiceSearchContext = () => {
  const context = React.useContext(ServiceSearchContext);
  if (context === undefined) {
    throw new Error(
      "useServiceSearchContext must be used within a ServiceSearchProvider"
    );
  }
  return {
    state: context.state,
    dispatch: context.dispatch,
    ctxOnEditService: context.ctxOnEditService,
    ctxRequestServicePoints: context.ctxRequestServicePoints,
    ctxRequestOperationDetails: context.ctxRequestOperationDetails,
    ctxRequestPartsPricingAndInventory:
      context.ctxRequestPartsPricingAndInventory,
    ctxRequestDealerTireConfig: context.ctxRequestDealerTireConfig,
    ctxRequestPartsInventoryCommon: context.ctxRequestPartsInventoryCommon
  };
};

export {
  ServiceSearchProvider,
  useServiceSearchContext,
  Actions,
  ServiceSearchContext
};
