/* eslint-disable no-console */
import { toast } from "@cx/ui/Toast";
import { makeSecureRestApi } from "../../../../../api/xmmAxios";
import { isSameValue } from "../../../../../commonUtil/utils/string";

export const Favorites = "favorites";
export const FavoriteVehicles = "favoriteVehicles";
export const RecentlyPreview = "recentlyPreview";
export const PreviouslyViewedVehicles = "previouslyViewedVehicles";
export const MaxVehiclePerMake = 8;

export const getUserPreviewed = (
  dealerCode,
  username,
  previewSettings,
  updateState
) => {
  const params = { dealerCode, username };
  const restEndPoint = "/ops/rest-db/getValues/userPreviewed";
  makeSecureRestApi(
    {
      url: restEndPoint,
      method: "get",
      params
    },
    userPreviewedList => {
      previewSettings.recentlyPreview = Array.isArray(userPreviewedList)
        ? userPreviewedList
        : [];
      if (updateState) {
        updateState();
      }
    },
    error => {
      toast.error(error.message);
    }
  );
};

export const getUserFavs = (
  dealerCode,
  username,
  previewSettings,
  updateState
) => {
  const params = { dealerCode, username };
  const restEndPoint = "/ops/rest-db/getValues/userFavs";
  makeSecureRestApi(
    {
      url: restEndPoint,
      method: "get",
      params
    },
    userFavsList => {
      previewSettings.favorites = Array.isArray(userFavsList)
        ? userFavsList
        : [];
      if (updateState) {
        updateState();
      }
    },
    error => {
      toast.error(error.message);
    }
  );
};

export const addUserPreviewed = (userPreviewed, updateState) => {
  const restEndPoint = "/ops/rest-db/function/addUserPreviewed";
  makeSecureRestApi(
    {
      url: restEndPoint,
      method: "post",
      data: userPreviewed
    },
    response => {
      if (updateState) {
        updateState();
      }
    },
    error => {
      toast.error(error.message);
    }
  );
};

export const addUserFav = (userFavs, updateState) => {
  const restEndPoint = "/ops/rest-db/function/addUserFav";
  makeSecureRestApi(
    {
      url: restEndPoint,
      method: "post",
      data: userFavs
    },
    response => {
      if (updateState) {
        updateState();
      }
    },
    error => {
      toast.error(error.message);
    }
  );
};

export const deleteUserFav = (userFavs, updateState) => {
  const restEndPoint = "/ops/rest-db/function/deleteUserFav";
  makeSecureRestApi(
    {
      url: restEndPoint,
      method: "post",
      data: userFavs
    },
    response => {
      if (updateState) {
        updateState();
      }
    },
    error => {
      toast.error(error.message);
    }
  );
};

export const getMetaVehicleIds = userPefs => {
  if (userPefs && userPefs.length > 0) {
    const metaVehicleIds = userPefs.map(userPref => {
      return userPref.metaVehicleId.toString();
    });
    return metaVehicleIds;
  }
  return [];
};
export const getMetaVehicleIdToDescMap = vehicleDescs => {
  if (vehicleDescs && vehicleDescs.length > 0) {
    const map = {};
    vehicleDescs.forEach(desc => {
      if (desc.id) {
        map[desc.id] = desc;
      }
    });
    return map;
  }
  return {};
};

export const existUserPref = (favorites, metaVehicleId, vin) => {
  for (let index = 0; index < favorites.length; index++) {
    const favorite = favorites[index];
    if (
      isSameValue(favorite.metaVehicleId, metaVehicleId) &&
      isSameValue(favorite.vin, vin)
    ) {
      return true;
    }
  }
  return false;
};
