/* eslint-disable no-console */
import React, { Component } from "react";
import ModalDialog from "@cx/ui/ModalDialog";
import Button from "@cx/ui/Button";
import PropTypes from "prop-types";
import keyCodes from "../../constants/keycodes.constants";
/**
 * * Reusable modal window, pass props {title, msg, okAction} from parent
 * @class ConfirmPopup
 */
class ConfirmPopup extends Component {
  /**
   * Add event listener
   */
  componentDidMount() {
    window.addEventListener("keydown", this.handleEscKey.bind(this));
  }

  /**
   * Remove event listener
   */
  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleEscKey.bind(this));
  }
  handleEscKey = event => {
    const { callbackClose, specialCase, show } = this.props;
    // the component is only interested in esc key event
    if (event.keyCode === keyCodes.escape && show === true) {
      // @note: special case: to hide modal when we have two action buttons instead of cancel link
      if (specialCase) {
        if (typeof callbackClose === "function") {
          callbackClose();
        }
        return;
      }
      this.props.cancelAction();
    }
  };
  hide = () => {
    this.props.cancelAction();
  };
  // cancel handler
  customCancel = () => {
    this.props.cancelAction();
  };
  // ok handler
  confirm = () => {
    this.props.okAction();
    if (!this.props.specialCase) this.customCancel();
  };

  render() {
    const { title, hideCancel, hideOk, okText, cancelText } = this.props;
    const classOk = hideOk ? "hide" : "";
    const classCancel = hideCancel ? "hide" : "";
    return (
      <React.Fragment>
        <ModalDialog
          htmlId="SearchConfirmPopup"
          className="sample-modal-dialog"
          show={this.props.show}
          header={<ModalDialog.Title>{title}</ModalDialog.Title>}
          footer={
            <div>
              <Button
                htmlId="popupCancelbtn"
                onClick={this.customCancel}
                className={classCancel}
                buttonStyle={this.props.cancelBtnStyle}
              >
                {cancelText}
              </Button>
              <Button
                htmlId="popupOkbtn"
                onClick={this.confirm}
                buttonStyle={this.props.buttonStyle}
                className={classOk}
              >
                {okText}
              </Button>
            </div>
          }
          onHide={this.hide}
        >
          <div>
            <p>{this.props.message}</p>
          </div>
        </ModalDialog>
      </React.Fragment>
    );
  }
}
export default ConfirmPopup;

ConfirmPopup.propTypes = {
  children: PropTypes.func,
  specialCase: PropTypes.bool,
  callbackClose: PropTypes.func.isRequired,
  buttonStyle: PropTypes.string,
  cancelBtnStyle: PropTypes.string,
  cancelAction: PropTypes.func,
  cancelText: PropTypes.string,
  hideCancel: PropTypes.bool,
  hideOk: PropTypes.bool,
  message: PropTypes.string,
  okAction: PropTypes.func,
  okText: PropTypes.string,
  show: PropTypes.bool,
  title: PropTypes.string
};

ConfirmPopup.defaultProps = {
  // events
  callbackClose: () => {},
  // data
  buttonStyle: "danger",
  cancelBtnStyle: "link",
  title: "Are you sure?",
  okText: "Ok",
  message: "You have unsaved changes. Are you sure you would like to proceed?",
  cancelText: "Cancel",
  specialCase: false,
  hideCancel: false,
  hideOk: false
};

/**
 * ? Usage - Pass these custom props to display confirm popup
 */
/*
<ConfirmPopup
        show={true}
        title="Are you Sure?"
        message="Sample Message."
        okText="Proceed"
        cancelText="Discard"
        okAction={this.handleProceedAction}
        cancelAction={this.handleCancelAction}
        hideCancel={true}
      >
      <div>{message}</div>
      </ConfirmPopup>
*/
