// Data model template used for edit page fields:
// {
//   recordId,
//   serviceName, // fuseJS serach label
//   price,
//   synonyms, // ["ALT", "50k"]
//   categoryId,
//   categoryGroupName, // {category filter based fuse search}
//   source,  // { ALACARTE, MENU, RECALL, DECLINED, GLOBAL_REPAIR_OPS,DEALER_PUB_MAINT_OPS, DEALER_PUB_REPAIR_OPS }
//   opCode,
//   rawRecord: {} // alacarte, menus, global ops raw response
// }
import {
  GlobalOpsServiceType,
  DealerPublishedCategory
} from "./search.constants";
import isEmpty from "lodash/isEmpty";
import isNumber from "lodash/isNumber";
import cloneDeep from "lodash/cloneDeep";
import has from "lodash/has";
import {
  doesEmpty,
  convertHoursToMinutes,
  convertMinutesToHours
} from "./quote.util";

const synonymsMapper = (synonymsList, categoryId) => {
  const match = synonymsList.find(
    item => item.serviceCategoryId === categoryId
  );
  if (match) {
    return match.synonyms.synonyms;
  } else {
    return [];
  }
};

const globalOperationMapper = (globalOperations, synonymsList) => {
  if (isEmpty(globalOperations)) {
    return [];
  }
  const globalOperationMapResult = globalOperations.map(operation => {
    let quoteServiceType = "DEALER_PUB_MAINT_OPS";
    if (
      operation.serviceKind === "repair" &&
      operation.operationSource === GlobalOpsServiceType.DEALERCATALOG
    ) {
      quoteServiceType = "DEALER_PUB_REPAIR_OPS";
    } else if (
      operation.serviceKind === "maintenance" &&
      operation.operationSource === GlobalOpsServiceType.DEALERCATALOG
    ) {
      quoteServiceType = "DEALER_PUB_MAINT_OPS";
    } else if (
      operation.serviceKind === "repair" &&
      operation.operationSource === GlobalOpsServiceType.GLOBALCATALOG
    ) {
      quoteServiceType = "GLOBAL_REPAIR_OPS";
    }
    return {
      recordId: String(operation.operationId),
      serviceName: operation.operationName,
      ...(operation.hasOwnProperty("price") && !isEmpty(operation.price)
        ? {
            price: !doesEmpty(operation.price.price)
              ? operation.price.price.toString()
              : ""
          }
        : { price: "" }),
      synonyms: synonymsMapper(synonymsList, operation.categoryId),
      categoryId: operation.categoryId,
      categoryName: operation.hasOwnProperty("categoryName")
        ? operation.categoryName
        : "",
      categoryGroupName: operation.hasOwnProperty("categoryGroupName")
        ? operation.categoryGroupName
        : "",
      source: quoteServiceType,
      tags: operation.hasOwnProperty("tags") ? operation.tags : null,
      operationSource: operation.operationSource,
      opCode: !isEmpty(operation.opCode) ? operation.opCode : "",
      rawRecord: operation
    };
  });
  return globalOperationMapResult;
};
// @note: filter records with "diagnosis/repair" and dealer catalog repair ops read from globalOperations
const diagnosisServicesMapper = globalOperations => {
  const diagnosisMap = [];
  for (const service of globalOperations) {
    if (isDiagnosisService(service)) {
      diagnosisMap.push(service);
    }
  }
  return diagnosisMap;
};

const isDiagnosisService = service => {
  if (service.source === "ALACARTE" && service.rawRecord.serviceCategoryName) {
    const serviceCategoryName = service.rawRecord.serviceCategoryName
      .split(" ")
      .join("")
      .toLowerCase();
    if (
      serviceCategoryName.indexOf("diagnose/repair") > -1 &&
      service.rawRecord.serviceKind &&
      service.rawRecord.serviceKind === "repair"
    ) {
      return true;
    }
  } else if (
    service.source === "DEALER_PUB_REPAIR_OPS" ||
    service.source === "TOP_SERVICES"
  ) {
    const categoryName = service.rawRecord.categoryName
      ? service.rawRecord.categoryName.split(" ").join("").toLowerCase()
      : "";
    if (
      categoryName.indexOf("diagnose/repair") > -1 &&
      service.rawRecord.serviceKind &&
      service.rawRecord.serviceKind === "repair"
    ) {
      return true;
    }
  }
  return false;
};

const isGlobalCatalog = service => {
  if (
    service.rawRecord.operationSource === GlobalOpsServiceType.GLOBALCATALOG &&
    service.rawRecord.serviceKind === "repair"
  ) {
    return true;
  } else {
    return false;
  }
};

const isDealerPublishedMaintenance = service => {
  if (
    service.rawRecord.operationSource === GlobalOpsServiceType.DEALERCATALOG &&
    service.rawRecord.serviceKind === DealerPublishedCategory.MAINTENANCE
  ) {
    return true;
  } else {
    return false;
  }
};

const isDealerPublishedRepair = service => {
  if (
    service.rawRecord.operationSource === GlobalOpsServiceType.DEALERCATALOG &&
    service.rawRecord.serviceKind === DealerPublishedCategory.REPAIR
  ) {
    return true;
  } else {
    return false;
  }
};

const topServicesMapper = (topServices, synonymsList) => {
  if (isEmpty(topServices)) {
    return [];
  }
  const topServicesMap = topServices.map(service => ({
    recordId: String(service.operationId),
    serviceName: service.operationName,
    ...(service.hasOwnProperty("price") && !isEmpty(service.price)
      ? {
          price: !doesEmpty(service.price.price)
            ? service.price.price.toString()
            : ""
        }
      : { price: "" }),
    synonyms: synonymsMapper(synonymsList, service.categoryId),
    categoryId: service.categoryId,
    categoryName: service.hasOwnProperty("categoryName")
      ? service.categoryName
      : "",
    categoryGroupName: service.hasOwnProperty("categoryGroupName")
      ? service.categoryGroupName
      : "",
    source: "TOP_SERVICES",
    operationSource: service.operationSource,
    tags: service.hasOwnProperty("tags") ? service.tags : null,
    opCode: !isEmpty(service.opCode) ? service.opCode : "",
    rawRecord: service
  }));
  return topServicesMap;
};

const declinedServicesMapper = (declinedServices, synonymsList) => {
  if (isEmpty(declinedServices)) {
    return [];
  }
  const declinedServicesMap = declinedServices.map(service => ({
    recordId: String(service.id),
    serviceName: service.name,
    ...(service.hasOwnProperty("price") && !doesEmpty(service.price)
      ? { price: service.price.toString() }
      : { price: "" }),
    synonyms: synonymsMapper(synonymsList, ""),
    categoryId: has(service, "categoryId") ? service.categoryId : "",
    categoryGroupName: "",
    source: "DECLINED",
    operationSource: "DECLINED",
    declinedDate: service.declinedDate,
    // declined service API returns notes only
    asrNotes: service.notes || "",
    duration: service.duration,
    // read description from api response
    description: service.description || "",
    // @todo-beta: decline service api won't return paytypes, set default paytype values for all decline services
    payTypeCode: "",
    payTypeDescription: "",
    // since declined api will not return paytypecode, set default paytypecode for declined service
    defaultPayTypeCode: "",
    // @note: declined api wrapper will copy opCode to declined services
    opCode:
      service.hasOwnProperty("opCode") && !isEmpty(service.opCode)
        ? service.opCode
        : "",
    // TBD - debug and cleanup this rawRecord usage
    rawRecord: {
      ...service,
      operationSource: "DECLINED",
      // TBD - verify if operationId needed in create/update payload
      operationId: service.id
    },
    // @fix - create rawApiService here with API response, instead of dependent on rawRecord in edit decline file
    rawApiService: {
      ...service,
      operationSource: "DECLINED"
    }
  }));
  return declinedServicesMap;
};
// @note: This wrapper called when Search module has recall api response to transform
const recallServiceMapper = recallServices => {
  if (isEmpty(recallServices)) {
    return [];
  }
  const recallServicesMap = recallServices.map(service => ({
    recordId: String(service.id),
    serviceName: service.name,
    ...(service.hasOwnProperty("price") && !doesEmpty(service.price)
      ? { price: service.price.toString() }
      : { price: "" }),
    synonyms: [],
    categoryId: has(service, "categoryId") ? service.categoryId : "",
    categoryGroupName: "",
    asrNotes: "",
    duration: service.duration,
    // read description from api response
    description: service.description || "",
    // @todo-beta: recall service api won't return paytypes, set default paytype values for all recall services
    payTypeCode: "",
    payTypeDescription: "",
    // since recall api willnot return paytypecode, set default paytypecode for recall service
    defaultPayTypeCode: "",
    // @note: recall api wrapper will copy opCode to recall services
    opCode:
      service.hasOwnProperty("opCode") && !isEmpty(service.opCode)
        ? service.opCode
        : "",
    source: "RECALL",
    operationSource: "RECALL",
    // TBD - debug and cleanup this rawRecord usage
    rawRecord: {
      ...service,
      operationSource: "RECALL",
      // TBD - verify if operationId needed in create/update payload
      operationId: service.id
    },
    // @fix - create rawApiService here with API response, instead of depending rawRecord in edit recall file
    rawApiService: {
      ...service,
      operationSource: "RECALL"
    }
  }));
  return recallServicesMap;
};
// Special case - Dealer tire
const editModulePropsForDealerTire = service => {
  const seriviceObj = editModulePropsFromService(service);
  seriviceObj.labor.notes = !isEmpty(service.laborApps[0])
    ? service.laborApps[0].notes
    : "";
  return seriviceObj;
};
// @param{service} API unified response called for all service types
const editModulePropsFromService = service => {
  if (service.operationSource === "GlobalCatalog") {
    return editModulePropsFromGlobalRepairService(service);
  }
  const { laborApps } = service;
  // IMPORTANT: this mapper returns serviceObject which is directly used to update editService context.service
  let serviceObject = {
    name: "",
    totalPrice: 0,
    laborAppId: null,
    labor: {
      serviceId: "",
      notes: "",
      price: 0,
      time: 0
    },
    defaultLaborRate: 0,
    asrNotes: "",
    commentsRequired: false,
    opCode: "",
    description: "",
    operationSource: "",
    serviceKind: "",
    defaultPayTypeCode: "",
    payTypeCode: "",
    payTypeDescription: "",
    parts: [],
    partsPrice: 0,
    totalPriceOverride: null,
    totalLaborPriceOverride: null,
    totalPartsPriceOverride: null,
    calculatedLaborPrice: null,
    calculatedPartsPrice: null,
    calculatedTotalPrice: null,
    finalLaborPrice: null,
    laborPriceOverridden: false,
    partsPriceOverridden: false,
    totalPriceOverridden: false
  };
  if (!isEmpty(laborApps)) {
    const laborAppsObj = service.laborApps[0];
    serviceObject = {
      name: service.laborApps[0].name,
      totalPrice: service.laborApps[0].totalPrice,
      // @todo-edit: fix - read laborAppId from unified API and pass in quote payload as labor.extLaborId
      laborAppId: !laborAppsObj.laborAppId ? null : laborAppsObj.laborAppId,
      labor: {
        serviceId: has(service, "operationId") ? service.operationId : "",
        price: service.laborApps[0].laborPrice,
        time: service.laborApps[0].laborHours,
        notes: "" // @fix - never read notes from catalog API repsonse;
      },
      defaultLaborRate: has(service, "defaultLaborRate")
        ? service.defaultLaborRate
        : 0,
      asrNotes: service.asrNotes || "",
      commentsRequired: has(service, "commentsRequired")
        ? Boolean(service.commentsRequired)
        : false,
      opCode: formatOpcode(service.laborApps[0].opCode) || "",
      description: service.description || "",
      operationSource: service.operationSource || "",
      serviceKind: service.serviceKind || "",
      partsPrice: service.laborApps[0].partsPrice,
      parts: service.laborApps[0].parts,
      // @edit-fix: except formatted unified response passed as method argu here, price override fields to be decided based on overridden flags
      totalLaborPriceOverride: laborAppsObj.laborPriceOverridden
        ? laborAppsObj.laborPrice
        : null,
      totalPartsPriceOverride: laborAppsObj.partsPriceOverridden
        ? laborAppsObj.partsPrice
        : null,
      totalPriceOverride: laborAppsObj.totalPriceOverridden
        ? laborAppsObj.totalPrice
        : null,
      laborPriceOverridden: laborAppsObj.laborPriceOverridden || false,
      partsPriceOverridden: laborAppsObj.partsPriceOverridden || false,
      totalPriceOverridden: laborAppsObj.totalPriceOverridden || false,
      // fix - unified response api will not have these calculated fields
      calculatedLaborPrice: null,
      calculatedPartsPrice: null,
      calculatedTotalPrice: null,
      finalLaborPrice: null,
      // @note: always read defaultPayTypeCode coming from catalog unified api response for non-global ops
      defaultPayTypeCode: has(service, "defaultPayTypeCode")
        ? service.defaultPayTypeCode
        : "",
      payTypeCode: "",
      payTypeDescription: ""
    };
  }
  return serviceObject;
};

const editModulePropsFromGlobalRepairService = service => {
  // IMPORTANT: this mapper returns serviceObject which is directly used to update editService context.service
  const serviceObject = {
    // @check - globalOps search case - {laborApps, operationSource, description, serviceKind} are copied
    ...service,
    totalPrice: 0,
    laborAppId: null,
    labor: {
      serviceId: has(service, "operationId") ? service.operationId : "",
      price: 0,
      notes: "",
      time: 0
    },
    partsPrice: 0,
    asrNotes: "",
    opCode: "",
    defaultLaborRate: service.defaultLaborRate ? service.defaultLaborRate : 0,
    commentsRequired: has(service, "commentsRequired")
      ? Boolean(service.commentsRequired)
      : false,
    // parts missing, will be mapped when laborApp is selected in UI
    // @edit-fix: globalops unified response as argu,
    // these price override and overridden flags to be updated in editService context.service when laborApp dropdown is selected in UI
    totalPriceOverride: null,
    totalLaborPriceOverride: null,
    totalPartsPriceOverride: null,
    calculatedLaborPrice: null,
    calculatedPartsPrice: null,
    calculatedTotalPrice: null,
    finalLaborPrice: null,
    laborPriceOverridden: false,
    partsPriceOverridden: false,
    totalPriceOverridden: false,
    // @note: always read defaultPayTypeCode coming from catalog unified api response - global repair
    defaultPayTypeCode: has(service, "defaultPayTypeCode")
      ? service.defaultPayTypeCode
      : "",
    payTypeCode: "",
    payTypeDescription: ""
  };
  return serviceObject;
};

const editModulePropsFromRecall = recallService => {
  const recallObject = {
    // @note:push service object to read operationId
    ...recallService,
    name: recallService.serviceName,
    totalPrice: has(recallService, "price") ? Number(recallService.price) : 0,
    laborAppId: null,
    labor: {
      serviceId: recallService.recordId,
      price: 0,
      time: convertMinutesToHours(recallService.duration),
      notes: ""
    },
    defaultLaborRate: has(recallService, "defaultLaborRate")
      ? recallService.defaultLaborRate
      : 0,
    asrNotes: has(recallService, "asrNotes") ? recallService.asrNotes : "",
    commentsRequired: has(recallService, "commentsRequired")
      ? recallService.commentsRequired
      : false,
    // note: opCode is extracted within declined API response wrapper
    opCode: has(recallService, "opCode")
      ? formatOpcode(recallService.opCode)
      : "",
    description: has(recallService, "description")
      ? recallService.description
      : "",
    operationSource: has(recallService, "source")
      ? recallService.source
      : "RECALL", // this default value never change
    serviceKind: has(recallService, "serviceKind")
      ? recallService.serviceKind
      : "recall", // this default value never change
    partsPrice: has(recallService, "partsPrice") ? recallService.partsPrice : 0,
    parts: has(recallService, "parts") ? recallService.parts : [],
    // @edit-fix: recall service - no price overrides
    totalPriceOverride: null,
    calculatedLaborPrice: null,
    totalLaborPriceOverride: null,
    totalPartsPriceOverride: null,
    totalPriceOverridden: false,
    laborPriceOverridden: false,
    partsPriceOverridden: false,
    calculatedPartsPrice: null,
    calculatedTotalPrice: null,
    finalLaborPrice: null,
    // @note: default paytype will be dervied in edit service module
    defaultPayTypeCode: "",
    payTypeCode: "",
    payTypeDescription: ""
  };
  return recallObject;
};

const editModulePropsFromDeclinedService = declinedService => {
  const declinedServiceObject = {
    // @note:push service object to read operationId
    ...declinedService,
    name: declinedService.serviceName,
    totalPrice: has(declinedService, "price")
      ? Number(declinedService.price)
      : 0,
    laborAppId: null,
    labor: {
      serviceId: declinedService.recordId,
      price: 0,
      time: convertMinutesToHours(declinedService.duration),
      notes: ""
    },
    defaultLaborRate: declinedService.defaultLaborRate
      ? declinedService.defaultLaborRate
      : 0,
    asrNotes: has(declinedService, "asrNotes") ? declinedService.asrNotes : "",
    commentsRequired: has(declinedService, "commentsRequired")
      ? declinedService.commentsRequired
      : false,
    // note: opCode is extracted within declined API response wrapper
    opCode: has(declinedService, "opCode")
      ? formatOpcode(declinedService.opCode)
      : "",
    description: has(declinedService, "description")
      ? declinedService.description
      : "",
    operationSource: has(declinedService, "source")
      ? declinedService.source
      : "DECLINED", // this default value never change
    serviceKind: has(declinedService, "serviceKind")
      ? declinedService.serviceKind
      : "declined", // this default value never change
    partsPrice: has(declinedService, "partsPrice")
      ? declinedService.partsPrice
      : 0,
    parts: has(declinedService, "parts") ? declinedService.parts : [],
    // IMPORTANT: as per requirement, decline service.price considered as total price override
    totalPriceOverridden: has(declinedService, "price") ? true : false,
    totalPriceOverride: has(declinedService, "price")
      ? Number(declinedService.price)
      : 0,
    totalLaborPriceOverride: null,
    totalPartsPriceOverride: null,
    laborPriceOverridden: false,
    partsPriceOverridden: false,
    calculatedLaborPrice: null,
    calculatedPartsPrice: null,
    calculatedTotalPrice: null,
    finalLaborPrice: null,
    // @note: default paytype will be dervied in edit service module
    defaultPayTypeCode: "",
    payTypeCode: "",
    payTypeDescription: ""
  };
  return declinedServiceObject;
};

// @note - Transform parts object for Menu service, return parts datamodel same like global operation details API datamodel
const prepareMenuServiceParts = parts => {
  const partsWithPartId = parts.map(part => {
    return {
      ...part,
      // @todo-poc: add rowId field to support ag-grid getRowNodeId() binded with rowId for poc.
      // @note: partId is a string used for filter logic accross parts grid.
      ...(has(part, "id") && { partId: part.id.toString() }),
      ...(!has(part, "id") &&
        has(part, "partId") && { partId: part.partId.toString() }),
      // @note: rowId is a number.
      ...(has(part, "id") && { rowId: Number(part.id) }),
      ...(!has(part, "id") &&
        has(part, "partId") && { rowId: Number(part.partId) }),
      ...(has(part, "description") ? { partName: part.description } : ""),
      ...(!has(part, "description") && has(part, "partName")
        ? { description: part.partName }
        : ""),
      quantity: has(part, "adjustedQuantity") ? part.adjustedQuantity : 0,
      unitPrice: has(part, "unitPrice") ? part.unitPrice : 0,
      oemPartNumber: has(part, "oemPartNumber") ? part.oemPartNumber : "",
      oilType: has(part, "oilType") ? part.oilType : "",
      unitOfMeasure: has(part, "adjustedUom") ? part.adjustedUom : "",
      priceSource:
        has(part, "priceSource") && part.priceSource
          ? part.priceSource
          : "MSRP",
      ...(has(part, "priceType") && { priceType: part.priceType }),
      position: "",
      notes: [],
      relationship: null,
      qualifiers: null,
      selected: false
    };
  });
  return partsWithPartId;
};

// @note: change - price overrides fields are read from menus catalog API
const editModulePropsFromMenuService = (menuService, originalService) => {
  const menuServiceObject = {
    // @menu: these fields not returned in API - laborAppId, asrNotes, commentsRequired, defaultLaborRate; hence set default values
    name: menuService.name,
    totalPrice: menuService.price,
    laborAppId: null, // always null for menus
    labor: {
      serviceId: menuService.id,
      price: has(menuService, "scheduledLaborPrice")
        ? menuService.scheduledLaborPrice
        : 0,
      notes: "",
      time: has(menuService, "durationMins")
        ? convertMinutesToHours(menuService.durationMins)
        : 0
    },
    asrNotes: "", // always set "" for menu
    commentsRequired: false, // always false for menu
    // always set 0; defaultLaborRate not exists in API
    defaultLaborRate: 0,
    opCode: has(menuService, "dmsOpcode")
      ? formatOpcode(menuService.dmsOpcode)
      : "",
    description: has(menuService, "description") ? menuService.description : "",
    operationSource: "MENUS",
    // check if menuService has payTypeCode field, api returns defaultPayTypeCode at menu level only
    defaultPayTypeCode: has(menuService, "payTypeCode")
      ? menuService.payTypeCode
      : "",
    payTypeCode: has(menuService, "payTypeCode") ? menuService.payTypeCode : "",
    payTypeDescription: has(menuService, "payTypeDescription")
      ? menuService.payTypeDescription
      : "",
    partsPrice: has(menuService, "partsPrice") ? menuService.partsPrice : 0,
    parts: has(menuService, "part")
      ? prepareMenuServiceParts(menuService.part)
      : [],
    originalParts: has(originalService, "part")
      ? prepareMenuServiceParts(originalService.part)
      : [],
    // @note: read calculated, final price, override fields from menu service updated before passed to edit menu service page
    totalPriceOverridden: menuService.totalPriceOverridden,
    totalPriceOverride: menuService.totalPriceOverridden
      ? menuService.price
      : null,
    laborPriceOverridden: menuService.laborPriceOverridden,
    partsPriceOverridden: menuService.partsPriceOverridden,
    totalLaborPriceOverride: menuService.totalLaborPriceOverride,
    totalPartsPriceOverride: menuService.totalPartsPriceOverride,
    calculatedLaborPrice: menuService.calculatedLaborPrice,
    calculatedPartsPrice: menuService.calculatedPartsPrice,
    calculatedTotalPrice: menuService.calculatedTotalPrice,
    finalLaborPrice: menuService.finalLaborPrice
  };
  console.log("editModulePropsFromMenuService", menuServiceObject);
  return menuServiceObject;
};

// @note: menuService sent from review menu page has overridden, calculated fields same as global operation details API data model
const updateRawOperationFromMenuService = service => {
  const newService = cloneDeep(service);
  delete newService.part;
  newService.displayName = service.name;
  newService.laborAppId = null; // always null for menus
  newService.opCode = service.dmsOpcode;
  newService.notes = null; // always null for menu
  newService.laborPrice = has(service, "scheduledLaborPrice")
    ? service.scheduledLaborPrice
    : 0;
  newService.laborHours = has(service, "durationMins")
    ? convertMinutesToHours(service.durationMins)
    : 0;
  newService.totalPrice = service.price;
  newService.partsPrice = service.partsPrice;
  newService.laborPriceOverridden = service.laborPriceOverridden;
  newService.partsPriceOverridden = service.partsPriceOverridden;
  newService.totalPriceOverridden = service.totalPriceOverridden;
  newService.dealerCode = service.dealerCode;
  newService.payTypeCode = service.payTypeCode;
  newService.payTypeDescription = service.payTypeDescription;
  newService.operationSource = "Menus";
  newService.parts = has(service, "part")
    ? prepareMenuServiceParts(service.part)
    : [];
  const laborApps = [];
  laborApps.push(newService);

  const rawObject = {
    laborApps,
    abbreviation: null,
    catalogSource: "Menus",
    operationSource: "Menus",
    commentsRequired: false,
    dealerCode: "",
    // @note: Menus, defaultLaborRate never returned from catalog API
    defaultLaborRate: 0,
    description: null,
    enabled: null,
    extId: null,
    laborTimeMarkupPercent: null,
    loanerAllowed: null,
    make: "",
    operationId: service.id,
    parentId: null,
    mandatoryInAppt: null,
    notApplicableParts: [],
    selectableVehicleAttributes: [],
    serviceKind: null,
    serviceCategoryId: service.serviceCategoryId,
    serviceCategoryName: service.serviceCategoryName,
    vehicleAttributes: null,
    variant: null,
    // @note: payType values are updated for selectedMenu and fowarded to each menuService when paytypes API is ready
    payTypeCode: service.payTypeCode,
    payTypeDescription: service.payTypeDescription
  };
  return rawObject;
};

// prepare service object with edited service fields here, applicable for dealer publish, diagnosis service only
const updateService = (service, updatedService) => {
  // @fix-later: why clone service holds entire rawops datamodel
  const newService = cloneDeep(service);
  // @edit-fix - read laborAppId from edited service context
  newService.laborApps[0].laborAppId = updatedService.laborAppId;
  newService.laborApps[0].totalPrice = updatedService.totalPrice;
  newService.laborApps[0].laborPrice = updatedService.calculatedLaborPrice;
  newService.laborApps[0].laborHours = updatedService.labor.time;
  newService.laborApps[0].laborMinutes = convertHoursToMinutes(
    newService.laborApps[0].laborHours
  );
  newService.laborApps[0].opCode = updatedService.opCode;
  newService.laborApps[0].serviceComments = updatedService.labor.notes; // pass edited notes from UI field
  newService.laborApps[0].asrNotes = updatedService.asrNotes; // asrNotes exists for declined service only
  newService.laborApps[0].parts = updatedService.parts;
  newService.laborApps[0].totalLaborPriceOverride =
    updatedService.totalLaborPriceOverride;
  newService.laborApps[0].totalPriceOverride =
    updatedService.totalPriceOverride;
  newService.laborApps[0].totalPartsPriceOverride =
    updatedService.totalPartsPriceOverride;
  newService.laborApps[0].defaultLaborRate = updatedService.defaultLaborRate;
  newService.laborApps[0].calculatedPartsPrice =
    updatedService.calculatedPartsPrice;
  newService.laborApps[0].calculatedTotalPrice =
    updatedService.calculatedTotalPrice;
  newService.laborApps[0].finalLaborPrice = updatedService.finalLaborPrice;
  // always partsPrice will have override parts price or calculated parts price
  newService.laborApps[0].finalPartsPrice = updatedService.partsPrice;
  newService.laborApps[0].payTypeCode = updatedService.payTypeCode;
  newService.laborApps[0].payTypeDescription =
    updatedService.payTypeDescription;

  return {
    ...newService
  };
};
// method returns service{} updated with edit service page values
const updateServiceGlobalRepair = (service, updatedService) => {
  const { labor } = updatedService;
  const { time } = labor;
  const laborMinutes = time * 60;
  return {
    ...cloneDeep(service),
    // Globalops case - always laborApps[0] updated with selected laborApp object (service options) which is passed from edit service context,
    laborApps: [
      {
        ...updatedService.laborApp, // laborApp passed to handler, read from edit service context
        laborMinutes,
        laborPrice: updatedService.calculatedLaborPrice,
        defaultLaborRate: updatedService.defaultLaborRate,
        // @edit-fix - read laborAppId from edited service context.laborApp {} for globalops case
        laborAppId: updatedService.laborApp.laborAppId
      }
    ],
    opCode: updatedService.opCode,
    parts: updatedService.filteredParts, // @fix- latest parts for globalops
    serviceComments: updatedService.labor.notes,
    totalPrice: updatedService.totalPrice,
    calculatedPartsPrice: updatedService.calculatedPartsPrice,
    calculatedTotalPrice: updatedService.calculatedTotalPrice,
    totalLaborPriceOverride: updatedService.totalLaborPriceOverride,
    totalPriceOverride: updatedService.totalPriceOverride,
    totalPartsPriceOverride: updatedService.totalPartsPriceOverride,
    finalLaborPrice: updatedService.finalLaborPrice,
    // always partsPrice will have override parts price or calculated parts price
    finalPartsPrice: updatedService.partsPrice,
    payTypeCode: updatedService.payTypeCode,
    payTypeDescription: updatedService.payTypeDescription,
    vehicleAttributes: updatedService.vehicleAttributes,
    shopDurationMins: laborMinutes > 0 ? laborMinutes : 1
  };
};

const updateDeclinedService = (declinedService, updatedDeclinedService) => {
  const newDeclinedService = cloneDeep(declinedService);
  newDeclinedService.recordId = updatedDeclinedService.labor.serviceId;
  newDeclinedService.serviceName = updatedDeclinedService.name;
  newDeclinedService.price = updatedDeclinedService.totalPrice;
  newDeclinedService.asrNotes = updatedDeclinedService.asrNotes;
  newDeclinedService.duration = updatedDeclinedService.labor.time;
  newDeclinedService.opCode = updatedDeclinedService.opCode;
  newDeclinedService.serviceComments = updatedDeclinedService.labor.notes; // pass edited notes from UI field
  newDeclinedService.rawRecord.price = updatedDeclinedService.totalPrice;
  newDeclinedService.laborPrice = updatedDeclinedService.calculatedLaborPrice;

  newDeclinedService.payTypeCode = updatedDeclinedService.payTypeCode;
  newDeclinedService.payTypeDescription =
    updatedDeclinedService.payTypeDescription;

  newDeclinedService.calculatedPartsPrice =
    updatedDeclinedService.calculatedPartsPrice;
  newDeclinedService.calculatedTotalPrice =
    updatedDeclinedService.calculatedTotalPrice;
  newDeclinedService.totalLaborPriceOverride =
    updatedDeclinedService.totalLaborPriceOverride;
  newDeclinedService.totalPriceOverride =
    updatedDeclinedService.totalPriceOverride;
  newDeclinedService.totalPartsPriceOverride =
    updatedDeclinedService.totalPartsPriceOverride;
  newDeclinedService.finalLaborPrice = updatedDeclinedService.finalLaborPrice;
  // always partsPrice will have override parts price or calculated parts price
  newDeclinedService.finalPartsPrice = updatedDeclinedService.partsPrice;
  newDeclinedService.parts = refactorPartsWithPartId(updatedDeclinedService);
  console.log("newDeclinedService>", newDeclinedService);
  return {
    ...newDeclinedService
  };
};

const updateRecallService = (recallService, updatedRecallService) => {
  const newRecallService = cloneDeep(recallService);
  newRecallService.recordId = updatedRecallService.labor.serviceId;
  newRecallService.serviceName = updatedRecallService.name;
  newRecallService.price = updatedRecallService.totalPrice;
  newRecallService.asrNotes = updatedRecallService.asrNotes;
  newRecallService.duration = updatedRecallService.labor.time;
  newRecallService.opCode = updatedRecallService.opCode;
  newRecallService.serviceComments = updatedRecallService.labor.notes; // pass edited notes from UI field
  newRecallService.rawRecord.price = updatedRecallService.totalPrice;
  newRecallService.laborPrice = updatedRecallService.calculatedLaborPrice;

  newRecallService.payTypeCode = updatedRecallService.payTypeCode;
  newRecallService.payTypeDescription = updatedRecallService.payTypeDescription;

  newRecallService.calculatedPartsPrice =
    updatedRecallService.calculatedPartsPrice;
  newRecallService.calculatedTotalPrice =
    updatedRecallService.calculatedTotalPrice;
  newRecallService.totalLaborPriceOverride =
    updatedRecallService.totalLaborPriceOverride;
  newRecallService.totalPriceOverride = updatedRecallService.totalPriceOverride;
  newRecallService.totalPartsPriceOverride =
    updatedRecallService.totalPartsPriceOverride;
  newRecallService.finalLaborPrice = updatedRecallService.finalLaborPrice;
  // always partsPrice will have override parts price or calculated parts price
  newRecallService.finalPartsPrice = updatedRecallService.partsPrice;
  newRecallService.parts = refactorPartsWithPartId(updatedRecallService);
  console.log("newRecallService>", newRecallService);
  return {
    ...newRecallService
  };
};
// @params{editStateService} - service field changes saved in edit context
// @params{selectedMenuSerivce} - menuService selected from Review menu page
const updateMenuService = (selectedMenuSerivce, editStateService) => {
  console.log("updateMenuService", selectedMenuSerivce, editStateService);
  const newService = cloneDeep(selectedMenuSerivce);
  newService.price = editStateService.totalPrice;
  newService.scheduledLaborPrice = editStateService.calculatedLaborPrice;
  newService.durationMins = convertHoursToMinutes(editStateService.labor.time);
  newService.dmsOpcode = editStateService.opCode;
  newService.part = prepareMenuServiceParts(editStateService.parts);
  newService.parts = prepareMenuServiceParts(editStateService.parts);
  newService.partsPrice = editStateService.partsPrice;
  // TBD - this field to be verify whether to pass in wrapper for quote payload util method
  newService.calculatedLaborPrice = editStateService.calculatedLaborPrice;
  newService.calculatedPartsPrice = editStateService.calculatedPartsPrice;
  newService.calculatedTotalPrice = editStateService.calculatedTotalPrice;
  newService.totalLaborPriceOverride = editStateService.totalLaborPriceOverride;
  newService.totalPriceOverride = editStateService.totalPriceOverride;
  newService.totalPartsPriceOverride = editStateService.totalPartsPriceOverride;
  newService.finalLaborPrice = editStateService.finalLaborPrice;
  // always partsPrice will have override parts price or calculated parts price
  newService.finalPartsPrice = editStateService.partsPrice;
  newService.dealerCode = editStateService.dealerCode;
  newService.payTypeCode = editStateService.payTypeCode;
  newService.payTypeDescription = editStateService.payTypeDescription;
  return {
    ...newService
  };
};

//  for CSR specific updatemenu service
const updateCSRMenuService = (selectedMenuSerivce, editStateService) => {
  console.log("updateMenuService", selectedMenuSerivce, editStateService);
  const newService = cloneDeep(selectedMenuSerivce);
  newService.price = editStateService.totalPrice;
  newService.scheduledLaborPrice = editStateService.calculatedLaborPrice;
  newService.durationMins = convertHoursToMinutes(editStateService.labor.time);
  newService.dmsOpcode = editStateService.opCode;
  newService.part = prepareMenuServiceParts(editStateService.parts);
  newService.parts = prepareMenuServiceParts(editStateService.parts);
  newService.partsPrice = editStateService.partsPrice;
  // TBD - this field to be verify whether to pass in wrapper for quote payload util method
  newService.calculatedLaborPrice = editStateService.calculatedLaborPrice;
  newService.calculatedPartsPrice = editStateService.calculatedPartsPrice;
  newService.calculatedTotalPrice = editStateService.calculatedTotalPrice;
  newService.totalLaborPriceOverride = editStateService.totalLaborPriceOverride;
  newService.totalPriceOverride = editStateService.totalPriceOverride;
  newService.totalPartsPriceOverride = editStateService.totalPartsPriceOverride;
  newService.finalLaborPrice = editStateService.finalLaborPrice;
  // always partsPrice will have override parts price or calculated parts price
  newService.finalPartsPrice = editStateService.partsPrice;
  newService.dealerCode = editStateService.dealerCode;
  newService.payTypeCode = editStateService.payTypeCode;
  newService.payTypeDescription = editStateService.payTypeDescription;

  // for CSR related service Type and paytype
  newService.serviceType = editStateService.serviceType;
  newService.payType = editStateService.payType;
  newService.threeCs = editStateService.threeCs;
  return {
    ...newService
  };
};

// @todo-edit: fix - derived price overrides for menu here similar to dealer publish service case
// CSR-specific
const editCSRModulePropsFromMenuService = (menuService, originalService) => {
  // @todo-edit: fix - API menu service will not have price override field values; only totalPriceOverridden to be derived as below
  const hasTotalPriceOverridden = isNumber(
    menuService.price !==
      menuService.partsPrice + menuService.scheduledLaborPrice
  );
  const menuServiceObject = {
    // @todo-edit: why asrNotes, commentsRequired missing in this object with deafult values
    name: menuService.name,
    totalPrice: menuService.price,
    laborAppId: null, // always null for menus
    labor: {
      serviceId: menuService.id,
      price: has(menuService, "scheduledLaborPrice")
        ? menuService.scheduledLaborPrice
        : 0,
      notes: "",
      time: has(menuService, "durationMins")
        ? convertMinutesToHours(menuService.durationMins)
        : 0
    },
    asrNotes: "", // this value not exist for menu
    commentsRequired: false, // always false
    // For menus, defaultLaborRate not exists in API, hence always 0
    defaultLaborRate: has(menuService, "defaultLaborRate")
      ? menuService.defaultLaborRate
      : 0,
    opCode: has(menuService, "dmsOpcode")
      ? formatOpcode(menuService.dmsOpcode)
      : "",
    description: has(menuService, "description") ? menuService.description : "",
    operationSource: "MENUS",
    // @todo-beta: check if menuService has payTypeCode field, api returns defaultPayTypeCode at menu level only
    defaultPayTypeCode: has(menuService, "payTypeCode")
      ? menuService.payTypeCode
      : "",
    payTypeCode: has(menuService, "payTypeCode") ? menuService.payTypeCode : "",
    payTypeDescription: has(menuService, "payTypeDescription")
      ? menuService.payTypeDescription
      : "",
    partsPrice: has(menuService, "partsPrice") ? menuService.partsPrice : 0,
    parts: has(menuService, "part")
      ? prepareMenuServiceParts(menuService.part)
      : [],
    originalParts: has(originalService, "part")
      ? prepareMenuServiceParts(originalService.part)
      : [],
    // fix - dervice totalPrice override values
    totalPriceOverridden: hasTotalPriceOverridden,
    totalPriceOverride: hasTotalPriceOverridden ? menuService.price : null,
    laborPriceOverridden: false,
    partsPriceOverridden: false,
    totalLaborPriceOverride: null,
    totalPartsPriceOverride: null,
    // API menu service will not have calculated, final price fields
    calculatedLaborPrice: null,
    calculatedPartsPrice: null,
    calculatedTotalPrice: null,
    finalLaborPrice: null,

    //  for CSR related service Type and paytype
    serviceType: has(menuService, "serviceType")
      ? menuService.serviceType
      : null,
    payType: has(menuService, "payType") ? menuService.payType : "",
    threeCs: menuService.threeCs
  };
  console.log("CSR editModulePropsFromMenuService", menuServiceObject);
  return menuServiceObject;
};

// split opcaode
const formatOpcode = opCode => {
  if (opCode) {
    const opCodes = !isEmpty(opCode) ? opCode.split(";") : [];
    const opCodeSelected = !isEmpty(opCodes) ? opCodes[0] : opCode;
    return opCodeSelected;
  }
};

// util used to update PartId for parts
const refactorPartsWithPartId = service => {
  const refinedParts = service.parts.map(part => {
    const partId = !part.partId ? "" : part.partId;
    const description = has(part, "partName") ? part.partName : "";
    return { ...part, partId, description };
  });
  return refinedParts;
};

export default {
  globalOperationMapper,
  diagnosisServicesMapper,
  topServicesMapper,
  declinedServicesMapper,
  isDiagnosisService,
  isGlobalCatalog,
  isDealerPublishedMaintenance,
  isDealerPublishedRepair,
  editModulePropsFromService,
  editModulePropsFromGlobalRepairService,
  editModulePropsFromDeclinedService,
  editModulePropsFromMenuService,
  updateService,
  updateDeclinedService,
  updateRecallService,
  editModulePropsFromRecall,
  recallServiceMapper,
  updateServiceGlobalRepair,
  updateMenuService,
  updateRawOperationFromMenuService,
  updateCSRMenuService,
  editModulePropsForDealerTire,
  editCSRModulePropsFromMenuService
};
