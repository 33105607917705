/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React from "react";
import { PropTypes } from "prop-types";
import IconKeyboardArrowDown from "@cx/ui/Icons/IconKeyboardArrowDown";
import IconKeyboardArrowRight from "@cx/ui/Icons/IconKeyboardArrowRight";
import PreviewContext from "../../preview-context";
import {
  doesEmpty,
  isCalculatedPricing,
  isNoPricing
} from "../../../../../commonUtil/utils/object";
import Tooltip from "@cx/ui/Tooltip";

class TotalTemplate extends React.Component {
  static contextType = PreviewContext;
  constructor(props, context) {
    super(props, context);
    this.toggleTotalClick = this.toggleTotalClick.bind(this);
    const { localeStrings } = context.appContext;
    this.initializeLocaleValues(localeStrings);
    this.state = {
      isExpand: false
    };
  }
  componentDidMount() {}
  initializeLocaleValues(localeStrings) {
    this.totalLabel = localeStrings["xmm.portal.common.total"];
    this.packageOpcdeLabel =
      localeStrings["xmm.portal.preview.vehicle.package_opcode"];
    this.durationLabel =
      localeStrings["xmm.portal.preview.vehicle.duration_in_mins"];
    this.laborLabel = localeStrings["xmm.portal.preview.vehicle.labor"];
    this.partsLabel = localeStrings["xmm.portal.preview.vehicle.parts"];
    this.flatRateLabel = localeStrings["xmm.portal.preview.vehicle.flat_rate"];
    this.effectiveRateLabel =
      localeStrings["xmm.portal.preview.vehicle.effective"];
    this.cappedPricingTip =
      localeStrings["xmm.portal.preview.menu_capped_price_tip"];
    this.maxCappedPricingTip =
      localeStrings["xmm.portal.preview.menu_max_capped_price_tip"];
    this.dynamicOpcodePricingTip =
      localeStrings["xmm.portal.preview.menu_dynamic_opcode_price_tip"];
  }
  toggleTotalClick = event => {
    event.preventDefault();
    this.setState(prevState => ({
      isExpand: !prevState.isExpand
    }));
  };
  renderMenuIcon(totalPriceType) {
    // let totalPriceType = totalPrice;
    // totalPriceType = 3;
    switch (totalPriceType) {
      case 1:
        return (
          <Tooltip
            htmlId="menuTooltip"
            position="right"
            tooltipContent={this.cappedPricingTip}
          >
            {/* <i className="fas fa-info-circle" /> */}
            <span className="xmm-cps-icon" />
          </Tooltip>
        );
      case 2:
        return (
          <Tooltip
            htmlId="menuTooltip"
            position="right"
            tooltipContent={this.maxCappedPricingTip}
          >
            {/* <i className="fas fa-info-circle" /> */}
            <span className="xmm-cps-icon" />
          </Tooltip>
        );
      case 3:
        return (
          <Tooltip
            htmlId="menuTooltip"
            position="right"
            tooltipContent={this.dynamicOpcodePricingTip}
          >
            <span className="xmm-dop-icon" />
          </Tooltip>
        );
      default:
        return "";
    }
  }
  render() {
    let totalTable = null;
    const { make } = this.context.appContext.preview.search;
    const pricingFlow = isCalculatedPricing(this.context.makelist, make);
    const noPricingFlow = isNoPricing(this.context.makelist, make);
    const clsHideTotals = pricingFlow ? "" : "hidden";
    const { sortedMenuTypes } = this.context.appContext.preview;
    const menuTypes = this.props.menuTypes;
    const clsHide = this.state.isExpand
      ? "xmm-row xmm-row-expanded"
      : "xmm-row xmm-row-hide";
    const bgcolors = ["#96BDFF", "#173A78", "#83F279", "#298C20"];
    const colors = ["#000", "#fff", "#000", "#fff"];
    const finalMenuTypes = [];
    /* add price colors to match with chart */
    sortedMenuTypes.forEach((menu, index) => {
      menuTypes.forEach(m => {
        // const localmenu = Object.assign({}, m);
        if (menu.id === m.id) {
          m.bgcolor = bgcolors[index];
          m.color = colors[index];
          finalMenuTypes.push(m);
        }
      });
    });
    // console.log("color added to Menus", finalMenuTypes);
    let totalLabels = (
      <div>
        {this.packageOpcdeLabel} <br />
        {this.durationLabel} <br />
      </div>
    );
    if (pricingFlow) {
      totalLabels = (
        <div>
          {this.packageOpcdeLabel} <br />
          {this.durationLabel} <br />
          {this.laborLabel}
          <br />
          {this.partsLabel}
          <br />
          {this.flatRateLabel}
          <br />
          {this.effectiveRateLabel}
          <br />
        </div>
      );
    }
    const iconCmp = this.state.isExpand ? (
      <IconKeyboardArrowDown />
    ) : (
      <IconKeyboardArrowRight />
    );
    totalTable = (
      <div>
        <div className="xmm-row">
          <div
            className={
              this.state.isExpand
                ? "xmm-left-header no-border"
                : "xmm-left-header"
            }
            onClick={this.toggleTotalClick}
          >
            <span>
              {/* <i className={toggleCls} /> */}
              {iconCmp}
            </span>
            <span>
              <b>{this.totalLabel}</b>
            </span>
          </div>

          <div className="xmm-services">
            {finalMenuTypes.map((menu, index) => {
              // menu.totalPriceType = index % 2 === 0 ? 1 : 2; // test
              const menuTotalPrice = doesEmpty(menu.selectableTotalPrice)
                ? ""
                : menu.selectableTotalPrice.toFixed(2);
              const styleColor = {
                background: menu.bgcolor,
                color: menu.color,
                height: "35px"
              };
              const pricingIcon = this.renderMenuIcon(menu.totalPriceType);
              return (
                <div className="xmm-total-labels" key={"menu-price-" + menu.id}>
                  <div className="menu-price" style={styleColor}>
                    {pricingIcon}
                    {noPricingFlow ? " " : "$"}
                    {noPricingFlow ? " " : menuTotalPrice}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className={clsHide}>
          <div className="xmm-desc-label">{totalLabels}</div>
          <div className="xmm-services">
            {menuTypes.map(menu => {
              return (
                <span key={menu.id}>
                  <div className="xmm-total-labels">
                    <div className="total-values">
                      <span> {!menu.dmsOpcode ? "-" : menu.dmsOpcode} </span>{" "}
                      <br />
                      <span>
                        {" "}
                        {menu.shopDuration > -1 ? menu.shopDuration : "-"}
                      </span>
                      <br className={clsHideTotals} />
                      <span className={clsHideTotals}>
                        {" "}
                        {menu.laborPrice > -1
                          ? "$" + menu.laborPrice.toFixed(2)
                          : "-"}
                      </span>
                      <br className={clsHideTotals} />
                      <span className={clsHideTotals}>
                        {menu.partsPrice > -1
                          ? "$" + menu.partsPrice.toFixed(2)
                          : "-"}
                      </span>
                      <br className={clsHideTotals} />
                      <span className={clsHideTotals}>{menu.flatRate}</span>
                      <br className={clsHideTotals} />
                      <span className={clsHideTotals}>
                        {menu.effectiveRate > 0
                          ? "$" + menu.effectiveRate.toFixed(2)
                          : "$0.00"}
                      </span>
                      <br className={clsHideTotals} />
                    </div>
                  </div>
                </span>
              );
            })}
          </div>
        </div>
      </div>
    );

    return <React.Fragment>{totalTable}</React.Fragment>;
  }
}

export default TotalTemplate;

TotalTemplate.propTypes = {
  menuTypes: PropTypes.array
};
/* eslint-enable no-console */
