/* eslint-disable react/no-multi-comp */
/* eslint-disable react/prop-types */
import React from "react";
import { isFloatingNumber } from "./value";

const NaNssNrParts = ["NA", "NR", "NSS"];
// This Speed up calls to hasOwnProperty
const doesOwnProperty = Object.prototype.hasOwnProperty;

export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
// Example:
// sleep(300).then(() => {
// console.log("Timeout started");
// });

export function toEmptyListIfUndefined(list) {
  if (list && Array.isArray(list)) {
    return list;
  }
  return [];
}

export function toEmptyStringIfUndefined(value) {
  return value === undefined || value === null ? "" : value.toString();
}

export const DisplayState = props => (
  <div style={{ margin: "1rem 0" }}>
    <pre className="pre-style">
      <strong>props</strong> = {JSON.stringify(props, null, 2)}
    </pre>
  </div>
);

export function isDifferentValue(newVal, oldVal) {
  // console.log("Field compare new vs old", newVal, oldVal);
  const newValue = toEmptyStringIfUndefined(newVal);
  const oldValue = toEmptyStringIfUndefined(oldVal);
  return newValue !== oldValue;
}

function internalFormatPrice(value) {
  const price = Math.round(value * 100) / 100;
  if (!isNaN(price) && price >= 0) {
    return "$".concat(price.toFixed(2));
  }
  return "-";
}

export const priceFormatter = value => {
  if (isNaN(value) || !value) {
    return "-";
  }
  return internalFormatPrice(value);
};

export const isEmpty = obj => {
  // null and undefined are "empty"
  if (obj == null) return true;

  // Assume if it has a length property with a non-zero value
  // that that property is correct.
  if (obj.length > 0) return false;
  if (obj.length === 0) return true;

  // If it isn't an object at this point
  // it is empty, but it can't be anything *but* empty
  // Is it empty?  Depends on your application.
  if (typeof obj !== "object") return true;

  // Otherwise, does it have any properties of its own?
  // Note that this doesn't handle
  // toString and valueOf enumeration bugs in IE < 9
  for (const key in obj) {
    if (doesOwnProperty.call(obj, key)) return false;
  }
  return true;
};

/**
 * Returns true if the passed value is empty, false otherwise. The value is deemed to be empty if it is either:
 *
 * - `null`
 * - `undefined`
 * - a zero-length array
 * - a zero-length string (Unless the `allowEmptyString` parameter is set to `true`)
 *
 * @param {Object} value The value to test
 * @param {Boolean} allowEmptyString (optional) true to allow empty strings (defaults to false)
 * @return {Boolean}
 * @markdown
 */
export const doesEmpty = (value, allowEmptyString) => {
  return (
    value === null ||
    value === undefined ||
    (!allowEmptyString ? value === "" : false) ||
    (isArrayExist(value) && value.length === 0)
  );
};

/**
 * Returns true if the passed value is a JavaScript Array, false otherwise.
 *
 * @param {Object} target The target to test
 * @return {Boolean}
 * @method
 */
export const isArrayExist = value => {
  return Object.prototype.toString.call(value) === "[object Array]";
};

export const isArrayList = val => {
  return Array.isArray(val);
};

export const defaultToZeroIfNullOrEmpty = value => {
  return !value ? 0 : Number(value);
};

export function convertMinutesToHours(timeParam, decimals) {
  /*
   * example:
   * 8.4/60 = 0.14
   * 0.14 * 100 = 14.000000000000002
   * 14.000000000000002 toFixed(1) becomes 14.0
   * Math.ceil(14.0) becomes 14
   * 14/100 = 0.14
   * 0.14.toFixed(2) becomes 0.14
   * 0.14 is returned
   *
   * */
  let time = 0;
  if (timeParam) {
    time = timeParam;
    time /= 60;
    time *= 100;
    /* just one decimal is enough to take ceiling */
    time = time.toFixed(1);
    time = Math.ceil(time);
    time /= 100;
    time = time.toFixed(!decimals ? 2 : decimals);
    return time;
  }
  return 0;
}

export function convertToHours(timeParam, decimals) {
  let time = 0;
  if (timeParam) {
    time = timeParam;
    time *= 100;
    time = time.toFixed(1);
    time = Math.ceil(time);
    time /= 100;
    time = time.toFixed(!decimals ? 2 : decimals);
    return time;
  }
  return 0;
}

export function getVehicleAttributeColmns(selectableVehicleAttributes) {
  if (!selectableVehicleAttributes) {
    return [];
  }
  return selectableVehicleAttributes.map(attribute => {
    const field = attribute; // convertNameToField(attribute);
    const headerName = attribute;
    return { field, headerName };
  });
}

/**
 *
 * @param {object} operation
 * @param {object} response
 * @returns { partsAndLabor, laborPartsTotal }
 */
export function getPartsAndLaborAndTotal(operation, response, laborRateValue) {
  const { catalogSource } = response;
  if (catalogSource) {
    return getPartsAndLaborAndTotalV2(operation, response, laborRateValue);
  }
  return getPartsAndLaborAndTotalV1(operation, response, laborRateValue);
}

/**
 *
 * @param {object} operation
 * @param {object} response
 * @returns { partsAndLabor, laborPartsTotal }
 */
function getPartsAndLaborAndTotalV1(operation, response, laborRateValue) {
  let laborRate = laborRateValue ? laborRateValue : 100;
  const { operationSource } = operation;

  if (operationSource === "DealerPublishedCatalog") {
    // Convert the response to global operation formart
    response.laborApps = convertToLaborApps(response);
    const { fluids, parts } = convertToFluidAndPartsApps(response);
    response.fluids = fluids;
    response.parts = parts;
    response.dealerPublishedCatalog = 1;
  }

  // if (operationSource === "GlobalCatalog") {
  const partsAndLabor = {
    operation,
    labors: [],
    laborsColumns: [],
    parts: [],
    partsColumns: [],
    selectedVehicleAttributeMap: {}
  };
  const {
    defaultLaborRate,
    laborApps,
    fluids,
    parts,
    dealerPublishedCatalog,
    vehicleAttributes,
    selectableVehicleAttributes
  } = response;
  if (defaultLaborRate) {
    laborRate = defaultLaborRate;
  }
  partsAndLabor.operation = operation;
  partsAndLabor.dealerPublishedCatalog = dealerPublishedCatalog;
  partsAndLabor.labors = laborApps ? laborApps : [];
  partsAndLabor.laborsColumns = getAdditionalColumns(partsAndLabor.labors);
  laborApps.forEach(row => {
    row.description = getLaborAppDescription(row);
    row.laborHours = convertMinutesToHours(row.baseEwtMinutes);
    if (!row.quantity) {
      row.quantity = 1;
    }
    row.notes = row.footnote ? [row.footnote] : [];
  });
  const fluidsAndParts = [];
  if (fluids) {
    fluidsAndParts.push(...fluids);
  }
  const naNrNssParts = [];
  if (parts) {
    parts.forEach(p => {
      if (NaNssNrParts.includes(p.oePartNumber)) {
        p.description = p.motorPartName;
        naNrNssParts.push(p);
      } else {
        fluidsAndParts.push(p);
      }
    });
    naNrNssParts.sort((a, b) => {
      return a.description > b.description ? 1 : -1;
    });
  }
  const fluidHasQualifiers = false;
  fluidsAndParts.forEach(row => {
    row.description = row.motorPartName
      ? row.motorPartName
      : row.motorFluidName;
    row.manuallySelected = row.selected;
    if (!row.quantity) {
      row.quantity = 1;
    }
    // if (!fluidHasQualifiers && !doesEmpty(row.qualifiers)) {
    //   fluidHasQualifiers = true;
    // }
  });

  partsAndLabor.fluidHasQualifiers = fluidHasQualifiers;
  partsAndLabor.parts = fluidsAndParts;
  partsAndLabor.naNrNssParts = naNrNssParts;
  partsAndLabor.partsColumns = getAdditionalColumns(fluidsAndParts);
  // set up the selectableVehicleAttributes, selectableVehAttrLabors, and selectableVehAttrParts
  partsAndLabor.selectableVehicleAttributes = selectableVehicleAttributes;

  if (
    hasSelectableVehicleAttributes(partsAndLabor.selectableVehicleAttributes)
  ) {
    partsAndLabor.selectableVehAttrLabors = [...partsAndLabor.labors];
    partsAndLabor.selectableVehAttrParts = [...partsAndLabor.parts];
    partsAndLabor.vehicleAttributes = vehicleAttributes;
  }
  const laborPartsTotal = {
    isPublishedCatalog: false,
    laborRate,
    laborTime: 0,
    partsTotal: 0,
    laborTotal: 0,
    total: 0
  };

  if (operationSource === "DealerPublishedCatalog") {
    convertDealerPublishedLaborPartsTotal(response, laborPartsTotal);
  }

  return { partsAndLabor, laborPartsTotal };
  // } else if (operationSource === "DealerPublishedCatalog") {
  //   return updatePartsAndLaborTotal(response, operation);
  // }
  // return null;
}

/**
 * @param {object} operation
 * @param {object} response
 * @param {number} laborRateValue
 * @returns { partsAndLabor, laborPartsTotal }
 */
function getPartsAndLaborAndTotalV2(operation, response, laborRateValue) {
  let laborRate = laborRateValue ? laborRateValue : 100;

  const partsAndLabor = {
    operation,
    labors: [],
    laborsColumns: [],
    parts: [],
    partsColumns: [],
    selectedVehicleAttributeMap: {}
  };
  const {
    catalogSource,
    defaultLaborRate,
    laborApps,
    notApplicableParts,
    vehicleAttributes,
    selectableVehicleAttributes
  } = response;

  if (notApplicableParts) {
    notApplicableParts.forEach(p => {
      p.oePartNumber = p.oemPartNumber;
    });
  }

  const dealerPublishedCatalog = catalogSource === "DealerPublishedCatalog";

  if (defaultLaborRate) {
    laborRate = defaultLaborRate;
  }

  partsAndLabor.operation = operation;
  partsAndLabor.dealerPublishedCatalog = dealerPublishedCatalog;
  partsAndLabor.labors = laborApps ? laborApps : [];
  let fluidsAndParts = [];
  if (partsAndLabor.labors.length !== 0) {
    // fluidsAndParts = partsAndLabor.labors[0].parts;
    partsAndLabor.labors.forEach(l => {
      const { parts } = l;
      fluidsAndParts = fluidsAndParts.concat(parts);
    });
  }
  partsAndLabor.laborsColumns = getAdditionalColumnsV2(partsAndLabor.labors);
  laborApps.forEach(row => {
    row.description = row.displayName;
    if (!row.quantity) {
      row.quantity = 1;
    }
    row.dealerPublishedCatalog = dealerPublishedCatalog;
  });
  const naNrNssParts = notApplicableParts ? notApplicableParts : [];
  const fluidHasQualifiers = false;
  fluidsAndParts.forEach(row => {
    row.description = toEmptyStringIfUndefined(row.partName);
    row.manuallySelected = row.selected;
    row.oePartNumber = row.oemPartNumber;
    if (row.partType === "part") {
      row.supersededParts = row.alternateParts;
    } else {
      row.supersededFluids = row.alternateParts;
    }
    row.price = row.unitPrice;
    row.units = row.unitOfMeasure;
    if (!row.quantity) {
      row.quantity = 1;
    }
    row.dealerPublishedCatalog = dealerPublishedCatalog;
  });

  partsAndLabor.apiVersion = 2;
  partsAndLabor.fluidHasQualifiers = fluidHasQualifiers;
  partsAndLabor.parts = fluidsAndParts; // laborApps.length === 1 ? fluidsAndParts : [];
  partsAndLabor.naNrNssParts = naNrNssParts;
  partsAndLabor.partsColumns = getAdditionalColumnsV2(fluidsAndParts);
  // set up the selectableVehicleAttributes, selectableVehAttrLabors, and selectableVehAttrParts
  partsAndLabor.selectableVehicleAttributes = selectableVehicleAttributes;

  if (
    hasSelectableVehicleAttributes(partsAndLabor.selectableVehicleAttributes)
  ) {
    partsAndLabor.selectableVehAttrLabors = [...partsAndLabor.labors];
    partsAndLabor.selectableVehAttrParts = [...partsAndLabor.parts];
    partsAndLabor.vehicleAttributes = vehicleAttributes;
  }
  const laborPartsTotal = {
    isPublishedCatalog: false,
    laborRate,
    laborTime: 0,
    partsTotal: 0,
    laborTotal: 0,
    total: 0
  };

  const priceOverrides = {};

  if (dealerPublishedCatalog) {
    updateLaborPartsTotalV2(response, laborPartsTotal, priceOverrides);
  }

  return { partsAndLabor, laborPartsTotal, priceOverrides };
}

export function hasSelectableVehicleAttributes(selectableVehicleAttributes) {
  return (
    selectableVehicleAttributes && selectableVehicleAttributes.length !== 0
  );
}

export function hasAllVehicleAttributesSelected(
  selectedVehicleAttributeMap,
  vehicleAttributeListLength
) {
  return (
    selectedVehicleAttributeMap &&
    Object.keys(selectedVehicleAttributeMap).length ===
      vehicleAttributeListLength
  );
}

function convertDealerPublishedLaborPartsTotal(response, laborPartsTotal) {
  // const laborPartsTotal = {
  //   isPublishedCatalog: false,
  //   laborRate,
  //   laborTime: 0,
  //   partsTotal: 0,
  //   laborTotal: 0,
  //   total: 0
  // };
  const { operations } = response;
  if (operations && Array.isArray(operations) && operations.length !== 0) {
    const op = operations[0];
    const { labor, partsPrice, price } = op;
    const laborTime = labor && labor.time ? labor.time : 0;
    if (laborTime) {
      const laborTotal = labor && labor.price ? labor.price : 0;
      const total = price && price.price ? price.price : 0;
      laborPartsTotal.isPublishedCatalog = true;
      laborPartsTotal.laborTime = laborTime / 60;
      laborPartsTotal.laborTotal = laborTotal;
      laborPartsTotal.partsTotal = partsPrice ? partsPrice : 0;
      laborPartsTotal.total = total;
    }
  }
}
function updateLaborPartsTotalV2(response, laborPartsTotal, priceOverrides) {
  const { laborApps } = response;
  if (laborApps && Array.isArray(laborApps) && laborApps.length !== 0) {
    const laborApp = laborApps[0];
    const {
      laborHours,
      laborPrice,
      partsPrice,
      totalPrice,
      laborPriceOverridden,
      partsPriceOverridden,
      totalPriceOverridden
    } = laborApp;
    if (laborHours) {
      laborPartsTotal.isPublishedCatalog = true;
      laborPartsTotal.laborTime = laborHours ? laborHours : 0;
      laborPartsTotal.laborTotal = laborPrice ? laborPrice : 0;
      laborPartsTotal.partsTotal = partsPrice ? partsPrice : 0;
      laborPartsTotal.total = totalPrice ? totalPrice : 0;
    }
    priceOverrides.laborPrice = laborPrice;
    priceOverrides.partsPrice = partsPrice;
    priceOverrides.totalPrice = totalPrice;
    priceOverrides.laborPriceOverridden = laborPriceOverridden;
    priceOverrides.partsPriceOverridden = partsPriceOverridden;
    priceOverrides.totalPriceOverridden = totalPriceOverridden;
  }
}

function getAdditionalColumns(list) {
  const additionalColumnNamesMap = {};
  let id = 1;
  list.forEach(item => {
    if (item.hasOwnProperty("motorFluidName")) {
      item.quantity = isFloatingNumber(item.volume) ? item.volume : 1;
    }
    if (item.quantity === 0) {
      item.quantity = 1;
    }
    item.id = id++;
    const { qualifiers, vehicleAttributes } = item;
    if (item.motorFluidName && qualifiers) {
      const columnNames = Object.keys(qualifiers);
      columnNames.forEach(key => {
        additionalColumnNamesMap[key] = key;
        const field = convertNameToField(key);
        item[field] = qualifiers[key];
      });
    }
    if (vehicleAttributes) {
      const columnNames = Object.keys(vehicleAttributes);
      columnNames.forEach(key => {
        additionalColumnNamesMap[key] = key;
        const field = convertNameToField(key);
        item[field] = vehicleAttributes[key];
      });
    }
  });
  const additionalColumnNames = Object.keys(additionalColumnNamesMap);
  const sortedAdditionalColumnNames = additionalColumnNames.sort((a, b) => {
    return a > b ? 1 : -1;
  });
  return sortedAdditionalColumnNames.map(headerName => {
    const field = convertNameToField(headerName);
    return { field, headerName };
  });
}

function getAdditionalColumnsV2(list) {
  const additionalColumnNamesMap = {};
  let id = 1;
  list.forEach(item => {
    if (item.quantity === 0) {
      item.quantity = 1;
    }
    item.id = id++;
    const { qualifiers, vehicleAttributes } = item;
    if (item.partType === "fluid" && qualifiers) {
      const columnNames = Object.keys(qualifiers);
      columnNames.forEach(key => {
        additionalColumnNamesMap[key] = key;
        const field = convertNameToField(key);
        item[field] = qualifiers[key];
      });
    }
    if (vehicleAttributes) {
      const columnNames = Object.keys(vehicleAttributes);
      columnNames.forEach(key => {
        additionalColumnNamesMap[key] = key;
        const field = convertNameToField(key);
        item[field] = vehicleAttributes[key];
      });
    }
  });
  const additionalColumnNames = Object.keys(additionalColumnNamesMap);
  const sortedAdditionalColumnNames = additionalColumnNames.sort((a, b) => {
    return a > b ? 1 : -1;
  });
  return sortedAdditionalColumnNames.map(headerName => {
    const field = convertNameToField(headerName);
    return { field, headerName };
  });
}

export function convertNameToField(name) {
  return name.replace(/\s+/g, "");
}
function getLaborAppDescription(laborApp) {
  const { globalOperationName, position, additionalDescription, qualifiers } =
    laborApp;
  let label = globalOperationName;
  if (toEmptyStringIfUndefined(position) !== "") {
    label += " - " + position;
  }
  if (toEmptyStringIfUndefined(additionalDescription) !== "") {
    label += " - " + additionalDescription;
  }
  if (qualifiers && Array.isArray(qualifiers) && qualifiers.length !== 0) {
    const allQualifiers = qualifiers.join(", ");
    label += " - " + allQualifiers;
  }
  return label;
}

function convertToLaborApps(response) {
  const { operations } = response;
  if (operations && Array.isArray(operations) && operations.length !== 0) {
    const op = operations[0];
    const { categoryId, id, name, labor, opCode } = op;
    const baseEwtMinutes = labor && labor.time ? labor.time : 0;
    const laborHours = convertMinutesToHours(baseEwtMinutes);
    return [
      {
        opCode,
        dealerPublishedCatalog: 1,
        additionalDescription: null,
        allAdditionalDescription: null,
        allEwtMinutes: 0,
        laborHours,
        baseEwtMinutes,
        baseWarrantyMinutes: null,
        categoryId,
        notes: [],
        globalOpPosition: null,
        globalOperationId: id,
        globalOperationName: name,
        motorAppId: id,
        motorOperationName: name,
        position: null,
        qualifiers: null,
        vehicleAttributes: null
      }
    ];
  }
  return [];
}

function convertToFluidAndPartsApps(response) {
  const fluids = [];
  const parts = [];
  const { operations } = response;
  if (
    operations &&
    Array.isArray(operations) &&
    operations.length !== 0 &&
    operations[0].parts
  ) {
    const op = operations[0];
    const { categoryId, name } = op;
    const globalOperationId = op.id;
    op.parts.forEach(p => {
      const {
        description,
        dtDmsPartCode,
        fluidSpec,
        id,
        oemPartNumber,
        oilType,
        quantity,
        priceSource,
        supersededParts,
        status,
        uom,
        unitPrice
      } = p;
      if (p.partType === "part") {
        parts.push({
          categoryId,
          dealerPublishedCatalog: 1,
          dtDmsPartCode,
          globalOperationId,
          globalOperationName: name,
          motorPartName: description,
          notes: null,
          oePartNumber: oemPartNumber,
          position: null,
          price: unitPrice,
          priceSource,
          quantity,
          status,
          selected: true,
          supersededParts,
          vehicleAttributes: null
        });
      } else if (p.partType === "fluid") {
        fluids.push({
          categoryId,
          dealerPublishedCatalog: 1,
          dtDmsPartCode,
          exactFluidQty: quantity,
          globalOperationName: name,
          // gradeCode: "API*"
          // gradeOrder: 2
          motorFluidAppId: id,
          motorFluidName: description,
          motorOperationName: name,
          notes: [],
          oePartNumber: oemPartNumber,
          oilType,
          // position: "N/A"
          price: unitPrice,
          priceSource,
          // qualifiers: {}
          // relationship: "Primary"
          specification: fluidSpec,
          status: null,
          selected: true,
          supersededFluids: supersededParts,
          // temperature: "Above -18"
          units: uom,
          vehicleAttributes: null,
          // viscosity: "5W-40"
          volume: quantity,
          volumeMax: null
        });
      }
    });
  }
  return { fluids, parts };
}
