/* eslint-disable no-console */
import React from "react";
import Col from "@cx/ui/Col";
import Button from "@cx/ui/Button";
import { toast } from "@cx/ui/Toast";
import { PropTypes } from "prop-types";
import PreviewContext from "../../preview-context";
import VehicleCard from "./VehicleCard";
import {
  getVehicleImage,
  makeSecureRestApi
} from "../../../../../api/xmmAxios";
import { isSameValue } from "../../../../../commonUtil/utils/string";
import { getMetaVehicleIds, getMetaVehicleIdToDescMap } from "./UserPrefs";
import * as gtmEvents from "../../../../utils/gtag-eventlist";
class Favorites extends React.Component {
  static contextType = PreviewContext;
  constructor(props, context) {
    super(props, context);
    this.filterByMake = this.filterByMake.bind(this);
    this.loadVehicles = this.loadVehicles.bind(this);
    this.selectActiveTab = this.selectActiveTab.bind(this);
    const { localeStrings } = context.appContext;
    // preview.previewTabs.selectedIndex = props.selectedIndex;

    this.cancelLabel = localeStrings["xmm.portal.common.cancel_button"];
    this.selectToAddLabel =
      localeStrings["xmm.portal.preview.vehicle.select_to_add_favorites_lbl"];
    this.noFavoriteLabel =
      localeStrings["xmm.portal.preview.vehicle.no_favorite_lbl"];
    this.state = {
      filteredVehicles: null,
      vehicles: null,
      metaVehicleId: null,
      selectedVehicle: null,
      selectedVehicleId: context.selectedVehicleId,
      selectedVin: context.selectedVin
    };
  }
  componentDidMount() {
    this.loadVehicles();
    this.selectActiveTab();
    gtmEvents.trackGtagPageview("/preview/favorites");
  }

  selectActiveTab = () => {
    const { filterByMake } = this;
    window.dispatchEvent(
      new CustomEvent("onActivePreviewTab", {
        detail: { name: "Favorites", filterByMake },
        bubbles: true,
        cancelable: true
      })
    );
  };

  filterByMake = make => {
    const { vehicles } = this.state;
    const filteredVehicles =
      make === "all"
        ? vehicles
        : vehicles.filter(vehicle => {
            return vehicle.make === make;
          });
    this.setState({ filteredVehicles });
    const { onUpdate } = this.props;
    onUpdate({
      detail: {
        vehicleCountName: "favoriteCount",
        vehicleCount: filteredVehicles.length
      }
    });
    return filteredVehicles;
  };

  loadVehicles = () => {
    const { showVehiclesLoadingIndicator } = this.context;
    showVehiclesLoadingIndicator(true);
    const { locale, previewSettings } = this.context.appContext;
    const { favorites } = previewSettings;
    const data = getMetaVehicleIds(favorites);
    const params = {};
    const restEndPoint = `/ops/proxyapi/oeproxy/rest/internal/v1/vehicle/descriptions/${locale}`;
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "post",
        params,
        data
      },
      response => {
        if (response && response.data) {
          const goodVehicles = response.data.filter(v => v !== null);
          const vehicleDescMap = getMetaVehicleIdToDescMap(goodVehicles);
          const vehicles = favorites.map(v => {
            const veh = vehicleDescMap[v.metaVehicleId];
            return { ...veh, ...v, matchVinFlag: !!v.vin };
          });
          this.updateImageUrl(vehicles);
          this.setState({ vehicles }, () => {
            const { selectedMake } = this.context.selectedMakeObj;
            this.filterByMake(selectedMake);
          });
        } else {
          toast.error("Server Error");
        }
        setTimeout(() => {
          showVehiclesLoadingIndicator(false);
        }, 500);
      },
      error => {
        setTimeout(() => {
          showVehiclesLoadingIndicator(false);
        }, 500);
        toast.error(error.message);
      }
    );
  };

  updateImageUrl = vehicles => {
    vehicles.forEach(vehicle => {
      const { make, modelValue, year, id } = vehicle;
      const { locale } = this.context.appContext;
      const countryCode = locale.split("_")[1];
      // vehicle.image = `${baseImgUrl}/oe/getVehicleImage?make=${make}&model=${model}&year=${year}&countryCode=${countryCode}`;
      vehicle.image = getVehicleImage(make, modelValue, year, countryCode);
    });
    return vehicles;
  };

  vehicleSelectHandler = (metaVehicleId, selectedVehicle) => {
    this.setState({ metaVehicleId, selectedVehicle });
    // callback to render preview results page
  };

  render() {
    const { filteredVehicles, selectedVehicleId, selectedVin } = this.state;
    let widgets = null;
    if (filteredVehicles) {
      if (filteredVehicles.length > 0) {
        let keyCount = new Date().getTime();
        widgets = filteredVehicles.map((vehicle, index) => {
          const selected =
            isSameValue(vehicle.id, selectedVehicleId) &&
            isSameValue(vehicle.vin, selectedVin);
          return (
            <div key={vehicle.id} className="vehicle-content">
              <VehicleCard
                key={keyCount++}
                index={index}
                vehicle={vehicle}
                selected={selected}
                onSelect={() => {
                  this.setState({ selectedVehicleId: vehicle.id });
                  this.vehicleSelectHandler(vehicle.id, vehicle);
                }}
                onFavorite={() => {
                  const { previewSettings } = this.context.appContext;
                  const { favorites } = previewSettings;
                  const vehicleCount = favorites.length;
                  const { onUpdate } = this.props;
                  onUpdate({
                    detail: {
                      vehicleCountName: "favoriteCount",
                      vehicleCount
                    }
                  });
                }}
              />
            </div>
          );
        });
      } else {
        widgets = (
          <Col xs={12} md={12}>
            <div className="xmm-content-adjust">
              <span className="xmm-no-favorites">{this.noFavoriteLabel}</span>
              <p className="xmm-favorite-note">{this.selectToAddLabel}</p>
            </div>
          </Col>
        );
      }
    }
    // const classHide = this.props.showCancel
    //   ? "xmm-string-link xmm-space-text float-right"
    //   : "hide";
    const classHide = this.props.showCancel ? "xmm-flexbox-right" : "hide";
    return (
      <div>
        <div className="xmm-float-container">
          <div className="xmm-common-vehicles">{widgets}</div>
        </div>
        <div className={classHide}>
          <Button
            className="pull-right"
            htmlId="CancelBtn"
            buttonStyle="link"
            onClick={this.props.closeModal}
          >
            {this.cancelLabel}
          </Button>
        </div>
      </div>
    );
  }
}

Favorites.propTypes = {
  // selectedIndex: PropTypes.number,
  showCancel: PropTypes.bool,
  closeModal: PropTypes.func,
  onUpdate: PropTypes.func
};

export default Favorites;
