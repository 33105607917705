/* eslint-disable no-console */
import React from "react";
import { PropTypes } from "prop-types";
import Row from "@cx/ui/Row";
import Col from "@cx/ui/Col";
import TextInput from "@cx/ui/TextInput";
import Button from "@cx/ui/Button";
import IconInfoOutline from "@cx/ui/Icons/IconInfoOutline";
import Tooltip from "@cx/ui/Tooltip";
import { AppContext } from "../../../../app-context";
import {
  toEmptyStringIfUndefined,
  isDifferentValue
} from "../../../../../commonUtil/utils/string";
import { getConsumerAppUrl } from "../../../../reusable/helper";
import * as formvalidator from "../../../../formik/formvalidator";

class ConsumerMenusRow extends React.Component {
  static contextType = AppContext;
  // Any changes to props, this method updates state
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.catalog !== prevState.catalog) {
      const { catalog } = nextProps;
      return {
        catalog,
        dealerName: catalog.dealerName,
        dirty: false,
        valid: true,
        errors: {
          dealerName: ""
        }
      };
    }
    return null;
  }
  constructor(props, context) {
    super(props, context);
    this.onChangeName = this.onChangeName.bind(this);
    this.onBlurName = this.onBlurName.bind(this);
    this.launchMenusHandler = this.launchMenusHandler.bind(this);
    const { catalog } = props;
    this.state = {
      dealerName: catalog.dealerName,
      dirty: false,
      valid: true,
      errors: {
        dealerName: ""
      }
    };
  }
  componentDidMount() {}

  launchMenusHandler = event => {
    event.preventDefault();
    // redirect to consumer menus page
    const { locale } = this.context;
    const { catalog } = this.props;
    const { webKey, make, variant, dealerCode } = catalog;
    const launchUrl = getConsumerAppUrl(catalog, locale);
    console.log("Launch click", webKey, make, variant, dealerCode, launchUrl);
    // Always - Open a new window tab for each make
    window.open(launchUrl, catalog.make);
  };

  // validate name field - should set error msg or ""
  onBlurName = event => {
    const { value } = event.target;
    const { localeStrings } = this.context;
    let valid = false;
    // NOTE: CX BUG - event.target.name missing in NumberInput, TextInput
    const name = "dealerName";
    let errorMsg = "";
    const newValue = toEmptyStringIfUndefined(value);
    // const newValue = !value || value === "" ? "" : value;
    if (newValue) {
      errorMsg = formvalidator.validateAlphaNumWithSpecialCharacters(newValue);
    }

    this.setState(
      prevState => {
        const { errors } = prevState;
        if (!newValue) {
          errors.dealerName = localeStrings["xmm.portal.errors.required_field"];
        } else {
          errors.dealerName = !errorMsg ? "" : errorMsg;
          valid = true;
        }
        return { errors, valid };
      },
      () => {
        // callback parent handler to push changes
        const payload = {};
        const { catalog, onUpdate } = this.props;
        const { valid, dirty } = this.state;
        if (dirty && valid) {
          catalog[name] = newValue;
          payload[name] = newValue;
          onUpdate(catalog, "dealerName", payload);
          this.setState({
            dirty: false,
            valid: true,
            dealerName: newValue
          });
        }
      }
    );
  };

  onChangeName = (cxEvent, isValid, domEvent) => {
    const { name, value } = cxEvent.target;
    const newValue = toEmptyStringIfUndefined(value);
    if (isDifferentValue(this.state[name], newValue)) {
      this.setState(
        prevState => {
          const { errors } = prevState;
          return {
            dirty: true, // set dirty flag to avoid server calls when there is no change
            [name]: newValue,
            errors
          };
        },
        () => {
          // Do nothing
        }
      );
    }
  };

  render() {
    const { localeStrings } = this.context;
    const { catalog } = this.props;
    // const markDisabled = catalog.pricingMethod === 0 ? true : false;
    const isAny = catalog.make === "ANY" ? true : false;
    const clsAny = isAny ? "xmm-form-content" : "hidden";
    const clsRow = catalog.make !== "ANY" ? "xmm-form-content" : "hide";
    const anyMakeRow = (
      <div className="xmm-colspan-label">
        {localeStrings["xmm.portal.settings.not_applicable_lbl"]}
        <Tooltip
          htmlId="tipRight"
          position="right"
          tooltipContent={
            localeStrings["xmm.portal.settings.not_applicable_tip"]
          }
        >
          <IconInfoOutline htmlId="iconInfo" />
        </Tooltip>
      </div>
    );
    const pricingMethodOptions = [
      {
        value: "2",
        label: localeStrings["xmm.portal.settings.total_flat_lbl"]
      },
      {
        value: "0",
        label: localeStrings["xmm.portal.settings.no_pricing_lbl"]
      }
    ];
    // Do not display Calculated Workflow for Makes with IsExternalPricing = 0
    if (catalog.isExternalPricing === 1) {
      pricingMethodOptions.splice(0, 0, {
        value: "1",
        label: localeStrings["xmm.portal.settings.calculated_parts_lbl"]
      });
    }
    return (
      <Row>
        <Col md={2} sm={2} className="xmm-row-title">
          {catalog.make}
        </Col>
        <Col md={10} sm={10} className={clsRow}>
          <div className="xmm-fixed-col no-border">
            <Button
              htmlId={"launch-" + catalog.make}
              buttonStyle="primary"
              size="small"
              onClick={this.launchMenusHandler}
            >
              {localeStrings["xmm.portal.common.launch_lbl"]}
            </Button>
          </div>
          <div className="xmm-input-col">
            <TextInput
              htmlId={"dealerName-" + catalog.make}
              label="Dealer Name"
              displayLabel={false}
              name="dealerName"
              maxLength={128}
              onBlur={this.onBlurName}
              onChange={this.onChangeName}
              placeholder={
                localeStrings["xmm.portal.settings.enter_dealer_name_lbl"]
              }
              displayPlaceholder={false}
              disabled={false}
              value={this.state.dealerName}
              required
            />
          </div>
        </Col>
        <Col md={10} sm={10} className={clsAny}>
          {anyMakeRow}
        </Col>
      </Row>
    );
  }
}

export default ConsumerMenusRow;
ConsumerMenusRow.propTypes = {
  catalog: PropTypes.object,
  onUpdate: PropTypes.func
};
