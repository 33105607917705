/* eslint-disable react/prop-types */
/* eslint-disable no-console */
import React from "react";
import SearchableSelect from "@cx/ui/SearchableSelect";

const StyledSearchableSelect = React.forwardRef(
  (
    {
      onChange,
      onBlur,
      placeholder,
      label,
      field,
      form,
      customevent,
      ...otherProps
    },
    ref
  ) => {
    // console.log(
    //   "render Searchable form > props of " + field.name,
    //   form,
    //   otherProps
    // );
    return (
      <SearchableSelect
        {...field}
        {...otherProps}
        ref={ref}
        htmlId={otherProps.id}
        className={
          form.errors[field.name] && form.touched[field.name]
            ? "xmm-error-input"
            : otherProps.className
        }
        customSelectableItem={otherProps.customSelectableItem}
        maxHeight={otherProps.maxHeight || 150}
        disabled={otherProps.disabled}
        displayLabel={false}
        allowNewEntries={false}
        enableMultiSelect={false}
        displayPlaceholder={true}
        name={field.name}
        value={
          form.values[field.name] ? form.values[field.name].toString() : ""
        }
        options={otherProps.options}
        onBlur={(changeEvent, isValid, domEvent) => {
          const optionValue = customevent(changeEvent, field, form, domEvent);
          onChange(optionValue);
        }}
        onChange={(changeEvent, isValid, domEvent) => {
          // console.log("Searchable CMP level event", changeEvent.target.value);
          // call in-built formik.Field handler
          // call parent event handler onChange of child field, to update field value to formik state
          const optionValue = customevent(changeEvent, field, form, domEvent);
          onChange(optionValue);
        }}
        // onBlur={e => {
        //   console.log("Searchable cmp blur", e.target.value);
        //   form.setFieldTouched(field.name, true);
        //   // call in-built Field handler
        //   onBlur(e.target.value);
        // }}
        placeholder={placeholder ? placeholder : "Select..."}
        // filterBy={options => {
        //   if (options.length) {
        //     return true;
        //   }
        //   return options;
        // }}
      />
    );
  }
);

export default StyledSearchableSelect;
StyledSearchableSelect.displayName = "StyledSearchableSelect";

/*
Example:
  <Field
    name="make"
    component={StyledSelect}
    id="makeSelect"
    disabled=false
    customevent={this.ownMakeChange}
  />
*/
