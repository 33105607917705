/* eslint-disable no-useless-constructor */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable no-console */
import React from "react";
import Button from "@cx/ui/Button";
import ModalDialog from "@cx/ui/ModalDialog";
import { PropTypes } from "prop-types";
import { FormattedMessage } from "react-intl";
import SelectInput from "@cx/ui/SelectInput";
import { xlate } from "../../../../../commonUtil/i18n/locales";

export default class BulkCopyModal extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      copyDisable: true
    };
  }

  componentDidMount() {
    if (this.state.bulkEditMake) {
      this.loadMenuTypes();
    }
  }

  onChange = (cxEvent, isValid, domEvent) => {
    const { value } = cxEvent.target;
    this.setState({ menuType: value });
    if (value === "") {
      this.setState({
        copyDisable: true
      });
    } else {
      this.setState({
        copyDisable: false
      });
    }
  };
  render() {
    const bulkCopy = this.props.bulkCopy;
    return (
      <div>
        <ModalDialog
          htmlId="bulkCopyModalDialog"
          className="sample-modal-dialog"
          show={this.props.show}
          header={
            <ModalDialog.Title>
              <FormattedMessage
                defaultMessage="Copy Overrides to a Different Menu Type"
                id="xmm.portal.common.bulk_cpy_btn"
              />
            </ModalDialog.Title>
          }
          footer={
            <div>
              <SelectInput
                htmlId="menuTypeSelect"
                label="Menu Type"
                name="menuType"
                placeholder={xlate("xmm.portal.common.select_menu_type")}
                displayDeselectOption={false}
                displayLabel={false}
                options={this.props.menuTypes}
                onChange={this.onChange}
                maxHeight={200}
              />
              <Button
                htmlId="cancelCopyButton"
                onClick={this.props.closeDialog}
                text="Close Button"
                buttonStyle="secondary"
              >
                <FormattedMessage
                  defaultMessage="Cancel"
                  id="xmm.portal.common.cancel_button"
                />
              </Button>
              <Button
                htmlId="okCopyButton"
                onClick={() => bulkCopy(this.state.menuType)}
                text="Select"
                buttonStyle="primary"
                disabled={this.state.copyDisable}
              >
                <FormattedMessage
                  defaultMessage="Select"
                  id="xmm.portal.common.select_label"
                />
              </Button>
            </div>
          }
          onHide={this.props.closeDialog}
        />
      </div>
    );
  }
}

BulkCopyModal.propTypes = {
  show: PropTypes.bool,
  bulkCopy: PropTypes.func,
  closeDialog: PropTypes.func,
  menuTypes: PropTypes.array
};

BulkCopyModal.defaultProps = {
  show: false
};
