/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-useless-constructor */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable no-console */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import ModalDialog from "@cx/ui/ModalDialog";
import CustomTooltip from "../../commonUtil/components/reusable/CustomToolTip";
import {
  makeSecureRestApi,
  showBodyMask,
  hideBodyMask
} from "../../api/xmmAxios";
import { toast } from "@cx/ui/Toast";
import { any, array, bool, func, object, string } from "prop-types";
import { AgGridReact } from "ag-grid-react";
import { isArrayExist, doesEmpty } from "../../commonUtil/utils/object";
import Row from "@cx/ui/Row";
import Col from "@cx/ui/Col";
import Tooltip from "@cx/ui/Tooltip";
import Button from "@cx/ui/Button";
import ButtonGroup from "react-bootstrap/lib/ButtonGroup";
import TruncateText from "@cx/ui/TruncateText";
// import Popover from "@cx/ui/Popover";
import CustomLoadingOverlay from "../../commonUtil/components/loadingmask/CustomLoadingOverlay";
import { AppContext } from "../../components/app-context";
import {
  dateComparator,
  formatDateTimezone
} from "../../commonUtil/utils/date";
import { KEY_ENTER } from "../../commonUtil/utils/keyNavigation";
import { navigateToMenuLink } from "../utils/menu";
import { toEmptyStringIfUndefined } from "../utils/string";
import { locale, xlate } from "../i18n/locales";

/**
 *
 * This component used as generic dialog in main react pages
 * @class FindOpCodesDialog
 * @extends {Component}
 */
export default class FindOpCodesDialog extends Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);
    this.closeModal = this.closeModal.bind(this);
    this.onToggleClick = this.onToggleClick.bind(this);
    this.getServiceOpcodeScores = this.getServiceOpcodeScores.bind(this);
    this.onSearchBoxChanged = this.onSearchBoxChanged.bind(this);
    this.onCellClickedEvent = this.onCellClickedEvent.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.dmsDescriptionFlag = false;

    const gridOptions = {
      addMode: this.isNewOperation(this.props.serviceId),
      isToggled: props.disableBestMatch,
      toggleStatus: props.disableBestMatch ? "showall" : "bestmatch",
      columnDefs: this.getColumnList(props.localeStrings),
      defaultColDef: {
        sortable: true,
        resizable: true,
        editable: false,
        suppressMenu: true,
        width: 80,
        sortingOrder: ["asc", "desc", null],
        rowGroup: false,
        showPopover: false
      },
      isRowSelectable(rowNode) {
        return true; // to see checkbox
      },
      rowData: null,
      opCodesList: null,
      frameworkComponents: {
        customTooltip: CustomTooltip,
        customLoadingOverlay: CustomLoadingOverlay,
        customNoRowsOverlay: CustomLoadingOverlay
      },
      loadingOverlayComponent: "customLoadingOverlay",
      loadingOverlayComponentParams: {
        loadingMessage: "Loading",
        isLoading: true,
        noRows: false
      },
      noRowsOverlayComponent: "customNoRowsOverlay",
      noRowsOverlayComponentParams: {
        loadingMessage: props.localeStrings["xmm.portal.common.no_records_msg"],
        isLoading: false,
        noRows: true
      },

      // Note: Set locale strings in this localeText {} for ag-grid controls
      localeText: {
        filteredRows: props.localeStrings["xmm.portal.ag_grid.filteredRows"],
        selectedRows: props.localeStrings["xmm.portal.ag_grid.selectedRows"],
        totalRows: props.localeStrings["xmm.portal.ag_grid.totalRows"],
        totalAndFilteredRows:
          props.localeStrings["xmm.portal.ag_grid.totalAndFilteredRows"],
        noRowsToShow: props.localeStrings["xmm.portal.ag_grid.noRowsToShow"]
      },
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          {
            statusPanel: "agSelectedRowCountComponent",
            align: "left"
          }
        ]
      }
    };

    this.state = gridOptions;
  }
  onGridReady = params => {
    console.log(this.props);
    const addMode = this.isNewOperation(this.props.serviceId);
    this.setState({
      addMode,
      toggleStatus:
        addMode || this.props.disableBestMatch ? "showall" : "bestmatch",
      dmsDescription: undefined
    });
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.enableActions(true);
    // Pacakge overrides case - call Show All button action
    if (this.props.disableBestMatch) {
      this.findDealerOpcodes();
    } else {
      // By default, get Automatic scores by passing {forceRescore = 0}
      this.getServiceOpcodeScores(0);
    }
    this.gridApi.closeToolPanel();
  };

  // apply sorter only for "ShowAll" case
  applySorter(show) {
    const defaultSortModel = [
      {
        colId: "description",
        sort: "asc"
      }
    ];
    // this.gridApi && this.gridApi.setSortModel(show ? defaultSortModel : null);
    this.assignColumnState(show ? defaultSortModel : null);
  }
  assignColumnState = defaultSortModel => {
    this.gridColumnApi &&
      this.gridColumnApi.applyColumnState({
        state: defaultSortModel,
        defaultState: {
          // important to say 'null' as undefined means 'do nothing'
          sort: null
        }
      });
  };
  customComparator(valA, valB, nodeA, nodeB, isInverted) {
    const s1 = valA.toLowerCase();
    const s2 = valB.toLowerCase();
    return s1 > s2 ? 1 : s1 < s2 ? -1 : 0;
    // return valA.localeCompare(valB, "en", {
    //   sensitivity: "base"
    // });
  }

  getColumnList(localeStrings) {
    const baseCols = [
      {
        headerName: localeStrings["xmm.portal.findopcodes.grid.dmsDescription"],
        field: "description",
        // tooltipField: "description",
        // tooltipComponent: "customTooltip",
        // tooltipComponentParams: { field: "description" },
        autoHeight: true,
        sortingOrder: ["asc", "desc", null],
        width: 340,
        cellClass: "xmm-wrap-cell",
        comparator: this.customComparator,
        getQuickFilterText: params => {
          return params.value;
        }
      },
      {
        headerName: localeStrings["xmm.portal.findopcodes.grid.dmsOpcode"],
        field: "opcode",
        autoHeight: true,
        cellClass: "xmm-link-cell",
        enableRowGroup: false,
        width: 120,
        comparator: this.customComparator,
        getQuickFilterText: params => {
          return params.value;
        }
      },
      {
        headerName: localeStrings["xmm.portal.findopcodes.grid.lastUsed"],
        field: "lastUsed",
        enableRowGroup: false,
        width: 100,
        valueFormatter: dateFormatter,
        comparator: dateComparator
      },
      {
        headerName: localeStrings["xmm.portal.findopcodes.grid.usage"],
        field: "totalUsage",
        enableRowGroup: false,
        width: 80,
        cellStyle: {
          color: "black",
          textAlign: "right"
        }
      }
    ];
    return baseCols;
  }

  isNewOperation(serviceId) {
    return serviceId === "" || serviceId === null || serviceId === undefined;
  }

  // Textfield onInput handler
  onSearchBoxChanged = event => {
    if (this.gridApi) {
      const { value } = event.target;
      this.gridApi.setQuickFilter(value);
    }
  };
  onKeyDown(event) {
    if (event.keyCode === KEY_ENTER) {
      event.stopPropagation();
      event.preventDefault();
      return false;
    }
  }

  getGlobalOpcodeScores = (dealerCode, operationId) => {
    const params = { dealerCode, operationId };
    const restEndPoint = "/ops/rest-db/getValues/opcodeScoreHistory";
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "get",
        params
      },
      opcodeScores => {
        let opCodesList = [];
        if (
          opcodeScores &&
          Array.isArray(opcodeScores) &&
          opcodeScores.length !== 0
        ) {
          const { scores } = opcodeScores[0];
          if (scores) {
            opCodesList = scores;
          }
        }
        if (opCodesList.length === 0) {
          this.gridApi && this.gridApi.showNoRowsOverlay();
        }
        hideBodyMask();
        this.setState({ opCodesList });
      },
      error => {
        toast.error(error.message);
        this.gridApi && this.gridApi.showNoRowsOverlay();
        hideBodyMask();
        this.setState({ opCodesList: [] });
      }
    );
  };

  // Get Automatic scores
  getServiceOpcodeScores = forceRescore => {
    const serviceId = this.props.serviceId;
    if (this.isNewOperation(serviceId)) {
      this.findDealerOpcodes();
      return;
    }
    if (typeof serviceId === "string" && (serviceId + "").startsWith("g")) {
      // @todo; Temporary hack not showing Best Matches for Global Ops
      const operationId = serviceId.substring(1);
      const { dealerCode } = this.props;
      this.getGlobalOpcodeScores(dealerCode, operationId);
      return;
    }
    this.setState({ opCodesList: [] });
    showBodyMask();
    this.gridApi && this.gridApi.showLoadingOverlay();
    this.enableActions(true);
    makeSecureRestApi(
      {
        // url: "/ops/proxyapi/ddsproxy/rest/proc/getServiceOpcodeScores",
        url: "/ops/proxyapi/oeproxy/rest/internal/opcode/getServiceOpcodeScores",
        method: "get",
        data: {},
        params: { serviceId: this.props.serviceId, forceRescore }
      },
      data => {
        let scoringNotCompleted = false;
        let isDisabled = false;
        if (data) {
          if (isArrayExist(data)) {
            if (data.length === 0) {
              isDisabled = true;
              // show 'no rows' overlay
              this.gridApi && this.gridApi.showNoRowsOverlay();
              // this.pollIndex = null;
            } else if (
              data.length === 1 &&
              data[0].message &&
              data[0].message.indexOf("not scored") !== -1
            ) {
              scoringNotCompleted = true;
              if (this.pollIndex === undefined || this.pollIndex === null) {
                this.pollIndex = 0;
              } else {
                this.pollIndex += 1;
              }
            } else {
              // this.pollIndex = null;
              // clear all overlays
              this.gridApi && this.gridApi.hideOverlay();
            }
          }
        }
        if (scoringNotCompleted) {
          // poll
          if (this.pollIndex < PollTimes.length) {
            setTimeout(() => {
              this.getServiceOpcodeScores(0);
            }, PollTimes[this.pollIndex] * 1000);
          } else {
            toast.warn(
              "Refresh is not completed in one minute.  Please retry to refresh results."
            );
            hideBodyMask();
            this.pollIndex = null;
            // this.setState({ opCodesList: data });
            this.enableActions(isDisabled);
          }
        } else {
          hideBodyMask();
          this.pollIndex = null;
          this.setState({ opCodesList: data });
          this.enableActions(isDisabled);
        }
      },
      error => {
        toast.error(error.message);
        this.gridApi && this.gridApi.showNoRowsOverlay();
        hideBodyMask();
      }
    );
  };

  // Get Manual scores
  findDealerOpcodes = () => {
    showBodyMask();
    this.setState({ opCodesList: [] });
    if (!this.props.manualOpcodes || this.props.manualOpcodes.length === 0) {
      this.enableActions(true);
      this.gridApi && this.gridApi.showLoadingOverlay();
      makeSecureRestApi(
        {
          url: "/ops/proxyapi/ddsproxy/rest/proc/findDealerOpcodes",
          method: "get",
          data: {},
          params: { dealerCode: this.props.dealerCode }
        },
        data => {
          this.setState({ opCodesList: data });
          this.props.setManualOpcodes(data);
          // clear all overlays
          if (data.length === 0) {
            this.gridApi && this.gridApi.showNoRowsOverlay();
          } else {
            this.gridApi && this.gridApi.hideOverlay();
          }
          hideBodyMask();
          this.enableActions(false);
        },
        error => {
          toast.error(error.message);
          this.gridApi && this.gridApi.showNoRowsOverlay();
          hideBodyMask();
        }
      );
    } else {
      this.gridApi && this.gridApi.showLoadingOverlay();
      // react takes time to update state, need timeout on sync block.
      // const startTime = new Date().getTime();
      this.setState({ opCodesList: this.props.manualOpcodes }, () => {
        //
        // const endTime = new Date().getTime();
        // console.log("endTime-startTime", (endTime - startTime) / 1000);
        this.enableActions(false);
        this.gridApi && this.gridApi.hideOverlay();
        hideBodyMask();
        // console.log("done time", (new Date().getTime() - endTime) / 1000);
      });
    }
  };
  closeModal = () => {
    const { disableBestMatch } = this.props;
    const isToggled = disableBestMatch;
    const toggleStatus = disableBestMatch ? "showall" : "bestmatch";
    this.setState({ isToggled, toggleStatus }, () => {
      this.props.closeDialog();
    });
  };
  onCellClickedEvent = params => {
    const field = params.colDef.field;
    if (field === "opcode") {
      const { opcode } = params.data;
      this.props.setOpcodeValue(opcode);
      this.setState({ opCodesList: [] });
      this.closeModal();
    }
  };
  /* toggle click handler  */
  onToggleClick = cxEvent => {
    if (this.props.disableBestMatch) {
      cxEvent.preventDefault();
      return;
    }
    const { name } = cxEvent.target;
    this.setState(prevState => {
      return {
        isToggled: !prevState.isToggled,
        toggleStatus: name
      };
    });
    // Automatic = "Best Match" should pass {forceRescore = 0 }
    if (cxEvent.target.name === "bestmatch") {
      this.applySorter(false);
      this.getServiceOpcodeScores(0);
    } else {
      // Manual = "Show All"
      setTimeout(() => {
        this.applySorter(true);
        this.findDealerOpcodes();
      }, 50);
    }
  };
  // Method to enable action fields when data is ready
  enableActions(markDisabled) {
    document.querySelector("#opcodeSearchInput").disabled = markDisabled;
    // if possible, disable toggle buttons while grid loading
  }
  launchOpcodesPage = (parent, node) => {
    navigateToMenuLink(parent, node);
  };

  getDmsOpcodeDescription = () => {
    const { dealerCode, serviceId, dmsDescription } = this.props;
    if (typeof serviceId === "string" && (serviceId + "").startsWith("g")) {
      // @todo; don't call it for global repair op
      return;
    }
    if (
      !this.dmsDescriptionFlag &&
      doesEmpty(this.state.dmsDescription) &&
      doesEmpty(dmsDescription) &&
      !doesEmpty(dealerCode) &&
      !doesEmpty(serviceId)
    ) {
      this.dmsDescriptionFlag = true;
      makeSecureRestApi(
        {
          url: "/ops/proxyapi/ddsproxy/rest/proc/getDealerOpcodeDescription",
          method: "get",
          data: {},
          params: { dealerCode, serviceId }
        },
        data => {
          if (data) {
            if (isArrayExist(data) && data.length > 0) {
              const dmsDescription = data[0].currentOpcodeDescription
                ? data[0].currentOpcodeDescription
                : "-";
              this.setState({ dmsDescription });
            }
          }
          this.dmsDescriptionFlag = false;
        },
        error => {
          this.dmsDescriptionFlag = false;
          toast.error(error.message);
        }
      );
    }
  };
  getAllMakesValidationStatus(dealerCode) {
    makeSecureRestApi(
      {
        url: "ops/proxyapi/ddsproxy/rest/proc/getAllOpcodeValidationStatus",
        method: "get",
        // data: {},
        params: { dealerCode }
        // headers
      },
      datalist => {
        if (datalist && Array.isArray(datalist)) {
          this.validationStatuses = datalist.filter(
            status => status.validationInProgress === 1
          );
          // let showPopover = false;
          if (this.validationStatuses.length === 0) {
            this.getServiceOpcodeScores(1);
          } else {
            // showPopover = true;
            toast.info(xlate("xmm.portal.msg.refresh_opcodes_result_later"));
          }
          // this.setState({ showPopover });
        }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : xlate("xmm.portal.errors.fetch_dealer_data");
        toast.error(msg);
      }
    );
  }
  renderRefreshResults(showPopover) {
    return (
      <Button
        htmlId="refreshResultsLink"
        buttonStyle="link"
        size="small"
        className="xmm-string-link"
        hidden={this.state.toggleStatus === "showall"}
        onClick={() => {
          this.getAllMakesValidationStatus(this.props.dealerCode);
          // this.getServiceOpcodeScores(1); // refresh case pass {forceRescore=1}
        }}
      >
        {this.props.localeStrings["xmm.portal.findopcodes.refresh_results_lbl"]}
      </Button>
    );
    // return !showPopover ? (
    //   <Button
    //     htmlId="refreshResultsLink"
    //     buttonStyle="link"
    //     size="small"
    //     className="xmm-string-link"
    //     hidden={this.state.toggleStatus === "showall"}
    //     onClick={() => {
    //       this.getAllMakesValidationStatus(this.props.dealerCode);
    //       // this.getServiceOpcodeScores(1); // refresh case pass {forceRescore=1}
    //     }}
    //   >
    //     {this.props.localeStrings["xmm.portal.findopcodes.refresh_results_lbl"]}
    //   </Button>
    // ) : (
    //   <Popover
    //     htmlId="popoverRight"
    //     popoverContent={xlate("xmm.portal.msg.refresh_opcodes_result_later")}
    //     position="right"
    //     tooltip={true}
    //     trigger={["click", "outsideClick"]}
    //   >
    //     <Button
    //       htmlId="refreshResultsLink"
    //       buttonStyle="link"
    //       size="small"
    //       className="xmm-string-link"
    //       hidden={this.state.toggleStatus === "showall"}
    //       onClick={() => {
    //         this.getAllMakesValidationStatus(this.props.dealerCode);
    //         // this.getServiceOpcodeScores(1); // refresh case pass {forceRescore=1}
    //       }}
    //     >
    //       {
    //         this.props.localeStrings[
    //           "xmm.portal.findopcodes.refresh_results_lbl"
    //         ]
    //       }
    //     </Button>
    //   </Popover>
    // );
  }
  render() {
    const { internalName, dmsDescription, localeStrings } = this.props;
    const { toggleStatus, showPopover } = this.state;
    const showNameTip =
      internalName && internalName.trim().length > 32 ? true : false;
    // get dms description from props if exist;
    let dmsDescTip = "";
    if (doesEmpty(this.state.dmsDescription)) {
      dmsDescTip = !doesEmpty(dmsDescription) ? dmsDescription : "";
    } else {
      dmsDescTip = this.state.dmsDescription;
    }
    const clsOpcodeTip = !dmsDescTip ? "hide" : "";
    const footerMsg =
      toggleStatus === "bestmatch"
        ? localeStrings["xmm.portal.findopcodes.suggested_opcodes_lbl"]
        : "";

    const bestMatchButtonStyle = !this.state.isToggled
      ? "primary"
      : "secondary";
    const showAllButtonStyle = this.state.isToggled ? "primary" : "secondary";
    const showLink = !this.props.showValidateCatalog ? false : true;
    const locationDescriptor = {
      pathname: "/opcodevalidation",
      search: window.location.search
    };
    let linkSection = null;
    if (showLink) {
      linkSection = (
        <Link
          to={locationDescriptor}
          className="float-right small"
          onClick={event =>
            this.launchOpcodesPage("operations", "opcodevalidation")
          }
        >
          {localeStrings["xmm.portal.findopcodes.validate_catalogs_lbl"]}
        </Link>
      );
    }
    const refreshResults = this.renderRefreshResults(showPopover);

    const locale = this.context.locale;

    return (
      <div>
        <ModalDialog
          htmlId="findOpcodeDialog"
          show={this.props.show}
          className={
            locale === "fr_CA"
              ? "xmm-findopcode-modal long-modal"
              : "xmm-findopcode-modal"
          }
          backdrop={"static"}
          animation={false}
          header={
            <ModalDialog.Title>
              {
                this.props.localeStrings[
                  "xmm.portal.findopcodes.dms_opcode_title"
                ]
              }
            </ModalDialog.Title>
          }
          onHide={this.closeModal}
        >
          <Row className="xmm-findopcode-header">
            <Col xs={4} md={4}>
              <h5>
                {
                  this.props.localeStrings[
                    "xmm.portal.findopcodes.operation_name_lbl"
                  ]
                }
              </h5>
            </Col>
            <Col xs={4} md={4}>
              <h5>
                {
                  this.props.localeStrings[
                    "xmm.portal.findopcodes.current_opcode_lbl"
                  ]
                }
              </h5>
            </Col>
            <Col xs={4} md={4}>
              <h5>
                {
                  this.props.localeStrings[
                    "xmm.portal.findopcodes.search_method_lbl"
                  ]
                }
              </h5>
            </Col>
            <Col xs={4} md={4}>
              <TruncateText
                htmlId="operationNameText"
                displayToolTip={showNameTip}
              >
                <span className="hand-cursor">
                  {" "}
                  {this.props.internalName || "--"}{" "}
                </span>
              </TruncateText>
            </Col>
            <Col xs={4} md={4}>
              <Tooltip
                htmlId="popoverOpcode"
                tooltipContent={dmsDescTip}
                className={clsOpcodeTip}
              >
                <span
                  className="xmm-truncate-text hand-cursor"
                  onMouseOver={this.getDmsOpcodeDescription}
                >
                  {this.props.dmsOpcode || "--"}
                </span>
              </Tooltip>
            </Col>
            <Col xs={4} md={4}>
              <ButtonGroup hidden={this.state.addMode}>
                <Button
                  htmlId="bestmatchBtn"
                  name="bestmatch"
                  buttonStyle={bestMatchButtonStyle}
                  size="sm"
                  disabled={this.props.disableBestMatch}
                  onClick={this.onToggleClick}
                >
                  {
                    this.props.localeStrings[
                      "xmm.portal.findopcodes.bestmatch_lbl"
                    ]
                  }
                </Button>
                <Button
                  htmlId="showallBtn"
                  name="showall"
                  buttonStyle={showAllButtonStyle}
                  onClick={this.onToggleClick}
                  size="sm"
                >
                  {
                    this.props.localeStrings[
                      "xmm.portal.findopcodes.showall_lbl"
                    ]
                  }
                </Button>
              </ButtonGroup>
              <div className={this.state.addMode ? "" : "hide"}>
                {this.props.localeStrings["xmm.portal.findopcodes.showall_lbl"]}
              </div>
            </Col>
          </Row>
          <div className="xmm-input-search full-row">
            <form id="findopcodeForm" autoComplete="off">
              <input
                type="text"
                id="opcodeSearchInput"
                className="xmm-input"
                value={this.state.opcodeSearchText}
                placeholder={
                  this.props.localeStrings[
                    "xmm.portal.findopcodes.search.opcodes"
                  ]
                }
                onKeyDown={this.onKeyDown}
                onInput={this.onSearchBoxChanged}
              />
            </form>
          </div>
          <h5>
            {
              this.props.localeStrings[
                "xmm.portal.findopcodes.instructions_lbl"
              ]
            }
          </h5>
          <div className="xmm-find-opcode-container ag-theme-balham">
            <AgGridReact
              localeText={this.state.localeText}
              columnDefs={this.state.columnDefs}
              defaultColDef={this.state.defaultColDef}
              // enterMovesDownAfterEdit={true}
              // enterMovesDown={true}
              rowDeselection={true}
              isRowSelectable={this.state.isRowSelectable}
              // suppressRowClickSelection={true}
              suppressMenuHide={false}
              // suppressContextMenu={true}
              rowData={this.state.opCodesList}
              onGridReady={this.onGridReady}
              onSelectionChanged={this.handleSelectionChanged}
              frameworkComponents={this.state.frameworkComponents}
              loadingOverlayComponent={this.state.loadingOverlayComponent}
              loadingOverlayComponentParams={
                this.state.loadingOverlayComponentParams
              }
              noRowsOverlayComponent={this.state.noRowsOverlayComponent}
              noRowsOverlayComponentParams={
                this.state.noRowsOverlayComponentParams
              }
              animateRows={true}
              rowStyle={this.state.rowStyle}
              // rowHeight={50}
              statusBar={this.state.statusBar}
              onCellValueChanged={this.onCellValueChanged}
              onCellClicked={this.onCellClickedEvent}
              enableRangeSelection={false}
              enableCharts={false}
              enableCellTextSelection={true}
              enableBrowserTooltips={false}
            />
          </div>
          <div>
            <div className="xmm-label-info">{footerMsg}</div>
            {refreshResults}
            {linkSection}
          </div>
        </ModalDialog>
      </div>
    );
  }
}

FindOpCodesDialog.propTypes = {
  disableBestMatch: bool,
  showValidateCatalog: bool,
  dealerCode: string,
  serviceId: string,
  internalName: string,
  // eslint-disable-next-line react/forbid-prop-types
  dmsOpcode: any,
  dmsDescription: string,
  localeStrings: object,
  manualOpcodes: array,
  show: bool,
  closeDialog: func,
  setManualOpcodes: func,
  setOpcodeValue: func
};

FindOpCodesDialog.defaultProps = {
  disableBestMatch: false,
  show: false
};

const PollTimes = [1, 2, 7, 20, 30];
function dateFormatter(params) {
  const val = toEmptyStringIfUndefined(params.data.lastUsed);
  let dateStr = "";
  if (val) {
    //  Format date string as June 15, 2007 at 2:05am (Central Daylight Time)
    dateStr = formatDateTimezone(val, false, locale);
  }
  return dateStr;
}
