/* eslint-disable no-console */
import React from "react";
import { PropTypes } from "prop-types";
import Tabs from "@cx/ui/Tabs";
import Tab from "@cx/ui/Tab";
import MenusPanel from "./content/MenusPanel";
import IndividualOperationsTable from "./content/individual/IndividualOperationsTable";
import InspectOperationsTable from "./content/inspect/InspectOperationsTable";
import PreviewContext from "./preview-context";
// import ResultsPane from "../../../previewGrids/components/ResultsPane";
import GlobalOpsPreview from "./content/inspect/GlobalOpsPreview";
// import { makeSecureRestApi } from "../../../api/xmmAxios";

class ResultTabs extends React.Component {
  static contextType = PreviewContext;
  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.alacarteResults !== prevState.alacarteResults ||
      nextProps.menuResults !== prevState.menuResults
    ) {
      const { menuResults, alacarteResults } = nextProps;
      return { menuResults, alacarteResults };
    }
    return null;
  }
  constructor(props, context) {
    super(props, context);

    this.globalOpsPreviewRef = React.createRef();

    const { localeStrings } = context.appContext;
    this.menuPackagesLabel =
      localeStrings["xmm.portal.preview.vehicle.menu_packages_lbl"];
    this.individualOperationsLabel =
      localeStrings["xmm.portal.preview.vehicle.individual_operations_lbl"];
    this.inspectPreviewUnpublishedLabel =
      localeStrings["xmm.portal.preview.vehicle.inspect_in_unpublished"];
    this.inspectPreviewPublishedNoVin =
      localeStrings["xmm.portal.preview.vehicle.inspect_in_published"];
    this.inspectPreviewLabel =
      localeStrings["xmm.portal.preview.vehicle.inspect_preview_lbl"];
    this.repairOperationsLabel =
      localeStrings["xmm.portal.preview.vehicle.repair_operations_lbl"];

    const { menuResults, alacarteResults } = props;

    const { preview } = context.appContext;
    const { selectedIndex } = preview.resultTabs;
    this.state = { menuResults, alacarteResults, selectedIndex };
  }
  componentDidMount() {}

  renderDefault = () => {
    const { appContext } = this.context;
    const { preview } = appContext;
    const { resultTabs } = preview;
    const { selectedIndex } = resultTabs;
    const { alacarteResults, menuResults } = this.state;
    const active = selectedIndex;
    return (
      <Tabs
        htmlId="ResultTabs"
        onActivate={selectedIndex => {
          // console.log(selectedIndex);
          resultTabs.selectedIndex = selectedIndex;
          this.setState({ selectedIndex }, () => {
            this.props.updateVehicleDetail();
          });
        }}
      >
        <Tab active={active === 0} label={this.menuPackagesLabel}>
          <MenusPanel result={menuResults} />
        </Tab>
        <Tab active={active === 1} label={this.individualOperationsLabel}>
          {" "}
          <IndividualOperationsTable result={alacarteResults} />
        </Tab>
      </Tabs>
    );
  };

  renderAllTabs = () => {
    const { appContext } = this.context;
    const { preview } = appContext;
    const { resultTabs } = preview;
    const { selectedIndex } = resultTabs;
    const { alacarteResults, menuResults } = this.state;
    const active = selectedIndex;
    return (
      <Tabs
        htmlId="ResultTabs"
        onActivate={selectedIndex => {
          // console.log(selectedIndex);
          resultTabs.selectedIndex = selectedIndex;
          this.setState({ selectedIndex }, () => {
            this.props.updateVehicleDetail();
          });
        }}
      >
        <Tab active={active === 0} label={this.menuPackagesLabel}>
          <MenusPanel result={menuResults} />
        </Tab>
        <Tab active={active === 1} label={this.individualOperationsLabel}>
          {" "}
          <IndividualOperationsTable result={alacarteResults} />
        </Tab>
        <Tab active={active === 2} label={this.repairOperationsLabel}>
          <GlobalOpsPreview ref={this.globalOpsPreviewRef} />
        </Tab>
        <Tab active={active === 3} label={this.inspectPreviewLabel}>
          <InspectOperationsTable />
        </Tab>
      </Tabs>
    );
  };
  renderCommonOpsFullCatalog = () => {
    const { appContext } = this.context;
    const { preview } = appContext;
    const { resultTabs } = preview;
    const { selectedIndex } = resultTabs;
    const { alacarteResults, menuResults } = this.state;
    const active = selectedIndex;
    return (
      <Tabs
        htmlId="ResultTabs"
        onActivate={selectedIndex => {
          // console.log(selectedIndex);
          resultTabs.selectedIndex = selectedIndex;
          this.setState({ selectedIndex }, () => {
            this.props.updateVehicleDetail();
          });
        }}
      >
        <Tab active={active === 0} label={this.menuPackagesLabel}>
          <MenusPanel result={menuResults} />
        </Tab>
        <Tab active={active === 1} label={this.individualOperationsLabel}>
          {" "}
          <IndividualOperationsTable result={alacarteResults} />
        </Tab>
        <Tab active={active === 2} label={this.repairOperationsLabel}>
          <GlobalOpsPreview ref={this.globalOpsPreviewRef} />
        </Tab>
      </Tabs>
    );
  };

  renderCommonOpsTwoAirFilters = () => {
    const { appContext } = this.context;
    const { preview } = appContext;
    const { resultTabs } = preview;
    const { selectedIndex } = resultTabs;
    const { alacarteResults, menuResults } = this.state;
    const active = selectedIndex;
    return (
      <Tabs
        htmlId="ResultTabs"
        onActivate={selectedIndex => {
          // console.log(selectedIndex);
          resultTabs.selectedIndex = selectedIndex;
          this.setState({ selectedIndex }, () => {
            this.props.updateVehicleDetail();
          });
        }}
      >
        <Tab active={active === 0} label={this.menuPackagesLabel}>
          <MenusPanel result={menuResults} />
        </Tab>
        <Tab active={active === 1} label={this.individualOperationsLabel}>
          {" "}
          <IndividualOperationsTable result={alacarteResults} />
        </Tab>
        <Tab active={active === 2} label={this.inspectPreviewLabel}>
          <InspectOperationsTable />
        </Tab>
      </Tabs>
    );
  };

  resetGlobalOpsPreview = () => {
    this.globalOpsPreviewRef &&
      this.globalOpsPreviewRef.current &&
      this.globalOpsPreviewRef.current.resetGlobalOpsPreview();
  };

  render() {
    const { appContext } = this.context;
    const { dealer } = appContext;
    const { commonOpsTwoAirFiltersEnabled, commonOpsRepairsEnabled } = dealer;
    if (commonOpsRepairsEnabled && commonOpsTwoAirFiltersEnabled) {
      return this.renderAllTabs();
    } else if (commonOpsRepairsEnabled && !commonOpsTwoAirFiltersEnabled) {
      return this.renderCommonOpsFullCatalog();
    } else if (commonOpsTwoAirFiltersEnabled && !commonOpsRepairsEnabled) {
      return this.renderCommonOpsTwoAirFilters();
    }
    return this.renderDefault();
  }
}
export default ResultTabs;

ResultTabs.propTypes = {
  showCancel: PropTypes.bool,
  menuResults: PropTypes.object,
  alacarteResults: PropTypes.object,
  updateVehicleDetail: PropTypes.func
};

/* eslint-enable no-console */
