/* eslint-disable unused-imports/no-unused-vars */
import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import throttle from "lodash/throttle";
import IconKeyboardArrowRight from "@cx/ui/Icons/IconKeyboardArrowRight";
import selectServiceFormat from "../../utils/select-service-format";
import {
  useServiceSearchContext,
  Actions
} from "../../state/service-search.context";
import { priceValueFormatter } from "../../utils/format.util";
import has from "lodash/has";
import { doesEmpty } from "../../utils/quote.util";
import { GlobalOpsServiceType } from "../../utils/search.constants";
import serviceDataMapper from "../../utils/service-data-mapper";
import "./GlobalServiceList.scss";

const GlobalServiceList = props => {
  const {
    services,
    viewType,
    updateParentState,
    updateService,
    onSelectService,
    ...restProps
  } = props;
  const { state, dispatch, ctxRequestOperationDetails, ctxOnEditService } =
    useServiceSearchContext();
  const { debugQuote, globalOperationDetails, appConfig } = state;
  const [service, setService] = useState(null);

  const editServiceClick = useCallback(
    (serviceType, service) => {
      updateParentState(serviceType, true);
      updateService(service);
      dispatch({
        type: Actions.SET_SEARCH_HEADER,
        payload: false
      });
    },
    [updateParentState, updateService, dispatch]
  );

  useEffect(() => {
    // We need this check because globalOperationDetails will not be available in context until ctxRequestOperationDetails is triggered
    // by onSelectGlobalService below. This setup requires service in local state, so we also need to check for it.
    if (!isEmpty(service) && !isEmpty(globalOperationDetails)) {
      const selectedService = selectServiceFormat.formatService(
        service,
        globalOperationDetails
      );
      if (!isEmpty(appConfig) && appConfig.editModuleAccess) {
        const { rawRecord } = service;
        if (rawRecord.operationSource) {
          if (serviceDataMapper.isGlobalCatalog(service)) {
            editServiceClick(
              GlobalOpsServiceType.GLOBALCATALOG,
              selectedService
            );
          } else if (serviceDataMapper.isDiagnosisService(service)) {
            editServiceClick(GlobalOpsServiceType.DIAGNOSIS, selectedService);
          } else if (
            serviceDataMapper.isDealerPublishedMaintenance(service) ||
            serviceDataMapper.isDealerPublishedRepair(service)
          ) {
            editServiceClick(
              GlobalOpsServiceType.DEALERCATALOG,
              selectedService
            );
          }
        }
      } else {
        // If parent application is not QUOTING, just dispatch selectedService
        dispatch({
          type: Actions.SET_SELECTED_GLOBAL_OPS_SERVICE,
          payload: selectedService
        });
      }
    }
  }, [service, globalOperationDetails, editServiceClick, dispatch, appConfig]);

  // ?faq: Edit Pages Save click will dispatch selectedGlobalOpsService to Search Context then save Quote
  const onSelectGlobalService = async service => {
    ctxOnEditService(true);
    ctxRequestOperationDetails(service);
    setService(service);
  };

  const handleOnClick = throttle(
    service => onSelectGlobalService(service),
    500,
    {
      leading: true,
      trailing: false
    }
  );
  const handleOnClickMemo = useCallback(service => handleOnClick(service), []);

  const renderDetailForParentApp = service => {
    let content = null;
    // @note: show price, opcode based on appConfig options passed as props
    if (appConfig.showPrice) {
      if (has(service, "price") && !doesEmpty(service.price)) {
        content = (
          <span className="service-price">
            {priceValueFormatter(service.price)}
          </span>
        );
      } else {
        content = (
          <span className="service-price">
            <IconKeyboardArrowRight
              key={`serviceicon-${service.recordId}`}
              onClick={() => handleOnClickMemo(service)}
            />
          </span>
        );
      }
    }
    return content;
  };

  return (
    <div id="globalServiceListContainer" {...restProps}>
      <ul id="globalServiceList">
        {services.map(service => (
          <li
            id={`service-${service.recordId}`}
            key={`servicekey-${service.recordId}`}
            className="service"
            onClick={() => handleOnClickMemo(service)}
          >
            <div className="service-info">
              <span className="service-name">{service.serviceName} &nbsp;</span>
              {appConfig.showOpcode ? (
                <span className="service-opcode">{service.opCode}</span>
              ) : null}
              {renderDetailForParentApp(service)}
            </div>
            {!debugQuote ? (
              ""
            ) : (
              <div className="service-info">
                {!service.synonyms || !debugQuote ? (
                  ""
                ) : (
                  <div className="search-truncate-text tags-div">
                    <i className="tags">
                      {service.categoryId} {service.synonyms.toString()}{" "}
                    </i>
                  </div>
                )}
                <div className="txt-upper">
                  {service.opCode} {service.rawRecord.operationSource}
                </div>
                <div className="txt-caps">{service.rawRecord.serviceKind}</div>
                {service.rawRecord.operationSource ===
                  GlobalOpsServiceType.GLOBALCATALOG &&
                  service.rawRecord.serviceKind === "repair" && <span />}
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

GlobalServiceList.defaultProps = {
  services: [],
  viewType: "GLOBAL",
  onSelectService: () => {},
  updateParentState: () => {},
  updateService: () => {}
};

GlobalServiceList.propTypes = {
  onSelectService: PropTypes.func,
  viewType: PropTypes.string,
  updateParentState: PropTypes.func,
  updateService: PropTypes.func,
  services: PropTypes.arrayOf(
    PropTypes.shape({
      recordId: PropTypes.string.isRequired,
      serviceName: PropTypes.string.isRequired,
      price: PropTypes.string,
      synonyms: PropTypes.array,
      categoryId: PropTypes.number,
      categoryGroupName: PropTypes.string,
      source: PropTypes.string.isRequired
    })
  )
};

export default GlobalServiceList;
