import moment from "moment";

// Add formatters
const formatNumberWithLetter = number => {
  let formattedNumber = `${number}`;

  if (number >= 1000000) {
    formattedNumber = `${Number((number / 1000000).toFixed(2))}M`;
  } else if (number >= 1000) {
    formattedNumber = `${Number((number / 1000).toFixed(2))}K`;
  }

  return formattedNumber;
};

const formatPrice = price => {
  const formattedPrice = Number(price).toFixed(2);
  return `$${formattedPrice}`;
};

// Advance price formatter
// input value can be 654321, null, 0, "", "65.34"
function priceValueFormatter(amount) {
  const options = { style: "currency", currency: "USD" };
  const priceFormat = new Intl.NumberFormat("en-US", options).format(
    amount || 0
  );
  return priceFormat;
}

/**
 *
 * @param {number} number
 * @returns {string}
 */
function formatNumberWithThousands(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

/**
 * Util return patterns for Date & Time based on country locale
 * @param {string} locale
 * @return {string}
 */
function getDatePattern(locale) {
  let pattern = "DD-MM-YYYY";
  if (locale === "en_US") {
    pattern = "MM-DD-YYYY";
  } else if (locale === "en_US_short") {
    pattern = "M-D-YY";
  } else if (locale === "en_CA" || locale === "fr_CA") {
    pattern = "YYYY/MM/DD";
  } else if (locale === "en_CA_short" || locale === "fr_CA_short") {
    pattern = "YY-M-D";
  } else if (locale === "en_AU") {
    pattern = "DD/MM/YYYY";
  } else if (locale === "en_AU_short") {
    pattern = "D-M-YY";
  } else if (locale === "quotes") {
    pattern = "YYYY-MM-DDTHH:mm:ssZZ";
  } else if (locale === "vehicle") {
    pattern = "YYYY-MM-DD";
  }
  return pattern;
}

/**
 *
 * @param {number} rawDate
 * @param {string} locale
 * @param {string} [version]
 * @return {string}
 */
const formatDate = (rawDate, locale, version) => {
  let pattern;
  if (version) {
    pattern = getDatePattern(locale + "_" + version);
  } else {
    pattern = getDatePattern(locale);
  }
  const formattedDate = moment(rawDate).format(pattern).replace(/-/g, "/");
  return formattedDate;
};

/**
 * Is Numeric Implementation
 * Reference: https://api.jquery.com/jquery.isnumeric/
 *
 * @param {string|number} n
 * @returns boolean
 */
function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

export {
  isNumeric,
  formatNumberWithLetter,
  formatPrice,
  priceValueFormatter,
  formatNumberWithThousands,
  formatDate
};
