import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import IconArrowUpward from "@cx/ui/Icons/IconArrowUpward";
import isEmpty from "lodash/isEmpty";
import {
  useServiceSearchContext,
  Actions
} from "../../../state/service-search.context";
import ConfirmPopup from "../../../components/modals/ConfirmPopup";
import serviceDataMapper from "../../../utils/service-data-mapper";
import { saveActionTypes } from "../../../utils/search.constants";

/**
 * @param {Prop} props
 * @param {Element} EditServiceModule
 * @param {object} service
 * @param {function} updateParentState
 * @return {Element}
 */
const EditDealerPublished = props => {
  const { service, EditServiceModule, updateParentState } = props;
  const [localService, setLocalService] = useState(null);
  const { dispatch, state, ctxRequestPartsInventoryCommon } =
    useServiceSearchContext();
  const { localeStrings, debugQuote, vehicle, userPermissions } = state;
  const [showDiscardEditPopup, setShowDiscardEditPopup] = useState(false);
  const [serviceHasChanged, setServiceHasChanged] = useState(false);
  const payTypes = state.payTypes;
  useEffect(() => {
    setLocalService(service);
  }, [service]);

  const backToService = () => {
    updateParentState("DealerPublishedCatalog", false);
    dispatch({
      type: Actions.SET_SEARCH_HEADER,
      payload: true
    });
    setLocalService(null);
  };

  const handleServiceUpdate = updatedService => {
    const newService = serviceDataMapper.updateService(
      localService,
      updatedService
    );
    console.log("service to be saved", newService);
    dispatch({
      type: Actions.SET_SAVE_ACTION_TYPE,
      payload: saveActionTypes.SAVE
    });
    dispatch({
      type: Actions.SET_SELECTED_GLOBAL_OPS_SERVICE,
      payload: newService
    });
  };

  const handleServiceChange = serviceChanged => {
    setServiceHasChanged(serviceChanged);
  };

  const handleCancel = () => {
    if (serviceHasChanged && localService.operationSource !== "GlobalCatalog") {
      setShowDiscardEditPopup(true);
    } else {
      backToService();
    }
  };

  const saveServiceAndGoBack = updatedService => {
    const newService = serviceDataMapper.updateService(
      localService,
      updatedService
    );
    dispatch({
      type: Actions.SET_SAVE_ACTION_TYPE,
      payload: saveActionTypes.SAVEANOTHER
    });
    dispatch({
      type: Actions.SET_SELECTED_GLOBAL_OPS_SERVICE,
      payload: newService
    });
    backToService();
  };
  const asyncUpdatePartsByPaytype = async (paytype, editedService) => {
    localService.payTypeCode = paytype;
    ctxRequestPartsInventoryCommon(localService, editedService);
  };
  const clonedEditServiceModule = !isEmpty(localService)
    ? React.cloneElement(EditServiceModule, {
        debugMode: debugQuote,
        userPermissions,
        vehicle,
        rawOperationDetails: localService,
        service: serviceDataMapper.editModulePropsFromService(localService),
        localeStrings,
        payTypes,
        partsPricingAndInventory: state.partsPricingAndInventory,
        onCancelHandler: handleCancel,
        onSaveHandler: handleServiceUpdate,
        onSaveAnotherHandler: saveServiceAndGoBack,
        onChangePaytype: asyncUpdatePartsByPaytype,
        onServiceChange: handleServiceChange
      })
    : null;

  const discardEditPopup = (
    <ConfirmPopup
      title={localeStrings["sq.search.common.alert_lbl"]}
      message={localeStrings["sq.search.common.leaving_edit_page"]}
      show={showDiscardEditPopup}
      okText={localeStrings["sq.search.common.proceed_button"]}
      cancelText={localeStrings["sq.search.common.cancel_button"]}
      okAction={backToService}
      cancelAction={() => setShowDiscardEditPopup(false)}
      hideCancel={false}
      hideOk={false}
      buttonStyle="danger"
    />
  );

  return (
    <>
      <div id="editGlobalRepair" className="search-flex-grid-container">
        <span className="back-nav-label" onClick={handleCancel}>
          <IconArrowUpward
            htmlId="backArrowIcon"
            isActive={false}
            className="back-arrow"
          />
          Back
        </span>
        {clonedEditServiceModule}
      </div>
      {discardEditPopup}
    </>
  );
};

export default memo(EditDealerPublished);

EditDealerPublished.defaultProps = {
  service: null,
  updateParentState: () => {},
  EditServiceModule: null
};
EditDealerPublished.propTypes = {
  service: PropTypes.object.isRequired,
  updateParentState: PropTypes.func.isRequired,
  EditServiceModule: PropTypes.element
};
