/* eslint-disable no-console */
import React, { Component } from "react";
import Button from "@cx/ui/Button";
import Card from "@cx/ui/Card";
import Col from "@cx/ui/Col";
import SearchableSelect from "@cx/ui/SearchableSelect";
import { toast } from "@cx/ui/Toast";
import { PropTypes } from "prop-types";
import ToggleSwitch from "@cx/ui/ToggleSwitch";
import IntervalSelector from "../../../../../components/reusable/Intervals/IntervalSelector";
import VehicleGroupSelector from "../../../../../components/reusable/VehicleGroups/VehicleGroupSelector";
import { optionListComparator } from "../../../../../commonUtil/utils/list";
import { getAllVehicleGroupName } from "../../../../../commonUtil/utils/menu";
import {
  isDifferentValue,
  toEmptyStringIfUndefined
} from "../../../../../commonUtil/utils/string";
// import { hasErrorStrings } from "../../../../../commonUtil/utils/error";
import { makeSecureRestApi } from "../../../../../api/xmmAxios";
import {
  // initBulkEditDealerRules,
  MVC_ALL_VEHICLES,
  MVC_CHOOSE_VEHICLES
} from "../../../../../constants/ModuleConstants";
import { AppContext } from "../../../../../components/app-context";
import { xlate } from "../../../../../commonUtil/i18n/locales";
import * as gtmEvents from "../../../../utils/gtag-eventlist";
import { doesEmpty } from "../../../../../commonUtil/utils/object";

export default class BulkEditPane extends Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);
    this.onMenuTypeBlur = this.onMenuTypeBlur.bind(this);
    this.onMenuTypeChange = this.onMenuTypeChange.bind(this);
    this.onIntervalBlur = this.onIntervalBlur.bind(this);
    this.onIntervalChange = this.onIntervalChange.bind(this);
    this.onVehicleGroupBlur = this.onVehicleGroupBlur.bind(this);
    this.onVehicleGroupChange = this.onVehicleGroupChange.bind(this);
    this.handleApply = this.handleApply.bind(this);
    this.loadMenuTypes = this.loadMenuTypes.bind(this);
    this.validate = this.validate.bind(this);

    this.initializeLocaleValues();

    const { dealerCode, locale } = context;
    const { make, variant } = props.selectionlist[0];
    this.state = {
      make,
      variant,
      errors: {},
      menuType: "",
      menuTypes: [],
      menuTypeId: "",
      metaVehicleScope: MVC_ALL_VEHICLES,
      metaVehicleFilterName: "",
      metaVehicleFilterId: "",
      intervalId: "",
      interval: {
        make,
        variant,
        dealerCode,
        intervalId: "",
        intervalName: "",
        locale
      },
      toggleCount: 0,
      toggleStatus: {
        menuTypeId: false,
        metaVehicleFilterId: false,
        intervalId: false
      },
      isValid: false
    };
  }
  componentDidMount() {
    this.loadMenuTypes();
  }
  componentWillUnmount() {}
  initializeLocaleValues() {
    this.savingMsg = xlate("xmm.portal.common.saving");
    this.savedMsg = xlate("xmm.portal.common.saved");
    this.bulkeditError = xlate("xmm.portal.errors.update_data_for_dealer");
  }

  handleApply = event => {
    event.preventDefault();
    this.bulkEditDealerScheduleRules();
    gtmEvents.gtmTrackEvent("xmm.dealermenus.apply_bulk_edit_click");
  };
  getColumns() {
    const { toggleStatus } = this.state;
    const columns = [];
    if (toggleStatus.menuTypeId) {
      columns.push("menuType");
    }
    if (toggleStatus.intervalId) {
      columns.push("intervalName");
    }
    if (toggleStatus.metaVehicleFilterId) {
      columns.push("metaVehicleFilterName");
    }
    return columns;
  }
  bulkEditDealerScheduleRules = () => {
    const { updateStatusBox } = this.props;
    updateStatusBox(this.savingMsg, "pending", false);

    const { toggleStatus } = this.state;
    const { selectionlist } = this.props;
    let count = selectionlist.length;
    selectionlist.forEach(dealserScheduleRule => {
      const { dealerScheduleRuleId, serviceId, dealerCode, make, variant } =
        dealserScheduleRule;
      let { menuTypeId, intervalId, intervalName, metaVehicleFilterId } =
        dealserScheduleRule;
      if (toggleStatus.menuTypeId) {
        menuTypeId = this.state.menuTypeId;
      }
      if (toggleStatus.intervalId) {
        intervalId = this.state.interval.intervalId;
        intervalName = this.state.interval.intervalName;
      }
      if (toggleStatus.metaVehicleFilterId) {
        metaVehicleFilterId = this.state.metaVehicleFilterId;
      }
      const data = {
        dealerScheduleRuleId,
        make,
        variant,
        dealerCode,
        serviceId,
        menuTypeId,
        metaVehicleFilterId: toEmptyStringIfUndefined(metaVehicleFilterId),
        intervalId: toEmptyStringIfUndefined(intervalId),
        mileages: doesEmpty(intervalId) ? [intervalName] : null
      };
      if (!doesEmpty(intervalId)) {
        delete data.mileages;
      }
      // showBodyMask();
      const restUrl =
        "/ops/proxyapi/ddsproxy/rest/proc/editDealerScheduleRuleP";
      makeSecureRestApi(
        {
          url: restUrl,
          method: "post",
          data
        },
        data => {
          if (data) {
            console.log(data);
            // hideBodyMask();
            // this.gridApi && this.gridApi.hideOverlay();
            // TODO: Replace Row
            const {
              interval,
              make,
              menuTypeId,
              menuTypeMap,
              metaVehicleFilterId,
              metaVehicleFilterName
            } = this.state;
            const { intervalName, intervalId } = interval;
            if (toggleStatus.menuTypeId) {
              const menuTypeOption = menuTypeMap[menuTypeId];
              const { label, value } = menuTypeOption;
              dealserScheduleRule.menuType = label;
              dealserScheduleRule.menuTypeId = value;
            }
            if (toggleStatus.intervalId) {
              dealserScheduleRule.intervalName = intervalName;
              dealserScheduleRule.intervalId = intervalId;
            }
            if (toggleStatus.metaVehicleFilterId) {
              dealserScheduleRule.metaVehicleFilterId = metaVehicleFilterId;
              dealserScheduleRule.metaVehicleFilterName = !metaVehicleFilterId
                ? getAllVehicleGroupName(
                    make,
                    xlate("xmm.portal.common.all_make_vehicles")
                  )
                : metaVehicleFilterName;
            }
          }
          count -= 1;
          if (count <= 0) {
            const { closeSlider, updateGridAfterBulkEdit } = this.props;
            closeSlider();
            const columns = this.getColumns();
            updateGridAfterBulkEdit(selectionlist, columns);
            updateStatusBox(this.savedMsg, "success", true);
          }
        },
        error => {
          const msg = error["message"] ? error.message : this.bulkeditError;
          // toast.error(msg, {
          //   closeOnClick: true
          // });
          updateStatusBox(msg, "error", false, true);
          // this.gridApi && this.gridApi.showNoRowsOverlay();
          // hideBodyMask();
        }
      );
    });
  };
  onToggleClick = cxEvent => {
    const { name, value } = cxEvent.target;
    const fieldName = name.split("-")[0];
    // const disable = value === "checked" ? false : true;
    const { errors, toggleCount, toggleStatus } = this.state;
    if (value === "checked") {
      this.setState(
        {
          toggleCount: toggleCount + 1,
          toggleStatus: { ...toggleStatus, [fieldName]: true }
        },
        () => {
          this.validate();
        }
      );
    } else if (value === "unchecked") {
      switch (fieldName) {
        case "menuTypeId": {
          //
          this.setState({ menuTypeId: "" });
          break;
        }
        case "intervalId": {
          //
          const { interval } = this.state;
          interval.intervalId = "";
          interval.intervalName = "";
          this.setState({ interval });
          break;
        }
        case "metaVehicleFilterId": {
          this.setState({ metaVehicleFilterId: "", metaVehicleScope: "1" });
          break;
        }
        default:
          break;
      }
      this.setState(
        {
          toggleCount: toggleCount - 1,
          toggleStatus: { ...toggleStatus, [fieldName]: false },
          errors: {
            ...errors,
            [fieldName]: ""
          }
        },
        () => {
          this.validate();
        }
      );
    }
  };
  // const errorMap = { menuTypeId: xlate("xmm.portal.errors.field_is_required"), intervalId: xlate("xmm.portal.errors.field_is_required")}
  onMenuTypeBlur = (cxEvent, isValid, domEvent) => {
    const { value } = cxEvent.target;
    const { errors } = this.state;
    if (!value || value.length === 0) {
      this.onMenuTypeChange(cxEvent, isValid, domEvent);
    } else {
      errors.menuTypeId = "";
    }
    this.setState({ errors });
  };
  onMenuTypeChange = (cxEvent, isValid, domEvent) => {
    const { value } = cxEvent.target;
    const optionValue = value && value.length !== 0 ? value[0].value : "";
    const { menuTypeId } = this.state;
    if (isDifferentValue(menuTypeId, optionValue)) {
      this.setState({ menuTypeId: optionValue }, () => {
        this.validate();
      });
    }
  };
  onIntervalBlur = event => {
    const { errors, interval } = this.state;
    const { intervalId } = interval;
    let errorText = "";
    if (toEmptyStringIfUndefined(intervalId) === "") {
      errorText = xlate("xmm.portal.errors.field_is_required");
    }
    if (errors.intervalId !== errorText) {
      this.setState({ errors: { ...errors, intervalId: errorText } });
    }
    this.validate();
  };
  onIntervalChange = event => {
    const { data } = event.detail;
    if (data) {
      const { intervalId, intervalName } = data;
      const { interval } = this.state;
      interval.intervalId = intervalId;
      interval.intervalName = intervalName;
      this.setState({ interval });
    }
  };
  onVehicleGroupBlur = event => {
    // TODO
  };
  onVehicleGroupChange = event => {
    const { data } = event.detail;
    const record = {
      metaVehicleScope: MVC_ALL_VEHICLES,
      metaVehicleFilterId: ""
    };
    if (record && data) {
      const { metaVehicleFilterId, metaVehicleScope, name } = data;
      record.metaVehicleScope = metaVehicleScope;
      if (metaVehicleScope === MVC_ALL_VEHICLES) {
        record.metaVehicleFilterId = "";
      } else {
        record.metaVehicleScope = MVC_CHOOSE_VEHICLES;
        record.metaVehicleFilterId = metaVehicleFilterId;
      }
      this.setState({
        metaVehicleScope: record.metaVehicleScope,
        metaVehicleFilterId: record.metaVehicleFilterId,
        metaVehicleFilterName: name
      });
    }
  };
  loadMenuTypes = () => {
    const { make, variant } = this.state;
    const { dealerCode, makelist, locale } = this.context;
    const makesMap = {};
    makelist.forEach(m => {
      makesMap[m.make] = true;
    });
    makeSecureRestApi(
      {
        url: "/ops/proxyapi/ddsproxy/rest/proc/getEnhancedMenuTypes",
        method: "get",
        params: { dealerCode, locale, make, variant }
      },
      dataList => {
        const menuTypes = dataList
          .filter(menuType => {
            const { description, parentId, enabled } = menuType;
            return description && !parentId && enabled;
          })
          // .sort(menuTypeComparator)
          .map(menuType => {
            const { description, menuTypeId } = menuType;
            return { label: description, value: menuTypeId.toString() };
          })
          .sort(optionListComparator);
        // this.setState({ menuTypes });
        const menuTypeMap = {};
        menuTypes.forEach(menuType => {
          menuTypeMap[menuType.value] = menuType;
        });
        this.setState({ menuTypes, menuTypeMap });
      },
      error => {
        toast.error(error.message);
      }
    );
  };
  validate = () => {
    let isValid = true;
    const { menuTypeId, interval, toggleCount, toggleStatus } = this.state;
    if (toggleCount > 0) {
      if (toggleStatus.menuTypeId) {
        isValid = isValid && menuTypeId !== "";
      }
      if (isValid && toggleStatus.intervalId) {
        const { intervalId, intervalName } = interval;
        isValid =
          isValid && (!doesEmpty(intervalId) || !doesEmpty(intervalName));
      }
      this.setState({ isValid });
    } else {
      this.setState({ isValid: false });
    }
  };

  render() {
    const { dealerCode } = this.context;
    const {
      errors,
      make,
      variant,
      interval,
      metaVehicleFilterId,
      metaVehicleScope,
      // toggleCount,
      toggleStatus,
      menuTypeId,
      menuTypes,
      isValid
    } = this.state;
    const vehicleGroup = {
      make,
      variant,
      dealerCode,
      metaVehicleScope,
      metaVehicleFilterId
    };
    // const hasErrors = hasErrorStrings(errors);
    // const interval = {
    //   make, // in
    //   variant, // in
    //   dealerCode, // in
    //   intervalName: "", // in
    //   intervalId
    // };

    return (
      <React.Fragment>
        <div className="xmm-bulk-form-bar">
          <Button
            htmlId="ApplyBulkEditAction"
            buttonStyle="primary"
            disabled={!isValid}
            onClick={this.handleApply}
            className="xmm-bulkedit-btn float-right"
          >
            {xlate("xmm.portal.bulkedit.apply_btn")}
          </Button>
        </div>
        <Card id="rulesBulkEditForm" autoComplete="off">
          <div className="bulk-edit-form">
            <div className="xmm-bulk-form-row selector-container">
              <span className="text-right">
                {xlate("xmm.portal.common.menutype")}
              </span>
              <SearchableSelect
                displayLabel={false}
                displayPlaceholder={true}
                placeholder={xlate("xmm.portal.common.select_label")}
                className="xmm-scrollable-select"
                htmlId="menuTypeId"
                label={xlate("xmm.portal.common.menutype")}
                name="menuTypeId"
                enableMultiSelect={false}
                maxHeight={150}
                onBlur={this.onMenuTypeBlur}
                onChange={this.onMenuTypeChange}
                options={menuTypes}
                value={menuTypeId}
                layout="horizontal"
                hasError={!!errors.menuTypeId}
                errorMessage={errors.menuTypeId}
                // error={errors.menuTypeId}
                disabled={!toggleStatus.menuTypeId}
              />
              <ToggleSwitch
                htmlId="sourceToggle"
                name="menuTypeId-chk"
                onClick={this.onToggleClick}
              />
            </div>
            <div className="xmm-bulk-form-row">
              <span className="text-right">
                {xlate("xmm.portal.common.interval")}
              </span>
              <IntervalSelector
                disabled={!toggleStatus.intervalId}
                context={this.context}
                data={interval}
                includeWildCardIntervals={true}
                error={errors.intervalId}
                onBlur={this.onIntervalBlur}
                onChange={this.onIntervalChange}
              />
              <ToggleSwitch
                htmlId="intervalToggle"
                name="intervalId-chk"
                onClick={this.onToggleClick}
              />
            </div>
            <div className="xmm-bulk-form-row">
              <span className="text-right">
                {xlate("xmm.portal.common.vehiclegroup")}
              </span>
              <div>
                <VehicleGroupSelector
                  disabled={!toggleStatus.metaVehicleFilterId}
                  data={vehicleGroup}
                  error={errors.metaVehicleFilterId}
                  context={this.context}
                  hideAllVehicles={false}
                  autoLoad={true}
                  onBlur={this.onVehicleGroupBlur}
                  onChange={this.onVehicleGroupChange}
                />
              </div>
              <ToggleSwitch
                htmlId="vehicalGroupToggle"
                name="metaVehicleFilterId-chk"
                onClick={this.onToggleClick}
              />
            </div>
            <div className="xmm-dealer-menu-bulk-edit-end-spacer">
              <Col xs={6} md={6}>
                <span>&nbsp;</span>
              </Col>
            </div>
          </div>
        </Card>
      </React.Fragment>
    );
  }
}

BulkEditPane.propTypes = {
  selectionlist: PropTypes.array,
  closeSlider: PropTypes.func,
  updateGridAfterBulkEdit: PropTypes.func,
  updateStatusBox: PropTypes.func
};
