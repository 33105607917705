/* eslint-disable no-console */
import React from "react";
import Button from "@cx/ui/Button";
import Row from "@cx/ui/Row";
import Col from "@cx/ui/Col";
import ServiceSearchModule from "../../../../../serviceSearch/ServiceSearchModule";
import PreviewContext from "../../preview-context";
import { makeSecureRestApi } from "../../../../../api/xmmAxios";
import { toast } from "@cx/ui/Toast";
import { getPartsAndLaborAndTotal } from "../../../../../previewGrids/components/utils/helper";
import ResultsPane from "../../../../../previewGrids/components/ResultsPane";
import generateApiToken from "../../../../../commonUtil/utils/apiTokenValidator";

class GlobalOpsPreview extends React.Component {
  constructor(props, context) {
    super(props, context);

    // this.filtersPaneRef = React.createRef();
    this.resultsPaneRef = React.createRef();

    const { dealerCode, localeStrings, preview, previewState } =
      context.appContext;

    const { search } = preview;
    const {
      make,
      year,
      model,
      trim,
      engineType,
      engineSize,
      driveType,
      transmissionType,
      vin,
      metaVehicleId,
      matchVinFlag
    } = search;
    const theVin = !vin || !matchVinFlag ? "UNKNOWN" : vin;

    this.initializeLocaleValues(localeStrings);
    console.log("inspect search{}", context.appContext.preview.search);

    previewState.showMask = false;
    previewState.showFilter = true;
    previewState.decodeVinOk = true;
    // previewState.acesAttributes [],
    // undecodedAttributes: previewState.undecodedAttributes,
    previewState.vin = theVin;
    previewState.dealerCode = dealerCode;
    previewState.dealerFlag = "1";
    // mileage: previewState.mileage || "",
    previewState.vehicleDesc = {
      year,
      make,
      model,
      trim,
      engineType,
      engineSize,
      driveType,
      transmissionType,
      metaVehicleId
    };

    this.state = {
      errors: {},
      viewMode: SERVICE_SEARCH,
      operationList: [],
      synonymsList: [],
      topServices: [],
      dealerCode,
      vin: theVin,
      vehicleDesc: previewState.vehicleDesc,
      globalOpsPreviewApiMap: {
        getOperationDetail: `/ops/proxyapi/catalogadminproxy/opsadmin/operations/getOperationDetail/make/${make}/vin/${theVin}`,
        getPartsPricingAndInventory: `/ops/proxyapi/catalogadminproxy/opsadmin/operations/getPartsPricingAndInventory/make/${make}/dealerCode/${dealerCode}`,
        uniqueLabels: `/ops/rest-db/getValues/uniqueLabels`,
        globalOpsWithLabels: `/ops/rest-db/getValues/globalOpsWithLabels`
      }
    };
  }
  componentDidMount() {
    this.getTopServices();
    this.getCategorySynonyms();
    this.getAllOperations();
  }
  static contextType = PreviewContext;

  initializeLocaleValues(localeStrings) {
    this.vinLabel = localeStrings["xmm.portal.preview.vehicle.vin_lbl"];
    this.previewLabel = localeStrings["xmm.portal.nav.preview"];
    this.categoryLabel = localeStrings["xmm.portal.common.category_lbl"];
    this.noRecordFound = localeStrings["xmm.portal.common.no_records_msg"];
    this.invalidVin =
      localeStrings["xmm.portal.preview.vehicle.inspect.invalid_vin"];
    this.allCategoriesLabel = localeStrings["xmm.portal.common.all_categories"];

    this.inspectPreviewUnpublishedLabel =
      localeStrings["xmm.portal.preview.vehicle.inspect_in_unpublished"];
    this.inspectPreviewPublishedNoVin =
      localeStrings["xmm.portal.preview.vehicle.inspect_in_published"];
  }

  getTopServices() {
    // const { preview } = this.context.appContext;
    // const { topServicesMap } = preview;
    const { dealerCode, vehicleDesc } = this.state;
    const { make, metaVehicleId, vin } = vehicleDesc;
    // if (topServicesMap && topServicesMap[make]) {
    //   const topServices = topServicesMap[make];
    //   this.setState({ topServices });
    //   return;
    // }
    const restUrl = `/ops/proxyapi/catalogadminproxy/opsadmin/topServices/${dealerCode}/make/${make}`;
    const params = { metaVehicleId, vin };

    makeSecureRestApi(
      {
        url: restUrl,
        method: "get",
        params
        // skipAccessToken: true
      },
      response => {
        const { operations, success } = response;
        if (success) {
          if (operations && Array.isArray(operations)) {
            // topServicesMap[make] = operations;
            this.setState({ topServices: operations });
          }
        } else {
          toast.error("Error in getting category synonyms.");
        }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error while fetching service category synonyms.";
        toast.error(msg, {
          closeOnClick: true
        });
      }
    );
  }

  getCategorySynonyms() {
    const { preview } = this.context.appContext;
    const { synonymsList } = preview;
    if (synonymsList && synonymsList.length !== 0) {
      this.setState({ synonymsList });
      return;
    }
    const restUrl =
      "/ops/proxyapi/catalogadminproxy/opsadmin/operations/getCategorySynonyms";

    makeSecureRestApi(
      {
        url: restUrl,
        method: "get"
        // skipAccessToken: true
      },
      data => {
        if (data && data.items && Array.isArray(data.items)) {
          preview.synonymsList = data.items;
          this.setState({ synonymsList: data.items });
        }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error while fetching service category group mappings.";
        toast.error(msg, {
          closeOnClick: true
        });
      }
    );
  }

  getAllOperations() {
    const { dealerCode, vehicleDesc, vin } = this.state;
    const { make, metaVehicleId } = vehicleDesc;
    // const serviceType = "GlobalRepair";
    const params = { dealerCode };
    if (!vin || vin === "UNKNOWN") {
      params.metaVehicleId = metaVehicleId;
    }

    makeSecureRestApi(
      {
        url: `/ops/proxyapi/catalogadminproxy/opsadmin/operations/findOperationsByVin/make/${make}/vin/${vin}`,
        method: "get",
        params
        // skipAccessToken: true
      },
      response => {
        const { success, operations, message } = response;
        if (success && message) {
          toast.info(message);
        }
        if (success) {
          const categoryIdToServiceListMap = {};
          operations.forEach(op => {
            const { operationName, categoryId } = op;
            op.label = `${operationName}`;
            op.value = makeOperationId(op); // `${operationId}:${operationSource}`;
            if (categoryId) {
              const list = categoryIdToServiceListMap[categoryId];
              if (list) {
                list.push(op);
              } else {
                categoryIdToServiceListMap[categoryId] = [op];
              }
            }
          });
          operations.sort((a, b) => {
            return a.operationName > b.operationName ? 1 : -1;
          });
          this.setState({
            // dealerCode,
            viewMode: SERVICE_SEARCH,
            showFilter: true,
            showMask: false,
            operationList: operations,
            // selectableOperations: [...operations]
            msgBox: "",
            partsAndLabor: null
            // errors: { ...errors }
          });
        } else {
          this.setState({
            // dealerCode,
            showFilter: true,
            showMask: false,
            operationList: [],
            partsAndLabor: null,
            msgBox: "No operations for this VIN"
            // errors: { ...errors }
          });
        }
      },
      error => {
        // let { message } = error;
        const { response } = error;
        const { success, message } = response;
        if (!success && message) {
          const { errors } = this.state;
          errors.dealerCode = message;
          this.setState({
            // dealerCode,
            showFilter: true,
            operationList: [],
            partsAndLabor: null,
            msgBox: message,
            errors: { ...errors }
          });
        }
        // this.updateMask(false);
      }
    );
  }

  selectOperation(selectedOperation, callback) {
    const { operationId, operationSource } = selectedOperation;
    const { dealerCode, vin, vehicleDesc } = this.state;
    let { make } = vehicleDesc;
    if (!make) {
      make = "ANY";
    }
    const apiVersion = 2;
    const params = { dealerCode, operationId, operationSource, apiVersion };
    if (!dealerCode) {
      delete params.dealerCode;
    }
    if (!vin || vin === "UNKNOWN") {
      params.metaVehicleId = vehicleDesc.metaVehicleId;
    }

    // // reset total and price overrides on selecting an operation
    // const laborPartsTotal = {
    //   isPublishedCatalog: false,
    //   laborRate: 100,
    //   laborTime: 0,
    //   partsTotal: 0,
    //   laborTotal: 0,
    //   total: 0
    // };

    // this.updateLaborPartsTotal(laborPartsTotal, {});

    // clear the selectedVehicleAttributeMap
    const partsAndLabor = {
      operation: null,
      labors: [],
      laborsColumns: [],
      parts: [],
      partsColumns: [],
      selectedVehicleAttributeMap: {}
    };
    const viewMode = SERVICE_DETAIL;
    this.setState({ partsAndLabor, selectedOperation, viewMode });
    // const { version } = this.props;
    // if (!isNaN(version)) {
    //   params.version = version;
    // }

    const request = {
      apiKey: "getOperationDetail",
      url: `/ops/proxyapi/catalogadminproxy/opsadmin/operations/getOperationDetail/make/${make}/vin/${vin}`,
      method: "get",
      params
    };
    // this.updateMask(true);
    makeSecureRestApi(
      request,
      response => {
        const { statusCode } = response;
        if (statusCode && statusCode !== 200) {
          const { errors } = this.state;
          errors.operations = "Internal error occurred.";
          this.setState({
            showMask: false,
            showFilter: true,
            errors: { ...errors }
          });
          // toast.error("Internal servrer error occurred.");
          return;
        }
        const { partsAndLabor, laborPartsTotal, priceOverrides } =
          getPartsAndLaborAndTotal(selectedOperation, response);

        const params = {
          make,
          vin,
          appSource: "CatalogManagerPreview",
          dealerCode,
          operationId,
          operationSource,
          payType: "C", // Inspect can pass a value
          serviceType: "", // Inspect can pass a value
          partsAndLabor
        };

        this.resultsPaneRef &&
          this.resultsPaneRef.current &&
          this.resultsPaneRef.current.getPartsPricingAndInventory(
            this.serviceApiCallback,
            params
          );
        // }

        this.setState(
          {
            viewMode: SERVICE_DETAIL,
            showMask: false,
            selectedOperation,
            partsAndLabor,
            selectedLabors: [],
            selectedParts: [],
            laborPartsTotal,
            priceOverrides
          }
          // ,
          // () => {
          //   this.props.setLaborPartsTotal(laborPartsTotal, priceOverrides);
          // }
        );

        // this.updateMask(false);
        callback(selectedOperation);
      },
      error => {
        // let { message } = error;
        const { response } = error;
        if (!response) {
          const { errors } = this.state;
          errors.operations = error.message;
          this.setState({
            showFilter: true,
            errors: { ...errors }
          });
        } else {
          const { success, message } = response.data;
          if (!success && message) {
            const { errors } = this.state;
            errors.dealerCode = message;
            this.setState({
              showFilter: true,
              errors: { ...errors }
            });
          }
        }
        callback({});
        // this.updateMask(false);
      }
    );
  }

  serviceApiCallback = (data, successCallback, errorCallback) => {
    const { dealerCode, vehicleDesc } = this.state;
    const { make } = vehicleDesc;
    const apiToken = generateApiToken();
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Api-Token": apiToken // "RGF2aWQgQ2hpIExlZQ==A"
    };

    const request = {
      headers,
      apiKey: "getPartsPricingAndInventory", // used by Catalog Admin and Catalog Manager only (not needed by inspect)
      url: `/ops/proxyapi/catalogadminproxy/opsadmin/operations/getPartsPricingAndInventory/make/${make}/dealerCode/${dealerCode}`,
      method: "post",
      data
    };
    makeSecureRestApi(
      request,
      response => {
        successCallback(response);
      },
      error => {
        errorCallback(error);
      }
    );
  };

  globalOpsPreviewApi = (request, resultHandler, errorHandler) => {
    const { globalOpsPreviewApiMap } = this.state;
    const url = globalOpsPreviewApiMap[request.apiKey];
    if (url) {
      request.url = url;
      if (request.apiKey === "getPartsPricingAndInventory" && request.data) {
        request.data.appSource = "CatalogManagerPreview";
      }
    }
    makeSecureRestApi(request, resultHandler, errorHandler);
  };

  resetGlobalOpsPreview = () => {
    this.filtersPaneRef &&
      this.filtersPaneRef.current &&
      this.filtersPaneRef.current.resetGlobalOpsPreview();
  };

  render() {
    const {
      viewMode,
      dealerCode,
      selectedOperation,
      operationList,
      synonymsList,
      topServices,
      partsAndLabor
    } = this.state;

    if (
      !(
        operationList &&
        synonymsList &&
        topServices &&
        operationList.length !== 0 &&
        synonymsList.length !== 0 &&
        topServices.length !== 0
      )
    ) {
      return "";
    }

    const serviceDetailContent =
      viewMode === SERVICE_DETAIL ? (
        <div>
          <Row>
            <Col xs={12} md={12}>
              <div className="content-header">
                <div className="xmm-main-title">
                  <h4>{selectedOperation.operationName}</h4>
                </div>
                <div className="xmm-form-header">
                  <Button
                    htmlId="ChangeServiceLink"
                    className="float-right"
                    buttonStyle="link"
                    onClick={() => {
                      this.setState({ viewMode: SERVICE_SEARCH });
                    }}
                  >
                    Change service
                    <span className="xmm-link-caret" />
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <ResultsPane
                ref={this.resultsPaneRef}
                // selectedLabors={selectedLabors}
                // selectedParts={selectedParts}
                partsAndLabor={partsAndLabor}
                selectGridRow={() => {}}
              />
            </Col>
          </Row>
        </div>
      ) : (
        ""
      );

    const serviceSearchClass = viewMode === SERVICE_SEARCH ? "" : "hide";
    const serviceSearchContent = (
      <div className={serviceSearchClass}>
        <ServiceSearchModule
          // @required props
          locale="en_US"
          synonymsList={synonymsList}
          vehicle={null}
          config={{
            isDebug: false,
            isDealerTireEnabled: false,
            webKey: "",
            dealer: {
              dealerCode
            },
            schemaName: "",
            user: {
              userId: "",
              userName: ""
            },
            customOperation: false,
            quickFilterAccess: {
              topServices: true,
              menu: false,
              diagnosis: false,
              declined: false,
              recall: false,
              tires: false
            },
            editModuleAccess: false,
            showPrice: true,
            showOpcode: true
          }}
          // data object props
          globalOperations={operationList}
          topServices={topServices}
          servicePoints={[]}
          declinedAndRecallServices={{}}
          // module react element as prop
          EditServiceModule={null}
          // @optional props
          quoteServices={[]}
          makeVariantMap={null}
          dealerTireParams={null}
          // callback restAPI handlers
          onGetOperationDetails={(operation, callback) => {
            console.log("onGetOperationDetails", operation);
            this.selectOperation(operation, callback);
          }}
          onSelectedGlobalOpsService={(operation, callback) => {
            console.log("onSelectedGlobalOpsService", operation);
            // this.selectOperation(operation, callback);
          }}
        />
      </div>
    );

    return (
      <React.Fragment>
        {serviceDetailContent}
        {serviceSearchContent}
      </React.Fragment>
    );
  }
}

export default GlobalOpsPreview;

const SERVICE_SEARCH = "ServiceSearch";
const SERVICE_DETAIL = "ServiceDetail";

function makeOperationId(op) {
  const { operationId, operationSource } = op;
  return `${operationId}:${operationSource}`;
}
