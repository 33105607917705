/* eslint-disable no-console */
import React, { Component } from "react";
import AccordionGroup from "@cx/ui/AccordionGroup";
import Row from "@cx/ui/Row";
import Col from "@cx/ui/Col";
import ButtonToolbar from "react-bootstrap/lib/ButtonToolbar";
import Button from "@cx/ui/Button";
import { AgGridReact } from "ag-grid-react";
import { AppContext } from "../../../../components/app-context";
import CustomLoadingOverlay from "../../../../commonUtil/components/loadingmask/CustomLoadingOverlay";
import { toEmptyStringIfUndefined } from "../../../../commonUtil/utils/string";
import { isArrayExist } from "../../../../commonUtil/utils/object";
import { applyCustomKeyNavigation } from "../../../../commonUtil/utils/keyNavigation";
import { makeSecureRestApi } from "../../../../api/xmmAxios";
import { YesNoDefault, DaysMap } from "../../../../constants/ModuleConstants";
import { toast } from "@cx/ui/Toast";
import * as gtmEvents from "../../../utils/gtag-eventlist";
import { loadAgGridLocale } from "../../../../i18n/LocaleSender";
import { xlate } from "../../../../commonUtil/i18n/locales";

class AutoPublish extends Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);
    // Bind functions in constructor
    this.getRowNodeId = this.getRowNodeId.bind(this);
    this.handleColumnResized = this.handleColumnResized.bind(this);
    this.onCellClickedEvent = this.onCellClickedEvent.bind(this);
    this.onCellValueChanged = this.onCellValueChanged.bind(this);

    this.refreshGrid = this.refreshGrid.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.saveHandler = this.saveHandler.bind(this);
    this.onFirstDataRendered = this.onFirstDataRendered.bind(this);

    const localeStrings = context.localeStrings;
    const adminEmail = !context.dealer.catalogAdminEmail
      ? ""
      : context.dealer.catalogAdminEmail;
    const gridOptions = {
      // other state props
      catalogs: null,
      adminEmail,
      disableSave: true,
      // ag-grid props
      columnDefs: this.getColumnList(localeStrings),
      defaultColDef: {
        sortable: true,
        resizable: true,
        editable: false,
        enableRowGroup: false,
        sortingOrder: ["asc", "desc", null],
        autoHeight: true,
        filter: true,
        rowGroup: false,
        suppressKeyboardEvent: applyCustomKeyNavigation
      },
      multiSortKey: "ctrl",
      rowData: null,
      isRowSelectable(rowNode) {
        return true; // to see checkbox
      },
      components: {},
      frameworkComponents: {
        customLoadingOverlay: CustomLoadingOverlay,
        customNoRowsOverlay: CustomLoadingOverlay
      },
      loadingOverlayComponent: "customLoadingOverlay",
      loadingOverlayComponentParams: {
        loadingMessage: xlate("xmm.portal.common.loading_msg"),
        isLoading: true,
        noRows: false
      },
      noRowsOverlayComponent: "customNoRowsOverlay",
      noRowsOverlayComponentParams: {
        loadingMessage: xlate("xmm.portal.common.no_records_msg"),
        isLoading: false,
        noRows: true
      },
      columnTypes: {
        actionColumn: {
          filter: false,
          editable: false,
          sortable: false,
          suppressMenu: true,
          enableRowGroup: false
        },
        nonEditableColumn: { editable: false }
      },
      // Note: Set locale strings in this localeText {} for ag-grid controls
      localeText: loadAgGridLocale(localeStrings),
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left"
          },
          {
            statusPanel: "agFilteredRowCountComponent"
          }
        ]
      },
      // true - use browser default tooltip instead of ag-grid tooltip
      enableBrowserTooltips: true,
      onColumnMoved: this.refreshGrid,
      onColumnPinned: this.refreshGrid,
      sideBar: false
    };
    this.state = gridOptions;
  }
  /**
   * Add event listener
   */
  componentDidMount() {
    gtmEvents.trackGtagPageview("/publish/auto-publish");
    // this.loadDealerCatalogs();
  }
  onFirstDataRendered(params) {
    this.sizeToFit();
  }
  handleColumnResized = () => {
    this.gridApi.resetRowHeights();
  };
  sizeToFit() {
    this.gridApi && this.gridApi.sizeColumnsToFit();
  }
  onGridReady = params => {
    this.gridParams = params;
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.loadAutoPublish();
    this.gridApi.closeToolPanel();
  };
  /* IMP - this function required for CRUD operations, to get RowNode */
  getRowNodeId(data) {
    return data.make;
  }
  // Merge publish records with supported makes
  mergeDealerMakes(datalist, adminEmail) {
    const final = [];
    const rawMakes = this.context.makelist;
    const baseRecord = {
      ackChecked: "1",
      enabled: 0, // disable state
      waitNumDays: "1",
      dealerAutoPublishId: "",
      emailAddress: adminEmail,
      modUser: this.context.user.userName ? this.context.user.userName : "",
      createDate: "",
      dealerCode: "",
      make: "",
      variant: "",
      modTime: "",
      uri: {}
    };
    if (rawMakes.length > 0) {
      rawMakes.forEach(function (makeobj, idx) {
        let cloneObj = Object.assign({}, baseRecord);
        datalist.forEach(function (record, index) {
          if (record.dealerAutoPublishId && record.make === makeobj.make) {
            cloneObj = Object.assign({}, record);
            // console.log(record.dealerAutoPublishId);
            cloneObj.waitNumDays = record.waitNumDays.toString();
            cloneObj.ackChecked = record.ackChecked.toString();
          }
        });
        if (!cloneObj.dealerAutoPublishId) {
          // console.log("rest make", makeobj.make);
          cloneObj = Object.assign({}, baseRecord);
          cloneObj.make = makeobj.make;
          cloneObj.variant = makeobj.variant;
          cloneObj.dealerCode = makeobj.dealerCode;
        }
        final.push(cloneObj);
      });
    }
    return final;
  }
  /* Call to get auto publish records for a dealer */
  loadAutoPublish() {
    const { dealerCode } = this.context.dealer;
    this.gridApi && this.gridApi.showLoadingOverlay();
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };
    makeSecureRestApi(
      {
        url: "/ops/proxyapi/ddsproxy/rest/table/dealerAutoPublish",
        method: "get",
        data: {},
        params: { dealerCode },
        headers
      },
      response => {
        if (response) {
          this.updateState(response);
        }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error while fetching data.Please try again later.";
        toast.error(msg);
      }
    );
  }
  // Deprecated - Not used yet
  /*
  transformData(datalist) {
    const newlist = [];
    if (datalist) {
      if (isArrayExist(datalist) && datalist.length > 0) {
        datalist.forEach(function(obj, index) {
          if (obj.dealerAutoPublishId) {
            const cloneObj = Object.assign({}, obj);
            console.log(cloneObj);
            cloneObj.waitNumDays = obj.waitNumDays.toString();
            cloneObj.ackChecked = obj.ackChecked.toString();
            newlist.push(cloneObj);
          }
        });
        console.log("new list", newlist);
      }
    }
    return newlist;
  }
  */
  updateState(data) {
    if (data) {
      const { adminEmail } = this.state;
      let datalist = [];
      const emailAdmin = !adminEmail ? "" : adminEmail;
      if (!isArrayExist(data) && typeof data === "object") {
        datalist.push(data);
      } else if (isArrayExist(data) && data.length > 0) {
        datalist = data;
        if (data[0] && data[0].hasOwnProperty("emailAddress")) {
          // emailAdmin = data[0].emailAddress;
        }
      }
      const final = this.mergeDealerMakes(datalist, emailAdmin);
      if (isArrayExist(final) && final.length === 0) {
        // show 'no rows' overlay
        this.gridApi && this.gridApi.showNoRowsOverlay();
      } else {
        // clear all overlays
        this.gridApi && this.gridApi.hideOverlay();
      }
      // console.log("final", final);
      this.setState(
        {
          catalogs: final,
          adminEmail,
          isLoaded: false
        },
        () => {
          this.applySortConfig();
          this.sizeToFit();
        }
      );
    }
  }
  applySortConfig() {
    const defaultSortModel = [
      {
        colId: "make",
        sort: "asc"
      }
    ];
    // this.gridApi && this.gridApi.setSortModel(defaultSortModel);
    this.assignColumnState(defaultSortModel);
  }
  assignColumnState = defaultSortModel => {
    this.gridColumnApi &&
      this.gridColumnApi.applyColumnState({
        state: defaultSortModel,
        defaultState: {
          // important to say 'null' as undefined means 'do nothing'
          sort: null
        }
      });
  };
  /* This method can be called to refresh single or multi rows */
  refreshGrid(params) {
    params.api.refreshCells({ force: true });
  }
  /* "cellClicked" event handler fired when clicked on cell renderer  */
  onCellClickedEvent(params) {
    const field = params.colDef.field;
    if (field === "enabled") {
      // console.log("enabled checked row data", params.value, params.data);
    }
  }
  // Auto-save: This event fired when any cell editor changes value from its default editing
  onCellValueChanged(params) {
    // console.log("onCellValueChanged", params.oldValue, params.newValue);
    if (
      toEmptyStringIfUndefined(params.oldValue) !==
      toEmptyStringIfUndefined(params.newValue)
    ) {
      // const record = params.data;
      const field = params.colDef.field;
      // console.log("onCellValueChanged: ", field, record);
      this.onSaveCellEdit(params, field);
    }
  }
  /* Return payload for single-row */
  getCellEditPayload(params, isNew) {
    const record = params.data;
    const { user } = this.context;
    const payload = {
      dealerAutoPublishId: "",
      make: record.make,
      variant: record.variant,
      dealerCode: record.dealerCode,
      waitNumDays: record.waitNumDays,
      // replace with catalogAdminEmail value for the dealer.
      emailAddress: this.state.adminEmail,
      enabled: record.enabled,
      modUser: user.userName,
      ackChecked: 1 // always ack to add/update case
    };
    if (!isNew) {
      payload.dealerAutoPublishId = record.dealerAutoPublishId;
    }
    return payload;
  }

  // call this method to auto-save upon onCellValueChanged, onCellClickedEvent
  onSaveCellEdit(params, field) {
    const record = params.data;
    let isNew = true;
    let restUrl = "/ops/proxyapi/ddsproxy/rest/table/dealerAutoPublish";
    // Update case - use POST call with param (_method=put)
    if (record.dealerAutoPublishId && record.dealerAutoPublishId > 0) {
      isNew = false;
      restUrl =
        "/ops/proxyapi/ddsproxy/rest/table/dealerAutoPublish/" +
        record.dealerAutoPublishId +
        "?_method=put";
    }
    const payload = this.getCellEditPayload(params, isNew);
    // console.log(restUrl, payload);
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };
    makeSecureRestApi(
      {
        url: restUrl,
        method: "post",
        data: payload,
        params: {},
        headers
      },
      data => {
        if (data) {
          let response = null;
          if (!isArrayExist(data) && typeof data === "object") {
            response = data.dealerAutoPublish;
            // console.log("AutoPublish saved", response);
            if (this.gridApi) {
              const rowNode = this.gridApi.getRowNode(record.make);
              rowNode.setData(response);
              const gridParams = {
                force: true,
                rowNodes: [rowNode]
              };
              this.callRefreshAfterMillis(gridParams, this.gridApi);
            }
          }
        }
        toast.success(record.make + " changes are saved.", {
          closeOnClick: true
        });
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error saving your changes.";
        toast.error(msg, {
          closeOnClick: true
        });
      }
    );
    if (field === "enabled") {
      gtmEvents.gtmTrackEvent("xmm.autopublish.enabled_cell_edit");
    } else if (field === "waitNumDays") {
      gtmEvents.gtmTrackEvent("xmm.autopublish.delay_publish_edit");
    }
  }

  callRefreshAfterMillis(params, gridApi) {
    setTimeout(function () {
      gridApi.refreshCells(params);
    }, 200);
  }

  // Deprecated - This call doesn't work for new records to save
  saveHandler = () => {
    const { localeStrings } = this.context;
    const payload = this.getAllRowsPayload();
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };
    const restEndPoint =
      "ops/proxyapi/ddsproxy/rest/table/dealerAutoPublish?_method=put";
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "post",
        data: payload,
        params: {},
        headers
      },
      data => {
        // NOTE: we get blank response on success;

        toast.success(localeStrings["xmm.portal.common.successful_save"], {
          closeOnClick: true
        });
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error saving your changes.";
        toast.error(msg, {
          closeOnClick: true
        });
      }
    );
  };
  /* Returns data for all rows from grid */
  getAllRowsPayload() {
    const payload = [];
    if (this.gridApi) {
      this.gridApi.forEachNode(function (rowNode, index) {
        const row = rowNode.data;
        // console.log("Row", row);
        const record = {
          dealerAutoPublishId: row.dealerAutoPublishId,
          make: row.make,
          dealerCode: row.dealerCode,
          variant: row.variant,
          waitNumDays: row.waitNumDays,
          emailAddress: row.emailAddress,
          enabled: row.enabled,
          modUser: row.modUser,
          ackChecked: 1
        };
        payload.push(record);
      });
    }
    // console.log("payload", payload);
    return payload;
  }

  /* Action event to clear column filters */
  clearFilters() {
    if (this.gridApi) {
      const filterModel = this.gridApi.getFilterModel();
      if (filterModel) {
        this.gridApi.setFilterModel(null);
      }
      this.gridApi.onFilterChanged();
    }
  }
  getColumnList(localeStrings) {
    const ctrl = this;
    const baseCols = [
      {
        headerName: localeStrings["xmm.portal.grid.make"],
        headerClass: "ag-text-header",
        field: "make",
        sortingOrder: ["asc", "desc"],
        filter: "agSetColumnFilter",
        filterParams: {
          buttons: ["clear"]
        },
        width: 100,
        minWidth: 100
      },
      {
        headerName: localeStrings["xmm.portal.common.enabled"],
        field: "enabled",
        cellRenderer(params) {
          const input = document.createElement("input");
          input.type = "checkbox";
          input.className = "form-checkbox";
          input.checked = params.value;
          const div = document.createElement("div");
          const span = document.createElement("span");
          span.className = "xmm-checkmark";
          div.appendChild(input);
          div.appendChild(span);
          div.className = "xmm-checkbox-container";
          input.addEventListener("click", event => {
            params.value = !params.value;
            const valueFormatted =
              params.value === true || params.value === 1 ? 1 : 0;
            params.value = valueFormatted;
            params.node.data.enabled = valueFormatted;
            // Note: Trigger save only when checkbox clicked but not on cellclick
            ctrl.onSaveCellEdit(params, params.colDef.field);
          });
          return div;
        },
        cellRendererParams: {
          field: "enabled"
        },
        editable: false,
        filter: false,
        suppressSizeToFit: true,
        width: 100,
        minWidth: 100,
        maxWidth: 100
      },
      {
        headerName: "PublishId",
        field: "dealerAutoPublishId",
        editable: false,
        filter: false,
        hide: true,
        maxWidth: 80,
        width: 80,
        minWidth: 80
      },
      {
        headerName: localeStrings["xmm.portal.auto_publish.grid.delay_publish"],
        headerClass: "ag-text-header",
        field: "waitNumDays",
        cellClass: "editable-caret-cell xmm-blue-cell",
        valueFormatter: waitDayFormatter,
        cellEditorSelector(params) {
          if (params.data.enabled === 1) {
            return {
              component: "agRichSelectCellEditor"
            };
          } else if (
            params.data.enabled === null ||
            params.data.enabled === 0
          ) {
            params.api.stopEditing();
            return null;
          } else {
            params.api.stopEditing();
            return null;
          }
        },
        cellEditorParams: {
          values: extractValues(DaysMap),
          cellHeight: 25
        },
        refData: { DaysMap },
        sortingOrder: ["asc", "desc"],
        suppressSizeToFit: true,
        filter: false,
        editable: true,
        maxWidth: 200,
        width: 200,
        minWidth: 200
      },
      {
        headerName:
          localeStrings["xmm.portal.auto_publish.grid.send_notification"],
        headerClass: "ag-text-header",
        field: "ackChecked",
        hide: true,
        // valueSetter: notifySetter,
        valueFormatter: notifyFormatter,
        cellClass: "editable-caret-cell xmm-blue-cell",
        suppressSizeToFit: true,
        maxWidth: 200,
        width: 200,
        minWidth: 200,
        cellEditorSelector(params) {
          if (params.data.enabled === 1) {
            return {
              component: "agRichSelectCellEditor"
            };
          } else if (
            params.data.enabled === null ||
            params.data.enabled === 0
          ) {
            params.api.stopEditing();
            return null;
          } else {
            params.api.stopEditing();
            return null;
          }
        },
        cellEditorParams: {
          cellHeight: 25,
          values: extractValues(YesNoDefault)
        },
        cellRenderer(params) {
          let val = toEmptyStringIfUndefined(params.value);
          if (val) {
            val = typeof val === "number" ? val.toString() : val;
            // console.log("email render", val, typeof val);
            return val === "1" ? "YES" : "NO";
          } else {
            return "-";
          }
        },
        refData: { YesNoDefault },
        filter: false,
        editable: true
      }
    ];
    return baseCols;
  }

  render() {
    const gridWidget = (
      <div
        id="autopublishGrid"
        className="xmm-publish-grid-container ag-theme-balham"
      >
        <AgGridReact
          localeText={this.state.localeText}
          columnDefs={this.state.columnDefs}
          defaultColDef={this.state.defaultColDef}
          suppressRowClickSelection={true}
          suppressMenuHide={false}
          // suppressContextMenu={true}
          rowData={this.state.catalogs}
          rowSelection={"multiple"}
          animateRows={true}
          onGridReady={this.onGridReady}
          frameworkComponents={this.state.frameworkComponents}
          loadingOverlayComponent={this.state.loadingOverlayComponent}
          loadingOverlayComponentParams={
            this.state.loadingOverlayComponentParams
          }
          noRowsOverlayComponent={this.state.noRowsOverlayComponent}
          noRowsOverlayComponentParams={this.state.noRowsOverlayComponentParams}
          statusBar={this.state.statusBar}
          components={this.state.components}
          singleClickEdit={true}
          stopEditingWhenCellsLoseFocus={true}
          onColumnResized={this.handleColumnResized}
          onCellValueChanged={this.onCellValueChanged}
          onCellClicked={this.onCellClickedEvent}
          getRowNodeId={this.getRowNodeId}
          sideBar={this.state.sideBar}
          columnTypes={this.state.columnTypes}
          multiSortKey={this.state.multiSortKey}
          enableRangeSelection={false}
          enableCellTextSelection={false}
          enableBrowserTooltips={true}
          // enterMovesDownAfterEdit={true}
          // enterMovesDown={true}
          rowHeight={50}
          onFirstDataRendered={this.onFirstDataRendered}
        />
      </div>
    );

    const footerContent = (
      <Row>
        <Col xs={12} md={12}>
          <ButtonToolbar>
            <Button
              htmlId="SavePublishBtn"
              className="hide"
              disabled={this.state.disableSave}
              buttonStyle="primary"
              onClick={this.saveHandler}
            >
              {xlate("xmm.portal.common.save_button")}
            </Button>
          </ButtonToolbar>
        </Col>
        <Col xs={12} md={12}>
          <div className="xmm-help-text">
            {xlate("xmm.portal.auto_publish.disclaimer_txt").replace(
              "%1",
              this.state.adminEmail
            )}
          </div>
        </Col>
      </Row>
    );

    const HelpContent = (
      <div className="template-content-wrapper content-wrapper__content-only">
        <AccordionGroup.Container htmlId="autoPublishAccordion" independent>
          <AccordionGroup.Item
            header={xlate("xmm.portal.auto_publish.how_it_works_lbl")}
            eventKey="1"
            htmlId="howItWorksAccordion"
          >
            <p className="xmm-modal-help-text">
              {xlate("xmm.portal.auto_publish.help_txt_1")}
            </p>
            <p className="xmm-modal-help-text">
              {xlate("xmm.portal.auto_publish.help_txt_2")}
            </p>
            <p className="xmm-modal-help-text">
              {xlate("xmm.portal.auto_publish.help_txt_3")}
            </p>
          </AccordionGroup.Item>
        </AccordionGroup.Container>
      </div>
    );
    return (
      <React.Fragment>
        <div className="xmm-tab-scroll-content">
          {HelpContent}
          {gridWidget}
          {footerContent}
        </div>
      </React.Fragment>
    );
  }
}
export default AutoPublish;

function extractValues(mappings) {
  return Object.keys(mappings);
}
/*
function notifySetter(params) {
  if (!params.data) {
    return;
  }
  const value = params.newValue;
  params.data.ackChecked = value;
}
*/
function notifyFormatter(params) {
  if (!params || !params.data) {
    return "-";
  }
  let val = toEmptyStringIfUndefined(params.value);
  if (val) {
    val = typeof val === "number" ? val.toString() : val;
    return val === "1" ? "YES" : "NO";
  } else {
    return "-";
  }
}

function waitDayFormatter(params) {
  if (!params || !params.data) {
    return "-";
  }
  const waitNumDays = toEmptyStringIfUndefined(params.value);
  const enabled = params.data && toEmptyStringIfUndefined(params.data.enabled);
  // console.log("wait format", waitNumDays, typeof enabled);
  if (enabled === "1") {
    if (waitNumDays) {
      return DaysMap[waitNumDays];
    } else {
      return "-";
    }
  } else {
    return "-";
  }
}

/* This renderer used for complex case; replaced with foramtter
function waitDayCellRenderer(params) {
  // console.log("wait render", params.value);
  let val = toEmptyStringIfUndefined(params.value);
  if (val) {
    val = typeof val === "number" ? val.toString() : val;
    const str = val === "1" ? "" : "s";
    return val + " Day" + str;
  } else {
    return "-";
  }
}
*/
