/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { isEmptyString, toEmptyStringIfUndefined } from "../utils/string";
import {
  // isPriceInput,
  isAllDigits,
  isFloatingNumberWithTenths,
  isFloatingNumberWithHundredths
} from "../utils/value";
import {
  isCopyAndPaste,
  isDeleteOrBackspace,
  isKeyPressedNumeric,
  isLeftOrRight,
  isNavKeyPressed
} from "../utils/keyNavigation";

const KEY_F2 = 113;

export default class PriceEditor extends Component {
  constructor(props) {
    super(props);

    this.inputRef = React.createRef();

    this.cancelBeforeStart =
      this.props.charPress && "1234567890".indexOf(this.props.charPress) < 0;

    this.state = this.createInitialState(props);

    this.onKeyDown = this.onKeyDown.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.inputRef.current.addEventListener("keydown", this.onKeyDown);
  }

  componentWillUnmount() {
    this.inputRef.current.removeEventListener("keydown", this.onKeyDown);
    const { parentHandle } = this.props;
    if (parentHandle) {
      parentHandle.handleSelectionChanged();
    }
  }

  createInitialState(props) {
    let startValue;
    let highlightAllOnFocus = true;
    const { allowDecimals, decimalPlaces, maxLength, maxValue } = props;

    const event = { keyCode: props.keyPress };
    if (isDeleteOrBackspace(event)) {
      // if backspace or delete pressed, we clear the cell
      startValue = "";
    } else if (props.charPress) {
      // if a letter was pressed, we start with the letter
      startValue = props.charPress;
      highlightAllOnFocus = false;
    } else {
      // otherwise we start with the current value
      startValue = props.value;
      if (props.keyPress === KEY_F2) {
        highlightAllOnFocus = false;
      }
    }

    const localState = {
      allowDecimals,
      decimalPlaces,
      maxLength,
      maxValue,
      value: toEmptyStringIfUndefined(startValue),
      highlightAllOnFocus
    };

    return localState;
  }

  afterGuiAttached() {
    // get ref from React component
    const eInput = this.inputRef.current;
    eInput.focus();
    if (this.state.highlightAllOnFocus) {
      eInput.select();

      this.setState({
        highlightAllOnFocus: false
      });
    } else {
      // when we started editing, we want the carot at the end, not the start.
      // this comes into play in two scenarios: a) when user hits F2 and b)
      // when user hits a printable character, then on IE (and only IE) the carot
      // was placed after the first character, thus 'apply' would end up as 'pplea'
      const length = eInput.value ? eInput.value.length : 0;
      if (length > 0) {
        eInput.setSelectionRange(length, length);
      }
    }
  }

  getValue() {
    return this.state.value;
  }

  isCancelBeforeStart() {
    return this.cancelBeforeStart;
  }

  // will reject the number if it greater than 1,000,000
  // not very practical, but demonstrates the method.
  isCancelAfterEnd() {
    const { keepInvalidValue } = this.props;
    return !keepInvalidValue && this.state.value > 1000000;
  }

  onKeyDown(event) {
    if (isLeftOrRight(event) || isDeleteOrBackspace(event)) {
      event.stopPropagation();
      return false;
    }

    if (
      !(
        isNavKeyPressed(event) ||
        isKeyPressedNumeric(event) ||
        isCopyAndPaste(event)
      )
    ) {
      if (event.preventDefault) {
        event.preventDefault();
        return false;
      }
    }
    return true;
  }
  handleChange(event) {
    // prevent copy and paste of non-numeric character
    // const { value } = event.target;
    // if (isEmptyString(value) || isPriceInput(value)) {
    //   this.setState({ value });
    // }
    const { value } = event.target;
    const { allowDecimals, decimalPlaces } = this.state;
    if (
      isEmptyString(value) ||
      isAllDigits(value) ||
      (allowDecimals &&
        ((decimalPlaces == 1 && isFloatingNumberWithTenths(value)) ||
          (decimalPlaces == 2 && isFloatingNumberWithHundredths(value))))
    ) {
      const { maxValue } = this.state;
      if (!maxValue || value <= maxValue) {
        this.setState({ value });
      }
    }
  }
  render() {
    return (
      <input
        ref={this.inputRef}
        maxLength={this.state.maxLength}
        value={this.state.value}
        onChange={this.handleChange}
        className="xmm-cell-editor xmm-grid-price"
      />
    );
  }
}

PriceEditor.propTypes = {
  parentHandle: PropTypes.object,
  maxLength: PropTypes.number,
  maxValue: PropTypes.number,
  allowDecimals: PropTypes.bool,
  decimalPlaces: PropTypes.number,
  // data: PropTypes.object,
  keepInvalidValue: PropTypes.bool
};

PriceEditor.defaultProps = {
  maxLength: 7,
  maxValue: 9999.99,
  allowDecimals: true,
  decimalPlaces: 2,
  keepInvalidValue: false
};
