/* eslint-disable no-console */
import React, { Component } from "react";
import { PropTypes } from "prop-types";
import AlertBox from "../../../../commonUtil/components/templates/AlertBox";
import Button from "@cx/ui/Button";
import IconMore from "@cx/ui/Icons/IconMore";
// import DropdownButton from "react-bootstrap/lib/DropdownButton";
// import DropdownMenuItem from "@cx/ui/DropdownMenuItem";
import Dropdown from "@cx/ui/Dropdown";
import { toast } from "@cx/ui/Toast";
import { sortByMake } from "../../../../commonUtil/utils/filter";
import {
  isEmptyString,
  toEmptyStringIfUndefined
} from "../../../../commonUtil/utils/string";
import { isArrayExist } from "../../../../commonUtil/utils/object";
import {
  blankValueFormatter,
  serviceKindFormatter
} from "../../../../commonUtil/utils/formatter";
import { createdByValueGetter } from "../../../../commonUtil/utils/valuegetter";
import { AgGridReact } from "ag-grid-react";
import {
  // baseAdminUrl,
  makeSecureRestApi,
  showBodyMask,
  hideBodyMask
} from "../../../../api/xmmAxios";
import { AppContext } from "../../../../components/app-context";
import { FormattedMessage } from "react-intl";
import ExternalFilters from "./content/ExternalFilters";
import ValidateOpcodeModal from "./content/ValidateOpcodeModal";
import CustomLoadingOverlay from "../../../../commonUtil/components/loadingmask/CustomLoadingOverlay";
import FindOpCodesDialog from "../../../../commonUtil/dialog/FindOpCodesDialog";
import OpcodeEditor from "../../../../commonUtil/editors/OpcodeEditor";
import StatusBox from "../../../../commonUtil/components/templates/StatusBox";
import {
  dateComparator,
  formatDateTimezone
} from "../../../../commonUtil/utils/date";
import * as gtmEvents from "../../../utils/gtag-eventlist";
import { applyCustomKeyNavigation } from "../../../../commonUtil/utils/keyNavigation";
import { loadAgGridLocale } from "../../../../i18n/LocaleSender";
import { xlate } from "../../../../commonUtil/i18n/locales";
class OpcodeValidation extends Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);
    this.childExternalFilterRef = React.createRef();
    // Bind functions in constructor
    this.getRowNodeId = this.getRowNodeId.bind(this);
    this.onCellClickedEvent = this.onCellClickedEvent.bind(this);
    this.onCellValueChanged = this.onCellValueChanged.bind(this);
    this.onFilterModified = this.onFilterModified.bind(this);
    this.showValidateOpcodeModal = this.showValidateOpcodeModal.bind(this);
    this.applyMultipleFilters = this.applyMultipleFilters.bind(this);
    this.closeOpcodeModal = this.closeOpcodeModal.bind(this);
    this.handleColumnResized = this.handleColumnResized.bind(this);
    this.transformData = this.transformData.bind(this);
    this.runStatus = this.runStatus.bind(this);
    this.refreshGrid = this.refreshGrid.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.isCancelOpcodeValidationInProgress =
      this.isCancelOpcodeValidationInProgress.bind(this);
    this.updateStatusBox = this.updateStatusBox.bind(this);
    this.onFirstDataRendered = this.onFirstDataRendered.bind(this);
    const { dealer, localeStrings } = context;
    const { commonOpsRepairsEnabled } = dealer;
    this.initializeLocaleValues();
    this.lastGloablOpcodeScoreDate = null;
    this.lastValidationDate = null;
    const gridOptions = {
      // other props
      commonOpsRepairsEnabled,
      opcodeValidationGrid: context.opcodeValidationGrid,
      showMask: false,
      allMakesValidationStatus: [],
      showModal: false,
      disableAction: false,
      disableFilters: true,
      statusType: "",
      statusMsg: "",
      statusCode: "",
      dealerCode: context.dealer.dealerCode,
      // ag-grid props
      operations: null,
      columnDefs: this.getColumnList(localeStrings),
      defaultColDef: {
        sortable: true,
        resizable: true,
        editable: false,
        // suppressMenu: true,
        enableRowGroup: false,
        sortingOrder: ["asc", "desc", null],
        // minWidth: 80,
        autoHeight: true,
        filter: true,
        rowGroup: false,
        headerComponentParams: {
          template: `
          <div class="ag-cell-label-container" role="presentation">
            <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
            <div ref="eLabel" class="ag-header-cell-label" role="presentation">
              <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
              <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
              <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>
              <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>
              <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>
            </div>
          </div>
          `
        },
        suppressKeyboardEvent: applyCustomKeyNavigation
      },
      multiSortKey: "ctrl",
      components: {
        iconCellRenderer,
        iconFilterCellRenderer,
        opcodeCellRenderer
      },
      frameworkComponents: {
        opcodeEditor: OpcodeEditor,
        customLoadingOverlay: CustomLoadingOverlay,
        customNoRowsOverlay: CustomLoadingOverlay
      },
      loadingOverlayComponent: "customLoadingOverlay",
      loadingOverlayComponentParams: {
        loadingMessage: xlate("xmm.portal.common.loading_msg"),
        isLoading: true,
        noRows: false
      },
      noRowsOverlayComponent: "customNoRowsOverlay",
      noRowsOverlayComponentParams: {
        loadingMessage: xlate("xmm.portal.common.no_records_msg"),
        isLoading: false,
        noRows: true
      },
      // Note: Get locale strings from util fun() for ag-grid controls
      localeText: loadAgGridLocale(localeStrings),
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left"
          },
          {
            statusPanel: "agFilteredRowCountComponent"
          },
          {
            statusPanel: "agSelectedRowCountComponent",
            align: "left"
          }
        ]
      },
      // true - use browser default tooltip instead of ag-grid tooltip
      enableBrowserTooltips: true,
      columnTypes: {
        nonEditableColumn: { editable: false },
        noFilterColumn: {
          width: 100,
          columnGroupShow: "open",
          filter: false
        }
      },
      onColumnMoved: this.refreshGrid,
      onColumnPinned: this.refreshGrid,
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
            toolPanelParams: {
              suppressPivots: true,
              suppressPivotMode: true,
              suppressValues: true,
              suppressRowGroups: true
            }
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
          }
        ],
        hiddenByDefault: false
      },
      // other state props
      showOpCodeModal: false,
      setOpcodeValueFunc: null,
      findOpcodeServiceId: "",
      findOpcodeInternalName: "",
      findOpcodeDmsDescription: "",
      findOpcodeOpcode: ""
    };
    this.state = gridOptions;
  }
  /**
   * Add event listeners
   * when an instance of a component is being created and inserted into the DOM
   */
  componentDidMount() {
    window.addEventListener(
      "showFindOpcodeEvent",
      this.handleShowFindOpcodeEvent,
      false
    );
  }
  /**
   * Remove event listeners
   * when a component is being removed from the DOM
   */
  componentWillUnmount() {
    this.stopTask(false);
    this.saveGridState();
    window.removeEventListener(
      "showFindOpcodeEvent",
      this.handleShowFindOpcodeEvent,
      false
    );
  }
  initializeLocaleValues(localeStrings) {
    this.refreshlbl = xlate("xmm.portal.common.refresh_button");
    this.clearFilterslbl = xlate("xmm.portal.common.clear_filters");
    this.validateOpcodelbl = xlate(
      "xmm.portal.opcode_validation_validate_opcode_lbl"
    );

    this.makelbl = xlate("xmm.portal.grid.make");
    this.createdBylbl = xlate("xmm.portal.grid.createdby");
    this.serviceKindlbl = xlate("xmm.portal.grid.servicekind");
    this.contentStatuslbl = xlate("xmm.portal.grid.content_status");
    this.xtimelbl = xlate("xmm.portal.common.xtime_label");
    this.operationNamelbl = xlate("xmm.portal.grid.operation_name");
    this.externalNamelbl = xlate("xmm.portal.operations.grid.external_name");
    this.opcodelbl = xlate("xmm.portal.grid.opcode");
    this.lastUsedlbl = xlate("xmm.portal.opcode_validation.grid.last_used");
    this.usagelbl = xlate("xmm.portal.opcode_validation.grid.usage");
    this.dmslbl = xlate("xmm.portal.common.dms_label");
    this.descriptionlbl = xlate("xmm.portal.grid.description");
    this.validateOpcodeAllMakesLabel = xlate(
      "xmm.portal.common.validate_opcode_mapping_msg"
    );
    this.validateOpcodeSingleMakeLabel = xlate(
      "xmm.portal.opcode_validation.single_make_pending_banner"
    );
    this.cancelLabel = xlate("xmm.portal.common.cancel_button");
    this.savingMsg = xlate("xmm.portal.common.saving");
    this.savedMsg = xlate("xmm.portal.common.saved");
    this.saveError = xlate("xmm.portal.errors.save_data_error");
    this.cancelSubmittedMsg = xlate("xmm.portal.msg.cancel_submitted");
    this.cancelingMsg = xlate("xmm.portal.msg.canceling");
    this.canceledMsg = xlate("xmm.portal.msg.canceled");
    this.cancelOpcdeValidationError = xlate(
      "xmm.portal.errors.cancelling_opcode_validation"
    );
  }
  /* Action to save ag-grid {column, filter, pivot, sort} to local state
   */
  saveGridState() {
    if (this.gridApi && this.gridColumnApi) {
      const { opcodeValidationGrid } = this.state;
      Object.assign(opcodeValidationGrid, {
        colState: this.gridColumnApi.getColumnState(),
        pivotState: this.gridColumnApi.isPivotMode(),
        filterState: this.gridApi.getFilterModel()
      });
      this.setState({
        opcodeValidationGrid
      });
      // console.log("save state", this.state);
      this.context.setOpcodeValidationGridState(opcodeValidationGrid);
    }
  }
  /* This Util called to restore ag-grid controls,filters,sorters from app-context when re-visited page */
  restoreGridState() {
    const { colState, filterState, pivotState, filters } =
      this.state.opcodeValidationGrid;
    if (colState && this.gridApi && this.gridColumnApi) {
      this.gridColumnApi.setColumnState(colState);
      this.gridColumnApi.setPivotMode(pivotState);
      // this.gridApi.setSortModel(sortState);
      this.assignColumnState(colState);
      this.gridApi.setFilterModel(filterState);
      const event = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true
      });
      this.applyMultipleFilters(event, filters);
    }
  }
  onFirstDataRendered(params) {
    this.restoreGridState();
    this.sizeToFit();
  }
  openOpcodeModal = (
    findOpcodeServiceId,
    findOpcodeInternalName,
    findOpcodeOpcode,
    findOpcodeDmsDescription,
    setOpcodeValueFunc
  ) => {
    this.setState({
      showOpCodeModal: true,
      findOpcodeServiceId,
      findOpcodeInternalName,
      findOpcodeOpcode,
      findOpcodeDmsDescription,
      setOpcodeValueFunc
    });
  };

  closeOpcodeModal = () => {
    this.setState({ showOpCodeModal: false });
  };

  handleShowFindOpcodeEvent = event => {
    // data is from ag-grid record
    const { data, colDef } = event.detail;
    const { currentOpcode } = data;
    // console.log("showopcode", data);
    this.openOpcodeModal(
      data.serviceId.toString(),
      data.internalName,
      data.currentOpcode,
      data.currentOpcodeDescription,
      value => {
        data.currentOpcode = value;
        const params = {
          newValue: value,
          oldValue: currentOpcode,
          value,
          data,
          colDef
        };
        this.onCellValueChanged(params);
      }
    );
  };

  trackFindOpcodeEvent = () => {
    const tagObj = {
      eventResult: "Select an Op Code from DMS Search tool",
      eventFeature: "Operations> Opcode validation Page",
      eventFeatures: "opcode validation page> Find Opcode",
      trackPageUrl: "operations/opcode-validation-grid/find-opcode"
    };
    gtmEvents.gtmTrackEventWithParams("xmm.common.findopcode_click", tagObj);
  };

  // This event fired after a cell has been changed with default editing
  onCellValueChanged(params) {
    // console.log("onCellValueChanged", params.oldValue, params.newValue, params);
    if (
      toEmptyStringIfUndefined(params.oldValue) !==
      toEmptyStringIfUndefined(params.newValue)
    ) {
      // const record = params.data;
      this.saveCurrentOpcode(params);
    }
  }
  // Save find opcode value from editor
  saveCurrentOpcode(params) {
    this.onSaveCellEdit(params);
  }

  closeValidateOpcodeModal = event => {
    this.setState({
      showModal: false,
      disableAction: false
    });
  };

  showValidateOpcodeModal = event => {
    event.preventDefault();
    this.setState({
      showModal: true,
      disableAction: true
    });
  };
  // Callback handler when validate button clicked from Modal
  validateOpcodeHandler = (event, hasStarted, makeName) => {
    let msg = this.validateOpcodeAllMakesLabel;
    if (makeName && makeName !== "all") {
      msg = this.validateOpcodeSingleMakeLabel.replace("%1", makeName);
      // "Op Code Validation is in Progress for " +
      // makeName +
      // ".Please check back later for results.";
    }
    this.setState(
      {
        showMask: true,
        showModal: false,
        disableAction: true, // disable "Validate Opcode Mapping" button
        statusType: "info",
        statusMsg: msg,
        statusCode: "PENDING"
      },
      () => {
        /* Start Task to Update banner status and trigger callback to get opcode status after 30 seconds; */
        this.triggerBanner();
      }
    );
  };
  // execute callback() after 30 seconds. since validation in queue will take 30 seconds to update DB table
  triggerBanner() {
    this.startTask();
  }
  /* These methods used to run getStatus() task in an interval */
  runStatus() {
    // 21 times means 10 mins
    this.pollCount++;
    if (this.pollCount > MaxPollCount) {
      return;
    }
    console.log(`this.pollCount: ${this.pollCount} at ${new Date()}`);

    this.loadAllMakesValidationStatus(this.state.dealerCode);

    const pollInterval =
      this.pollCount < InitialCount ? InitialPollInterval : DefaultPollInterval;
    setTimeout(this.runStatus, pollInterval);
  }

  startTask() {
    this.pollCount = 0;
    setTimeout(this.runStatus, DefaultPollInterval);
  }
  stopTask(doRefresh) {
    this.pollCount = MaxPollCount;
    if (doRefresh) {
      // refresh grid data when opcode status completed.
      this.loadOpcodes();
    }
  }

  applySortConfig() {
    const defaultSortModel = [
      {
        colId: "make",
        sortIndex: 0,
        sort: "asc"
      },
      {
        colId: "internalName",
        sortIndex: 1,
        sort: "asc"
      }
    ];
    // this.gridApi && this.gridApi.setSortModel(defaultSortModel);
    this.assignColumnState(defaultSortModel);
  }
  assignColumnState = defaultSortModel => {
    this.gridColumnApi &&
      this.gridColumnApi.applyColumnState({
        state: defaultSortModel,
        defaultState: {
          // important to say 'null' as undefined means 'do nothing'
          sort: null
        }
      });
  };
  sizeToFit() {
    this.gridApi && this.gridApi.sizeColumnsToFit();
  }
  /* This method will fix row hieght when columns are resized */
  handleColumnResized = () => {
    this.gridApi.resetRowHeights();
  };
  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.loadOpcodes();
    this.triggerOpcodeStatus();
    this.gridApi.closeToolPanel();
    this.applySortConfig();
    this.sizeToFit();
  };
  /* Important - When Page loaded, run task to get recent OPCODE status for each make with 30 seconds interval;
     Stop task until opcode status is completed for each make */
  triggerOpcodeStatus() {
    this.stopTask(false); // This will cancel all previous task.
    this.loadAllMakesValidationStatus(this.state.dealerCode);
    this.triggerBanner();
  }

  /* This callback() used to update grid state */
  updateState(data) {
    const { locale } = this.context;
    if (data) {
      let datalist = [];
      let rawlist = [];
      console.log("opcodes data", data);
      if (!isArrayExist(data) && typeof data === "object") {
        rawlist.push(data);
      } else if (isArrayExist(data) && data.length > 0) {
        rawlist = data;
      }

      if (isArrayExist(rawlist) && rawlist.length === 0) {
        // show 'no rows' overlay
        this.gridApi && this.gridApi.showNoRowsOverlay();
      } else {
        // clear all overlays
        this.gridApi && this.gridApi.hideOverlay();
      }
      if (isArrayExist(rawlist) && rawlist.length > 0) {
        datalist = rawlist.map((m, index) => {
          const row = Object.assign({}, m);
          row.recId = index;
          const clast = toEmptyStringIfUndefined(m.currentOpcodeLastUsed);
          if (clast) {
            row.currentOpcodeLastUsed = formatDateTimezone(
              clast,
              false,
              locale
            );
          }
          const slast = toEmptyStringIfUndefined(m.suggestedOpcodeLastUsed);
          if (slast) {
            row.suggestedOpcodeLastUsed = formatDateTimezone(
              slast,
              false,
              locale
            );
          }
          return row;
        });
      }

      setTimeout(() => {
        const { statusCode } = this.state;
        this.setState(
          {
            operations: datalist,
            disableFilters: false,
            disableAction: statusCode === "PENDING" ? true : false
            // Disable "validate opcode" button if atleast one make in-progress
          },
          () => {
            this.sizeToFit();
            // this.restoreGridState();
          }
        );
      }, 200);
    }
  }

  loadOpcodes() {
    if (this.loadOpcodesInProgress) {
      return;
    }
    this.loadOpcodesInProgress = true;
    this.loadXmmOpcodes();
  }

  toOpcodeMappings(list) {
    return list.map(item => {
      const { operationName, operationId, opCode } = item;
      return {
        global: true,
        serviceId: operationId,
        make: "ANY",
        internalName: operationName,
        name: operationName,
        currentOpcode: opCode,
        currentOpcodeLastUsed: "",
        currentOpcodeTotalUsage: "",
        parentId: "",
        serviceKind: "repair",
        contentStatusNew: "",
        contentStatus: "",
        opcodeChangeStatus: "",
        suggestedOpcodeDescription: "",
        suggestedOpcode: "",
        suggestedOpcodeIcon: "",
        suggestedOpcodeLastUsed: "",
        suggestedOpcodeTotalUsage: ""
      };
    });
  }

  generateGlobalOpcodeScores(datalist) {
    const { dealer, dealerCode, makeVariantMap } = this.context;
    const { dmsType } = dealer;
    const make = "ANY";
    const dealerCatalog = makeVariantMap[make];
    let variant = "SERVICETAB";
    if (dealerCatalog) {
      variant = dealerCatalog.variant;
    }
    const restUrl = `/ops/proxyapi/catalogadminproxy/opsadmin/opcodes/scores/dealerCode/${dealerCode}/make/${make}/variant/${variant}`;
    const params = { dmsType: !dmsType ? "" : dmsType };
    makeSecureRestApi(
      {
        url: restUrl,
        method: "get",
        params
        // skipAccessToken: true
      },
      response => {
        let opcodeMappings = datalist;
        if (
          response &&
          response.operations &&
          response.operations.length !== 0
        ) {
          const { operations } = response;
          operations.forEach(op => {
            op.isGlobalOp = true;
            op.serviceId = "g" + op.serviceId;
            op.internalName = op.name;
            // op.serviceKind = "global";
          });
          opcodeMappings = datalist.concat(operations);
          const { createTime } = operations[0];
          let scoreDate = null;
          if (createTime) {
            scoreDate = new Date(createTime);
          }
          if (
            !this.lastGloablOpcodeScoreDate ||
            scoreDate > this.lastGloablOpcodeScoreDate
          ) {
            this.lastGloablOpcodeScoreDate = scoreDate;
          }
        }
        this.updateState(opcodeMappings);
        hideBodyMask();
        this.loadOpcodesInProgress = false;
      },
      error => {
        toast.error("Error in loading global repair operations.");
        this.setState({
          disableFilters: false,
          disableAction: false
        });
        this.updateState(datalist);
        // this.gridApi && this.gridApi.showNoRowsOverlay();
        hideBodyMask();
        this.loadOpcodesInProgress = false;
      }
    );
  }

  loadGlobalOpcodeMappings(datalist) {
    const { dealerCode } = this.context;
    const restUrl = `/ops/proxyapi/catalogadminproxy/opsadmin/opcodes/opcodemappings/dealerCode/${dealerCode}`;
    makeSecureRestApi(
      {
        url: restUrl,
        method: "get"
        // skipAccessToken: true
      },
      response => {
        let opcodeMappings = datalist;
        if (
          response &&
          response.operations &&
          response.operations.length !== 0
        ) {
          const { operations } = response;
          operations.forEach(op => {
            op.isGlobalOp = true;
            op.serviceId = "g" + op.serviceId;
            op.internalName = op.name;
            // op.serviceKind = "global";
          });
          console.log(
            "lastGloablOpcodeScoreDate",
            this.lastGloablOpcodeScoreDate
          );
          const { createTime } = operations[0];
          let scoreDate = null;
          if (createTime) {
            scoreDate = new Date(createTime);
          }
          if (
            !this.lastGloablOpcodeScoreDate ||
            scoreDate > this.lastGloablOpcodeScoreDate
          ) {
            this.lastGloablOpcodeScoreDate = scoreDate;
          }
          if (this.lastValidationDate < this.lastGloablOpcodeScoreDate) {
            opcodeMappings = datalist.concat(operations);
            this.updateState(opcodeMappings);
            hideBodyMask();
            this.loadOpcodesInProgress = false;
          } else {
            this.generateGlobalOpcodeScores(opcodeMappings);
          }
        } else {
          this.updateState(datalist);
          hideBodyMask();
          this.loadOpcodesInProgress = false;
        }
      },
      error => {
        toast.error("Error in loading global repair operations.");
        this.setState({
          disableFilters: false,
          disableAction: false
        });
        this.updateState(datalist);
        // this.gridApi && this.gridApi.showNoRowsOverlay();
        hideBodyMask();
        this.loadOpcodesInProgress = false;
      }
    );
  }

  loadXmmOpcodes() {
    const { dealerCode } = this.context;
    const { commonOpsRepairsEnabled } = this.state;
    const payload = {
      dealerCode
    };
    const restUrl = "/ops/proxyapi/ddsproxy/rest/proc/getAllOpcodeMappings";

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      "dds-json-conversion-style": "camel-case"
    };
    showBodyMask();
    makeSecureRestApi(
      {
        url: restUrl,
        method: "get",
        data: {},
        params: payload,
        headers
      },
      data => {
        if (data) {
          if (commonOpsRepairsEnabled) {
            if (
              this.lastGloablOpcodeScoreDate &&
              this.lastValidationDate &&
              this.lastValidationDate > this.lastGloablOpcodeScoreDate
            ) {
              this.generateGlobalOpcodeScores(data);
            } else {
              this.loadGlobalOpcodeMappings(data);
            }
          } else {
            this.updateState(data);
            hideBodyMask();
            this.loadOpcodesInProgress = false;
          }
        }
      },
      error => {
        toast.error(error.message);
        this.setState({
          disableFilters: false,
          disableAction: false
        });
        this.gridApi && this.gridApi.showNoRowsOverlay();
        hideBodyMask();
        this.loadOpcodesInProgress = false;
      }
    );
  }

  loadContentAndPriceStatuses = updateData => {
    // get content status and price status for all operations
    const { dealerCode } = this.context;
    makeSecureRestApi(
      {
        url: "/ops/proxyapi/ddsproxy/rest/proc/findDealerOperationsList",
        method: "get",
        data: {},
        params: { dealerCode }
      },
      response => {
        // console.log(response);
        const servicelist = response.data;
        let datalist = [];
        // check if response has single object or array of objects
        if (!isArrayExist(servicelist) && typeof servicelist === "object") {
          datalist.push(servicelist);
        } else if (isArrayExist(servicelist) && servicelist.length > 0) {
          datalist = servicelist;
        }
        const serviceidMap = [];
        datalist.forEach(obj => {
          serviceidMap[obj.serviceId] = obj;
        });

        this.context.setContentPriceStatuses(serviceidMap);
        // this.setContentPriceStatusesInGrid(serviceidMap);
        updateData(serviceidMap);
      },
      error => {
        toast.error(error.message);
      }
    );
  };

  /* "cellClicked" event handler  */
  onCellClickedEvent(params) {
    const field = params.colDef.field;
    if (field === "suggestedOpcodeIcon") {
      if (params.data.isGlobalOp) {
        this.onSaveCellEditGlobal(params, true);
      } else {
        this.saveSugggestedOpcode(params);
      }
    }
  }
  /* celledit handler to save current opcode value */
  onSaveCellEdit(params) {
    const { isGlobalOp } = params.data;
    if (isGlobalOp) {
      this.onSaveCellEditGlobal(params, false);
    } else {
      this.onSaveCellEditXmm(params);
    }
  }
  onSaveCellEditXmm(params) {
    this.updateStatusBox(this.savingMsg, "pending", false);
    const record = params.data;
    const opcodeVal = toEmptyStringIfUndefined(record.currentOpcode);
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      "dds-json-conversion-style": "camel-case"
    };
    const restEndPoint = "/ops/proxyapi/ddsproxy/rest/proc/setCurrentOpcode";
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "get",
        data: {},
        params: {
          serviceId: record.serviceId,
          dmsOpcode: opcodeVal
        },
        headers
      },
      data => {
        if (data) {
          let response = null;
          let datalist = [];
          if (!isArrayExist(data) && typeof data === "object") {
            datalist.push(data);
          } else if (isArrayExist(data) && data.length > 0) {
            datalist = data;
          }
          // console.log("save current opcode -> response", datalist);
          if (isArrayExist(datalist) && datalist.length > 0) {
            response = datalist[0];
            const rowNode =
              this.gridApi && this.gridApi.getRowNode(record.serviceId);
            rowNode.setData(response);
            const params = {
              force: true,
              rowNodes: [rowNode]
            };
            this.callRefreshAfterMillis(params, this.gridApi);
            this.updateStatusBox(this.savedMsg, "success", true);
          }
        }
      },
      error => {
        const msg = error["message"] ? error.message : this.saveError;
        this.updateStatusBox(msg, "error", false, true);
      }
    );
  }

  getGlobalOpId(serviceId) {
    return serviceId.toString().substring(1);
  }
  onSaveCellEditGlobal(params, replaceFlag) {
    this.updateStatusBox(this.savingMsg, "pending", false);
    const record = params.data;
    const { dealerCode } = this.state;
    const { user } = this.context;
    const { userName } = user;
    const {
      serviceId,
      currentOpcode,
      suggestedOpcode,
      suggestedOpcodeDescription,
      suggestedOpcodeLastUsed,
      suggestedOpcodeTotalUsage
    } = record;
    if (
      replaceFlag &&
      toEmptyStringIfUndefined(suggestedOpcode).trim() === ""
    ) {
      // do perform replace with suggested opcode if it is blank
      return;
    }
    const operationId = Number(this.getGlobalOpId(serviceId));
    const opcode = replaceFlag ? suggestedOpcode : currentOpcode;
    const restUrl = `/ops/opCodes/overrides/operation/${operationId}`;
    makeSecureRestApi(
      {
        url: restUrl,
        method: "post",
        data: {
          dealerCode,
          operationId,
          modUser: userName,
          opcode,
          description: toEmptyStringIfUndefined(suggestedOpcodeDescription),
          lastUsed: toEmptyStringIfUndefined(suggestedOpcodeLastUsed),
          totalUsage: toEmptyStringIfUndefined(suggestedOpcodeTotalUsage)
        },
        params: { replaceFlag },
        skipAccessToken: true
      },
      data => {
        this.updateStatusBox(this.savedMsg, "success", true);
        const { opcode, description, lastUsed, totalUsage } = data;
        record.currentOpcode = opcode;
        record.currentOpcodeDescription = toEmptyStringIfUndefined(description);
        record.currentOpcodeLastUsed = toEmptyStringIfUndefined(lastUsed);
        record.currentOpcodeTotalUsage = toEmptyStringIfUndefined(totalUsage);
        this.setOpcodeChangeStatus(record);
        const rowNode = this.gridApi && this.gridApi.getRowNode(serviceId);
        if (rowNode) {
          rowNode.setData(record);
          const params = {
            force: true,
            rowNodes: [rowNode]
          };
          this.callRefreshAfterMillis(params, this.gridApi);
        }
        this.updateStatusBox(this.savedMsg, "success", true);
      },
      error => {
        const msg = error["message"] ? error.message : this.saveError;
        this.updateStatusBox(msg, "error", false, true);
      }
    );
  }

  setOpcodeChangeStatus = mapping => {
    let opcodeChangeStatus = "Invalid";
    const currentOpcode = mapping.currentOpcode;
    const suggestedOpcode = mapping.suggestedOpcode;
    if ("OCM" === currentOpcode) {
      opcodeChangeStatus = "Invalid";
    } else if (
      currentOpcode === suggestedOpcode ||
      (currentOpcode && !suggestedOpcode)
    ) {
      opcodeChangeStatus = "Valid";
    } else {
      opcodeChangeStatus = "Review";
    }
    // @todo: need to handle "Ignored" case
    mapping.opcodeChangeStatus = opcodeChangeStatus;
  };

  /* save handler for suggested opcode saving */
  saveSugggestedOpcode(params) {
    const record = params.data;
    const opcodeVal = toEmptyStringIfUndefined(record.suggestedOpcode);
    let opcodeStatus = toEmptyStringIfUndefined(record.opcodeChangeStatus);
    opcodeStatus = opcodeStatus ? opcodeStatus.toLowerCase() : "";
    if (!opcodeVal) {
      // Don't  save blank opcode value
    } else {
      if (opcodeStatus === "invalid" || opcodeStatus === "review") {
        this.updateStatusBox(this.savingMsg, "pending", false);
        const headers = {
          Accept: "application/json",
          "Content-Type": "application/json",
          "dds-json-conversion-style": "camel-case"
        };
        const restEndPoint =
          "/ops/proxyapi/ddsproxy/rest/proc/setOpcodeToSuggested";
        makeSecureRestApi(
          {
            url: restEndPoint,
            method: "get",
            data: {},
            params: {
              serviceId: record.serviceId
            },
            headers
          },
          data => {
            if (data) {
              let response = null;
              let datalist = [];
              if (!isArrayExist(data) && typeof data === "object") {
                datalist.push(data);
              } else if (isArrayExist(data) && data.length > 0) {
                datalist = data;
              }
              // console.log("save suggested opcode response", datalist);
              if (isArrayExist(datalist) && datalist.length > 0) {
                response = datalist[0];
                const rowNode =
                  this.gridApi && this.gridApi.getRowNode(record.serviceId);
                rowNode.setData(response);
                this.refreshGrid(params);
              }
            }
            this.updateStatusBox(this.savedMsg, "success", true);
          },
          error => {
            const msg = error["message"] ? error.message : this.saveError;
            this.updateStatusBox(msg, "error", false, true);
          }
        );
      }
    }
  }
  callRefreshAfterMillis(params, gridApi) {
    setTimeout(function () {
      gridApi.refreshCells(params);
    }, 300);
  }
  refreshGrid(params) {
    params.api.refreshCells({ force: true });
  }
  /* callback when Save() triggerd to refresh parent grid */
  refreshOperations = () => {
    this.updateStatusBox("", "text", false);
    this.setState(
      {
        showMask: true,
        disableAction: true
      },
      prevState => {
        this.loadOpcodes();
        this.triggerOpcodeStatus();
      }
    );
  };
  /* IMP - this function required for CRUD operations, to get RowNode */
  getRowNodeId(data) {
    return data.serviceId;
  }
  getColumnList(localeStrings) {
    const suggestedOpcodeTip =
      localeStrings["xmm.portal.opcode_validation.suggested_opcode.tip"];
    const baseCols = [
      {
        headerName: this.xtimelbl,
        headerClass: "ag-text-header",
        groupId: "xtimeGroup",
        children: [
          {
            headerName: this.makelbl,
            field: "make",
            editable: false,
            sortingOrder: ["asc", "desc"],
            valueFormatter(params) {
              return params.value;
            },
            filter: "agSetColumnFilter",
            suppressSizeToFit: true,
            maxWidth: 100,
            // minWidth: 100,
            width: 100,
            filterParams: {
              buttons: ["clear"],
              comparator: sortByMake
            }
          },
          {
            headerName: this.operationNamelbl,
            field: "internalName",
            headerClass: "ag-text-header",
            cellClass: "xmm-wrap-cell",
            autoHeight: true,
            sortingOrder: ["asc", "desc"],
            filter: "agTextColumnFilter",
            filterParams: {
              buttons: ["clear"]
            }
          },
          {
            headerName: this.externalNamelbl,
            field: "name",
            hide: true,
            headerClass: "ag-text-header",
            cellClass: "xmm-wrap-cell",
            autoHeight: true,
            sortingOrder: ["asc", "desc"],
            filter: "agTextColumnFilter",
            filterParams: {
              buttons: ["clear"]
            }
          },
          {
            headerName: this.opcodelbl,
            field: "currentOpcode",
            editable: true,
            headerClass: "ag-text-header",
            valueFormatter: blankValueFormatter,
            tooltipField: "currentOpcodeDescription",
            cellEditor: "opcodeEditor",
            cellClass: "editable-cell xmm-blue-cell",
            filter: "agTextColumnFilter",
            suppressSizeToFit: true,
            // maxWidth: 110,
            minWidth: 110,
            width: 110,
            filterParams: {
              buttons: ["clear"]
            }
          },
          {
            headerName: this.lastUsedlbl,
            field: "currentOpcodeLastUsed",
            headerClass: "ag-text-header",
            suppressSizeToFit: true,
            maxWidth: 90,
            // minWidth: 90,
            width: 90,
            valueFormatter: blankValueFormatter,
            filter: "agDateColumnFilter",
            comparator: dateComparator,
            filterParams: {
              buttons: ["clear"],
              comparator(filterLocalDateAtMidnight, cellValue) {
                const dateAsString = cellValue;
                if (dateAsString == null) return -1;
                const dateParts = dateAsString.split("/");
                const cellDate = new Date(
                  Number(dateParts[2]),
                  Number(dateParts[1]) - 1,
                  Number(dateParts[0])
                );
                if (
                  filterLocalDateAtMidnight.getTime() === cellDate.getTime()
                ) {
                  return 0;
                }
                if (cellDate < filterLocalDateAtMidnight) {
                  return -1;
                }
                if (cellDate > filterLocalDateAtMidnight) {
                  return 1;
                }
              }
            }
          },
          {
            headerName: this.usagelbl,
            field: "currentOpcodeTotalUsage",
            headerClass: "ag-text-header",
            valueFormatter: blankValueFormatter,
            filter: "agNumberColumnFilter",
            suppressSizeToFit: true,
            maxWidth: 90,
            // minWidth: 90,
            width: 90,
            filterParams: {
              buttons: ["clear"]
            }
          },
          {
            headerName: this.createdBylbl,
            field: "parentId",
            editable: false,
            valueGetter: createdByValueGetter,
            filter: "agSetColumnFilter",
            filterParams: {
              buttons: ["clear"]
            },
            hide: true,
            maxWidth: 100
            // minWidth: 80
          },
          {
            headerName: this.serviceKindlbl,
            field: "serviceKind",
            valueFormatter: serviceKindFormatter,
            filter: "agSetColumnFilter",
            filterParams: {
              buttons: ["clear"]
            },
            editable: false,
            hide: true
            // minWidth: 100
          },
          /* Derived column - content status to work as Text Filter using matching string */
          {
            headerName: "Dervied Content",
            field: "contentStatusNew",
            hide: true,
            suppressColumnsToolPanel: true,
            suppressFiltersToolPanel: true,
            valueGetter: contentStatusGetter,
            refData: {
              "mb-lb": "mb-lb",
              mb: "mb",
              lb: "lb",
              dis: "dis"
            },
            enableRowGroup: false,
            filter: "agSetColumnFilter",
            filterParams: {
              buttons: ["clear"]
            }
          },

          /* Default - hide this derived column to display content status */
          {
            headerName: this.contentStatuslbl,
            field: "contentStatus",
            valueGetter: contentStatusGetter,
            filter: "agSetColumnFilter",
            filterParams: {
              suppressMiniFilter: false,
              // selectAllOnMiniFilter: true,
              newRowsAction: "keep",
              buttons: ["clear"]
            },
            hide: true,
            editable: false,
            refData: {
              "mb-lb": "Active in Menu and A la Carte",
              mb: "Active in Menu",
              lb: "Active in A la Carte",
              dis: "Inactive for All Content"
            }
            /* PROD case: refData
            refData: {
              "1-1": "Active in Menu and A la Carte",
              "1-0": "Active in Menu",
              "0-1": "Active in A la Carte",
              "0-0": "Inactive for All Content"
            }
            */
          }
        ]
      },
      // Icon column + tooltip
      {
        headerName: "",
        headerClass: "ag-text-header header-hidden",
        groupId: "statusGroup",
        suppressColumnsToolPanel: true,
        children: [
          {
            headerName: "",
            field: "opcodeChangeStatus",
            headerClass: "ag-text-header",
            cellClass: "white-bg",
            enableValue: true,
            cellRenderer: "iconCellRenderer",
            // tooltipField: "opcodeChangeStatus",
            suppressSizeToFit: true,
            suppressColumnsToolPanel: true, // hide item in sidebar.columns
            maxWidth: 50,
            // minWidth: 100,
            width: 50,
            filterParams: {
              cellRenderer: "iconFilterCellRenderer",
              // selectAllOnMiniFilter: true,
              newRowsAction: "keep",
              buttons: ["clear"]
            }
          }
        ]
      },
      {
        headerName: this.dmslbl,
        headerClass: "ag-text-header",
        groupId: "dmsGroup",
        children: [
          {
            headerName: this.descriptionlbl,
            field: "suggestedOpcodeDescription",
            headerClass: "ag-text-header",
            cellClass: "xmm-wrap-cell",
            valueFormatter: blankValueFormatter,
            autoHeight: true,
            sortingOrder: ["asc", "desc"],
            filter: "agTextColumnFilter",
            filterParams: {
              buttons: ["clear"]
            }
          },
          {
            headerName: this.opcodelbl,
            field: "suggestedOpcode",
            headerClass: "ag-text-header no-separator",
            cellClass: "no-separator",
            cellRenderer(params) {
              const opcodeVal = toEmptyStringIfUndefined(params.value);

              if (!opcodeVal) {
                return "-";
              } else {
                return params.value;
              }
            },
            filter: "agTextColumnFilter",
            suppressSizeToFit: true,
            // maxWidth: 110,
            minWidth: 110,
            width: 110,
            filterParams: {
              buttons: ["clear"]
            }
          },
          {
            headerName: "",
            field: "suggestedOpcodeIcon",
            valueGetter(params) {
              return params.data.suggestedOpcode;
            },
            headerClass: "ag-text-header",
            cellRenderer(params) {
              const opcodeVal = toEmptyStringIfUndefined(params.value);
              let opcodeStatus = toEmptyStringIfUndefined(
                params.data.opcodeChangeStatus
              );
              opcodeStatus = opcodeStatus ? opcodeStatus.toLowerCase() : "";
              if (!opcodeVal) {
                return "";
              } else {
                if (opcodeStatus === "invalid" || opcodeStatus === "review") {
                  const iconHtml = `<span title=${suggestedOpcodeTip} class="float-right"><i class="far fa-copy"></i></span>`;
                  return iconHtml;
                }
              }
            },
            filter: false,
            suppressSizeToFit: true,
            suppressMenu: true,
            suppressColumnsToolPanel: true, // hide item in sidebar.columns
            maxWidth: 50,
            // minWidth: 35,
            width: 50
          },
          {
            headerName: this.lastUsedlbl,
            field: "suggestedOpcodeLastUsed",
            headerClass: "ag-text-header",
            comparator: dateComparator,
            valueFormatter: blankValueFormatter,
            suppressSizeToFit: true,
            maxWidth: 90,
            // minWidth: 90,
            width: 90,
            filter: "agDateColumnFilter",
            filterParams: {
              buttons: ["clear"],
              comparator(filterLocalDateAtMidnight, cellValue) {
                const dateAsString = cellValue;
                if (dateAsString == null) return -1;
                const dateParts = dateAsString.split("/");
                const cellDate = new Date(
                  Number(dateParts[2]),
                  Number(dateParts[1]) - 1,
                  Number(dateParts[0])
                );
                if (
                  filterLocalDateAtMidnight.getTime() === cellDate.getTime()
                ) {
                  return 0;
                }
                if (cellDate < filterLocalDateAtMidnight) {
                  return -1;
                }
                if (cellDate > filterLocalDateAtMidnight) {
                  return 1;
                }
              }
            }
          },
          {
            headerName: this.usagelbl,
            field: "suggestedOpcodeTotalUsage",
            headerClass: "ag-text-header",
            valueFormatter: blankValueFormatter,
            filter: "agNumberColumnFilter",
            suppressSizeToFit: true,
            maxWidth: 90,
            // minWidth: 90,
            width: 90,
            filterParams: {
              buttons: ["clear"]
            }
          }
        ]
      }
    ];
    return baseCols;
  }
  /* This event listener to know when clear filter button clicked in column level filter */
  onFilterModified = params => {
    // console.log("onFilterChanges fired event as", params);
    const colId = params.column.colId;
    const filterInstance = params.api.getFilterInstance(colId);
    const modelFromUi = filterInstance.getModelFromUi();
    if (!modelFromUi) {
      // console.log("clear button clicked for ", colId);
      // callback to reset external filter
    }
  };
  applyMultipleFilters = (event, filters) => {
    if (this.gridApi && filters && Object.keys(filters).length !== 0) {
      this.setMakeModel(filters.make);
      this.setTypeModel(filters.type);
      this.setServiceKind(filters.kind);
      this.setStatusModel(filters.opcodestatus);
      this.customContentStatus(filters.contentstatus); // Fix for Content status filter
      this.gridApi.onFilterChanged();
      this.gridApi.setQuickFilter(filters.searchkey);
      // const savedModel = this.gridApi.getFilterModel();
      // console.log("get filter model", savedModel);
    }
  };
  // These Filters will support multi-select dropdowns in future as well
  setMakeModel(filterVal) {
    const makeFilter = this.gridApi.getFilterInstance("make");
    const model = [];
    if (filterVal && filterVal === "all") {
      makeFilter.setModel(null);
    } else {
      model.push(filterVal);
      makeFilter.setModel({ values: model });
    }
  }
  setTypeModel(filterVal) {
    const typeFilter = this.gridApi.getFilterInstance("parentId");
    const model = [];
    if (filterVal && filterVal === "all") {
      typeFilter.setModel(null);
    } else {
      model.push(filterVal);
      typeFilter.setModel({ values: model });
    }
  }
  setServiceKind(filterVal) {
    const kindFilter = this.gridApi.getFilterInstance("serviceKind");
    const model = [];
    if (filterVal && filterVal === "all") {
      kindFilter.setModel(null);
    } else {
      kindFilter.setModel({ values: [] });
      model.push(filterVal);
      console.log("kind model", model);
      kindFilter.setModel({ values: model });
    }
  }
  setStatusModel(filterVal) {
    const statusFilter = this.gridApi.getFilterInstance("opcodeChangeStatus");
    // const model = ["Valid", "Invalid"];
    const model = [];
    if (filterVal && filterVal === "all") {
      statusFilter.setModel(null);
    } else {
      model.push(filterVal);
      console.log("status model", model);
      statusFilter.setModel({ values: model });
    }
  }
  // This Custom logic to convert Set filter works like Text filter
  customContentStatus(filterVal) {
    const countryFilterComponent =
      this.gridApi.getFilterInstance("contentStatusNew");
    if (filterVal && filterVal === "all") {
      countryFilterComponent.setModel(null);
    } else {
      // countryFilterComponent.selectNothing();
      countryFilterComponent.setModel({ values: [] });
      for (let i = 0; i < countryFilterComponent.getUniqueValueCount(); i++) {
        const matchValue = countryFilterComponent.getUniqueValue(i);
        if (matchValue) {
          const valueEndsWithStan = matchValue.indexOf(filterVal) !== -1;
          if (valueEndsWithStan) {
            // console.log("matchValue", matchValue);
            countryFilterComponent.selectValue(matchValue);
          }
        }
      }
      countryFilterComponent.applyModel();
    }
  }
  // Place holder - This works for Set Filter ref Data only
  setContentStatus(filterVal) {
    const statusFilter = this.gridApi.getFilterInstance("contentStatus");

    if (filterVal && filterVal === "all") {
      statusFilter.setModel(null);
    } else {
      // Case- Either Active Menu or Ala carte - old
      const model = [];
      if (filterVal === "1-1") {
        model.push("0-1");
        model.push("1-0");
        model.push("1-1");
      } else {
        model.push(filterVal);
      }
      statusFilter.setModel({ values: model });
    }
  }
  /* For Demo purpose only,
  setFilters(){
    const defaultFilterModel = {
      parentId: {
        filterType: "set",
        values: ["Dealer"]
      },
      make: {
        filterType: "set",
        values: ["AUDI"]
      }
    };
    this.gridApi.setFilterModel(defaultFilterModel);
    this.gridApi.onFilterChanged();
  }
  clearStatusFilter() {
    const filterComponent = this.gridApi.getFilterInstance(
      "opcodeChangeStatus"
      );
      filterComponent.selectEverything();
      this.gridApi.onFilterChanged();
    }
  */

  /* Action event to clear column filters */
  clearFilters() {
    if (this.gridApi) {
      const filterModel = this.gridApi.getFilterModel();
      // console.log("before clear> fitler", filterModel);
      if (filterModel) {
        this.gridApi.setFilterModel(null);
      }
      this.gridApi.onFilterChanged();
      this.gridApi.setQuickFilter("");
      // callback to clear external filters values
      this.clearExternalFilters();
    }
  }
  clearExternalFilters() {
    this.childExternalFilterRef.current.clearExternalFilters();
  }

  /* This method returns All opcode validation status for a dealer */
  loadAllMakesValidationStatus(dealerCode) {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };
    makeSecureRestApi(
      {
        url: "ops/proxyapi/ddsproxy/rest/proc/getAllOpcodeValidationStatus",
        method: "get",
        data: {},
        params: { dealerCode },
        headers
      },
      response => {
        if (response) {
          this.validationStatusCallback(response);
        }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : xlate("xmm.portal.errors.fetch_dealer_data");
        toast.error(msg);
      }
    );
  }
  validationStatusCallback(data) {
    if (data) {
      let datalist = [];
      if (!isArrayExist(data) && typeof data === "object") {
        datalist.push(data);
      } else if (isArrayExist(data) && data.length > 0) {
        datalist = data;
      }
      /* update state with status data */
      setTimeout(() => {
        this.setState({
          allMakesValidationStatus: datalist
        });
      }, 50);
      const statusobj = this.transformData(datalist, this);
      if (statusobj && Object.keys(statusobj).length > 0) {
        const { statusType, statusMsg, statusCode } = statusobj;
        this.setState({
          showMask: statusCode === "PENDING" ? true : false,
          statusType,
          statusMsg,
          statusCode,
          disableAction: statusCode === "PENDING" ? true : false
        });
      }
      console.log("validation status for All makes", statusobj);
    }
  }
  /* transform each Make status object into one UI supported status object here.  */
  transformData(datalist, me) {
    const { locale } = this.context;
    // const cancelInProgress = this.isCancelOpcodeValidationInProgress();
    const newlist = [];
    const makeNames = [];
    let hasStatus = false;
    let statusMsg = null;
    let statusType = "";
    let statusCode = null;
    let lastValidationDateStr = "";
    const totalMakes = datalist.length;
    let startCount = 0;
    let completeCount = 0;
    let pendingCount = 0;
    if (datalist) {
      if (isArrayExist(datalist) && totalMakes > 0) {
        datalist.forEach((obj, index) => {
          if (obj.make) {
            // if (cancelInProgress) {
            //   if (obj.validationInProgress === 1) {
            //     obj.validationInProgress = 0;
            //   }
            // }
            const cloneObj = Object.assign({}, obj);
            const makeObj = me.generateStatusText(obj);

            if (Object.keys(makeObj).length > 0) {
              if (makeObj.statusCode === "NONE") {
                startCount = startCount + 1;
              } else if (makeObj.statusCode === "COMPLETE") {
                completeCount = completeCount + 1;
                // compare dates and get latest date among completed makes.
                const dateVal = toEmptyStringIfUndefined(obj.lastValidation);
                const validationDate = new Date(dateVal);
                console.log("validationDate", validationDate);
                if (
                  !this.lastValidationDate ||
                  validationDate > this.lastValidationDate
                ) {
                  this.lastValidationDate = validationDate;
                  lastValidationDateStr = formatDateTimezone(
                    dateVal,
                    true,
                    locale
                  );
                }
              } else if (makeObj.statusCode === "PENDING") {
                pendingCount = pendingCount + 1;
                // if (makeObj.make.toUpperCase() !== "ALL") {}
                makeNames.push(makeObj.make);
              }
            }
            newlist.push(cloneObj);
          }
        });
        // console.log(
        //   "{totalMakes, start,complete,pending}",
        //   totalMakes,
        //   startCount,
        //   completeCount,
        //   pendingCount
        // );
        if (startCount === totalMakes) {
          statusCode = "NONE";
          statusType = "warning";
          hasStatus = true;
          statusMsg = xlate("xmm.portal.opcode_validation.default_banner");
          // "Click 'Validate Op Code Mapping' to check your current op codes or to setup for the first time.";
        } else if (pendingCount === 0 && completeCount > 0) {
          statusCode = "COMPLETE";
          statusType = "success";
          hasStatus = true;
          statusMsg = xlate(
            "xmm.portal.opcode_validation.complete_banner"
          ).replace("%1", lastValidationDateStr);
          // "Displaying results from validation completed on " + lastValidationDateStr + ". Please fix any Invalid opcodes below.";
          this.stopTask(true);
        } else if (pendingCount === totalMakes) {
          statusCode = "PENDING";
          statusType = "info";
          hasStatus = true;
          statusMsg = xlate(
            "xmm.portal.opcode_validation.allmakes_pending_banner"
          );
          // "Op Code Validation is in Progress for ALL Makes. Please check back later for results.";
        } else if (pendingCount < totalMakes && makeNames.length > 0) {
          statusCode = "PENDING";
          statusType = "info";
          hasStatus = true;
          const strMakes = makeNames.toString();
          statusMsg = xlate(
            "xmm.portal.opcode_validation.makes_pending_banner"
          ).replace("%1", strMakes);
          // "Op Code Validation is in Progress for " + strMakes + ". Please check back later for results.";
        }
      }
      if (!hasStatus && !statusMsg) {
        return null;
      } else {
        return {
          statusMsg,
          statusType,
          statusCode
        };
      }
    } else {
      return null;
    }
  }

  /* This returns formatted status text based on status object */
  generateStatusText(statusObj) {
    let statusCode = "";
    let make = "";
    if (statusObj.hasOwnProperty("validationInProgress")) {
      // "inprogress" state
      if (statusObj.validationInProgress === 1) {
        statusCode = "PENDING";
        make = statusObj.make;
      } else if (
        statusObj.validationInProgress === 0 &&
        !isEmptyString(statusObj.lastValidation)
      ) {
        // compare dates to return latest date among makes
        statusCode = "COMPLETE";
      } else if (
        statusObj.validationInProgress === 0 &&
        isEmptyString(statusObj.lastValidation)
      ) {
        // Never validated for any make
        statusCode = "NONE";
      }
      // console.log(statusObj.make, statusCode);
    }
    const status = {
      statusCode,
      make
    };
    return status;
  }

  /* cancel opcode validation for All Makes */
  cancelAllOpCodeValidation = event => {
    this.updateStatusBox(this.cancelingMsg, "pending", false);
    const { dealerCode } = this.context.dealer;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };
    const restEndPoint =
      "/ops/proxyapi/ddsproxy/rest/proc/cancelAllOpcodeScoring";
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "get",
        data: {},
        params: {
          dealerCode
        },
        headers
      },
      data => {
        if (data) {
          if (isArrayExist(data) && data.length > 0) {
            const cancelObj = data[0];
            if (cancelObj && cancelObj.isError === 0) {
              if (
                cancelObj.message &&
                cancelObj.message.indexOf("request submitted") !== -1
              ) {
                this.updateStatusBox(this.cancelSubmittedMsg, "success", true);
                this.context.setCancelOpcodeValidationDate(new Date());
              } else {
                this.updateStatusBox(this.canceledMsg, "success", true);
              }
              this.resetProcess();
              this.triggerBanner();
            } else {
              this.updateStatusBox(
                this.cancelOpcdeValidationError,
                "error",
                false,
                true
              );
            }
          }
        }
      },
      error => {
        toast.error(error.message);
      }
    );
  };
  /* trigger start task to render banner */
  resetProcess() {
    this.stopTask(false);
    this.setState(
      {
        showMask: true,
        statusType: "",
        statusMsg: "",
        statusCode: "",
        disableAction: false
      },
      () => {
        this.loadAllMakesValidationStatus(this.state.dealerCode);
      }
    );
  }
  // This hadnler called from child; to update local state with latest All makes status
  updateAllMakeStatus = statuslist => {
    this.setState(prevState => {
      return { allMakesValidationStatus: statuslist };
    });
  };
  updateStatusBox(msg, type, close, errorInTooltip) {
    console.log("status", msg, type, close);
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
    sleep(0).then(() => {
      this.setState({
        statusBoxMsg: msg,
        autoClose: close,
        statusBoxType: type,
        errorInTooltip
      });
    });
  }
  isCancelOpcodeValidationInProgress() {
    const { cancelOpcodeValidationDate } = this.context;
    let cancelJobInProgress = false;
    if (cancelOpcodeValidationDate) {
      const now = new Date();
      if (now.getTime() - cancelOpcodeValidationDate.getTime() < 120000) {
        cancelJobInProgress = true;
      } else {
        this.context.setCancelOpcodeValidationDate(null);
      }
    }
    return cancelJobInProgress;
  }
  render() {
    const { dealerCatalogs, localeStrings } = this.context;
    const makelistFilter = [...dealerCatalogs];
    makelistFilter.splice(0, 0, {
      label: localeStrings["xmm.portal.common.all_makes"],
      value: "all",
      make: "all",
      dealerCode: "all",
      variant: "all"
    });
    const statusBox = this.state.statusBoxMsg ? (
      <StatusBox
        htmlId="statusBox"
        type={this.state.statusBoxType}
        autoClose={this.state.autoClose}
        linkHtml={null}
        message={this.state.statusBoxMsg}
        autoCloseTime={1500}
        errorInTooltip={this.state.errorInTooltip}
      />
    ) : (
      ""
    );
    const { statusType, statusMsg, statusCode } = this.state;
    let showValidateOpcodeModal = null;
    let msgSection = null;
    const gridWidget = (
      <div id="grid-wrapper">
        <div
          id="opcodeMappingGrid"
          className={
            "ag-grid-container ag-theme-balham xmm-filters " +
            (statusMsg ? "banner-enabled" : "")
          }
        >
          <AgGridReact
            localeText={this.state.localeText}
            columnDefs={this.state.columnDefs}
            defaultColDef={this.state.defaultColDef}
            suppressMenuHide={false}
            suppressContextMenu={true}
            rowData={this.state.operations}
            onGridReady={this.onGridReady}
            onColumnResized={this.handleColumnResized}
            frameworkComponents={this.state.frameworkComponents}
            loadingOverlayComponent={this.state.loadingOverlayComponent}
            loadingOverlayComponentParams={
              this.state.loadingOverlayComponentParams
            }
            noRowsOverlayComponent={this.state.noRowsOverlayComponent}
            noRowsOverlayComponentParams={
              this.state.noRowsOverlayComponentParams
            }
            animateRows={true}
            statusBar={this.state.statusBar}
            components={this.state.components}
            onCellClicked={this.onCellClickedEvent}
            onCellValueChanged={this.onCellValueChanged}
            getRowNodeId={this.getRowNodeId}
            sideBar={this.state.sideBar}
            columnTypes={this.state.columnTypes}
            multiSortKey={this.state.multiSortKey}
            singleClickEdit={true}
            stopEditingWhenCellsLoseFocus={true}
            enableRangeSelection={false}
            enableCellTextSelection={true}
            enableBrowserTooltips={true}
            // enterMovesDownAfterEdit={true}
            // enterMovesDown={true}
            // onFilterModified={this.onFilterModified}
            // rowHeight={50}
            onFirstDataRendered={this.onFirstDataRendered}
          />
        </div>
      </div>
    );

    const { opcodeValidationGrid } = this.state;
    const externalFilters = (
      <ExternalFilters
        ref={this.childExternalFilterRef}
        makes={makelistFilter}
        applyfilters={this.applyMultipleFilters}
        disableFilters={this.state.disableFilters}
        opcodeValidationGrid={opcodeValidationGrid}
      />
    );
    /* render cancel for In-progress banner only */
    const cancelLink = this.isCancelOpcodeValidationInProgress() ? (
      <span>&nbsp; Cancel in Progress.</span>
    ) : (
      <Button
        htmlId="cancelAllOpCodeValidation"
        buttonStyle="link"
        onClick={this.cancelAllOpCodeValidation}
      >
        {this.cancelLabel}
      </Button>
    );

    if (statusMsg) {
      const cancelText = statusCode === "PENDING" ? cancelLink : null;
      msgSection = (
        <AlertBox
          htmlId="opcodeValidationBanner"
          type={statusType}
          closeButton={statusCode === "PENDING" ? false : true}
          linkHtml={cancelText}
          message={statusMsg}
          loading={this.state.showMask}
        />
      );
    }
    if (this.state.showModal) {
      showValidateOpcodeModal = (
        <ValidateOpcodeModal
          allMakesValidationState={this.state.allMakesValidationStatus}
          show={this.state.showModal}
          title={xlate("xmm.portal.opcode_validation_validate_opcode_lbl")}
          okAction={this.validateOpcodeHandler}
          closeModal={this.closeValidateOpcodeModal}
          updateAllMakeStatus={this.updateAllMakeStatus}
          okText={xlate("xmm.portal.opcode_validation.validate_lbl")}
          cancelText={xlate("xmm.portal.common.cancel_button")}
        >
          <div />
        </ValidateOpcodeModal>
      );
    }
    const header = (
      <React.Fragment>
        {msgSection}
        <div className="content-header">
          <div className="xmm-main-title">
            <h3>
              <FormattedMessage
                defaultMessage="Op Code Validation"
                id="xmm.portal.nav.opcode_validation"
              />
            </h3>{" "}
          </div>
          <div className="xmm-form-header">
            {statusBox}{" "}
            <Button
              htmlId="validateOpcodeBtn"
              disabled={this.state.disableAction}
              onClick={this.showValidateOpcodeModal}
            >
              {this.validateOpcodelbl}
            </Button>
            <Dropdown
              icon={<IconMore />}
              id="opcodesActionBtn"
              htmlId="opcodesActionBtn"
              name="opcodesActionBtn"
              className="xmm-dotted-dropdown btn--icon"
              buttonStyle="link"
              displayCaret={false}
              size="small"
              options={[
                {
                  label: this.refreshlbl,
                  value: "refresh",
                  onSelect: this.refreshOperations
                },
                {
                  label: this.clearFilterslbl,
                  value: "clear-filters",
                  onSelect: this.clearFilters
                }
              ]}
              pullRight
            />
            {/*
            <DropdownButton
              title={<IconMore />}
              id="opcodesActionBtn"
              className="xmm-dotted-dropdown btn--icon"
              pullRight
            >
              <DropdownMenuItem
                htmlId="refreshBtn"
                eventKey={{ eventKey: ["refresh"] }}
                onSelect={this.refreshOperations}
              >
                {this.refreshlbl}
              </DropdownMenuItem>
              <DropdownMenuItem
                htmlId="clearFiltersBtn"
                eventKey={{ eventKey: ["clear-filters"] }}
                onSelect={this.clearFilters}
              >
                {this.clearFilterslbl}
              </DropdownMenuItem>
            </DropdownButton>
 */}
          </div>
        </div>
      </React.Fragment>
    );
    const findOpCodesModal = (
      <FindOpCodesDialog
        showValidateCatalog={false}
        dealerCode={this.context.dealerCode}
        serviceId={this.state.findOpcodeServiceId}
        internalName={this.state.findOpcodeInternalName}
        dmsOpcode={this.state.findOpcodeOpcode}
        dmsDescription={this.state.findOpcodeDmsDescription}
        localeStrings={this.context.localeStrings}
        manualOpcodes={this.context.manualOpcodes}
        show={this.state.showOpCodeModal}
        closeDialog={this.closeOpcodeModal}
        setManualOpcodes={this.context.setManualOpcodes}
        setOpcodeValue={this.state.setOpcodeValueFunc}
      />
    );
    return (
      <React.Fragment>
        {header}
        {externalFilters}
        {gridWidget}
        {showValidateOpcodeModal}
        {findOpCodesModal}
      </React.Fragment>
    );
  }
}

export default OpcodeValidation;

OpcodeValidation.propTypes = {
  payload: PropTypes.object
};
/*
// Use this fun() to support string matching filter
function contentStatusGetterNew(params) {
  if (!params || !params.data) {
    return "";
  }
  const { contentEnabledAlacarte, contentEnabledMenus } = params.data;
  let result = "";
  if (contentEnabledMenus) {
    if (result !== "") {
      result += " or Menu";
    } else {
      result = "Active in Menu";
    }
  }
  if (contentEnabledAlacarte) {
    if (result !== "") {
      result += " or A la Carte";
    } else {
      result = "Active in A la Carte";
    }
  }
  if (contentEnabledMenus === 0 && contentEnabledAlacarte === 0) {
    result = "Inactive for All Content";
  }
  return result;
}
*/

// opcode validation poll interval and max count
const InitialPollInterval = 15000;
const DefaultPollInterval = 2 * InitialPollInterval;
const InitialCount = 4;
const MaxPollCount = 22;

// Getter fun() returns custom strings to match with content status external filter
function contentStatusGetter(params) {
  if (!params || !params.data) {
    return "";
  }
  const contentEnabledMenus = params.data
    ? params.data.contentEnabledMenus
    : null;
  const contentEnabledAlacarte = params.data
    ? params.data.contentEnabledAlacarte
    : null;
  let mapValue = null;

  if (contentEnabledMenus === 0 && contentEnabledAlacarte === 0) {
    // filter has 'Inactive for all Content';
    mapValue = "dis";
  }
  if (contentEnabledMenus === 1) {
    // filter has 'Enabled in Menu';
    mapValue = "mb";
  }
  if (contentEnabledAlacarte === 1) {
    // filter has 'Enabled in A la Carte';
    if (!mapValue) {
      // null value case
      mapValue = "lb";
    } else {
      // filter has 'Enabled in Menu or A la Carte';
      mapValue = "mb-lb";
    }
  }

  if (contentEnabledMenus === null && contentEnabledAlacarte === null) {
    // filter has 'All Content Status';
    mapValue = "all";
  }
  // console.log(
  //   "status menu, ala, map",
  //   params.data.serviceId,
  //   contentEnabledMenus,
  //   contentEnabledAlacarte,
  //   mapValue
  // );
  return mapValue;
}

const OpcodeStatusKeyMap = {
  Valid: "xmm.portal.common.valid",
  Invalid: "xmm.portal.common.invalid",
  Review: "xmm.portal.common.review",
  Ignored: "xmm.portal.common.ignored"
};

function iconCellRenderer(params) {
  const displayValue = toEmptyStringIfUndefined(
    xlate(OpcodeStatusKeyMap[params.value])
  );
  let statusValue = toEmptyStringIfUndefined(params.value);
  statusValue = statusValue ? statusValue.toLowerCase() : "";
  // console.log(params.data.serviceId, params.value);
  let iconHtml = "";
  if (statusValue === "invalid") {
    iconHtml = `<span title=${displayValue} class="float-right"><i class="fas fa-exclamation-circle"></i></span>`;
  } else if (statusValue === "valid") {
    iconHtml = `<span title=${displayValue} class="float-right"><i class="fas fa-check"></i></span>`;
  } else if (statusValue === "review") {
    iconHtml = `<span title=${displayValue} class="float-right"><i class="far fa-question-circle"></i></span>`;
  } else if (statusValue === "ignored") {
    iconHtml = `<span title=${displayValue} class="float-right"><i class="fas fa-minus"></i></span>`;
  } else {
    iconHtml = "-";
  }
  return iconHtml;
}

function iconFilterCellRenderer(params) {
  let statusValue = toEmptyStringIfUndefined(params.value);
  const displayValue = toEmptyStringIfUndefined(
    xlate(OpcodeStatusKeyMap[params.value])
  );
  statusValue = statusValue ? statusValue.toLowerCase() : "";
  let iconHtml = "";
  if (statusValue === "invalid") {
    iconHtml = '<i class="fas fa-exclamation-circle"></i>' + displayValue;
  } else if (statusValue === "valid") {
    iconHtml = '<i class="fas fa-check"></i>' + displayValue;
  } else if (statusValue === "review") {
    iconHtml = '<i class="far fa-question-circle"></i>' + displayValue;
  } else if (statusValue === "ignored") {
    iconHtml = '<i class="fas fa-minus"></i>' + displayValue;
  } else {
    const emptyLabel = xlate("xmm.portal.common.lowercase_empty");
    iconHtml = `(${emptyLabel})`;
  }
  return iconHtml;
}

function opcodeCellRenderer(params) {
  const opcode = toEmptyStringIfUndefined(params.value);
  if (!opcode) {
    return "-";
  } else {
    const iconHtml = '<i class="fas fa-plus"></i>';
    return params.value + " " + iconHtml;
  }
}
/* eslint-enable no-console */
