/* eslint-disable no-console */
import React from "react";
// import DropdownMenu from "@cx/ui/DropdownMenu";
// import DropdownMenuItem from "@cx/ui/DropdownMenuItem";
import Dropdown from "@cx/ui/Dropdown";
import { AppContext } from "../../../components/app-context";
import { PropTypes } from "prop-types";

class AddIntervalButton extends React.Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);
    this.checkForUnsavedChanges = this.checkForUnsavedChanges.bind(this);
    this.onSelectMake = this.onSelectMake.bind(this);
    this.state = {
      groupNames: [],
      supportedMakes: context.makelist
    };
  }
  /* common select handler for dropdownMenuItem  */
  onSelectMake = (eventKey, event) => {
    // console.log("selected eventKey:", eventKey);
    window.dispatchEvent(
      new CustomEvent("addIntervalEvent", {
        detail: eventKey,
        bubbles: true,
        cancelable: true
      })
    );
    window.document.dispatchEvent(new MouseEvent("click"));
    // callback something
  };
  checkForUnsavedChanges = (cxEvent, event) => {
    const eventKey = cxEvent.target.value;
    const { discardUnsavedChanges } = this.context;
    const { isFormDirty, resetForm } = this.props;
    if (
      discardUnsavedChanges(
        event,
        () => {
          resetForm();
          this.onSelectMake(eventKey, event);
        },
        isFormDirty
      )
    ) {
      this.onSelectMake(eventKey, event);
    }
  };
  generateItems = () => {
    const items = this.state.supportedMakes.map(c => {
      return {
        label: c.value,
        value: c,
        onSelect: this.checkForUnsavedChanges
      };
    });
    // const items = (
    //   <React.Fragment>
    //     {this.state.supportedMakes.map(c => {
    //       /*
    //       makeObj = { dealerCode: "XTIMEMOTORS14",
    //           label: "ACURA",
    //           pricingMethod: 1,
    //           value: "ACURA",
    //           variant: "ACURAUSA_ENH2" }
    //       */
    //       return (
    //         <DropdownMenuItem
    //           key={c.label}
    //           htmlId={c.value}
    //           eventKey={c}
    //           onSelect={this.checkForUnsavedChanges}
    //         >
    //           {c.value}
    //         </DropdownMenuItem>
    //       );
    //     })}
    //   </React.Fragment>
    // );
    return items;
  };
  render() {
    const items = this.generateItems();
    return (
      <Dropdown
        id="AddIntervalBtn"
        className="xmm-dotted-dropdown btn--icon"
        size="small"
        buttonStyle="secondary"
        options={items}
      >
        {this.context.localeStrings["xmm.portal.common.add_button"]}
      </Dropdown>

      // <DropdownMenu
      //   buttonText={this.context.localeStrings["xmm.portal.common.add_button"]}
      //   htmlId="AddIntervalBtn"
      //   className="scroll-enabled"
      //   size="small"
      // >
      //   {items}
      // </DropdownMenu>
    );
  }
}

export default AddIntervalButton;

AddIntervalButton.propTypes = {
  isFormDirty: PropTypes.func,
  resetForm: PropTypes.func
};
/* eslint-enable no-console */
