import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import has from "lodash/has";
import IconArrowUpward from "@cx/ui/Icons/IconArrowUpward";
import ConfirmPopup from "../../components/modals/ConfirmPopup";
import {
  useServiceSearchContext,
  Actions
} from "../../state/service-search.context";
import serviceDataMapper from "../../utils/service-data-mapper";
import "./EditDeclinedServicePage.scss";
import { convertHoursToMinutes } from "../../utils/quote.util";
import { saveActionTypes } from "../../utils/search.constants";

const EditDeclinedServicePage = props => {
  const { service, updateParentState, EditServiceModule } = props;
  const { dispatch, state, ctxRequestPartsInventoryCommon } =
    useServiceSearchContext();
  const { localeStrings, debugQuote, vehicle, userPermissions } = state;
  const [localService, setLocalService] = useState(null);
  const [showDiscardEditPopup, setShowDiscardEditPopup] = useState(false);
  const [serviceHasChanged, setServiceHasChanged] = useState(false);
  const payTypes = state.payTypes;
  useEffect(() => {
    setLocalService(service);
  }, [service]);

  const backToService = () => {
    updateParentState(false);
    dispatch({
      type: Actions.SET_SEARCH_HEADER,
      payload: true
    });
    setLocalService(null);
  };

  const handleServiceChange = serviceChanged => {
    setServiceHasChanged(serviceChanged);
  };

  const handleCancel = () => {
    if (serviceHasChanged) {
      setShowDiscardEditPopup(true);
    } else {
      backToService();
    }
  };

  const handleServiceUpdate = updatedService => {
    const newDeclinedService = serviceDataMapper.updateDeclinedService(
      localService,
      updatedService
    );
    // @issue - rawService should not be formatted, store API response - declined service record
    // const rawService = JSON.stringify(newDeclinedService);
    // @fix - read new object coming from service-data-mapper
    const rawApiService = has(localService, "rawApiService")
      ? localService.rawApiService
      : {};
    const rawService = JSON.stringify(rawApiService);
    const declinedServiceObj = {
      ...newDeclinedService,
      durationMins: convertHoursToMinutes(newDeclinedService.duration),
      dmsOpcode: newDeclinedService.opCode,
      // @fix - always read asrNotes field from edit context as well
      asrNotes: newDeclinedService.asrNotes,
      quoteRawService: { rawService }
    };
    console.log("final edited declined", declinedServiceObj);
    dispatch({
      type: Actions.SET_SAVE_ACTION_TYPE,
      payload: saveActionTypes.SAVE
    });
    dispatch({
      type: Actions.SET_SELECTED_DECLINED_SERVICE,
      payload: declinedServiceObj
    });
  };

  const saveServiceAndGoBack = updatedService => {
    const newDeclinedService = serviceDataMapper.updateDeclinedService(
      localService,
      updatedService
    );
    // @issue - rawService should not be formatted, store API response - declined service record
    const rawService = JSON.stringify(newDeclinedService);
    const declinedServiceObj = {
      ...newDeclinedService,
      durationMins: convertHoursToMinutes(newDeclinedService.duration),
      dmsOpcode: newDeclinedService.opCode,
      // @fix - always read asrNotes field from edit context as well
      asrNotes: newDeclinedService.asrNotes,
      quoteRawService: { rawService }
    };
    dispatch({
      type: Actions.SET_SAVE_ACTION_TYPE,
      payload: saveActionTypes.SAVEANOTHER
    });
    dispatch({
      type: Actions.SET_SELECTED_DECLINED_SERVICE,
      payload: declinedServiceObj
    });
    backToService();
  };
  const asyncUpdatePartsByPaytype = async (paytype, editedService) => {
    localService.payTypeCode = paytype;
    ctxRequestPartsInventoryCommon(localService, editedService);
  };

  const discardEditPopup = (
    <ConfirmPopup
      title={localeStrings["sq.search.common.alert_lbl"]}
      message={localeStrings["sq.search.common.leaving_edit_page"]}
      show={showDiscardEditPopup}
      okText={localeStrings["sq.search.common.proceed_button"]}
      cancelText={localeStrings["sq.search.common.cancel_button"]}
      okAction={backToService}
      cancelAction={() => setShowDiscardEditPopup(false)}
      hideCancel={false}
      hideOk={false}
      buttonStyle="danger"
    />
  );

  const clonedEditServiceModule = !isEmpty(localService)
    ? React.cloneElement(EditServiceModule, {
        // @todo-beta: rawOperationDetails - pass localService to be converted as same datamodel like dealer publish record
        rawOperationDetails: localService,
        debugMode: debugQuote,
        userPermissions,
        vehicle,
        service:
          serviceDataMapper.editModulePropsFromDeclinedService(localService),
        localeStrings,
        payTypes,
        onCancelHandler: handleCancel,
        onSaveHandler: handleServiceUpdate,
        onSaveAnotherHandler: saveServiceAndGoBack,
        onChangePaytype: asyncUpdatePartsByPaytype,
        onServiceChange: handleServiceChange
      })
    : null;

  return (
    <>
      <div id="editDeclinedService" className="search-flex-grid-container">
        <span className="back-nav-label" onClick={handleCancel}>
          <IconArrowUpward
            htmlId="backArrowIcon"
            isActive={false}
            className="back-arrow"
          />
          Back to results
        </span>
        {clonedEditServiceModule}
        {discardEditPopup}
      </div>
    </>
  );
};

export default EditDeclinedServicePage;

EditDeclinedServicePage.defaultProps = {
  service: null,
  updateParentState: () => {},
  onSaveDeclined: () => {},
  EditServiceModule: null
};

EditDeclinedServicePage.propTypes = {
  service: PropTypes.object.isRequired,
  updateParentState: PropTypes.func.isRequired,
  onSaveDeclined: PropTypes.func.isRequired,
  EditServiceModule: PropTypes.element
};
