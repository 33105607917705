/* This objects are freezed, used for cell renderer  */
export const UnitOfMeasureMap = {
  Gallons: "gal",
  Grams: "g",
  Inches: "in",
  Liters: "l",
  Milliliters: "ml",
  Millimeters: "mm",
  Ounces: "oz",
  Pints: "pt",
  Quarts: "qt"
};

export const IncludedInspectionTags = [
  "inspect",
  "disclaimer",
  "lubricate",
  "reset",
  "tighten",
  "test"
];

export const GlobalOpsServiceType = {
  GLOBALCATALOG: "GlobalCatalog",
  DEALERCATALOG: "DealerPublishedCatalog",
  DIAGNOSIS: "Diagnose",
  DECLINED: "declined",
  AUTORECALL: "autorecall"
};

export const DealerPublishedCategory = {
  REPAIR: "repair",
  MAINTENANCE: "maintenance"
};

export const saveActionTypes = {
  NA: null,
  SAVE: "SAVE",
  SAVEANOTHER: "SAVEANOTHER"
};

// called in service-data-mapper
export const EditServiceTemplate = {
  name: "",
  totalPrice: 0,
  laborAppId: null,
  labor: {
    serviceId: "",
    notes: "",
    price: 0,
    time: 0
  },
  defaultLaborRate: 0,
  asrNotes: "",
  commentsRequired: false,
  opCode: "",
  description: "",
  operationSource: "",
  serviceKind: "",
  defaultPayTypeCode: "",
  payTypeCode: "",
  payTypeDescription: "",
  parts: [],
  partsPrice: 0,
  totalPriceOverride: null,
  totalLaborPriceOverride: null,
  totalPartsPriceOverride: null,
  calculatedLaborPrice: null,
  calculatedPartsPrice: null,
  calculatedTotalPrice: null,
  finalLaborPrice: null,
  laborPriceOverridden: false,
  partsPriceOverridden: false,
  totalPriceOverridden: false
};
