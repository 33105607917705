/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-useless-constructor */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable no-console */
import React, { Component } from "react";
import Col from "@cx/ui/Col";
import Grid from "@cx/ui/Grid";
import Row from "@cx/ui/Row";
import SelectInput from "@cx/ui/SelectInput";
// import TextInput from "@cx/ui/TextInput";
import CustomTooltip from "../../../../../commonUtil/components/reusable/CustomToolTip";
import {
  makeSecureRestApi,
  showBodyMask,
  hideBodyMask
} from "../../../../../api/xmmAxios";
import { toast } from "@cx/ui/Toast";
import { AppContext } from "../../../../../components/app-context";
import { PropTypes } from "prop-types";
import { AgGridReact } from "ag-grid-react";
import CustomLoadingOverlay from "../../../../../commonUtil/components/loadingmask/CustomLoadingOverlay";
import {
  CreatedByOptions
  // TestActionsList
} from "../../../../../constants/ModuleConstants";
import PickyFilter from "../../../../../commonUtil/filters/PickyFilter";
import { toEmptyStringIfUndefined } from "../../../../../commonUtil/utils/string";
import { xlate } from "../../../../../commonUtil/i18n/locales";
// import data from "../../../../../../public/data/findDealerScheduledOperations.json";

/** Find Op Codes */
export default class SelectionOperationsGridContainer extends Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props);
    this.isDirty = this.isDirty.bind(this);
    this.isValid = this.isValid.bind(this);
    this.onCreatedByChange = this.onCreatedByChange.bind(this);
    this.onSearchBoxChanged = this.onSearchBoxChanged.bind(this);
    this.onSelectActions = this.onSelectActions.bind(this);
    this.loadDealerOperations = this.loadDealerOperations.bind(this);
    this.sizeToFit = this.sizeToFit.bind(this);
    this.onFilterChanged = this.onFilterChanged.bind(this);
    const { localeStrings } = context;
    const gridOptions = {
      dirty: false,
      createdBy: "all",
      selectionlist: [],
      menuTypes: [],
      actionsList: [], // TestActionsList,
      selectedActions: [],
      createdByOptions: CreatedByOptions,
      visibleColumns: ["checked", "internalName"],
      columnDefs: [],
      defaultColDef: {
        sortable: true,
        resizable: true,
        editable: false,
        suppressMenu: true,
        width: 200,
        sortingOrder: ["asc", "desc", null],
        rowGroup: false,
        headerComponentParams: {
          template: `
          <div class="ag-cell-label-container" role="presentation">
            <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
            <div ref="eLabel" class="ag-header-cell-label" role="presentation">
              <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
              <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
              <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>
              <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>
              <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>
            </div>
          </div>
          `
        },
        getQuickFilterText: params => {
          return params.value;
        }
      },
      isRowSelectable(rowNode) {
        return true; // to see checkbox
      },
      rowData: null,
      components: {
        usedIndicatorCellRenderer
      },
      frameworkComponents: {
        customTooltip: CustomTooltip,
        customLoadingOverlay: CustomLoadingOverlay,
        customNoRowsOverlay: CustomLoadingOverlay
      },
      loadingOverlayComponent: "customLoadingOverlay",
      loadingOverlayComponentParams: {
        loadingMessage: "Loading",
        isLoading: true,
        noRows: false
      },
      noRowsOverlayComponent: "customNoRowsOverlay",
      noRowsOverlayComponentParams: {
        // loadingMessage: "No Operations",
        isLoading: false,
        noRows: true
      },
      columnTypes: {
        numberColumn: {
          // width: 160,
          sortable: true,
          filter: "agNumberColumnFilter"
        }
        // noFilterColumn: {
        //   width: 100,
        //   columnGroupShow: "open",
        //   filter: false
        // },
        // actionColumn: {
        //   filter: false,
        //   editable: false,
        //   sortable: false,
        //   suppressMenu: true,
        //   enableRowGroup: false
        // },
        // nonEditableColumn: { editable: false }
      },
      // Note: Set locale strings in this localeText {} for ag-grid controls
      localeText: {
        filteredRows: localeStrings["xmm.portal.ag_grid.filteredRows"],
        selectedRows: localeStrings["xmm.portal.ag_grid.selectedRows"],
        totalRows: localeStrings["xmm.portal.ag_grid.totalRows"],
        totalAndFilteredRows:
          localeStrings["xmm.portal.ag_grid.totalAndFilteredRows"],
        noRowsToShow: localeStrings["xmm.portal.ag_grid.noRowsToShow"]
      },
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          {
            statusPanel: "agSelectedRowCountComponent",
            align: "left"
          }
        ]
      }
    };

    this.state = gridOptions;
  }

  componentDidMount() {
    this.loadDealerOperations();
  }

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    // this.gridColumnApi.setColumnsVisible(
    //   ["createdBy", "serviceCategoryNodeName"],
    //   false
    // );
    // this.loadDealerOperations();
    this.gridApi.closeToolPanel();
  };
  sizeToFit() {
    this.gridApi && this.gridApi.sizeColumnsToFit();
  }
  handleColumnResized = () => {
    this.gridApi.resetRowHeights();
  };
  // Quick filter handler
  onSearchBoxChanged = event => {
    if (this.gridApi) {
      const { value } = event.target;
      this.gridApi.setQuickFilter(value);
    }
  };
  /* "filterChanged" - listen to the column filter events; can be used to  clear column filters */
  onFilterChanged = params => {};

  /* Un-select all rows, regardless of filtering from grid */
  clearGridSelections = () => {
    if (this.gridApi) {
      this.gridApi.deselectAll();
      this.setState({ selectionlist: [] });
    }
  };
  getColumnList(localeStrings) {
    const baseCols = [
      {
        headerName: localeStrings["xmm.portal.common.name_lbl"],
        field: "internalName",
        pinned: "left",
        headerClass: "ag-text-header",
        // tooltipField: "serviceName",
        // tooltipComponentParams: { field: "serviceName" },
        // tooltipComponent: "customTooltip",
        sortingOrder: ["asc", "desc"],
        cellClass: "xmm-wrap-cell",
        minWidth: 300,
        // maxWidth: 500,
        // width: 400,
        autoHeight: true,
        filter: "agTextColumnFilter",
        filterParams: {
          buttons: ["clear"]
        }
      },
      {
        headerName: localeStrings["xmm.portal.grid.createdby"],
        field: "createdBy",
        suppressColumnsToolPanel: true
      },
      {
        headerName:
          localeStrings["xmm.portal.operations.grid.service_category"],
        field: "serviceCategoryNodeName",
        suppressColumnsToolPanel: true
      }
    ];
    const parentCols = [
      {
        headerName: "",
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        enableRowGroup: true,
        pinned: "left",
        field: "checked",
        suppressSizeToFit: true,
        suppressColumnsToolPanel: true,
        maxWidth: 40,
        minWidth: 40,
        width: 40
      },
      {
        headerName: localeStrings["xmm.portal.operations.grid.operation"],
        field: "",
        children: baseCols
      }
    ];

    return parentCols;
  }
  getMenuTypeColumns = menuTypes => {
    const menuTypeColumns = menuTypes.map((menuType, index) => {
      const { menuTypeId, description } = menuType;
      const colDef = {
        // headerClass: "ag-numeric-header",
        headerName: description,
        field: menuTypeId,
        type: "numberColumn",
        valueFormatter: ruleCountFormatter,
        valueGetter: ruleCountGetter,
        minWidth: 150
      };
      return colDef;
    });
    return menuTypeColumns;
  };
  getMenuTypeColumnGroup = menuTypes => {
    const menuTypeColumns = menuTypes.map((menuType, index) => {
      const { menuTypeId, description } = menuType;
      const colDef = {
        headerName: description,
        // cellStyle: { textAlign: "center" },
        columnGroupShow: "open",
        field: menuTypeId,
        type: "numberColumn",
        valueFormatter: ruleCountFormatter,
        valueGetter: ruleCountGetter
      };
      return colDef;
    });
    const usedIndicatorCol = {
      headerName: xlate("xmm.portal.common.used_in_dealer_menus"),
      // headerClass: "ag-text-header",
      // cellStyle: { textAlign: "center" },
      field: "usedIndicator",
      cellRenderer: "usedIndicatorCellRenderer",
      minWidth: 50,
      maxWidth: 150,
      // width: 80,
      suppressSizeToFit: true
    };
    menuTypeColumns.splice(0, 0, usedIndicatorCol);
    const dealerMenuRuleColDef = {
      headerName: xlate("xmm.portal.common.dealer_menu_rules"),
      children: menuTypeColumns
    };
    return [dealerMenuRuleColDef];
  };
  appendMenuTypeColumns = menuTypes => {
    // add menu type columns
    const baseCols = this.getColumnList(this.context.localeStrings);
    const columnDefs = baseCols.concat(this.getMenuTypeColumnGroup(menuTypes));
    return columnDefs;
  };
  getSelectionList = () => {
    const { selectionlist } = this.state;
    return selectionlist;
  };
  handleSelectionChanged = event => {
    if (this.gridApi) {
      const selectedRows = this.gridApi.getSelectedRows();
      const dirty = true;
      this.setState({ dirty, selectionlist: selectedRows }, () => {
        this.props.onValidate();
        this.props.onTotalCount(selectedRows.length);
      });
    }
  };
  isDirty = () => {
    const { dirty } = this.state;
    console.log("select operations", dirty);
    return dirty;
  };
  isValid = () => {
    const { selectionlist } = this.state;
    return selectionlist && selectionlist.length > 0;
  };
  loadDealerOperations = () => {
    const { dealerCode } = this.context;
    const { make } = this.props;
    showBodyMask();
    this.gridApi && this.gridApi.showLoadingOverlay();
    makeSecureRestApi(
      {
        url: "/ops/proxyapi/ddsproxy/rest/proc/findAllDealerOpCounts",
        method: "get",
        params: { make, dealerCode }
      },
      dataList => {
        if (dataList) {
          const menuTypeMap = {}; // key,value = menuTypeId, menuDescription
          const serviceIdToMenuTypeCountMap = {}; // serviceId,menuTypeMap;
          const serviceMap = {};
          const actionsMap = {};
          dataList.forEach(operation => {
            const {
              parentId,
              serviceId,
              internalName,
              nodeName,
              menuTypeId,
              menuDescription,
              countScheduleRules
            } = operation;
            const serviceCategoryNodeName = !nodeName
              ? "<Empty Value>"
              : toEmptyStringIfUndefined(nodeName);
            if (
              // serviceCategoryNodeName !== "" &&
              !actionsMap[serviceCategoryNodeName]
            ) {
              actionsMap[serviceCategoryNodeName] = serviceCategoryNodeName;
            }
            const serviceIdStr = serviceId.toString();
            // build serviceMap
            let service = serviceMap[serviceIdStr];
            if (!service) {
              const createdBy = !parentId ? "dealer" : "factory";
              service = {
                createdBy,
                serviceId,
                internalName,
                serviceCategoryNodeName
              };
              serviceMap[serviceIdStr] = service;
            }
            // build menuTypeMap
            const menuTypeIdStr = menuTypeId.toString();
            let menuType = menuTypeMap[menuTypeIdStr];
            if (!menuType) {
              menuType = {
                menuTypeId: menuTypeIdStr,
                description: menuDescription
              };
              menuTypeMap[menuTypeIdStr] = menuType;
            }
            // build serviceIdToMenuTypeCountMap
            let menuTypeRuleCountMap =
              serviceIdToMenuTypeCountMap[serviceIdStr];
            if (!menuTypeRuleCountMap) {
              menuTypeRuleCountMap = {};
              // attach menuTypeRuleCountMap to the service
              service.menuTypeRuleCountMap = menuTypeRuleCountMap;
            }
            // menuTypeMap: key is menuTypeId value is menuType
            menuTypeRuleCountMap[menuTypeIdStr] = countScheduleRules;
            if (countScheduleRules > 0) {
              service.usedIndicator = true;
            }
            // countScheduleRules !== 0 ? countScheduleRules : "";
            // serviceIdToMenuTypeCountMap: key is serviceId, value is menuTypeMap
            serviceIdToMenuTypeCountMap[serviceIdStr] = menuTypeRuleCountMap;
          });
          // get sorted list of dealer operations from serviceMap
          const dealerOperations = Object.values(serviceMap).sort((a, b) => {
            if (!a.internalName || !b.internalName) {
              return -1;
            }
            return a.internalName.toLowerCase() < b.internalName.toLowerCase()
              ? -1
              : 1;
          });
          const actionsList = Object.keys(actionsMap).sort((a, b) => {
            return a < b ? -1 : 1;
          });
          const selectedActions = [...actionsList];
          const menuTypes = Object.values(menuTypeMap).sort((a, b) => {
            return a.description.toLowerCase() < b.description.toLowerCase()
              ? -1
              : 1;
          });
          const columnDefs = this.appendMenuTypeColumns(menuTypes);
          this.setState(
            {
              actionsList,
              selectedActions,
              columnDefs,
              rowData: dealerOperations,
              menuTypes
            },
            () => {
              this.gridColumnApi.setColumnsVisible(
                ["createdBy", "serviceCategoryNodeName"],
                false
              );
              setTimeout(() => {
                this.sizeToFit();
              }, 0);
            }
          );
        }
        hideBodyMask();
        this.gridApi && this.gridApi.hideOverlay();
      },
      error => {
        toast.error(error.message);
        this.gridApi && this.gridApi.showNoRowsOverlay();
        hideBodyMask();
      }
    );
  };

  /* External filter - filterBy packageType */
  onCreatedByChange = (cxEvent, isValid) => {
    const { value, name } = cxEvent.target;
    const filterValue = value === "all" ? "" : toEmptyStringIfUndefined(value);
    this.setState(
      {
        [name]: filterValue
      },
      prevState => {
        // this.showAllColumns(filterValue);
        this.applyTypeFilter(cxEvent, filterValue);
        this.sizeToFit();
      }
    );
  };
  applyTypeFilter = (event, filterVal) => {
    if (this.gridApi) {
      const typeFilter = this.gridApi.getFilterInstance("createdBy");
      const model = [];
      if (!filterVal) {
        typeFilter.setModel(null);
      } else {
        model.push(filterVal);
        typeFilter.setModel({ values: model });
      }
      this.gridApi.onFilterChanged();
      // this.clearGridSelections();
      // document.querySelector("#package-search-box").value = "";
      this.gridApi.setQuickFilter("");
    }
  };

  applyActionFilters = selectedActions => {
    if (this.gridApi) {
      const typeFilter = this.gridApi.getFilterInstance(
        "serviceCategoryNodeName"
      );
      if (!selectedActions || selectedActions.length === 0) {
        typeFilter.setModel(null);
      } else {
        typeFilter.setModel(selectedActions);
      }
      this.gridApi.onFilterChanged();
      this.gridApi.setQuickFilter("");
    }
  };
  onSelectActions = selectedActions => {
    console.log("Val", selectedActions);
    this.setState({ selectedActions }, () => {
      this.applyActionFilters(selectedActions);
      // this.clearGridSelections();
    });
  };

  render() {
    const { localeStrings } = this.context;
    const {
      createdBy,
      actionsList,
      selectedActions,
      createdByOptions,
      rowData
    } = this.state;
    return (
      <div>
        <Grid htmlId="pageHead">
          <Row className="show-grid">
            <Col xs={4} md={4} className="full-padding-right-col">
              <SelectInput
                htmlId="createdBySelect"
                name="createdBy"
                label={localeStrings["xmm.portal.grid.createdby"]}
                // placeholder=""
                displayDeselectOption={false}
                displayLabel={true}
                displayPlaceholder={false}
                value={createdBy}
                options={createdByOptions}
                onChange={this.onCreatedByChange}
                maxHeight={200}
              />
            </Col>
            <Col xs={4} md={4} className="full-col">
              <PickyFilter
                htmlId="actionFilter"
                name="actionFilter"
                label={localeStrings["xmm.portal.common.actions"]}
                initialValue={selectedActions}
                // value={selectedActions}
                options={actionsList}
                includeFilter={true}
                includeSelectAll={true}
                dropdownHeight={300}
                onChange={this.onSelectActions}
              />
            </Col>
            <Col xs={4} md={4} className="full-col pull-right">
              <div className="xmm-add-rules-search pull-right">
                <div className="xmm-add-rules-search-label">
                  {localeStrings["xmm.portal.common.search_label"]}
                </div>
                <div className="xmm-input-search">
                  <input
                    type="text"
                    id="page-search-box"
                    className="xmm-input"
                    placeholder={
                      localeStrings["xmm.portal.common.search_label"]
                    }
                    onInput={this.onSearchBoxChanged}
                    autoComplete="off"
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Grid>
        <div className="xmm-select-operations-container ag-theme-balham">
          <AgGridReact
            localeText={this.state.localeText}
            columnDefs={this.state.columnDefs}
            defaultColDef={this.state.defaultColDef}
            rowSelection="multiple"
            // enterMovesDownAfterEdit={true}
            // enterMovesDown={true}
            rowDeselection={true}
            rowMultiSelectWithClick={true}
            isRowSelectable={this.state.isRowSelectable}
            suppressRowClickSelection={true}
            suppressMenuHide={false}
            // suppressContextMenu={true}
            rowData={rowData}
            columnTypes={this.state.columnTypes}
            onGridReady={this.onGridReady}
            onColumnResized={this.handleColumnResized}
            onColumnGroupOpened={this.sizeToFit}
            onSelectionChanged={this.handleSelectionChanged}
            components={this.state.components}
            frameworkComponents={this.state.frameworkComponents}
            loadingOverlayComponent={this.state.loadingOverlayComponent}
            loadingOverlayComponentParams={
              this.state.loadingOverlayComponentParams
            }
            noRowsOverlayComponent={this.state.noRowsOverlayComponent}
            noRowsOverlayComponentParams={
              this.state.noRowsOverlayComponentParams
            }
            animateRows={true}
            rowStyle={this.state.rowStyle}
            rowHeight={50}
            statusBar={this.state.statusBar}
            // onCellValueChanged={this.onCellValueChanged}
            // onCellClicked={this.onCellClickedEvent}
            enableRangeSelection={false}
            enableCharts={false}
            enableCellTextSelection={true}
            enableBrowserTooltips={false}
            onFilterChanged={this.onFilterChanged}
          />
        </div>
      </div>
    );
  }
}

SelectionOperationsGridContainer.propTypes = {
  make: PropTypes.string,
  // menuTypes: PropTypes.array,
  onValidate: PropTypes.func,
  onTotalCount: PropTypes.func
};

function ruleCountFormatter(params) {
  const { colDef, data } = params;
  const { field } = colDef;
  const { menuTypeRuleCountMap } = data;
  const ruleCount = menuTypeRuleCountMap[field];
  return ruleCount === 0 ? "" : ruleCount;
}

function ruleCountGetter(params) {
  const { colDef, data } = params;
  const { field } = colDef;
  const { menuTypeRuleCountMap } = data;
  return menuTypeRuleCountMap[field];
}

function usedIndicatorCellRenderer(params) {
  const usedIndicator = params.value;
  return !usedIndicator ? "" : '<i class="fas fa-check"></i>';
}
