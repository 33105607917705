/* eslint-disable no-console */
import React from "react";
import { PropTypes } from "prop-types";
import Button from "@cx/ui/Button";
import ButtonGroup from "react-bootstrap/lib/ButtonGroup";
import RadioButtonList from "@cx/ui/RadioButtonList";
import IconStar from "@cx/ui/Icons/IconStar";
import IconStarEmpty from "@cx/ui/Icons/IconStarEmpty";
import Badge from "@cx/ui/Badge";
import IconInfoOutline from "@cx/ui/Icons/IconInfoOutline";
import Tooltip from "@cx/ui/Tooltip";
import PreviewContext from "../preview-context";
import {
  getUserFavs,
  addUserFav,
  deleteUserFav,
  existUserPref
} from "../content/selectors/UserPrefs";
import * as gtmEvents from "../../../utils/gtag-eventlist";

function vehicleTitle(vehicle) {
  const { make, model, year } = vehicle;
  if (make === "OTHER") {
    return make + " " + model;
  }
  return year + " " + make + " " + model;
}

class VehicleDetails extends React.Component {
  static contextType = PreviewContext;
  constructor(props, context) {
    super(props, context);
    this.onActionClick = this.onActionClick.bind(this);
    this.onRadioChange = this.onRadioChange.bind(this);
    this.togglePublish = this.togglePublish.bind(this);
    this.toggleFavorite = this.toggleFavorite.bind(this);
    // console.log(
    //   "VehicleDetails constr()",
    //   context.search,
    //   context.appContext.preview.isPreview
    // );
    // Read values from App context
    const { localeStrings, preview } = context.appContext;
    const { isPreview, search } = preview;
    const { defaultDrivingCondition } = search;
    const mode = isPreview === null ? true : isPreview;
    console.log("veh details", preview);
    this.initializeLocalicationValues(localeStrings);
    this.state = {
      showPreviewBadge: false,
      isToggled: true, // local purpose
      isPreview: mode, // get from app context
      previewMode: mode ? "unpublish" : "publish",
      showDriving: false,
      drivingCondition: !defaultDrivingCondition ? "" : defaultDrivingCondition
    };
  }
  // Note: to set any context values to state; use getDerivedStateFromProps()
  componentDidMount() {
    this.setFavorite();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.showPreviewBadge !== prevProps.showPreviewBadge) {
      this.updateBadge(this.props.showPreviewBadge);
    }
  }
  updateBadge(showPreviewBadge) {
    this.setState({
      showPreviewBadge
    });
  }
  initializeLocalicationValues(localeStrings) {
    this.drivingConditionLabel =
      localeStrings["xmm.portal.preview.vehicle.driving_conditions"].concat(
        ":"
      );
    this.changeVehicleLabel =
      localeStrings["xmm.portal.preview.vehicle.change_vehicle_lbl"];
    this.publishedLabel =
      localeStrings["xmm.portal.preview.vehicle.published_lbl"];
    this.unpublishedLabel =
      localeStrings["xmm.portal.preview.vehicle.unpublished_lbl"];
    this.partsNotAvailableLbl =
      localeStrings["xmm.portal.preview.parts_not_available_lbl"];
    this.partsLaborMsg = localeStrings["xmm.portal.preview.parts_labor_msg"];
  }

  onActionClick = event => {
    event.preventDefault();
    this.setState(
      {
        show: true
      },
      () => {
        this.context.showChangeVehicle(true, true);
      }
    );
  };

  // when driving condition
  onRadioChange = (cxEvent, isValid, domEvent) => {
    const { value } = cxEvent.target;
    // if (value === "Severe") {
    // } else if (value === "Normal") {
    // }

    this.setState(
      prevState => ({
        drivingCondition: value
      }),
      () => {
        this.dispatchSearch(false);
      }
    );
  };
  /* toggle publish handler  */
  togglePublish = cxEvent => {
    const { name } = cxEvent.target;
    let isToggled = true; // by default, unpublish mode
    // Unpublish switch
    if (cxEvent.target.name === "unpublish") {
      // callback unpublish data
      isToggled = true;
    } else if (cxEvent.target.name === "publish") {
      // callback to load publish data
      isToggled = false;
    }
    this.setState(
      prevState => ({
        isToggled,
        isPreview: !prevState.isPreview,
        previewMode: name
      }),
      () => {
        this.dispatchSearch(true);
      }
    );
  };
  /* dispatch search action */
  dispatchSearch = actionMethod => {
    const { drivingCondition, isPreview, previewMode } = this.state;
    if (actionMethod) {
      // preview case
    } else {
      // driving conditon case
    }
    console.log("dispatch search params", drivingCondition, isPreview);
    const { appContext, previewVehicle } = this.context;
    const { updatePreviewMode, preview } = appContext;
    updatePreviewMode(isPreview, drivingCondition);
    const { search, drivingConditions } = preview;
    console.log("dispatchSearch Driving click", preview);
    previewVehicle(search, drivingConditions);
    // window.dispatchEvent(
    //   new CustomEvent("previewWithParams", {
    //     detail: {
    //       drivingCondition,
    //       isPreview
    //     },
    //     bubbles: true,
    //     cancelable: true
    //   })
    // );

    // GTM - push click event to dataLayer
    this.trackToggleAction(previewMode);
  };
  // push event to GTM
  trackToggleAction = fieldName => {
    const tagObj = {
      eventResult: fieldName + " Preview",
      eventFeature: "Preview Page",
      eventFeatures: "preview page> toggle>" + fieldName,
      trackPageUrl: "preview/" + fieldName
    };
    gtmEvents.gtmTrackEventWithParams("xmm.preview.unpublish_click", tagObj);
  };
  setFavorite = () => {
    const { preview, previewSettings } = this.context.appContext;
    const { search } = preview;
    const { favorites } = previewSettings;
    const { metaVehicleId, vin } = search;
    const isFavorite = existUserPref(favorites, metaVehicleId, vin);
    this.setState({ isFavorite });
  };
  renderFavoriteIcon = isFavorite => {
    return isFavorite ? (
      <IconStar htmlId="IconFavorite" />
    ) : (
      <IconStarEmpty htmlId="IconFavorite" />
    );
  };
  refreshUserFavs = (dealerCode, username, previewSettings) => {
    const { setPreviewTabs } = this.context;
    getUserFavs(dealerCode, username, previewSettings, setPreviewTabs);
  };
  toggleFavorite = event => {
    const { dealerCode, preview, previewSettings } = this.context.appContext;
    const search = !previewSettings.search
      ? preview.search
      : previewSettings.search;
    const { make, metaVehicleId, vin } = search;
    const { isFavorite } = this.state;
    const addFavorite = !isFavorite;
    this.setState({ isFavorite: addFavorite });
    const username = this.context.appContext.user.userName;
    const userFavs = {
      username,
      make,
      metaVehicleId,
      dealerCode,
      vin: !vin ? "" : vin
    };
    console.log("Fav", preview);
    if (addFavorite) {
      addUserFav(userFavs, () => {
        this.refreshUserFavs(dealerCode, username, previewSettings);
      });
    } else {
      deleteUserFav(userFavs, () => {
        this.refreshUserFavs(dealerCode, username, previewSettings);
      });
    }
  };

  getPreviewBadge() {
    const { showPreviewBadge } = this.state;
    const clsHide = showPreviewBadge ? "xmm-popover-badge" : "hide";
    return (
      <div className="header-manage-badge">
        <div className={clsHide}>
          <Badge htmlId="previewBadge" color="yellow" className="">
            {this.partsNotAvailableLbl}
          </Badge>
          <Tooltip
            htmlId="tipRight"
            position="bottom"
            tooltipContent={this.partsLaborMsg}
          >
            <IconInfoOutline htmlId="iconInfo" />
          </Tooltip>
        </div>
      </div>
    );
  }
  render() {
    const { vehicle } = this.props;
    let actionLink = null;
    if (Object.keys(vehicle).length !== 0) {
      actionLink = (
        <Button
          htmlId="ChangeVehicleLink"
          buttonStyle="link"
          onClick={this.onActionClick}
        >
          {this.changeVehicleLabel}
          <span className="xmm-link-caret" />
        </Button>
      );
    }
    const unpublishButtonStyle = this.state.isPreview ? "primary" : "default";
    const publishButtonStyle = !this.state.isPreview ? "published" : "default";
    /*
    const countWidget = (
      <div className="xmm-count-msg hidden">
        <span>
          <h5>Data placeholder</h5>
          <h1>{250}</h1>
        </span>
        <span>
          <h5>Data placeholder</h5>
          <h1> {250}</h1>
        </span>
      </div>
    );
    */
    const normalLabel =
      this.context.localeStrings["xmm.portal.preview.vehicle.normal_lbl"];
    const severeLabel =
      this.context.localeStrings["xmm.portal.preview.vehicle.severe_lbl"];
    /* show/hide or disable driving conditions radio here */
    let showRadio = false;
    let disableRadio = true;
    if (this.context.drivingConditions.length === 2) {
      showRadio = true;
      disableRadio = false;
    }
    if (this.context.drivingConditions.length === 1) {
      showRadio = true;
    }
    const { makeVariantMap, preview, dealer } = this.context.appContext;
    // const globalOpsPreviewTabSelected =
    //   preview.resultTabs.selectedIndex === 2 && dealer.commonOpsRepairsEnabled;
    const globalOpsPreviewTabSelected = false;
    const showDrivingConditions =
      vehicle.make != "OTHER"
        ? makeVariantMap[vehicle.make].showDrivingConditions
        : false;
    const classHide =
      !globalOpsPreviewTabSelected && showDrivingConditions && showRadio
        ? ""
        : "hide";
    const classDisable = disableRadio
      ? "xmm-nowrap xmm-disabled"
      : "xmm-nowrap";
    const { isFavorite } = this.state;
    const favoriteIcon = this.renderFavoriteIcon(isFavorite);
    return (
      <div className="xmm-vehicle-header">
        <div className="flex-item">
          <div className="xmm-vehicle-card">
            <div
              className="vehicle-image"
              style={{ backgroundImage: `url(${vehicle.image})` }}
            />
            <div>
              <strong>{vehicleTitle(vehicle)}</strong>
              <div>
                {vehicle.trim} {vehicle.engineType} {vehicle.engineSize}{" "}
                {vehicle.driveType} {vehicle.transmissionType}
              </div>
              <div>{vehicle.matchVinFlag ? vehicle.vin : ""}</div>
              <div className={classHide} disabled={disableRadio}>
                <RadioButtonList
                  htmlId="drivingConditionRadio"
                  className={classDisable}
                  name="drivingCondition"
                  label={this.drivingConditionLabel}
                  layout="horizontal"
                  onChange={this.onRadioChange}
                  value={this.state.drivingCondition || "Normal"}
                  options={[
                    { value: "Normal", label: normalLabel },
                    { value: "Severe", label: severeLabel }
                  ]}
                  inline
                />
              </div>
            </div>
            <div className="xmm-icon-col">
              <Button
                htmlId="favoriteVehicle"
                className="btn--icon"
                aria-label="Add"
                buttonStyle="link"
                size="sm"
                onClick={this.toggleFavorite}
              >
                {favoriteIcon}
              </Button>
            </div>
          </div>
        </div>

        <div className="flex-item flex-top">{this.getPreviewBadge()}</div>
        <div className="xmm-toggle-col">
          <ButtonGroup disabled={false}>
            <Button
              htmlId="unpublishBtn"
              name="unpublish"
              buttonStyle={unpublishButtonStyle}
              size="sm"
              onClick={this.togglePublish}
            >
              {this.unpublishedLabel}
            </Button>
            <Button
              htmlId="publishBtn"
              name="publish"
              buttonStyle={publishButtonStyle}
              onClick={this.togglePublish}
              size="sm"
            >
              {this.publishedLabel}
            </Button>
          </ButtonGroup>

          <div className="float-right">{actionLink}</div>
        </div>
      </div>
    );
  }
}

VehicleDetails.propTypes = {
  vehicle: PropTypes.object,
  showPreviewBadge: PropTypes.bool
};
export default VehicleDetails;

/* eslint-enable no-console */
