/* eslint-disable no-console */
import React, { Component } from "react";
import { PropTypes } from "prop-types";
import Card from "@cx/ui/Card";
import Row from "@cx/ui/Row";
import Col from "@cx/ui/Col";
import SearchableSelect from "@cx/ui/SearchableSelect";
import Button from "@cx/ui/Button";
import { makeSecureRestApi } from "../../../../../api/xmmAxios";
import { defaultToZeroIfNull } from "../../../../../commonUtil/utils/value";
import { PackageContext } from "../package-context";
import { isDifferentValue } from "../../../../../commonUtil/utils/string";
import { toastMessageFormatter } from "../../../../../commonUtil/utils/formatter";
import * as gtmEvents from "../../../../utils/gtag-eventlist";
import StatusBox from "../../../../../commonUtil/components/templates/StatusBox";
import { toast } from "@cx/ui/Toast";
import { xlate } from "../../../../../commonUtil/i18n/locales";

class MenuTypeField extends Component {
  static contextType = PackageContext;
  static propTypes = {
    onChangeField: PropTypes.func
  };
  constructor(props, context) {
    super(props, context);
    this.onBlur = this.onBlur.bind(this);
    this.onChangeField = this.onChangeField.bind(this);
    this.updateStatusBox = this.updateStatusBox.bind(this);
    this.initializeLocaleValues();
    this.state = {
      menuTypeId: "",
      bulkEditMake: context.bulkEditMake,
      disableAction: true,
      dirty: false,
      valid: false,
      errors: {
        menuTypeId: ""
      },
      rawMenuTypes: [],
      menuTypes: []
    };
  }

  componentDidMount() {
    if (this.state.bulkEditMake) {
      this.loadMenuTypes();
    }
  }
  initializeLocaleValues() {
    this.menuTypeLabel = xlate("xmm.portal.common.menutype");
    this.bulkeditTextLabel = xlate(
      "xmm.portal.common.bulkedit_packages_lbl"
    ).replace("%1", this.menuTypeLabel);
    this.selectLabel = xlate("xmm.portal.common.select_label");
    this.applyEditLabel = xlate("xmm.portal.common.apply_edit");
    this.correctInvalidFielsError = xlate(
      "xmm.portal.errors.correct_invalid_fields"
    );
    this.savingMsg = xlate("xmm.portal.common.saving");
    this.savedMsg = xlate("xmm.portal.common.saved");
    this.saveError = xlate("xmm.portal.errors.save_data_error");
  }
  // validate value on blur event
  // NOTE: CX BUG - event.target.name missing in NumberInput, TextInput, TextArea
  onBlur = (cxEvent, isValid, domEvent) => {
    const { name, value } = cxEvent.target;
    const optionValue = value && value.length !== 0 ? value[0].value : "";

    if (isDifferentValue(this.state[name], optionValue)) {
      this.onChangeField(cxEvent, isValid, domEvent);
    } else {
      const valid = this.validate(name);
      this.setState({ valid });
    }
  };

  onChangeField = (cxEvent, isValid, domEvent) => {
    const { name, value } = cxEvent.target;
    const optionValue = value && value.length !== 0 ? value[0].value : "";

    // console.log("menu change", name, optionValue, value);
    if (isDifferentValue(this.state[name], optionValue)) {
      this.markDirty(name);
    }
    if (value && value.length !== 0) {
      // const { menuTypeId } = value[0];
      this.setState(
        prevState => {
          const { errors } = prevState;
          return {
            [name]: optionValue,
            errors
          };
        },
        () => {
          // callback to validate form
          this.validate(name);
        }
      );
    }
  };
  // validation utils
  // call this for each field change event
  markDirty(fieldName, validate) {
    const dirty = true;
    if (validate) {
      const valid = this.validate(fieldName);
      this.setState({ dirty, valid });
    } else {
      this.setState({ dirty });
    }
  }

  /* common validator called upon onblur() of each field */
  validate(fieldName) {
    const { errors } = this.state;
    const { menuTypeId } = this.state;
    let valid = false;
    if (!fieldName || fieldName === "menuTypeId") {
      if (!menuTypeId) {
        errors["menuTypeId"] = "Menu Type is Required.";
        valid = false;
      } else {
        errors["menuTypeId"] = "";
        valid = true;
      }
    }
    this.setState({ valid, errors });
    return valid;
  }
  loadMenuTypes = () => {
    const { dealerCode, locale } = this.context;
    const params = {
      dealerCode,
      locale,
      make: this.state.bulkEditMake
    };
    makeSecureRestApi(
      {
        url: "/ops/proxyapi/ddsproxy/rest/proc/getEnhancedMenuTypes",
        method: "get",
        data: {},
        params
      },
      data => {
        const menuTypes = [];
        // sort the menu types by rank
        const rawMenuTypes = data.sort(this.menuTypeComparator);
        rawMenuTypes.forEach(m => {
          // display only un-selectable menus (i,e selectable = 0)
          if (m.selectable === 0) {
            m.label = m.description;
            m.value = m.menuTypeId.toString();
            menuTypes.push(m);
          }
        });
        const disableAction = menuTypes.length === 0 ? true : false;
        this.setState({ menuTypes, rawMenuTypes, disableAction }, () => {
          // console.log("bulkedit sorted menus", menuTypes);
        });
      },
      error => {
        toast.error(error.message);
      }
    );
  };
  /* This comparator will orderby enabled menus and ascending by rank */
  menuTypeComparator = (menuType1, menuType2) => {
    if (!menuType1 || !menuType2) {
      return -1;
    }
    const make1 = menuType1.make.toUpperCase();
    const make2 = menuType2.make.toUpperCase();
    const comparison = 0;
    if (make1 === make2) {
      const enabled1 = menuType1.enabled;
      const enabled2 = menuType2.enabled;
      if (enabled1 === enabled2) {
        const rank1 = Number(defaultToZeroIfNull(menuType1.rank).toString());
        const rank2 = Number(defaultToZeroIfNull(menuType2.rank).toString());
        if (rank1 === rank2) {
          return 0;
        }
        return rank1 > rank2 ? 1 : -1;
      }
      return enabled1 > enabled2 ? -1 : 1;
    }
    return comparison;
  };
  getEditPayload() {
    const { appContext, selectionlist } = this.context;
    const { locale } = appContext;
    const { menuTypeId } = this.state;
    const selections = [];
    const requestObj = {
      requestId: "",
      authId: ""
    };
    if (selectionlist && selectionlist.length > 0) {
      selectionlist.forEach(op => {
        if (!op.id) {
          // donot add to list
        } else {
          selections.push(op.id.toString());
        }
      });
      requestObj["packages"] = selections;
    }
    if (menuTypeId) {
      requestObj.menuTypeId = parseInt(menuTypeId, 10);
      requestObj.locale = locale;
    }
    // packages.forEach(key => {
    //   const cloneObj = Object.assign({}, request);
    //   cloneObj.id = key.id;
    //   postArray.push(cloneObj);
    // });

    // console.log("bulkedit postobj", requestObj);
    return requestObj;
  }
  /* This func) checks for error object has strings with {null,"", undefined} in given object
  and returns count for error strings.*/
  hasErrorStrings(state) {
    const array1 = Object.values(state);
    const iterator = array1.values();
    let errCount = 0;
    for (const value of iterator) {
      if (value === "" || value === null || value.length === 0) {
        // In case of valid error string
      } else if (value && value.length > 1) {
        errCount++;
      }
    }
    return errCount === 0 ? false : true;
  }
  handleApply = e => {
    e.preventDefault();
    this.updateStatusBox(this.savingMsg, "pending", false);
    const { errors, menuTypeId } = this.state;
    let isValid = true;
    // check if any errors exist on fields
    if (Object.keys(errors).length) {
      isValid = !this.hasErrorStrings(errors);
    }
    // cancel save if we have errors on fields
    if (!isValid || !menuTypeId) {
      // toast.warning(this.correctInvalidFielsError);
      this.updateStatusBox(this.correctInvalidFielsError, "warning", false);
      return;
    }
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };
    const payload = this.getEditPayload();
    const restEndPoint =
      "ops/proxyapi/ddsproxy/rest/proc/bulkUpdPkgOvrdMenuType";
    // call rest api
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "post",
        data: payload,
        params: {},
        headers
      },
      data => {
        if (data) {
          // const regex = /\n/gi;
          let msgToShow = "";
          // Error case
          if (data.error) {
            msgToShow =
              "Unable to save Bulk Edit for selected packages at this time. Please try again.";
            // toast.error(msgToShow, {
            //   autoClose: 5000,
            //   closeOnClick: true
            // });
            this.updateStatusBox(msgToShow, "error", false, true);
          }
          // Overlap case - duplicate packages found
          if (data.response && data.response.statusCode !== 0) {
            // toast.error(toastMessageFormatter(msgToShow), {
            //   autoClose: 30000,
            //   closeOnClick: true
            // });
            msgToShow = toastMessageFormatter(data.response.statusMessage);
            this.updateStatusBox(msgToShow, "error", false, true);
          } else if (data.response && data.response.statusCode === 0) {
            // Success case
            this.updateStatusBox(this.savedMsg, "success", true);
            // callback to close slider and refresh grid rows.
            setTimeout(() => {
              this.props.closeSlider();
            }, 2000);
            const record = {
              menuTypeId: this.state.menuTypeId,
              make: this.state.bulkEditMake
            };
            this.context.updateGridAfterSave(record);
          }
        }
      },
      error => {
        const msg = error["message"] ? error.message : this.saveError;
        this.updateStatusBox(msg, "error", false, true);
      }
    );
    this.trackBulkEditEvent("MenuType");
  };
  // push event to GTM
  trackBulkEditEvent = fieldName => {
    const tagObj = {
      eventResult: "Bulk Edit - " + fieldName,
      eventFeature: "Menus> Packages Page",
      eventFeatures: "packages page> BulkEdit> " + fieldName,
      trackPageUrl: "menus/packages/bulk-edit/" + fieldName.toLowerCase()
    };
    gtmEvents.gtmTrackEventWithParams("xmm.packages.bulk_edit_click", tagObj);
  };
  updateStatusBox(msg, type, close, errorInTooltip) {
    console.log("status", msg, type, close);
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
    sleep(0).then(() => {
      this.setState({
        statusMsg: msg,
        autoClose: close,
        statusType: type,
        errorInTooltip
      });
    });
  }
  render() {
    const { disableAction, valid, dirty } = this.state;
    const alertMsg = null;
    let markDisabled = disableAction;
    markDisabled = !valid || !dirty;

    const statusBox = this.state.statusMsg ? (
      <StatusBox
        htmlId="statusBox"
        type={this.state.statusType}
        autoClose={this.state.autoClose}
        linkHtml={null}
        message={this.state.statusMsg}
        autoCloseTime={1500}
        errorInTooltip={this.state.errorInTooltip}
      />
    ) : (
      ""
    );
    // if (Object.keys(this.state.errors).length !== 0) {
    //   markDisabled = false; // returns true - when no errors
    // }

    return (
      <div>
        <Row>
          <Col xs={7} sm={7}>
            <div className="xmm-help-text">{this.bulkeditTextLabel}</div>
            <div className="xmm-error-msg">{alertMsg}</div>
          </Col>
          <Col xs={5} sm={5}>
            <div className="xmm-tab-bar no-margin">
              {statusBox}{" "}
              <Button
                htmlId="applyMenutypeBtn"
                className="float-right"
                size="sm"
                disabled={markDisabled}
                onClick={this.handleApply}
                buttonStyle="primary"
              >
                {this.applyEditLabel}
              </Button>
            </div>
          </Col>
        </Row>
        {/*
        <Row>
          <Col xs={9} sm={9}>
            <div className="xmm-help-text">{this.bulkeditTextLabel}</div>
            <div className="xmm-error-msg">{alertMsg}</div>
          </Col>
          <Col xs={3} sm={3}>
            {statusBox}{" "}
            <Button
              htmlId="applyMenutypeBtn"
              className="float-right"
              size="sm"
              disabled={markDisabled}
              onClick={this.handleApply}
              buttonStyle="primary"
            >
              {this.applyEditLabel}
            </Button>
          </Col>
        </Row>
         */}
        <Card>
          <Col xs={9} sm={9} className="full-col">
            <SearchableSelect
              displayPlaceholder={true}
              placeholder={this.selectLabel}
              className="xmm-scrollable-select"
              htmlId="menuTypeId"
              label={this.menuTypeLabel}
              name="menuTypeId"
              enableMultiSelect={false}
              maxHeight={150}
              onBlur={this.onBlur}
              onChange={this.onChangeField}
              options={this.state.menuTypes}
              value={this.state.menuTypeId}
              layout="horizontal"
              displayLabel={true}
              hasError={!!this.state.errors.menuTypeId}
              errorMessage={this.state.errors.menuTypeId}
            />
          </Col>
        </Card>
      </div>
    );
  }
}

export default MenuTypeField;

MenuTypeField.propTypes = {
  closeSlider: PropTypes.func
};
/* eslint-enable no-console */
