/* eslint-disable no-console */
import React from "react";
import SearchableSelect from "@cx/ui/SearchableSelect";
import Grid from "@cx/ui/Grid";
import Row from "@cx/ui/Row";
import Col from "@cx/ui/Col";
import Button from "@cx/ui/Button";
import { PropTypes } from "prop-types";
import TextInput from "@cx/ui/TextInput";
import {
  findRecord,
  isEmpty,
  hasCappedPricing,
  doesEmpty
} from "../../../../commonUtil/utils/object";
import { makeSecureRestApi } from "../../../../api/xmmAxios";
import { toast } from "@cx/ui/Toast";
import PreviewContext from "../preview-context";
import {
  SearchFieldTemplate
  // VehicleDescriptor
} from "../../../../constants/ModuleConstants";
import * as gtmEvents from "../../../utils/gtag-eventlist";
import { xlate } from "../../../../commonUtil/i18n/locales";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import { enCA, enUS, enAU, frCA } from "date-fns/locale";
import "react-datepicker/dist/react-datepicker.css";
import { toEmptyStringIfUndefined } from "../../../../commonUtil/utils/string";
import otherMakeServices from "../services/preview-services";

class VehicleSelector extends React.Component {
  static contextType = PreviewContext;
  static propTypes = {
    onChange: PropTypes.func,
    onVinChange: PropTypes.func
  };
  constructor(props, context) {
    super(props, context);
    this.selectActiveTab = this.selectActiveTab.bind(this);
    this.filterByMake = this.filterByMake.bind(this);
    this.onSearchAction = this.onSearchAction.bind(this);
    this.triggerSearch = this.triggerSearch.bind(this);
    this.onClear = this.onClear.bind(this);
    this.onYearChange = this.onYearChange.bind(this);
    this.onModelChange = this.onModelChange.bind(this);
    this.onTrimChange = this.onTrimChange.bind(this);
    // this.onEngineTypeChange = this.onEngineTypeChange.bind(this);
    // this.onEngineSizeChange = this.onEngineSizeChange.bind(this);
    this.onTransmissionTypeChange = this.onTransmissionTypeChange.bind(this);
    this.onDrivingConditionChange = this.onDrivingConditionChange.bind(this);
    this.onVinChange = this.onVinChange.bind(this);
    this.updatePreviewSettings = this.updatePreviewSettings.bind(this);
    this.updateState = this.updateState.bind(this);
    // this.onVinBlur = this.onVinBlur.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.onMakeChange = this.onMakeChange.bind(this);
    this.loadYearsForOtherMake = this.loadYearsForOtherMake.bind(this);

    // Read values from App context
    const { localeStrings, previewSettings } = context.appContext;
    // preview.previewTabs.selectedIndex = props.selectedIndex;
    const { search } = previewSettings;
    const initSearch = !isEmpty(search)
      ? search
      : Object.assign({}, SearchFieldTemplate);
    const selectedMake =
      initSearch.make !== ""
        ? findRecord(context.makelist, "make", initSearch.make)
        : {};
    const { selectors } = previewSettings;
    const isCappedPricing = this.checkCappedPricing(selectedMake);
    const regDate = !doesEmpty(initSearch.regDate) ? initSearch.regDate : "";
    const {
      // makes,
      models,
      years,
      trims,
      engineSizeTypes,
      driveTypes,
      transmissionTypes,
      drivingConditions
    } = selectors;
    this.initializeLocaleValues(localeStrings);
    this.state = {
      supportedMakes: [...context.makelist, { label: "OTHER", value: "OTHER" }],
      vehicleInfo: {},
      vin: toEmptyStringIfUndefined(initSearch.vin),
      regDate,
      isCappedPricing,
      selectedMake,
      search: initSearch,
      showResult: false, // true - when results are ready
      data: null,
      // selectors data
      models, // : [],
      years, // : [],
      trims, // : [],
      engineSizeTypes, // : [],
      // engineTypes: [],
      // engineSizes: [],
      driveTypes, // : [],
      transmissionTypes, // : [],
      drivingConditions, // : [],
      isMakeLoaded: false,
      warning: "",
      errors: {},
      error: null
    };
  }
  /**
   * Add event listeners
   * when an instance of a component is being created and inserted into the DOM
   */
  componentDidMount() {
    // console.log("vehicle selector Cmp didmount", this.props.renderFrom);
    window.addEventListener("previewWithParams", this.triggerSearch, false);
    this.selectActiveTab();
    this.preSelectMake();
    gtmEvents.trackGtagPageview("/preview/select-vehicle");
  }

  /**
   * Remove event listeners
   * when a component is being removed from the DOM
   */
  componentWillUnmount() {
    window.removeEventListener("previewWithParams", this.triggerSearch, false);
  }
  /* handlers for Registration Date field */

  onDateChange = date => {
    const name = "regDate";
    const { regDate, search } = this.state;
    const { errors } = Object.assign({}, this.state);
    if (regDate === date) {
      return;
    }
    errors[name] = !doesEmpty(date)
      ? ""
      : xlate("xmm.portal.errors.date_required");
    if (!doesEmpty(date)) {
      // const regDate = getRegDate(date, "YYYY-MM-DD");
      search.regDate = date;
      console.log("date change", date);
      this.setState({
        search,
        [name]: date,
        errors
      });
    } else {
      search.regDate = "";
      this.setState({
        errors,
        search,
        [name]: ""
      });
    }
  };

  initializeLocaleValues(localeStrings) {
    registerLocale("fr_CA", frCA);
    registerLocale("en_US", enUS);
    registerLocale("en_CA", enCA);
    registerLocale("en_AU", enAU);
    setDefaultLocale(this.context.appContext.locale);
    this.dateFieldFormat = this.getDateFieldFormat();
    this.selectLabel = localeStrings["xmm.portal.common.select_label"];
    this.makeLabel = localeStrings["xmm.portal.common.make_lbl"];
    this.yearLabel = localeStrings["xmm.portal.showvehicles.grid.year"];
    this.modelLabel = localeStrings["xmm.portal.showvehicles.grid.model"];
    this.trimLabel = localeStrings["xmm.portal.preview.vehicle.trim_lbl"];
    this.engineLabel = localeStrings["xmm.portal.preview.vehicle.engine_lbl"];
    this.driveLabel = localeStrings["xmm.portal.preview.vehicle.drive_lbl"];
    this.transmissionLabel =
      localeStrings["xmm.portal.preview.vehicle.transmission_lbl"];
    this.decodeLabel = localeStrings["xmm.portal.preview.vehicle.decode_lbl"];
    this.decodeVinOptionalLabel =
      localeStrings["xmm.portal.preview.vehicle.decode_vin_optional_lbl"];
    this.previewVehicleLabel =
      localeStrings["xmm.portal.preview.vehicle.button_lbl"];
    this.cancelLabel = localeStrings["xmm.portal.common.cancel_button"];
    this.selectDateLabel = localeStrings["xmm.portal.common.select"];
    this.registrationDateLabel =
      localeStrings["xmm.portal.common.registration_date"];
    this.vinNotMatchingSelectors =
      localeStrings["xmm.portal.preview.vehicle.vin_not_matching_selectors"];
  }
  getDateFieldFormat() {
    const { locale } = this.context;
    switch (locale) {
      case "en_CA":
      case "fr_CA":
        return "yyyy/MM/dd";
      case "en_AU":
        return "dd/MM/yyyy";
      default:
        return "MM/dd/yyyy";
    }
  }
  selectActiveTab = () => {
    const { filterByMake } = this;
    window.dispatchEvent(
      new CustomEvent("onActivePreviewTab", {
        detail: { name: "VehicleSelector", filterByMake },
        bubbles: true,
        cancelable: true
      })
    );
  };

  onVinDecode = async event => {
    event.preventDefault();
    const { vin } = this.state;
    if (vin.length !== 17) {
      const { errors } = this.state;
      errors.vin = "17 Characters Required";
      this.setState({ errors, warning: "" });
      // } else if (errors.vin !== "") {
      // const { errors } = this.state;
      // errors.vin = "17 Characters Required";
      // this.setState({ errors, warning: "" });
    } else {
      this.decodeVin(vin);
      // GTM - push click event to dataLayer
      gtmEvents.gtmTrackEvent("xmm.preview.vin_decode_click");
    }
  };
  /*
  // This handler not used for VIN field
  onVinBlur = (cxEvent, isValid, domEvent) => {
    if (cxEvent.preventDefault) {
      cxEvent.preventDefault();
    }
    if (cxEvent.stopPropagation) {
      cxEvent.stopPropagation();
    }
  };
 */

  onVinChange = (cxEvent, isValid, domEvent) => {
    const { name, value } = cxEvent.target;
    if (!isAlphaNumeric(value)) {
      return;
    }
    const { search, showMsg, errors } = this.state;
    if (!showMsg) {
      // user is entering text
      errors.vin = "";
      this.setState({ errors, warning: "" });
    } else {
      // reset the showMsg flag so that subsequence user input can clear the message
      this.setState({ showMsg: false });
    }

    // then can change values on the copy.
    // errors[name] = isValid ? "" : "Invalid VIN";
    search[name] = value;
    this.setState({ vin: value });
    // console.log("change", this.state.showResult, isValid, value);
    if (value) {
      this.setState({
        showResult: false
      });
    } else {
      this.setState({
        showResult: false,
        warning: ""
      });
    }
  };
  onVinBlur = (cxEvent, isValid, domEvent) => {
    const { value } = cxEvent.target;
    const { errors } = this.state;
    switch (value.length) {
      case 0:
        errors.vin = "";
        this.setState({ warning: "", showMsg: false });
        break;
      case 17:
        break;
      default:
        // if less than 17 chars
        errors.vin = "17 Characters Required";
        this.setState({ errors, showMsg: true });
        break;
    }
  };

  /* This method will pre-select make in case we have one make; else show all makes  */
  preSelectMake() {
    const rawMakes = this.context.makelist;
    // console.log("preselect - context makes[]", rawMakes);
    const makeExist = rawMakes.length > 0 ? true : false;
    if (rawMakes && rawMakes.length === 1) {
      const selectedMake = rawMakes[0];
      const make = selectedMake && selectedMake.value;
      const isCappedPricing = hasCappedPricing(rawMakes, make);
      // When dealer has single Make, load Years
      this.setState(
        prevState => {
          return {
            selectedMake,
            isMakeLoaded: makeExist,
            make,
            isCappedPricing,
            search: {
              ...prevState.search,
              make,
              defaultDrivingCondition: selectedMake.defaultDrivingCondition
            }
          };
        },
        () => {
          // console.log("preSelected make{} ", selectedMake);
          this.loadYears(selectedMake);
        }
      );
    } else {
      // Do Nothing. search will refer to constr() state
    }
  }
  resetChanges = (cxEvent, isValid) => {
    const { name } = cxEvent.target;
    // const { search } = this.state;
    // search[name] = "";
    // this.setState({ search });
    switch (name) {
      case "make":
        this.onMakeChange(cxEvent, isValid);
        break;
      case "year":
        this.onYearChange(cxEvent, isValid);
        break;
      case "model":
        this.onModelChange(cxEvent, isValid);
        break;
      case "trim":
        this.onTrimChange(cxEvent, isValid);
        break;
      case "engineSizeType":
        this.onEngineSizeTypeChange(cxEvent, isValid);
        break;
      case "driveType":
        this.onDriveTypeChange(cxEvent, isValid);
        break;
      case "transmissionType":
        this.onTransmissionTypeChange(cxEvent, isValid);
        break;
      default:
        break;
    }
  };
  onBlur = (cxEvent, isValid, domEvent) => {
    const { search } = this.state;
    const { name, value } = cxEvent.target;
    const optionValue = value && value.length !== 0 ? value[0].value : "";
    if (search[name] !== optionValue) {
      this.resetChanges(cxEvent, isValid);
    }
  };
  onMakeChange = async (event, isValid) => {
    const { name, value } = event.target;
    const { search } = this.state;
    const { make } = search;
    const optionValue = value && value.length !== 0 ? value[0].value : "";
    if (optionValue === make) {
      return;
    }
    search[name] = optionValue;
    this.resetSearchListByMake();
    if (optionValue === "OTHER") {
      // search.model = search.modelValue = "OTHER";
      // search.year = "1950";
    }
    const warning = this.validateVinVehicleSelectors(search);
    const showMsg = warning ? true : false;
    this.setState({ warning, search, showMsg, vinDecode: false }, async () => {
      if (optionValue === "OTHER") {
        // const { webKey } = this.context.appContext;
        // const otherYearsResponse = await otherMakeServices.getYearsForOtherMake(
        //   webKey,
        //   optionValue
        // );
        // if (otherYearsResponse) {
        //   console.log(otherYearsResponse);
        //   this.loadYearsForOtherMake(otherYearsResponse);
        // }
      } else if (optionValue !== "") {
        this.findMake(optionValue);
      }
    });
  };
  filterByMake = make => {
    this.resetSearchListByMake();
    const { search } = this.state;
    search.make = make;
    this.setState({ search, vinDecode: false }, () => {
      this.findMake(make);
    });
  };
  /* This method to find make from All makes validate status object  */
  findMake(makeName) {
    const rawMakes = this.context.makelist;
    const property = "make";
    const selectedMake = findRecord(rawMakes, property, makeName);
    const isCappedPricing = hasCappedPricing(rawMakes, makeName);
    if (Object.keys(selectedMake).length > 0) {
      this.setState(
        prevState => {
          return {
            selectedMake,
            isCappedPricing,
            regDate: "",
            search: {
              ...prevState.search,
              make: makeName,
              regDate: "",
              defaultDrivingCondition: selectedMake.defaultDrivingCondition
            },
            showMask: true
          };
        },
        () => {
          if (selectedMake.make) {
            this.loadYears(selectedMake);
          }
        }
      );
    }
  }
  // This fun() passed as props to child and callback using parent ref
  // each search click event, update state with searchId, search props, so child com will mount new props
  onSearchAction = event => {
    event.preventDefault();
    const { search, drivingConditions } = this.state;
    const { make, year, modelValue } = search;
    if (make === "OTHER" || (make && year && modelValue)) {
      const { previewVehicle } = this.context;
      console.log(
        "onSearchAction calling previewVehicle with values",
        search,
        drivingConditions
      );
      const matchVinFlag = !this.validateVinVehicleSelectors(search);
      search.matchVinFlag = matchVinFlag;
      previewVehicle(search, drivingConditions, this.updateState);
    }
  };

  updateState = () => {
    const { search } = this.state;
    this.setState({ search, warning: "" });
    this.updatePreviewSettings();
  };

  // previewVehicle = () => {
  //   this.context.updateMask(true);
  //   this.getDefaultMetaVehicleAndDetail();
  // };

  onClear = event => {
    event.preventDefault();
    this.resetSearchList();
  };

  onYearChange = (cxEvent, isValid, domEvent) => {
    const { search, isCappedPricing } = this.state;
    const value = cxEvent.target.value;
    const { make, year } = search;
    const optionValue = value && value.length !== 0 ? value[0].value : "";
    let regDate = "";

    if (optionValue === year) {
      return;
    }

    search.year = optionValue;

    if (make === "OTHER") {
      search.model = "OTHER";
      this.setState({ search, models: [{ label: "OTHER", value: "OTHER" }] });
      return;
    }

    search.regDate = regDate;
    if (isCappedPricing) {
      regDate = this.formatRegisterDate(optionValue);
      search.regDate = regDate;
      console.log("year change", regDate);
    }
    this.resetSearchListByYear();
    const warning = this.validateVinVehicleSelectors(search);
    const showMsg = warning ? true : false;
    this.setState(
      { warning, search, showMsg, vinDecode: false, regDate },
      () => {
        if (optionValue !== "") {
          this.loadModels();
        }
      }
    );
  };

  onModelChange = (cxEvent, isValid, domEvent) => {
    const { search } = this.state;
    const value = cxEvent.target.value;
    const { modelValue } = search;
    const optionValue = value && value.length !== 0 ? value[0].value : "";
    if (optionValue === modelValue) {
      return;
    }
    this.resetSearchListByModel();
    const optionLabel =
      value && value.length !== 0 ? value[0].label : optionValue;
    search.model = optionLabel;
    search.modelValue = optionValue;
    const warning = this.validateVinVehicleSelectors(search);
    const showMsg = warning ? true : false;
    this.setState({ warning, search, showMsg, vinDecode: false }, () => {
      if (optionValue !== "") {
        this.loadTrims();
      }
    });
  };

  onTrimChange = (cxEvent, isValid, domEvent) => {
    const { search } = this.state;
    const value = cxEvent.target.value;
    const { trimValue } = search;
    const optionValue = value && value.length !== 0 ? value[0].value : "";
    if (optionValue === trimValue) {
      return;
    }
    const optionLabel =
      value && value.length !== 0 ? value[0].label : optionValue;
    search.trim = optionLabel;
    search.trimValue = optionValue;
    this.resetSearchListByTrim();
    const warning = this.validateVinVehicleSelectors(search);
    const showMsg = warning ? true : false;
    this.setState({ warning, search, showMsg, vinDecode: false }, () => {
      if (optionValue !== "") {
        this.loadEngineSizeTypes(search.trimValue);
      }
    });
  };

  onEngineSizeTypeChange = (cxEvent, isValid, domEvent) => {
    const { search, engineSizeTypes } = this.state;
    const value = cxEvent.target.value;
    const { engineSizeTypeValue } = search;
    const optionValue = value && value.length !== 0 ? value[0].value : "";
    if (optionValue === engineSizeTypeValue) {
      return;
    }
    const optionLabel =
      value && value.length !== 0 ? value[0].label : optionValue;
    search.engineSizeType = optionLabel;
    search.engineSizeTypeValue = optionValue;
    this.resetSearchListByEnginieSizeType();
    if (search.engineSizeType && search.engineSizeType.indexOf("," !== -1)) {
      const sizeAndTypes = search.engineSizeType.split(",");
      search.engineSize = sizeAndTypes[0].trim();
      search.engineType = sizeAndTypes[1].trim();
      const sizeAndTypeValues = search.engineSizeTypeValue.split("|");
      search.engineSizeValue = sizeAndTypeValues[0].trim();
      search.engineTypeValue = sizeAndTypeValues[1].trim();
      const warning = this.validateVinVehicleSelectors(search);
      const showMsg = warning ? true : false;
      this.setState({ warning, search, showMsg, vinDecode: false }, () => {
        if (optionValue !== "") {
          this.loadDriveTypes(search.engineSizeTypeValue, engineSizeTypes);
        }
      });
    }
  };

  // onEngineTypeChange = (cxEvent, isValid, domEvent) => {
  //   const { search } = this.state;
  //   const value = cxEvent.target.value;
  //   const { engineType } = search;
  //   const optionValue = value && value.length !== 0 ? value[0].value : "";
  //   if (optionValue === engineType) {
  //     return;
  //   }
  //   search.engineType = optionValue;
  //   this.setState({ search }, () => {
  //     if (optionValue !== "") {
  //       this.loadEngineSizes(search.engineType);
  //     }
  //   });
  // };

  // onEngineSizeChange = (cxEvent, isValid, domEvent) => {
  //   const { search, engineSizeTypes } = this.state;
  //   const value = cxEvent.target.value;
  //   const { engineSize } = search;
  //   const optionValue = value && value.length !== 0 ? value[0].value : "";
  //   if (optionValue === engineSize) {
  //     return;
  //   }
  //   search.engineSize = optionValue;
  //   this.setState({ search }, () => {
  //     if (optionValue !== "") {
  //       this.loadDriveTypes(search.engineSize, engineSizeTypes);
  //     }
  //   });
  // };

  onDriveTypeChange = (cxEvent, isValid, domEvent) => {
    const { search, driveTypes } = this.state;
    const value = cxEvent.target.value;
    const { driveTypeValue } = search;
    const optionValue = value && value.length !== 0 ? value[0].value : "";
    if (optionValue === driveTypeValue) {
      return;
    }
    this.resetSearchListByDriveType();
    const optionLabel =
      value && value.length !== 0 ? value[0].label : optionValue;
    search.driveType = optionLabel;
    search.driveTypeValue = optionValue;
    const warning = this.validateVinVehicleSelectors(search);
    const showMsg = warning ? true : false;
    this.setState({ warning, search, showMsg, vinDecode: false }, () => {
      if (optionValue !== "") {
        this.loadTransmissionTypes(search.driveTypeValue, driveTypes);
      }
    });
  };

  onTransmissionTypeChange = (cxEvent, isValid, domEvent) => {
    const { search, transmissionTypes } = this.state;
    const value = cxEvent.target.value;
    const { transmissionTypeValue } = search;
    const optionValue = value && value.length !== 0 ? value[0].value : "";
    if (optionValue === transmissionTypeValue) {
      return;
    }
    const optionLabel =
      value && value.length !== 0 ? value[0].label : optionValue;
    search.transmissionType = optionLabel;
    search.transmissionTypeValue = optionValue;
    const warning = this.validateVinVehicleSelectors(search);
    const showMsg = warning ? true : false;
    this.setState({ warning, search, showMsg, vinDecode: false });
    this.loadDrivingConditions(search.transmissionTypeValue, transmissionTypes);
  };

  onDrivingConditionChange = (cxEvent, isValid, domEvent) => {
    const { search } = this.state;
    const value = cxEvent.target.value;
    const { drivingCondition } = search;
    const optionValue = value && value.length !== 0 ? value[0].value : "";
    if (optionValue === drivingCondition) {
      return;
    }
    search.drivingCondition = optionValue;
    this.setState({ search });
  };
  checkCappedPricing = makeObj => {
    return (
      makeObj.hasOwnProperty("cappedPricing") && makeObj.cappedPricing === 1
    );
  };
  formatRegisterDate = year => {
    return !this.state.isCappedPricing
      ? ""
      : new Date("01/01/" + year.toString());
  };
  /* pre-fill selectors with search values for VIN decode or Edit vehicle case */
  loadPreviewCriteria = (vehicleInfo, actionCode) => {
    const { search } = this.state;
    let warning = "";
    search.make = vehicleInfo.make;
    search.year =
      search.model =
      search.trim =
      search.engineSizeType =
      search.engineSize =
      search.engineType =
      search.driveType =
      search.transmissionType =
        "";
    search.modelValue =
      search.trimValue =
      search.engineSizeTypeValue =
      search.engineSizeValue =
      search.engineTypeValue =
      search.driveTypeValue =
      search.transmissionTypeValue =
        "";
    this.setState(
      {
        vinDecode: true,
        search,
        vehicleInfo
      },
      () => {
        const { makelist } = this.context;
        const makes = makelist.filter(m => {
          return m.make === vehicleInfo.make;
        });

        if (makes.length !== 0) {
          const { make, variant } = makes[0];
          const isCappedPricing = this.checkCappedPricing(makes[0]);
          search.regDate = "";
          this.setState({
            search,
            isCappedPricing,
            regDate: ""
          });

          this.loadYears({ make, variant });
        } else {
          if (actionCode === "vinDecode") {
            warning = `This VIN number's make "${vehicleInfo.make}" is not supported for this dealer.`;
            this.setState(
              {
                showMsg: true,
                warning,
                search
              },
              async () => {
                this.onMakeChange({
                  target: {
                    name: "make",
                    value: [{ label: "OTHER", value: "OTHER" }]
                  }
                });
              }
            );
          } else {
            toast.info("Error while loading edit vehicle");
          }
        }
      }
    );
  };

  decodeVin(vin) {
    const { webKey } = this.context;
    const params = { webKey };
    const restEndPoint = `/ops/proxyapi/oeproxy/rest/vehicles/vin/${vin}/decode`;
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "get",
        params
      },
      data => {
        const { errors } = this.state;
        const { statusCode } = data;
        if (statusCode !== 0) {
          errors.vin = "Invalid VIN";
          this.setState({ errors, warning: "", showMsg: true });
          return;
        }
        errors.vin = "";
        const warning = "";
        this.setState({ errors, warning });
        const { vehicleInfo } = data;
        const {
          driveType,
          engineSize,
          engineType,
          model,
          transmissionType,
          trim
        } = vehicleInfo;
        vehicleInfo.driveTypeValue = toEmptyStringIfUndefined(driveType);
        vehicleInfo.engineSizeValue = toEmptyStringIfUndefined(engineSize);
        vehicleInfo.engineTypeValue = toEmptyStringIfUndefined(engineType);
        vehicleInfo.modelValue = model;
        vehicleInfo.transmissionTypeValue =
          toEmptyStringIfUndefined(transmissionType);
        vehicleInfo.trimValue = toEmptyStringIfUndefined(trim);
        this.loadPreviewCriteria(vehicleInfo, "vinDecode");
      },
      error => {
        const { errors } = this.state;
        errors.vin = error.message;
        this.setState({ errors, warning: "", showMsg: true });
        // toast.error(error.message);
      }
    );
  }

  validateVinVehicleSelectors(search) {
    const { vin, vehicleInfo } = this.state;
    if (search && vin && vehicleInfo) {
      const {
        make,
        year,
        modelValue,
        trimValue,
        driveTypeValue,
        engineSizeValue,
        engineTypeValue,
        transmissionTypeValue
      } = vehicleInfo;
      if (
        make !== search.make ||
        year !== search.year ||
        modelValue !== search.modelValue ||
        trimValue !== search.trimValue ||
        driveTypeValue !== search.driveTypeValue ||
        engineSizeValue !== search.engineSizeValue ||
        engineTypeValue !== search.engineTypeValue ||
        transmissionTypeValue !== search.transmissionTypeValue
      ) {
        return this.vinNotMatchingSelectors;
      }
    }
    return "";
  }

  loadYearsForOtherMake = data => {
    const { years } = data;
    const yearOptions = years.map(yr => {
      return { label: yr, value: yr };
    });
    this.setState(
      {
        isYearsLoaded: true,
        selectedMake: "OTHER",
        years: yearOptions
        // search
      },
      () => {
        // const { vinDecode } = this.state;
        // if (vinDecode) {
        const { search, vehicleInfo, isCappedPricing } = this.state;
        // let regDate = "";
        search.year = vehicleInfo.year;
        search.regDate = "";
        // if (isCappedPricing) {
        //   regDate = this.formatRegisterDate(vehicleInfo.year);
        //   search.regDate = regDate;
        // }
        this.setState({ search, regDate: "" });

        // this.loadModels();
        const modelOptions = [{ label: "OTHER", value: "OTHER" }];
        this.setState({ models: modelOptions }, () => {
          // const { vinDecode } = this.state;
          // if (vinDecode) {
          const { vehicleInfo } = this.state;
          if (modelOptions && modelOptions.length !== 0) {
            search.model = vehicleInfo.model;
            search.modelValue = vehicleInfo.modelValue;
            search.model = "OTHER";
            search.modelValue = "OTHER";
            search.year = vehicleInfo.year;
            // this.loadTrims();
            this.setState({ search });
          }
          // }
        });
      }
      // }
    );
  };

  loadYears(selectedMake) {
    const { webKey, locale } = this.context;
    const { make, variant } = selectedMake;
    const params = {};
    // const data = `webKey=${webKey}&variant=${variant}&locale=${locale}&isPreview=true`;
    const data = `webKey=${webKey}&scheduledOnly=0`;
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      Accept: "application/json"
    };
    const restEndPoint =
      "/ops/proxyapi/oeproxy/rest/vehicles/make/" + make + "/years";
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "post",
        params,
        data,
        headers
      },
      data => {
        const { years } = data;
        const yearOptions = years.map(yr => {
          return { label: yr, value: yr };
        });
        this.setState(
          {
            isYearsLoaded: true,
            selectedMake,
            years: yearOptions
          },
          () => {
            const { vinDecode } = this.state;
            if (vinDecode) {
              const { search, vehicleInfo, isCappedPricing } = this.state;
              let regDate = "";
              search.year = vehicleInfo.year;
              search.regDate = "";
              if (isCappedPricing) {
                regDate = this.formatRegisterDate(vehicleInfo.year);
                search.regDate = regDate;
              }
              this.setState({ search, regDate });
              this.loadModels();
            }
          }
        );
      },
      error => {
        toast.error(error.message);
      }
    );
  }

  loadModels() {
    const { webKey, locale } = this.context;
    const { search, selectedMake } = this.state;
    const { make, variant } = selectedMake;
    const params = {};
    const data = `webKey=${webKey}&variant=${variant}&locale=${locale}&isPreview=true&year=${search.year}`;
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      Accept: "application/json"
    };
    const restEndPoint =
      "/ops/proxyapi/oeproxy/rest/vehicles/make/" + make + "/models";
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "post",
        params,
        data,
        headers
      },
      data => {
        const { models } = data;
        const modelOptions = models.map(model => {
          return { label: model.label, value: model.data };
        });
        this.setState({ models: modelOptions }, () => {
          const { vinDecode } = this.state;
          if (vinDecode) {
            const { vehicleInfo } = this.state;
            if (modelOptions && modelOptions.length !== 0) {
              search.model = vehicleInfo.model;
              search.modelValue = vehicleInfo.modelValue;
              this.loadTrims();
              this.setState({ search });
            }
          } else if (modelOptions && modelOptions.length === 1) {
            this.onModelChange({
              target: { value: modelOptions }
            });
          }
        });
      },
      error => {
        toast.error(error.message);
      }
    );
  }

  loadTrims() {
    const { webKey, locale } = this.context;
    const { search, selectedMake } = this.state;
    const { modelValue, year } = search;
    const { make, variant } = selectedMake;
    // const params = `webKey=${webKey}&variant=${variant}&locale=${locale}&isPreview=true&useSkipTrim=0&page=1&start=0&limit=25`;
    const params = {
      webKey,
      variant,
      locale,
      isPreview: true,
      useSkipTrim: 0,
      page: 1,
      start: 0,
      limit: 25
    };
    const restEndPoint = `/ops/proxyapi/oeproxy/rest/vehicles/make/${make}/variant/${variant}/model/${modelValue}/year/${year}/trims`;
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "get",
        // data,
        params
      },
      data => {
        const { vehicleInfo } = data;
        let { trims } = vehicleInfo;
        if (!trims) {
          trims = [];
        }
        const trimOptions = trims.map(trim => {
          trim.label = trim.display;
          trim.value = trim.data;
          return trim;
        });
        search.defaultDrivingCondition = vehicleInfo.defaultDrivingCondition;
        this.setState({ trims: trimOptions, search }, () => {
          const { vinDecode } = this.state;
          if (vinDecode) {
            if (trimOptions && trimOptions.length !== 0) {
              const { vehicleInfo } = this.state;
              // set rest of search criteria
              search.trim = vehicleInfo.trim;
              search.engineSize = vehicleInfo.engineSize;
              search.engineType = vehicleInfo.engineType;
              search.engineSizeType = vehicleInfo.engineSize.concat(
                "|",
                vehicleInfo.engineType
              );
              search.driveType = vehicleInfo.driveType;
              search.transmissionType = vehicleInfo.transmissionType;

              search.trimValue = vehicleInfo.trimValue;
              search.engineSizeValue = vehicleInfo.engineSizeValue;
              search.engineTypeValue = vehicleInfo.engineTypeValue;
              search.engineSizeTypeValue = vehicleInfo.engineSizeValue.concat(
                "|",
                vehicleInfo.engineTypeValue
              );
              search.driveTypeValue = vehicleInfo.driveTypeValue;
              search.transmissionTypeValue = vehicleInfo.transmissionTypeValue;

              search.drivingCondition = vehicleInfo.drivingCondition
                ? vehicleInfo.drivingCondition
                : "";
              search.defaultDrivingCondition =
                vehicleInfo.defaultDrivingCondition;
              // set rest of search options
              const engineSizeTypes = this.loadEngineSizeTypes(search.trim);
              const driveTypes = this.loadDriveTypes(
                search.engineSizeType,
                engineSizeTypes
              );
              const transmissionTypes = this.loadTransmissionTypes(
                search.driveType,
                driveTypes
              );
              this.loadDrivingConditions(
                search.transmissionType,
                transmissionTypes
              );
              this.setState({ search });
            }
          } else if (trimOptions && trimOptions.length === 1) {
            this.onTrimChange({
              target: { value: trimOptions }
            });
          } else {
            this.handleNoTrim();
          }
        });
      },
      error => {
        toast.error(error.message);
      }
    );
  }
  handleNoTrim() {
    const { search } = this.state;
    search.trim = "";
    search.engineSize = "";
    search.engineType = "";
    search.engineSizeType = "";
    search.driveType = "";
    search.transmissionType = "";
    search.trimValue = "";
    search.engineSizeValue = "";
    search.engineTypeValue = "";
    search.engineSizeTypeValue = "";
    search.driveTypeValue = "";
    search.transmissionTypeValue = "";
    search.drivingCondition = "";
    search.defaultDrivingCondition = "";
    // const transmissionTypes = "";
    // this.loadDrivingConditions(
    //   search.transmissionType,
    //   transmissionTypes
    // );
    this.setState({ search });
  }
  loadEngineSizeTypes = trim => {
    const { trims } = this.state;
    const trimList = trims.filter(t => {
      return t.data === trim;
    });
    if (trimList.length === 0) {
      this.setState({ engineSizeTypes: [] });
      return [];
    }
    const engineTypeList = trimList[0].engineTypes;
    const engineSizeTypes = [];
    engineTypeList.forEach(engineType => {
      const { engineSizes } = engineType;
      engineSizes.forEach(engineSize => {
        const label = `${engineSize.display}, ${engineType.display}`;
        const value = `${engineSize.data}|${engineType.data}`;
        engineSizeTypes.push({ label, value, engineType, engineSize });
      });
    });
    this.setState({ engineSizeTypes }, () => {
      if (engineSizeTypes && engineSizeTypes.length === 1) {
        this.onEngineSizeTypeChange({
          target: { value: engineSizeTypes }
        });
      }
    });
    return engineSizeTypes;
  };

  // loadEngineTypes = trim => {
  //   const { trims } = this.state;
  //   const trimList = trims.filter(t => {
  //     return t.data === trim;
  //   });
  //   const engineTypeList = trimList[0].engineTypes;
  //   const engineTypes = engineTypeList.map(engineType => {
  //     engineType.label = engineType.display;
  //     engineType.value = engineType.data;
  //     return engineType;
  //   });
  //   this.setState({ engineTypes });
  // };

  // loadEngineSizes = engineType => {
  //   const { engineTypes } = this.state;
  //   const engineTypeList = engineTypes.filter(t => {
  //     return t.data === engineType;
  //   });
  //   const engineSizeList = engineTypeList[0].engineSizes;
  //   const engineSizes = engineSizeList.map(engineSize => {
  //     engineSize.label = engineSize.display;
  //     engineSize.value = engineSize.data;
  //     return engineSize;
  //   });
  //   this.setState({ engineSizes });
  // };

  loadDriveTypes = (engineSizeTypeValue, engineSizeTypes) => {
    const sizeAndType = engineSizeTypeValue.split("|");
    const engineSizeValue = sizeAndType[0];
    const engineTypeValue = sizeAndType[1];
    // const { engineSizeTypes } = this.state;
    const engineSizeTypeList = engineSizeTypes.filter(t => {
      return (
        t.engineSize.data === engineSizeValue &&
        t.engineType.data === engineTypeValue
      );
    });
    if (engineSizeTypeList.length === 0) {
      this.setState({ driveTypes: [] });
      return [];
    }
    const driveTypeList = engineSizeTypeList[0].engineSize.driveTypes;
    const driveTypes = driveTypeList.map(driveType => {
      driveType.label = driveType.display;
      driveType.value = driveType.data;
      return driveType;
    });
    this.setState({ driveTypes }, () => {
      if (driveTypes && driveTypes.length === 1) {
        this.onDriveTypeChange({ target: { value: driveTypes } });
      }
    });
    return driveTypes;
  };

  loadTransmissionTypes = (driveTypeValue, driveTypes) => {
    // const { driveTypes } = this.state;
    const driveTypeList = driveTypes.filter(t => {
      return t.data === driveTypeValue;
    });
    if (driveTypeList.length === 0) {
      this.setState({ transmissionTypes: [] });
      return [];
    }
    const transmissionTypeList = driveTypeList[0].transmissionTypes;
    const transmissionTypes = transmissionTypeList.map(transmissionType => {
      transmissionType.label = transmissionType.display;
      transmissionType.value = transmissionType.data;
      return transmissionType;
    });
    this.setState({ transmissionTypes }, () => {
      if (transmissionTypes && transmissionTypes.length === 1) {
        this.onTransmissionTypeChange({
          target: { value: transmissionTypes }
        });
      }
    });
    return transmissionTypes;
  };

  loadCOUserSetting = search => {
    const { userName } = this.context;
    const params = { username: userName };
    const restEndPoint = "/ops/proxyapi/ddsproxy/rest/proc/getCOUserSettings";
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "get",
        params,
        data: {}
      },
      response => {
        // console.log("saveCOUserSetting", response);
      },
      error => {
        toast.error(error.message);
      }
    );
  };

  loadDrivingConditions = (transmissionTypeValue, transmissionTypes) => {
    // const { transmissionTypes } = this.state;
    const transmissionTypeList = transmissionTypes.filter(t => {
      return t.data === transmissionTypeValue;
    });
    if (transmissionTypeList.length === 0) {
      this.setState({ drivingConditions: [] });
      return [];
    }
    const drivingConditionList =
      transmissionTypeList && transmissionTypeList.length !== 0
        ? transmissionTypeList[0].drivingConditions
          ? transmissionTypeList[0].drivingConditions
          : []
        : [];
    const drivingConditions = drivingConditionList.map(drivingCondition => {
      drivingCondition.value = drivingCondition.data;
      return drivingCondition;
    });
    this.setState({ drivingConditions }, () => {
      if (drivingConditions && drivingConditions.length === 1) {
        this.onDrivingConditionChange({
          target: { value: drivingConditions }
        });
      }
    });
    return drivingConditions;
  };

  updatePreviewSettings = () => {
    // update selectors
    const { updatePreviewSettings } = this.context.appContext;
    const {
      search,
      supportedMakes,
      models,
      years,
      trims,
      engineSizeTypes,
      driveTypes,
      transmissionTypes,
      drivingConditions
    } = this.state;
    const selectors = {
      makes: supportedMakes,
      models,
      years,
      trims,
      engineSizeTypes,
      driveTypes,
      transmissionTypes,
      drivingConditions
    };
    // make a clone of search since it would be set into context.appContext.preview object as well
    const searchCopy = Object.assign({}, search);
    const previewSettings = { search: searchCopy, selectors };
    updatePreviewSettings(previewSettings);
  };

  resetVehicleSelector = () => {
    this.resetSearchList();
    const { selectedMake } = this.state;
    selectedMake.make = "";
    selectedMake.variant = "";
    this.setState({ selectedMake });
  };

  resetSearchList() {
    this.setState({
      showResult: false,
      warning: "",
      search: Object.assign({}, SearchFieldTemplate),
      makes: [],
      years: [],
      models: [],
      trims: [],
      engineSizeTypes: [],
      // engineTypes: [],
      // engineSizes: [],
      driveTypes: [],
      transmissionTypes: [],
      drivingConditions: []
    });
  }

  resetSearchListByMake() {
    const { search } = this.state;
    search.year =
      search.model =
      search.trim =
      search.engineSizeType =
      search.driveType =
      search.transmissionType =
        "";
    search.modelValue =
      search.trimValue =
      search.engineSizeTypeValue =
      search.driveTypeValue =
      search.transmissionTypeValue =
        "";
    this.setState({
      showResult: false,
      search,
      years: [],
      models: [],
      trims: [],
      engineSizeTypes: [],
      engineTypes: [],
      engineSizes: [],
      driveTypes: [],
      transmissionTypes: [],
      drivingConditions: []
    });
  }

  resetSearchListByYear() {
    const { search } = this.state;
    search.model =
      search.trim =
      search.engineSizeType =
      search.driveType =
      search.transmissionType =
        "";
    search.modelValue =
      search.trimValue =
      search.engineSizeTypeValue =
      search.driveTypeValue =
      search.transmissionTypeValue =
        "";
    this.setState({
      showResult: false,
      search,
      // years: [],
      models: [],
      trims: [],
      engineSizeTypes: [],
      engineTypes: [],
      engineSizes: [],
      driveTypes: [],
      transmissionTypes: [],
      drivingConditions: []
    });
  }

  resetSearchListByModel() {
    const { search } = this.state;
    search.trim =
      search.engineSizeType =
      search.driveType =
      search.transmissionType =
        "";
    search.trimValue =
      search.engineSizeTypeValue =
      search.driveTypeValue =
      search.transmissionTypeValue =
        "";
    search.engineSizeValue = search.engineTypeValue = "";
    this.setState({
      showResult: false,
      search,
      // years: [],
      // models: [],
      trims: [],
      engineSizeTypes: [],
      engineTypes: [],
      engineSizes: [],
      driveTypes: [],
      transmissionTypes: [],
      drivingConditions: []
    });
  }

  resetSearchListByTrim() {
    const { search } = this.state;
    search.engineSizeType = search.driveType = search.transmissionType = "";
    search.engineSizeTypeValue =
      search.driveTypeValue =
      search.transmissionTypeValue =
        "";
    search.engineSizeValue = search.engineTypeValue = "";
    this.setState({
      showResult: false,
      search,
      // years: [],
      // models: [],
      // trims: [],
      engineSizeTypes: [],
      engineTypes: [],
      engineSizes: [],
      driveTypes: [],
      transmissionTypes: [],
      drivingConditions: []
    });
  }

  resetSearchListByEnginieSizeType() {
    const { search } = this.state;
    search.driveType = search.transmissionType = "";
    search.driveTypeValue = search.transmissionTypeValue = "";
    this.setState({
      showResult: false,
      search,
      // years: [],
      // models: [],
      // trims: [],
      // engineSizeTypes: [],
      // engineTypes: [],
      // engineSizes: [],
      driveTypes: [],
      transmissionTypes: [],
      drivingConditions: []
    });
  }

  resetSearchListByDriveType() {
    const { search } = this.state;
    search.transmissionType = search.transmissionTypeValue = "";
    this.setState({
      showResult: false,
      search,
      // years: [],
      // models: [],
      // trims: [],
      // engineSizeTypes: [],
      // engineTypes: [],
      // engineSizes: [],
      // driveTypes: [],
      transmissionTypes: [],
      drivingConditions: []
    });
  }
  /* Dispatch handler used for driving condition change, publish vs unpublish toggle switch */
  triggerSearch = event => {
    event.stopPropagation();
    const { drivingCondition, isPreview } = event.detail;
    // console.log(
    //   "trigger search action",
    //   this.context.appContext.preview,
    //   drivingCondition,
    //   isPreview
    // );
    const { search } = this.context.appContext.preview;
    if (!isEmpty(search)) {
      // const localSearch = Object.assign({}, search);
      search.drivingCondition = drivingCondition;
      search.isPreview = isPreview;
      this.setState(
        {
          search
        },
        () => {
          /* Trigger search handler */
          setTimeout(() => {
            this.onSearchAction(event);
          }, 300);
        }
      );
    }
  };

  render() {
    let searchContent = null;
    const { isCappedPricing } = this.state;
    // const clsHide = !isCappedPricing ? "hide" : "";

    const {
      // isMakeLoaded,
      // showResult,
      // data,
      supportedMakes,
      years,
      models,
      trims,
      engineSizeTypes,
      driveTypes,
      transmissionTypes,
      errors,
      warning
    } = this.state;
    const {
      make,
      model,
      modelValue,
      year,
      trim,
      trimValue,
      engineSizeType,
      engineSizeTypeValue,
      // engineSize,
      driveType,
      driveTypeValue,
      transmissionType,
      transmissionTypeValue
      // drivingCondition
    } = this.state.search;

    const warningClass = warning ? "xmm-warning-box" : "hide";

    const disablePreviewButton =
      make !== "OTHER" && (make === "" || year === "" || model === "");
    // model === "" ||
    // trim === "" ||
    // engineSizeType === "" ||
    // driveType === "" ||
    // transmissionType === "";

    /* selectors block */
    searchContent = (
      <div>
        <Row>
          <Col xs={3} md={3} className="small-separation">
            <SearchableSelect
              htmlId="makeSelect"
              name="make"
              label={this.makeLabel}
              placeholder={this.selectLabel}
              enableMultiSelect={false}
              maxHeight={200}
              value={make}
              options={supportedMakes}
              onBlur={this.onBlur}
              onChange={this.onMakeChange}
            />
          </Col>
          <Col xs={2} md={2} className="small-separation">
            <SearchableSelect
              htmlId="SelectInputYear"
              name="year"
              label={this.yearLabel}
              placeholder={this.selectLabel}
              disabled={years.length === 0}
              enableMultiSelect={false}
              maxHeight={200}
              value={year}
              options={years}
              onBlur={this.onBlur}
              onChange={this.onYearChange}
            />
          </Col>
          <Col xs={3} md={3} className="small-separation">
            <SearchableSelect
              htmlId="SelectInputModel"
              name="model"
              label={this.modelLabel}
              placeholder={this.selectLabel}
              disabled={models.length === 0}
              enableMultiSelect={false}
              maxHeight={200}
              value={modelValue}
              options={models}
              onBlur={this.onBlur}
              onChange={this.onModelChange}
            />
          </Col>
          <Col xs={4} md={4} className="small-separation">
            <SearchableSelect
              htmlId="SelectInputTrim"
              name="trim"
              label={this.trimLabel}
              placeholder={this.selectLabel}
              disabled={trims.length === 0}
              enableMultiSelect={false}
              maxHeight={200}
              value={trimValue}
              options={trims}
              onBlur={this.onBlur}
              onChange={this.onTrimChange}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={3} md={3} className="small-separation">
            <SearchableSelect
              htmlId="SelectInputEngineSizeType"
              name="engineSizeType"
              label={this.engineLabel}
              placeholder={this.selectLabel}
              disabled={engineSizeTypes.length === 0}
              enableMultiSelect={false}
              maxHeight={200}
              value={engineSizeTypeValue}
              options={engineSizeTypes}
              onBlur={this.onBlur}
              onChange={this.onEngineSizeTypeChange}
            />
          </Col>
          <Col xs={2} md={2} className="small-separation">
            <SearchableSelect
              htmlId="SelectInputDriveType"
              name="driveType"
              label={this.driveLabel}
              placeholder={this.selectLabel}
              disabled={driveTypes.length === 0}
              enableMultiSelect={false}
              maxHeight={200}
              value={driveTypeValue}
              options={driveTypes}
              onBlur={this.onBlur}
              onChange={this.onDriveTypeChange}
            />
          </Col>
          <Col xs={3} md={3} className="small-separation">
            <SearchableSelect
              htmlId="SelectInputTransType"
              name="transmissionType"
              label={this.transmissionLabel}
              placeholder={this.selectLabel}
              disabled={transmissionTypes.length === 0}
              enableMultiSelect={false}
              maxHeight={200}
              value={transmissionTypeValue}
              options={transmissionTypes}
              onBlur={this.onBlur}
              onChange={this.onTransmissionTypeChange}
            />
          </Col>
        </Row>
      </div>
    );
    const vinSection = (
      <div>
        <Col xs={4} md={4} className="full-col">
          <TextInput
            displayLabel
            htmlId="VinNumberInput"
            label={this.decodeVinOptionalLabel}
            minLength={0}
            maxLength={17}
            name="vin"
            onBlur={this.onVinBlur}
            onChange={this.onVinChange}
            value={this.state.vin}
            error={errors.vin}
            appendChild={
              <Button
                htmlId="vinInputButton"
                buttonStyle="secondary"
                onClick={this.onVinDecode}
              >
                {this.decodeLabel}
              </Button>
            }
          />
          <div className={warningClass}>{warning}</div>
        </Col>
        <Col xs={4} md={4} className="full-col" style={{ marginLeft: "10px" }}>
          <div
            id="textInputDefault-inputWrapper"
            className={
              !isCappedPricing
                ? "hide"
                : "form-group input-wrapper textInput xmm_registered_date"
            }
          >
            <div>
              <label
                id="textInputDefault-label"
                className="textInput-label control-label"
              >
                {this.registrationDateLabel}
              </label>
            </div>
            <DatePicker
              name="regDate"
              className={
                !isCappedPricing
                  ? "hide"
                  : "textInputDefault-textInput form-control"
              }
              selected={this.state.regDate}
              onChange={this.onDateChange}
              onChangeRaw={e => e.preventDefault()}
              dateFormat={this.dateFieldFormat}
              maxDate={new Date()}
              placeholderText={this.selectDateLabel}
            />
          </div>
        </Col>
      </div>
    );
    const classHide = this.props.showCancel
      ? "xmm-string-link float-right"
      : "hide";
    return (
      <React.Fragment>
        <div className="xmm-box-padding">
          <Grid htmlId="SelectorPane">
            <Row>
              <Col xs={12} md={12} className="full-col">
                {vinSection}
                <Col xs={12} md={12} className="full-col">
                  <hr />
                </Col>
              </Col>
            </Row>
            <Row>{searchContent}</Row>
            <Row>
              <Col xs={12} md={12} className="full-col xmm-header-right">
                <Button
                  className="float-right"
                  htmlId="SearchBtn"
                  buttonStyle="primary"
                  disabled={disablePreviewButton}
                  onClick={this.onSearchAction}
                >
                  {this.previewVehicleLabel}
                </Button>
                <Button
                  className={classHide}
                  htmlId="CancelBtn"
                  buttonStyle="link"
                  onClick={this.props.closeModal}
                >
                  {this.cancelLabel}
                </Button>
              </Col>
            </Row>
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}
export default VehicleSelector;

VehicleSelector.propTypes = {
  // selectedIndex: PropTypes.number,
  renderFrom: PropTypes.string,
  showCancel: PropTypes.bool,
  closeModal: PropTypes.func
};

// VehicleSelector.defaultProps = {
//   selectedIndex: 0,
//   renderFrom: "changeVehicle",
//   showCancel: false,
//   closeModal: null
// };

function isAlphaNumeric(value) {
  const regex = /^[a-zA-Z0-9]([a-zA-Z0-9*]){0,}$/i;
  if (value && !regex.test(value)) {
    return false;
  }
  return true;
}
/* eslint-enable no-console */
