/* eslint-disable no-console */
import * as gtag from "./gtag-manager";

/* Call this method in the page to capture any events */
export function gtmTrackEvent(key) {
  const tagObj = gtmEvents[key];
  gtag.trackEvent(tagObj);
}

/* Call this method in the page to capture any events with dynamic params */
export function gtmTrackEventWithParams(key, params) {
  const tagObj = gtmEvents[key];
  // Pass dynamic strings to capture in GTM
  if (params && Object.keys(params).length > 0) {
    tagObj.eventResult = params.eventResult;
    tagObj.eventFeatures = params.eventFeatures;
    tagObj.eventFeature = params.eventFeature;
    tagObj.trackPageUrl = params.trackPageUrl;
  }
  gtag.trackEvent(tagObj);
  printEvent(key);
}
// @params {key} eg: "xmm.operations.add_operation_click"
export function getTagModule(key) {
  return gtmEvents[key];
}

function printEvent(key) {
  console.log(key, gtmEvents[key]);
}

/* primary method called in App.js to track live vs stages catalogs when switched */
export function trackCatalogs(type) {
  if (type === "live") {
    const params = {
      eventResult: "Launch Live catalogs",
      eventFeature: "Common-ops> Live Catalogs",
      eventFeatures: "application> Manage Catalogs> Live",
      trackPageUrl: "common-catalog/manage-catalogs/live"
    };
    gtmTrackEventWithParams("xmm.common.manage_catalogs_click", params);
  } else if (type === "staging") {
    const params = {
      eventResult: "Launch Staged catalogs",
      eventFeature: "Common-ops> Staged Catalogs",
      eventFeatures: "application> Manage Catalogs> Staged",
      trackPageUrl: "common-catalog/manage-catalogs/staged"
    };
    gtmTrackEventWithParams("xmm.common.manage_catalogs_click", params);
  }
}
/* primary method called in App.js to track catalog mgr launched from which parent application  */
export function trackLaunchHub(type) {
  let appName = null;
  let rootPage = null;
  if (!type) {
    appName = "DealerPortal";
    rootPage = "dealer-portal";
  } else if (type === "catalogadmin") {
    appName = "CatalogAdmin";
    rootPage = "catalog-admin";
  } else if (type === "inspect") {
    appName = "Inspect";
    rootPage = "inspect";
  }
  if (appName && rootPage) {
    const params = {
      eventResult: "Launch Catalog Manager from " + appName,
      eventFeature: appName + " > Launch Catalog Manager",
      eventFeatures: appName + " > Catalog Manager",
      trackPageUrl: rootPage + "/catalog-manager"
    };
    gtmTrackEventWithParams("xmm.common.launch_app_click", params);
  }
}
/**
 * For Common-ops, Track sub-pages using GTM track type "pageview"
 * hits are logged in GA (under realtime content, behavior/ site content)
 */
export function trackGtagPageview(pagePath) {
  const eventobj = {
    event: "virtualPageview",
    eventPathname: pagePath,
    trackPageUrl: pagePath
  };
  gtag.trackPage(eventobj);
}

// Listout all events { btn clicks etc } under single Object to access within Application
export const gtmEvents = Object.freeze({
  "xmm.common.launch_app_click": {
    event: "userEvent",
    eventElement: "Launch Catalog Manager button",
    eventAction: "Clicked",
    eventResult: "Launch Catalog Manager from DealerPortal",
    eventFeature: "DealerPortal> Launch Catalog Manager",
    eventFeatures: "DealerPortal> Catalog Manager",
    eventValue: undefined,
    trackPageUrl: "dealer-portal/catalog-manager"
  },
  "xmm.common.manage_catalogs_click": {
    event: "userEvent",
    eventElement: "Manage Catalogs Toggle",
    eventAction: "Clicked",
    eventResult: "Launch Live catalogs",
    eventFeature: "Common-ops> Live Catalogs",
    eventFeatures: "common-catalog> Manage Catalogs toggle",
    eventValue: undefined,
    trackPageUrl: "common-catalog/manage-catalogs/live"
  },
  "xmm.operations.add_operation_click": {
    event: "userEvent",
    eventElement: "Add Operation Button",
    eventAction: "Clicked",
    eventResult: "Render New Operation form",
    eventFeature: "Operations> Operations Page",
    eventFeatures: "operations page> Add Operation",
    eventValue: undefined,
    trackPageUrl: "operations/operations/add-operation"
  },
  "xmm.operations.proceed_delete_operations_click": {
    event: "userEvent",
    eventElement: "Proceed Button",
    eventAction: "Clicked",
    eventResult: "Delete Operations",
    eventFeature: "Operations> Operations Page",
    eventFeatures: "operations page> Delete Operations",
    eventValue: undefined,
    trackPageUrl: "operations/operations/delete-operations"
  },

  "xmm.operations.apply_bulk_edit_click": {
    event: "userEvent",
    eventElement: "Apply Edit Button",
    eventAction: "Clicked",
    eventResult: "Apply Bulk Edit",
    eventFeature: "Operations> Operations Page",
    eventFeatures: "operations page> BulkEdit",
    eventValue: undefined,
    trackPageUrl: "operations/operations/bulk-edit"
  },
  "xmm.operations.proceed_import_click": {
    event: "userEvent",
    eventElement: "Proceed Button",
    eventAction: "Clicked",
    eventResult: "Import Operations",
    eventFeature: "Operations> Operations Page",
    eventFeatures: "operations page> ImportOperations",
    eventValue: undefined,
    trackPageUrl: "operations/operations/import-operations"
  },
  "xmm.operations.proceed_export_click": {
    event: "userEvent",
    eventElement: "Proceed Button",
    eventAction: "Clicked",
    eventResult: "Export Operations",
    eventFeature: "Operations> Operations Page",
    eventFeatures: "operations page> ExportOperations",
    eventValue: undefined,
    trackPageUrl: "operations/operations/export-operations"
  },
  "xmm.operations.preview_tab_click": {
    event: "userEvent",
    eventElement: "Preview Tab",
    eventAction: "Clicked",
    eventResult: "Preview Operations",
    eventFeature: "Operations> Operations Page> Preview",
    eventFeatures: "operations page> Preview",
    eventValue: undefined,
    trackPageUrl: "operations/operations/preview"
  },
  "xmm.operations.save_operation_click": {
    event: "userEvent",
    eventElement: "Save Operation Button",
    eventAction: "Clicked",
    eventResult: "Save Operation ",
    eventFeature: "Operations> Operations Page",
    eventFeatures: "operations page> Single Operation> Save",
    eventValue: undefined,
    trackPageUrl: "operations/operations/single-operation/save"
  },
  "xmm.operations.edit_hours_click": {
    event: "userEvent",
    eventElement: "Edit Hours Button",
    eventAction: "Clicked",
    eventResult: "Open Store Hours modal for Operation ",
    eventFeature: "Operations> Operations Page",
    eventFeatures: "operations page> Single Operation> Edit Hours",
    eventValue: undefined,
    trackPageUrl: "operations/operations/single-operation/edit-hours"
  },
  "xmm.common.findopcode_click": {
    event: "userEvent",
    eventElement: "Find Opcode Button",
    eventAction: "Clicked",
    eventResult: "Select an Op Code from DMS Search tool",
    eventFeature: "Operations> Operations Page",
    eventFeatures: "operations page> Find Opcode",
    eventValue: undefined,
    trackPageUrl: "operations/operations/find-opcode"
  },
  "xmm.declinedservice.save_click": {
    event: "userEvent",
    eventElement: "Save Button",
    eventAction: "Clicked",
    eventResult: "Save Declined service",
    eventFeature: "Operations> IntegratedOperations Page",
    eventFeatures: "integrated operations> Save",
    eventValue: undefined,
    trackPageUrl: "operations/integrated-operations/save"
  },
  "xmm.opcodevalidation.validate_opcode_save_click": {
    event: "userEvent",
    eventElement: "Validate Button",
    eventAction: "Clicked",
    eventResult: "Validate Opcode Mapping for selected Make",
    eventFeature: "Operations> Opcode validation Page",
    eventFeatures: "opcode validation page> Validate Opcode Mapping> Validate",
    eventValue: undefined,
    trackPageUrl:
      "operations/opcode-validation/validate-opcode-mapping/validate"
  },
  "xmm.opcodevalidation.manage_makes_click": {
    event: "userEvent",
    eventElement: "Manage Makes Button",
    eventAction: "Clicked",
    eventResult: "Manage Prioritize Op Codes for Dealer Supported Makes",
    eventFeature: "Operations> Opcode validation Page",
    eventFeatures:
      "opcode validation page> Validate Opcode Mapping> Manage Makes",
    eventValue: undefined,
    trackPageUrl:
      "operations/opcode-validation/validate-opcode-mapping/manage-makes"
  },
  "xmm.autopublish.enabled_cell_edit": {
    event: "userEvent",
    eventElement: "Cell edit",
    eventAction: "Clicked",
    eventResult: "Save Enabled value for selected Make",
    eventFeature: "Publish> Auto Publish Page",
    eventFeatures: "auto publish page> Save Enabled",
    eventValue: undefined,
    trackPageUrl: "publish/auto-publish/save/enabled"
  },
  "xmm.autopublish.delay_publish_edit": {
    event: "userEvent",
    eventElement: "Cell edit",
    eventAction: "Clicked",
    eventResult: "Save Delay Publish for selected Make",
    eventFeature: "Publish> Auto Publish Page",
    eventFeatures: "auto publish page> Save Delay Publish",
    eventValue: undefined,
    trackPageUrl: "publish/auto-publish/save/delay-publish"
  },
  "xmm.publish.submit_click": {
    event: "userEvent",
    eventElement: "Publish Button",
    eventAction: "Clicked",
    eventResult: "Manual Publish for selected makes",
    eventFeature: "Publish> Publish Page",
    eventFeatures: "publish page> Publish",
    eventValue: undefined,
    trackPageUrl: "publish/save-publish"
  },
  "xmm.publish.validate_opcodes_link": {
    event: "userEvent",
    eventElement: "Validate Opcodes Link",
    eventAction: "Clicked",
    eventResult: "Validate Opcodes from Publish page",
    eventFeature: "Publish> Publish Page",
    eventFeatures: "publish page> Validate Opcodes link",
    eventValue: undefined,
    trackPageUrl: "publish/validate-opcodes-link"
  },
  "xmm.intervals.add_click": {
    event: "userEvent",
    eventElement: "Add Interval Button",
    eventAction: "Clicked",
    eventResult: "Add Interval for selected make",
    eventFeature: "Maintenance Menus> Package Overrides Page",
    eventFeatures: "package overrides page> Manage Dealer Intervals> Add",
    eventValue: undefined,
    trackPageUrl:
      "maintenance-menus/package-overrides/manage-dealer-intervals/add"
  },
  "xmm.intervals.save_click": {
    event: "userEvent",
    eventElement: "Save Interval Button",
    eventAction: "Clicked",
    eventResult: "Save Interval for selected make",
    eventFeature: "Maintenance Menus> Package Overrides Page",
    eventFeatures: "package overrides page> Manage Dealer Intervals> Save",
    eventValue: undefined,
    trackPageUrl:
      "maintenance-menus/package-overrides/manage-dealer-intervals/save"
  },
  "xmm.intervals.delete_click": {
    event: "userEvent",
    eventElement: "Delete Interval Button",
    eventAction: "Clicked",
    eventResult: "Delete Interval for selected make",
    eventFeature: "Maintenance Menus> Package Overrides Page",
    eventFeatures: "package overrides page> Manage Dealer Intervals> Delete",
    eventValue: undefined,
    trackPageUrl:
      "maintenance-menus/package-overrides/manage-dealer-intervals/delete"
  },
  "xmm.intervals.copy_click": {
    event: "userEvent",
    eventElement: "Copy Button",
    eventAction: "Clicked",
    eventResult: "Copy Interval within Same Make",
    eventFeature: "Maintenance Menus> Package Overrides Page",
    eventFeatures: "package overrides page> Manage Dealer Intervals> Copy",
    eventValue: undefined,
    trackPageUrl:
      "maintenance-menus/package-overrides/manage-dealer-intervals/copy"
  },
  "xmm.intervals.copy_othermake_click": {
    event: "userEvent",
    eventElement: "Copy to Another Make Button",
    eventAction: "Clicked",
    eventResult: "Copy Interval to Another Make",
    eventFeature: "Maintenance Menus> Package Overrides Page",
    eventFeatures:
      "package overrides page> Manage Dealer Intervals> Copy to Another Make",
    eventValue: undefined,
    trackPageUrl:
      "maintenance-menus/package-overrides/manage-dealer-intervals/copy-to-another-make"
  },
  "xmm.vehiclegroups.add_click": {
    event: "userEvent",
    eventElement: "Add Vehicle Group Button",
    eventAction: "Clicked",
    eventResult: "Add Vehicle Group for selected make",
    eventFeature: "Operations> Operations Page",
    eventFeatures: "operations page> Manage Vehicle groups> Add",
    eventValue: undefined,
    trackPageUrl: "operations/manage-vehicle-groups/add"
  },
  "xmm.vehiclegroups.save_click": {
    event: "userEvent",
    eventElement: "Save Button",
    eventAction: "Clicked",
    eventResult: "Save Vehicle Group for selected make",
    eventFeature: "Operations> Operations Page",
    eventFeatures: "operations page> Manage Vehicle groups> Save",
    eventValue: undefined,
    trackPageUrl: "operations/manage-vehicle-groups/save"
  },
  "xmm.vehiclegroups.delete_click": {
    event: "userEvent",
    eventElement: "Delete Button",
    eventAction: "Clicked",
    eventResult: "Delete Vehicle Group for selected make",
    eventFeature: "Operations> Operations Page",
    eventFeatures: "operations page> Manage Vehicle groups> Delete",
    eventValue: undefined,
    trackPageUrl: "operations/manage-vehicle-groups/delete"
  },
  "xmm.vehiclegroups.show_vehicles_click": {
    event: "userEvent",
    eventElement: "Show Vehicles Button",
    eventAction: "Clicked",
    eventResult: "Show Vehicles for given criteria",
    eventFeature: "Operations> Operations Page",
    eventFeatures: "operations page> Manage Vehicle groups> Show Vehicles",
    eventValue: undefined,
    trackPageUrl: "operations/manage-vehicle-groups/show-vehicles"
  },
  "xmm.packages.add_override_click": {
    event: "userEvent",
    eventElement: "Add Override Button",
    eventAction: "Clicked",
    eventResult: "Render New Package Override form",
    eventFeature: "Maintenance Menus> Package Overrides Page",
    eventFeatures: "package overrides page> Add Override",
    eventValue: undefined,
    trackPageUrl: "maintenance-menus/package-overrides/add-override"
  },
  "xmm.packages.delete_packages_click": {
    event: "userEvent",
    eventElement: "Proceed Button",
    eventAction: "Clicked",
    eventResult: "Delete Packages",
    eventFeature: "Maintenance Menus> Package Overrides Page",
    eventFeatures: "package overrides page> Delete Packages",
    eventValue: undefined,
    trackPageUrl: "maintenance-menus/package-overrides/delete-packages"
  },
  "xmm.packages.bulk_edit_click": {
    event: "userEvent",
    eventElement: "Apply Edit Button",
    eventAction: "Clicked",
    eventResult: "Bulk Edit - MenuType",
    eventFeature: "Maintenance Menus> Package Overrides Page",
    eventFeatures: "package overrides page> BulkEdit> MenuType",
    eventValue: undefined,
    trackPageUrl: "maintenance-menus/package-overrides/bulk-edit/menu-type"
  },
  "xmm.packages.copy_overrides_different_menutype_click": {
    event: "userEvent",
    eventElement: "Copy Overrides to Different Menutype Button",
    eventAction: "Clicked",
    eventResult: "Bulk Copy Overrides to Different Menutype Button",
    eventFeature: "Maintenance Menus> Package Overrides Page",
    eventFeatures: "package overrides page> Bulk Copy Overrides",
    eventValue: undefined,
    trackPageUrl: "maintenance-menus/package-overrides/bulk-copy-overrides"
  },
  "xmm.packages.save_override_click": {
    event: "userEvent",
    eventElement: "Save Override Button",
    eventAction: "Clicked",
    eventResult: "Save Single Override ",
    eventFeature: "Maintenance Menus> Package Overrides Page",
    eventFeatures: "package overrides page> Single Override> Save",
    eventValue: undefined,
    trackPageUrl: "maintenance-menus/package-overrides/single-override/save"
  },
  "xmm.packages.delete_single_override_click": {
    event: "userEvent",
    eventElement: "Delete Button",
    eventAction: "Clicked",
    eventResult: "Delete Single Override",
    eventFeature: "Maintenance Menus> Package Overrides Page",
    eventFeatures: "package overrides page> Single Override> Delete",
    eventValue: undefined,
    trackPageUrl: "maintenance-menus/package-overrides/single-override/delete"
  },
  "xmm.packages.copy_single_override_click": {
    event: "userEvent",
    eventElement: "Copy Override Button",
    eventAction: "Clicked",
    eventResult: "Copy Single Override",
    eventFeature: "Maintenance Menus> Package Overrides Page",
    eventFeatures: "package overrides page> Single Override> Copy",
    eventValue: undefined,
    trackPageUrl: "maintenance-menus/package-overrides/single-override/copy"
  },
  "xmm.laborrates.add_rate_click": {
    event: "userEvent",
    eventElement: "Add Rate Button",
    eventAction: "Clicked",
    eventResult: "Save New Labor Rate",
    eventFeature: "Parts Labor> Labor Rates Page",
    eventFeatures: "labor rates page> Add Rate> Save",
    eventValue: undefined,
    trackPageUrl: "parts-labor/labor-rates/add-rate/save"
  },
  "xmm.laborrates.delete_rate_click": {
    event: "userEvent",
    eventElement: "Delete Button",
    eventAction: "Clicked",
    eventResult: "Delete Labor Rate",
    eventFeature: "Parts Labor> Labor Rates Page",
    eventFeatures: "labor rates page> Delete",
    eventValue: undefined,
    trackPageUrl: "parts-labor/labor-rates/delete"
  },
  "xmm.partspricing.bulk_edit_click": {
    event: "userEvent",
    eventElement: "Apply Button",
    eventAction: "Clicked",
    eventResult: "Bulk Edit - Parts Pricing",
    eventFeature: "Parts Labor> Parts Pricing Page",
    eventFeatures: "parts pricing page> Bulk Edit",
    eventValue: undefined,
    trackPageUrl: "parts-labor/parts-pricing/bulk-edit"
  },
  "xmm.partspricing.import_click": {
    event: "userEvent",
    eventElement: "Import DMS Parts Button",
    eventAction: "Clicked",
    eventResult: "Import DMS Parts Pricing",
    eventFeature: "Parts Labor> Parts Pricing Page",
    eventFeatures: "parts pricing page> Import DMS Parts",
    eventValue: undefined,
    trackPageUrl: "parts-labor/parts-pricing/import-dms-parts"
  },
  "xmm.fluidpricing.bulk_edit_click": {
    event: "userEvent",
    eventElement: "Apply Button",
    eventAction: "Clicked",
    eventResult: "Bulk Edit - Fluid Pricing",
    eventFeature: "Parts Labor> Fluid Pricing Page",
    eventFeatures: "fluid pricing page> Bulk Edit",
    eventValue: undefined,
    trackPageUrl: "parts-labor/fluid-pricing/bulk-edit"
  },
  "xmm.fluidpricing.import_click": {
    event: "userEvent",
    eventElement: "Import DMS Fluids Button",
    eventAction: "Clicked",
    eventResult: "Import DMS Fluids Pricing",
    eventFeature: "Parts Labor> Fluid Pricing Page",
    eventFeatures: "fluid pricing page> Import DMS Fluids",
    eventValue: undefined,
    trackPageUrl: "parts-labor/fluid-pricing/import-dms-fluids"
  },
  "xmm.preview.preview_vehicle_click": {
    event: "userEvent",
    eventElement: "Preview Vehicle Button",
    eventAction: "Clicked",
    eventResult: "Preview Menu packages at mileage points",
    eventFeature: "Preview Page",
    eventFeatures: "preview page> Preview Vehicle",
    eventValue: undefined,
    trackPageUrl: "preview/preview-vehice"
  },
  "xmm.preview.vin_decode_click": {
    event: "userEvent",
    eventElement: "Vin Decode Button",
    eventAction: "Clicked",
    eventResult: "Preview Menu packages using vin decode",
    eventFeature: "Preview Page",
    eventFeatures: "preview page> Vin Decode",
    eventValue: undefined,
    trackPageUrl: "preview/vin-decode"
  },
  "xmm.preview.unpublish_click": {
    event: "userEvent",
    eventElement: "Unpublish-Publish Toggle",
    eventAction: "Clicked",
    eventResult: "UnPublish Preview",
    eventFeature: "Preview Page",
    eventFeatures: "preview page> toggle> Unpublish",
    eventValue: undefined,
    trackPageUrl: "preview/unpublish"
  },
  "xmm.dealermenus.addrules_click": {
    event: "userEvent",
    eventElement: "Add Rules",
    eventAction: "Clicked",
    eventResult: "Add Rules",
    eventFeature: "Maintenance Menus> Dealer Menus Page",
    eventFeatures: "dealer menus page> Add Rules",
    eventValue: undefined,
    trackPageUrl: "maintenance-menus/dealer-menus/add-rules"
  },
  "xmm.dealermenus.deleterule_click": {
    event: "userEvent",
    eventElement: "Delete Rule",
    eventAction: "Clicked",
    eventResult: "Delete Rule",
    eventFeature: "Maintenance Menus> Dealer Menus Page ",
    eventFeatures: "dealer menus page> Delete",
    eventValue: undefined,
    trackPageUrl: "maintenance-menus/dealer-menus/delete"
  },
  "xmm.dealermenus.copytoanothermenutype_click": {
    event: "userEvent",
    eventElement: "Copy To Another Menu Type",
    eventAction: "Clicked",
    eventResult: "Copy To Another Menu Type",
    eventFeature: "Maintenance Menus> Dealer Menus Page",
    eventFeatures: "dealer menus page> Copy To Another Menu Type",
    eventValue: undefined,
    trackPageUrl: "maintenance-menus/dealer-menus/copy-to-another-menu-type"
  },
  "xmm.dealermenus.copytoanotheroperation_click": {
    event: "userEvent",
    eventElement: "Copy To Another Operation",
    eventAction: "Clicked",
    eventResult: "Copy To Another Operation",
    eventFeature: "Maintenance Menus> Dealer Menus Page",
    eventFeatures: "dealer menus page>Copy To Another Operation",
    eventValue: undefined,
    trackPageUrl: "maintenance-menus/dealer-menus/copy-to-another-operation"
  },
  "xmm.dealermenus.copytoanothervehiclegroup_click": {
    event: "userEvent",
    eventElement: "Copy To Another Vehicle Group",
    eventAction: "Clicked",
    eventResult: "Copy To Another Vehicle Group",
    eventFeature: "Maintenance Menus> Dealer Menus Page",
    eventFeatures: "dealer menus page> Copy To Another Vehicle Group",
    eventValue: undefined,
    trackPageUrl: "maintenance-menus/dealer-menus/copy-to-another-vehicle-group"
  },
  "xmm.dealermenus.copytoanotherinterval_click": {
    event: "userEvent",
    eventElement: "Copy To Another Interval",
    eventAction: "Clicked",
    eventResult: "Copy To Another Interval",
    eventFeature: "Maintenance Menus> Dealer Menus Page ",
    eventFeatures: "dealer menus page> Copy To Another Interval",
    eventValue: undefined,
    trackPageUrl: "maintenance-menus/dealer-menus/copy-to-another-interval"
  },
  "xmm.dealermenus.deleterules_click": {
    event: "userEvent",
    eventElement: "Delete Rules",
    eventAction: "Clicked",
    eventResult: "Delete Rules",
    eventFeature: "Maintenance Menus> Dealer Menus Page ",
    eventFeatures: "dealer menus page> Delete Rules",
    eventValue: undefined,
    trackPageUrl: "maintenance-menus/dealer-menus/delete-rules"
  },
  "xmm.dealermenus.apply_bulk_edit_click": {
    event: "userEvent",
    eventElement: "Apply Edit Button",
    eventAction: "Clicked",
    eventResult: "Apply Bulk Edit",
    eventFeature: "Maintenance Menus> Dealer Menus Page ",
    eventFeatures: "dealer menus page> BulkEdit",
    eventValue: undefined,
    trackPageUrl: "maintenance-menus/dealer-menus/bulk-edit"
  },

  "xmm.dealermenus.group_by_click": {
    event: "userEvent",
    eventElement: "Group by",
    eventAction: "Clicked",
    eventResult: "Group by",
    eventFeature: "Maintenance Menus> Dealer Menus Page ",
    eventFeatures: "dealer menus page> Group by",
    eventValue: undefined,
    trackPageUrl: "maintenance-menus/dealer-menus/group-by"
  },
  "xmm.operations.pricing&opcodes.delete_click": {
    event: "userEvent",
    eventElement: "Delete Button",
    eventAction: "Clicked",
    eventResult: "Delete Rule",
    eventFeature: "Operations> Pricing & Op code Tab ",
    eventFeatures: "pricing and op code tab > Delete",
    eventValue: undefined,
    trackPageUrl: "operations/pricing-&-op-codes-tab/delete"
  },
  "xmm.operations.pricing&opcodes.add_override_click": {
    event: "userEvent",
    eventElement: "Add Override Button",
    eventAction: "Clicked",
    eventResult: "Add Rule",
    eventFeature: "Operations> Pricing & Op code Tab ",
    eventFeatures: "pricing and op code tab > Add Override",
    eventValue: undefined,
    trackPageUrl: "operations/pricing-&-op-codes-tab/add-override"
  },
  "xmm.maintenancemenus.menutypes.copy_click": {
    event: "userEvent",
    eventElement: "Copy Button",
    eventAction: "Clicked",
    eventResult: "Copy Menu Type",
    eventFeature: "Maintenance Menus> Menu Types Page ",
    eventFeatures: "menu types page> Copy",
    eventValue: undefined,
    trackPageUrl: "maintenance-menus/menu-types/copy"
  },
  "xmm.maintenancemenus.menutypes.delete_click": {
    event: "userEvent",
    eventElement: "Delete Button",
    eventAction: "Clicked",
    eventResult: "Delete Menu Type",
    eventFeature: "Maintenance Menus> Menu Types Page ",
    eventFeatures: "menu types page> Delete",
    eventValue: undefined,
    trackPageUrl: "maintenance-menus/menu-types/delete"
  },
  "xmm.operations.copy_ops_same_make": {
    event: "userEvent",
    eventElement: "Copy Button",
    eventAction: "Clicked",
    eventResult: "Copy Operations with Same Make",
    eventFeature: "Operations> Operations Page",
    eventFeatures: "operations page> CopyOperations> SameMake",
    eventValue: undefined,
    trackPageUrl: "operations/operations/copy-operations-same-make"
  },
  "xmm.operations.copy_ops_diff_make": {
    event: "userEvent",
    eventElement: "Copy Button",
    eventAction: "Clicked",
    eventResult: "Copy Operations with Different Make",
    eventFeature: "Operations> Operations Page",
    eventFeatures: "operations page> CopyOperations> DifferentMake",
    eventValue: undefined,
    trackPageUrl: "operations/operations/copy-operations-different-make"
  }
});
