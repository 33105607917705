/* eslint-disable no-console */
import React from "react";
import PropTypes from "prop-types";
import LoadingIndicator from "@cx/ui/LoadingIndicator";
import "./body-mask.scss";
const BodyMask = ({ loadingText }) => {
  return (
    <div className="cmp-loading-mask">
      <div className="loading-label">{loadingText}</div>
      <LoadingIndicator htmlId="searchModalMask" size="large" />
    </div>
  );
};

export default BodyMask;

BodyMask.propTypes = {
  loadingText: PropTypes.string
};
BodyMask.defaultProps = {
  loadingText: "Loading"
};
