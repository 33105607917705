/* eslint-disable no-console */
import React from "react";
import Button from "@cx/ui/Button";
import ModalDialog from "@cx/ui/ModalDialog";
import { bool, PropTypes } from "prop-types";
import { injectIntl, intlShape } from "react-intl";

class DeleteFactoryOperationsWarning extends React.Component {
  constructor(props) {
    super(props);
    this.closeDeleteOperationsWarning =
      this.closeDeleteOperationsWarning.bind(this);
  }

  closeDeleteOperationsWarning = () => {
    // this.props.showDeleteOperationsWarning = false;
    this.props.closeWarning();
  };

  render() {
    const { intl } = this.props;
    const modalTitle = intl.formatMessage({
      defaultMessage: "Warning",
      id: "xmm.portal.common.warn_label"
    });

    return (
      <div>
        <ModalDialog
          htmlId="deleteOperationsWarning"
          className="sample-modal-dialog"
          show={this.props.showDeleteOperationsWarning}
          header={
            <ModalDialog.Title>
              {modalTitle} {"!"}
            </ModalDialog.Title>
          }
          footer={
            <Button
              htmlId="deleteFactoryOpsOKButton"
              onClick={this.closeDeleteOperationsWarning}
              text="OK Button"
              buttonStyle="secondary"
            >
              {intl.formatMessage({
                defaultMessage: "OK",
                id: "xmm.portal.common.ok_button"
              })}
            </Button>
          }
          onHide={this.closeDeleteOperationsWarning}
        >
          <div>
            <p>
              {intl.formatMessage({
                id: "xmm.portal.operations.delete_factory_warn_msg"
              })}
            </p>
          </div>
        </ModalDialog>
      </div>
    );
  }
}

export default injectIntl(DeleteFactoryOperationsWarning);

DeleteFactoryOperationsWarning.propTypes = {
  intl: intlShape.isRequired,
  showDeleteOperationsWarning: bool,
  closeWarning: PropTypes.func
};
