/* eslint-disable no-console */
import React, { Component } from "react";
import ButtonToolbar from "react-bootstrap/lib/ButtonToolbar";
import { PropTypes } from "prop-types";
import { AppContext } from "../../../../app-context";
import Grid from "@cx/ui/Grid";
import Col from "@cx/ui/Col";
import Row from "@cx/ui/Row";
import Button from "@cx/ui/Button";
import { makeSecureRestApi } from "../../../../../api/xmmAxios";
import { findRecord, doesEmpty } from "../../../../../commonUtil/utils/object";
import {
  isDifferentValue,
  toEmptyStringIfUndefined,
  convertToFloat
} from "../../../../../commonUtil/utils/string";
import { xlate } from "../../../../../commonUtil/i18n/locales";
import { AgGridReact } from "ag-grid-react";
import { loadAgGridLocale } from "../../../../../i18n/LocaleSender";

// import IconArrowDownward from "@cx/ui/Icons/IconArrowDownward";
import IconArrowUpward from "@cx/ui/Icons/IconArrowUpward";
import PriceEditor from "../../../../../commonUtil/editors/PriceEditor";
import { applyCustomKeyNavigation } from "../../../../../commonUtil/utils/keyNavigation";
import { priceFormatter } from "../../../../../commonUtil/utils/formatter";
import { FormattedMessage } from "react-intl";
import { toast } from "@cx/ui/Toast";
import Badge from "@cx/ui/Badge";
import ImportExportLaborGrid from "./ImportExportLaborGrid";

class LaborGridEditPage extends Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);
    this.onChange = this.onChange.bind(this);
    this.updateStatusBox = this.updateStatusBox.bind(this);

    this.onCellClickedEvent = this.onCellClickedEvent.bind(this);
    this.onCellValueChanged = this.onCellValueChanged.bind(this);
    this.onSaveLaborGrid = this.onSaveLaborGrid.bind(this);
    this.onCancelLaborGrid = this.onCancelLaborGrid.bind(this);

    this.initializeGridData = this.initializeGridData.bind(this);
    this.initializeLocaleValues();

    const laborGridData = this.initializeGridData();

    const { user, localeStrings } = context;
    const { userName } = user;
    const gridOptions = {
      pageTitle: this.pageTitle,
      userName,
      laborGridData,
      laborGridDataChanged: false,
      columnDefs: this.getColumnList(localeStrings),
      defaultColDef: {
        sortable: false,
        resizable: true,
        editable: false, // default disable editor
        // suppressColumnMoveAnimation: true,
        suppressMovable: true,
        enableRowGroup: false,
        // sortingOrder: ["asc", "desc", null],
        width: 120,
        autoHeight: true,
        filter: "agTextColumnFilter",
        filterParams: {
          suppressMiniFilter: false,
          buttons: ["clear"]
        },
        menuTabs: ["filterMenuTab"],
        headerComponentParams: {
          template: `
          <div class="ag-cell-label-container" role="presentation">
            <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
            <div ref="eLabel" class="ag-header-cell-label" role="presentation">
              <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
              <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
              <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>
              <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>
              <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>
            </div>
          </div>
          `
        },
        suppressKeyboardEvent: applyCustomKeyNavigation,
        floatingFilter: false, // true - enable column header filters
        suppressMenu: true, // disabled menu filters
        rowGroup: false
      },
      multiSortKey: "ctrl",
      components: {},
      frameworkComponents: {
        priceEditor: PriceEditor
      },
      enableBrowserTooltips: true,
      columnTypes: {
        numberColumn: {
          maxWidth: 130,
          minWidth: 130,
          filter: "agNumberColumnFilter",
          filterParams: {
            includeBlanksInEquals: false,
            includeBlanksInLessThan: false,
            includeBlanksInGreaterThan: false,
            buttons: ["clear"]
          }
        },
        nonEditableColumn: { editable: false },
        noFilterColumn: {
          width: 100,
          columnGroupShow: "open",
          filter: false
        }
      },
      localeText: loadAgGridLocale(localeStrings),
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
            toolPanelParams: {
              suppressPivots: true,
              suppressPivotMode: true,
              suppressValues: true,
              suppressRowGroups: true
            }
          }
        ],
        hiddenByDefault: false,
        showConfirmAddDefaultPayTypes: false
      }
    };
    this.state = gridOptions;
  }

  componentDidMount() {}

  initializeLocaleValues(localeStrings) {
    this.saveLabel = xlate("xmm.portal.common.save_button");
    // this.savingMsg = xlate("xmm.portal.common.saving");
    this.savedMsg = xlate("xmm.portal.common.import_file_saved");
    this.savedLaborMsg = xlate("xmm.portal.common.saved");
    this.showExportLbl = xlate("xmm.portal.labor_rates.show_export_lbl");
    this.infoExportLaborGrid = xlate(
      "xmm.portal.labor_rates.info_to_export_labor_grid"
    );
    this.selectFieldLbl = xlate("xmm.portal.labor_rates.lbl_select_field");
    this.importLaborGridLbl = xlate(
      "xmm.portal.labor_rates.lbl_import_labor_grid"
    );
    this.exportLaborGridLbl = xlate(
      "xmm.portal.labor_rates.lbl_export_labor_grid"
    );
  }
  onBlur = name => {
    const valid = this.validate(name);
    this.setState({ valid });
  };
  // changeEvent for textfield
  onChange = (event, isValid, domEvent) => {
    const { name, value } = event.target;
    if (domEvent && domEvent.type === "blur") {
      this.onBlur(name);
      return;
    }
    const { newPayType } = this.state;
    if (
      domEvent &&
      domEvent.type !== "blur" &&
      isDifferentValue(newPayType[name], value)
    ) {
      this.setState(
        prevState => {
          return {
            newPayType: {
              ...prevState.newPayType,
              [name]: value
            }
          };
        },
        () => {
          this.markDirty(name, true);
        }
      );
    }
  };
  // call this for each field change event
  markDirty(fieldName, validate) {
    const dirty = true;
    if (validate) {
      const valid = this.validate(fieldName);
      this.setState({ dirty, valid });
    } else {
      this.setState({ dirty });
    }
  }
  hasErrorStrings(state) {
    const array1 = Object.values(state);
    const iterator = array1.values();
    let errCount = 0;
    for (const value of iterator) {
      if (value === "" || value === null) {
        // In case of valid error string
      } else if (value && typeof value === "string") {
        errCount++;
      }
    }
    return errCount === 0 ? false : true;
  }
  getAddPayload() {
    return {};
  }
  // TODO - first validate duplicates for code, description per selected make; then proceed
  saveHandler = event => {
    this.updateStatusBox(this.savingMsg, "pending", false);
    const { errors } = this.state;
    const data = this.getAddPayload();
    // cancel save if we have errors on fields
    const url = "/ops/proxyapi/ddsproxy/rest/table/dealerPayType?_method=post";
    // const restEndPoint = "/ops/rest-db/addTableData/paytypes";
    makeSecureRestApi(
      {
        url,
        method: "post",
        data
      },
      response => {
        if (response && response.success) {
          this.updateStatusBox(this.savedMsg, "success", true);
          // callback grid to add record
          const { dealerPayType } = response;
          this.props.updatePayTypeAfterSave(dealerPayType);
          this.setState({ dirty: false, valid: false }, () => {
            setTimeout(() => {
              this.props.hideSlider();
            }, 800);
          });
        }
      },
      error => {
        const msg = error["message"] ? error.message : this.saveErrorMsg;
        this.updateStatusBox(msg, "error", false, true);
      }
    );
  };
  updateStatusBox(msg, type, close, errorInTooltip) {
    console.log("status", msg, type, close);
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
    sleep(0).then(() => {
      this.setState({
        statusMsg: msg,
        autoClose: close,
        statusType: type,
        errorInTooltip
      });
    });
  }

  getColumnList(localeStrings) {
    const laborGridColumns = [];
    laborGridColumns.push({
      headerName: "",
      headerClass: "ag-text-header",
      cellClass: "xmm-wrap-cell cell-bg-labor-grid-edit",
      field: "hours",
      // sortingOrder: ["asc", "desc"],
      minWidth: 45,
      maxWidth: 45,
      editable: false,
      filter: "agSetColumnFilter",
      suppressSizeToFit: true,
      filterParams: {
        buttons: ["clear"]
      },
      cellRenderer(params, index) {
        return params.rowIndex;
      }
    });
    for (let tenthsHours = 0; tenthsHours < 10; tenthsHours++) {
      const field = tenthsHours.toString();
      const headerName = "." + field;
      laborGridColumns.push({
        headerName,
        field,
        editable: true,
        headerClass: "ag-numeric-header",
        cellEditorSelector(params) {
          const { colDef, node } = params;
          const { field } = colDef;
          const { rowIndex } = node;
          if (field !== "0" || rowIndex !== 0) {
            return { component: "priceEditor" };
          }
          params.api.stopEditing();
          return null;
        },
        // cellEditorParams: {
        //   maxLength: 7,
        //   maxValue: 9999.99,
        //   allowDecimals: true,
        //   decimalPlaces: 2
        // },
        cellClass: localCellClass,
        cellStyle: { "text-align": "right" },
        valueFormatter: localPriceFormatter,
        type: "numberColumn",
        minWidth: 65,
        maxWidth: 100,
        width: 100
      });
    }
    return laborGridColumns;
  }
  getRowNodeId(data) {
    return data.id; // primary or unique key of record
  }
  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.closeToolPanel();
    this.loadGridData();
  };
  sizeToFit() {
    this.gridApi && this.gridApi.sizeColumnsToFit();
  }
  getSavePayload(laborGridData) {
    const data = {};
    laborGridData.forEach((currentValue, currentIndex) => {
      const keys = Object.keys(currentValue);
      if (keys.length !== 0) {
        const savedValue = {};
        keys.forEach(tenths => {
          const value = convertToFloat(currentValue[tenths]);
          if (!(isNaN(value) || !value)) {
            savedValue[tenths] = value;
          }
        });
        if (Object.keys(savedValue).length !== 0) {
          data[currentIndex.toString()] = savedValue;
        }
      }
    });
    return data;
  }
  onSaveLaborGrid = () => {
    const { dealerLaborRate } = this.props;
    const { laborGridId } = dealerLaborRate;
    const { laborGridData, userName } = this.state;
    if (!laborGridId) {
      this.createLaborGrid(dealerLaborRate, laborGridData, userName);
    } else {
      this.updateLaborGrid(dealerLaborRate, laborGridData, userName);
    }
  };
  createLaborGrid = (dealerLaborRate, laborGridData, userName) => {
    const data = this.getSavePayload(laborGridData);
    if (!data) {
      return;
    }
    const url = `/ops/proxyapi/oeproxy/rest/internal/xmmdealer/createLaborGrid?modUser=${userName}`;
    makeSecureRestApi(
      {
        url,
        method: "post",
        data
      },
      response => {
        if (response && response.success) {
          // callback grid to add record
          toast.success(this.savedLaborMsg);
          const { data } = response;
          dealerLaborRate.laborGridId = data;
          const gridParams = {
            data: dealerLaborRate,
            colDef: { field: "laborGridId" }
          };
          this.props.onSaveLaborGrid(gridParams);
        } else {
          toast.error("Error in creating labor grid...");
        }
      },
      error => {
        const msg = error["message"] ? error.message : this.saveErrorMsg;
        toast.error(msg);
      }
    );
  };
  updateLaborGrid = (dealerLaborRate, laborGridData, userName) => {
    const { laborGridId } = dealerLaborRate;
    const data = this.getSavePayload(laborGridData);
    if (!data) {
      return;
    }
    const url = `/ops/proxyapi/oeproxy/rest/internal/xmmdealer/updateLaborGrid/${laborGridId}?modUser=${userName}`;
    makeSecureRestApi(
      {
        url,
        method: "post",
        data
      },
      response => {
        if (response && response.success) {
          // callback grid to add record
          toast.success(this.savedLaborMsg);
          const { data } = response;
          this.props.onSaveLaborGrid();
        } else {
          toast.error("Error in updating labor grid...");
        }
      },
      error => {
        const msg = error["message"] ? error.message : this.saveErrorMsg;
        toast.error(msg);
        // this.updateStatusBox(msg, "error", false, true);
      }
    );
  };
  isLaborGridDataChanged() {
    return this.state.laborGridDataChanged;
  }
  onCancelLaborGrid = () => {
    this.props.onCancel(this.state.laborGridDataChanged);
  };
  initializeGridData() {
    const laborGridRows = [];
    for (let i = 0; i < 16; i++) {
      // const row = { id: "id" + i };
      // for (let j = 0; j < 10; j++) {
      //   row[j.toString()] = "";
      // }
      laborGridRows.push({});
    }
    return laborGridRows;
  }
  callRefreshAfterMillis(params, gridApi) {
    setTimeout(function () {
      gridApi.refreshCells(params);
    }, 300);
  }
  loadGridData() {
    const { laborGridId } = this.props.dealerLaborRate;
    if (!laborGridId) {
      const { laborGridData } = this.state;
      this.gridApi.setRowData(laborGridData);
      return;
    }
    makeSecureRestApi(
      {
        url: `/ops/proxyapi/internaloeproxy/rest/internal/xmmdealer/getLaborGrid/${laborGridId}`,
        method: "get"
      },
      data => {
        if (data && typeof data === "object") {
          const laborGridData = this.initializeGridData();
          const hours = Object.keys(data);
          hours.forEach(hr => {
            laborGridData[hr.toString()] = data[hr];
          });
          this.setState({ laborGridData });
          this.gridApi.setRowData(laborGridData);
        } else {
          toast.error("No Labor Grid data for laborGridId = " + laborGridId);
        }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "Error in loading Labor Grid..,";
        toast.error(msg);
      }
    );
  }
  onCellClickedEvent(params) {
    // do nothing for now
  }
  // This event fired after a cell has been changed with default editing
  onCellValueChanged(params) {
    const { colDef, data, newValue, oldValue } = params;
    if (
      toEmptyStringIfUndefined(oldValue) !== toEmptyStringIfUndefined(newValue)
    ) {
      this.setState({ laborGridDataChanged: true });
    }
  }
  setToastError = msg => {
    toast.error(msg);
  };

  renderSelectOperationAccordionHeader = () => {
    const { localeStrings } = this.context;
    const { totalOperationCount } = this.state;
    const title = this.showExportLbl;
    return (
      <div>
        <span>{title}</span>
        <Badge htmlId="badgeBlue" color="blue" className="pull-right">
          {totalOperationCount}
        </Badge>
      </div>
    );
  };
  updateImportLaborGrid = data => {
    const laborGridData = this.initializeGridData();
    const hours = Object.keys(data);
    hours.forEach(hr => {
      laborGridData[hr.toString()] = data[hr];
    });
    this.setState({ laborGridData });
    this.gridApi.setRowData(laborGridData);
    toast.success(this.savedMsg);
  };
  render() {
    let editSection = null;
    const msgSection = null;
    const { newPayType, valid, errors, supportedMakes, payTypeGroupOptions } =
      this.state;

    const isValid = valid;

    const gridWidget = (
      <div id="grid-wrapper">
        <div
          id="mainGrid"
          className="ag-grid-container ag-theme-balham"
          style={{
            height: "500px",
            width: "100%",
            marginTop: "10px"
          }}
        >
          <AgGridReact
            localeText={this.state.localeText}
            columnDefs={this.state.columnDefs}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            suppressMenuHide={false}
            suppressContextMenu={true}
            rowData={this.state.laborGridData}
            // rowSelection={this.state.rowSelection}
            rowDeselection={true}
            singleClickEdit={true}
            stopEditingWhenCellsLoseFocus={true}
            animateRows={true}
            onGridReady={this.onGridReady}
            onFirstDataRendered={() => {
              this.sizeToFit();
            }}
            onGridSizeChanged={this.handleGridSizeChanged}
            frameworkComponents={this.state.frameworkComponents}
            // loadingOverlayComponent={this.state.loadingOverlayComponent}
            // loadingOverlayComponentParams={
            //   this.state.loadingOverlayComponentParams
            // }
            // noRowsOverlayComponent={this.state.noRowsOverlayComponent}
            // noRowsOverlayComponentParams={
            //   this.state.noRowsOverlayComponentParams
            // }
            // statusBar={this.state.statusBar}
            components={this.state.components}
            onCellClicked={this.onCellClickedEvent}
            onCellValueChanged={this.onCellValueChanged}
            // onColumnResized={this.handleColumnResized}
            // getRowNodeId={this.getRowNodeId}
            // onRowSelected={this.handleRowSelected}
            // onSelectionChanged={this.handleSelectionChanged}
            // sideBar={this.state.sideBar}
            // columnTypes={this.state.columnTypes}
            // multiSortKey={this.state.multiSortKey}
            enableRangeSelection={true}
            enableCellTextSelection={true}
            enableBrowserTooltips={true}
            rowHeight={50}
          />
        </div>
      </div>
    );
    editSection = (
      <React.Fragment>
        <div>
          <Grid>
            <Row>
              <Col xs={12} md={12}>
                <div className="show-export-containerr">
                  <ImportExportLaborGrid
                    dealerLaborRate={this.props.dealerLaborRate}
                    userName={this.context.user.userName}
                    laborGridData={this.state.laborGridData}
                    updateImportLaborGrid={this.updateImportLaborGrid}
                    setToastError={this.setToastError}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12}>
                {gridWidget}
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12}>
                <ButtonToolbar style={{ marginTop: "10px" }}>
                  <Button
                    htmlId="saveButton"
                    className="float-right"
                    text="Save"
                    buttonStyle="primary"
                    disabled={!this.state.laborGridDataChanged}
                    onClick={this.onSaveLaborGrid}
                  >
                    <FormattedMessage
                      defaultMessage="Save"
                      id="xmm.portal.common.save_button"
                    />
                  </Button>
                  <Button
                    htmlId="cancelButton"
                    className="float-right"
                    text="Cancel"
                    buttonStyle="secondary"
                    onClick={this.onCancelLaborGrid}
                  >
                    <FormattedMessage
                      defaultMessage="Cancel"
                      id="xmm.portal.common.cancel_button"
                    />
                  </Button>
                </ButtonToolbar>
              </Col>
            </Row>
          </Grid>
        </div>
      </React.Fragment>
    );
    return <React.Fragment>{editSection}</React.Fragment>;
  }
}

export default LaborGridEditPage;

LaborGridEditPage.propTypes = {
  dealerLaborRate: PropTypes.object,
  onCancel: PropTypes.func,
  onSaveLaborGrid: PropTypes.func
};
/* eslint-enable no-console */

function localPriceFormatter(params) {
  const { colDef, node } = params;
  const { field } = colDef;
  const { rowIndex } = node;
  if (field === "0" && rowIndex === 0) {
    return "---";
  }
  return priceFormatter(params);
}

function localCellClass(params) {
  const { colDef, node } = params;
  const { field } = colDef;
  const { rowIndex } = node;
  if (field === "0" && rowIndex === 0) {
    return "editable-disabled-cell xmm-grid-price";
  }
  return "editable-cell xmm-grid-price";
}
